import React, { Component } from 'react';

import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor() {
		super();

		this.state = {
			portfolio_items: [],
			showModal: false,
			selectedItemIds: [],
		};

		this.deletePortfolioPic = this.deletePortfolioPic.bind(this);
		this.addPortfolioPic = this.addPortfolioPic.bind(this);
		this.swapImageOrders = this.swapImageOrders.bind(this);
	}

	sortPortfolioItems(items) {
		return items.sort((a, b) => {
			if (a.order < b.order) return -1;
			if (a.order > b.order) return 1;
			return 0;
		});
	}

	getDefaultOrderForNewPic() {
		const { portfolio_items } = this.state;
		// if no items yet, set the order to 1
		if (!portfolio_items.length) return 1;
		// find the highest order value from the existing portfolio items
		const highestOrderValue = Math.max.apply(Math, portfolio_items.map(item => item.order));
		// the new picture should default to last position
		return highestOrderValue + 1;
	}

	async loadPortfolioPics() {
		const { professional_id } = this.props;

		if (!professional_id) return;

		try {
			const result = await this.api.request('get', `/users/${professional_id}?load=portfolio_items`);

			if (!result.success) throw new Error(result.error);

			const { portfolio_items } = result.data;
			this.sortPortfolioItems(portfolio_items);

			this.setState({
				portfolio_items,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async deletePortfolioPic(item) {
		const { id, order } = item;
		const { portfolio_items, selectedItemIds } = this.state;
		const { professional_id } = this.props;
		try {
			const result = await this.api.request("delete", `/user_portfolio_items/${id}`);

			if (!result.success) throw new Error(result.error);

			// remove from portfolio_items array
			for (const [portfolioItemPosition, portfolioItem] of portfolio_items.entries()) {
				if (portfolioItem.id === id) {
					portfolio_items.splice(portfolioItemPosition, 1);
					break;
				}
			}

			// if in selectedItemIds, remove from selectedItemIds
			for (const [selectedItemIdPosition, selectedItemId] of selectedItemIds.entries()) {
				if (selectedItemId === id) {
					selectedItemIds.splice(selectedItemIdPosition, 1);
					break;
				}
			}

			// update the order of remaining portfolio_items
			for (const [portfolioItemPosition, portfolioItem] of portfolio_items.entries()) {
				if (portfolioItem.order > order) {
					const reOrderResult = await this.api.request(
						'put',
						`/user_portfolio_items/${portfolioItem.id}`, {
							order: portfolioItem.order - 1,
						},
					);
					if (!reOrderResult.success) throw new Error(reOrderResult.Error);
					portfolio_items[portfolioItemPosition].order = portfolioItem.order - 1;
				}
			}

			this.setState({
				portfolio_items,
				selectedItemIds,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async addPortfolioPic(base64) {
		const { professional_id } = this.props;
		const order = await this.getDefaultOrderForNewPic();

		try {
			// create portfolio item entry on the backend
			const createItemResult = await this.api.request(
				'post',
				'/user_portfolio_items', {
					user_id: professional_id,
					order,
				},
			);
			if (!createItemResult.success) throw new Error(createItemResult.error);

			const { id } = createItemResult.data;

			// update the db item with the image
			const updateImageResult = await this.api.request(
				'post',
				`/user_portfolio_items/${id}/image`, {
					image: base64.split(';base64,').pop(),
				},
			);

			if (!updateImageResult.success) {
				// delete the created item if image not successfully updated,
				// so we don't have random items with no image
				this.api.request("delete", `/user_portfolio_items/${id}`);
				throw new Error(updateImageResult.error);
			}

			this.setState({
				portfolio_items: [...this.state.portfolio_items, updateImageResult.data],
			});

			this.notification.success('Image successfully added!');
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async swapImageOrders() {
		if (this.state.selectedItemIds.length !== 2) return;
		const a = this.state.portfolio_items.find(item => item.id === this.state.selectedItemIds[0]);
		const b = this.state.portfolio_items.find(item => item.id === this.state.selectedItemIds[1]);
		try {
			const swapAResult = await this.api.request(
				'put',
				`/user_portfolio_items/${a.id}`, {
					order: b.order,
				},
			);
			if (!swapAResult.success) throw new Error(swapAResult.error.message);
			const swapBResult = await this.api.request(
				'put',
				`/user_portfolio_items/${b.id}`, {
					order: a.order,
				},
			);
			if (!swapBResult.success) throw new Error(swapAResult.error.message);
			this.loadPortfolioPics();
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}
