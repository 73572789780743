import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeView from './view';

import './style.less';

export default class NewHomeAppointment extends LeView {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.resetData();
		this.loadCustomers();
		this.loadProfessionals();
		this.loadServices();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.resetData();
		}
	}
}

NewHomeAppointment.propTypes = {
	onNewData: PropTypes.func.isRequired
}