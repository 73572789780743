import React, { Component } from 'react';

import TableModel from '../_TableModel';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class UsersTable extends TableModel {
	constructor() {
		super();

		this.className = "UsersTable";

		this.UNIQUE_INDEX = 1;

		this.titles = [{
			title: '',
			searchable: false,
			sortable: false,
			description: "Avatar"
		}, {
			title: 'ID',
			type: Number,
			searchable: true,
			sortable: true,
			description: "User ID"
		}, {
			title: 'Type',
			type: String,
			searchable: true,
			sortable: true,
			description: "User Type"
		}, {
			title: 'Name',
			type: String,
			searchable: true,
			sortable: true,
			description: "Name"
		}, {
			title: 'Email',
			type: String,
			searchable: true,
			sortable: true,
			description: "Email Address"
		}, {
			title: 'Phone',
			type: String,
			searchable: true,
			sortable: true,
			description: "Phone Number"
		}, {
			title: 'Description',
			type: String,
			searchable: true,
			sortable: true,
			description: "Description"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let user = datum;

			let columns = [
				<Avatar url={user.avatar} />,
				user.id,
				user.type,
				user.first_name + " " + user.last_name,
				user.email,
				user.phone_number,
				user.description
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/users/' + data[1];
	}
}