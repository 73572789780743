import React, { Component } from 'react';

import Moment from 'moment';

import TableModel from '../_TableModel';

import './style.less';

export default class VouchersTable extends TableModel {
	constructor() {
		super();

		this.className = "VouchersTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Voucher ID"
		}, {
			title: 'Code',
			type: String,
			sortable: true,
			searchable: true,
			description: "Code"
		}, {
			title: 'Amount',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Amount (in £)"
		}, {
			title: 'Percentage',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Percentage"
		}, {
			title: 'Customer Limit',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Customer Limit"
		}, {
			title: 'Global Limit',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Global Limit"
		}, {
			title: 'Channel',
			type: String,
			sortable: true,
			searchable: true,
			description: "Channel"
		}, {
			title: 'Expiry Date',
			type: Date,
			sortable: true,
			searchable: true,
			description: "Expiry Date"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let voucher = datum;

			let columns = [
				voucher.id,
				voucher.code,
				voucher.amount || null,
				voucher.percentage || null,
				voucher.customer_limit || null,
				voucher.global_limit || null,
				voucher.channel || null,
				voucher.expiry_date ? Moment(voucher.expiry_date).unix() : null
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/vouchers/' + data[0];
	}
}