import React, { Component, Fragment } from 'react';

import RRule from 'rrule';

import './style.less';
import Moment from 'moment';
import UserLabel from '../../labels/UserLabel';
import { Table, THead, TBody, TRow, TCol } from '../../tables/Generic';

export default class UserRecurrenceRulesCard extends Component {
	constructor() {
		super();

		this.state = {
			fancy_rules: [],
			showSchedules: [],
		}
	}

	componentWillReceiveProps() {
		let { recurrence_rules } = this.props;

		if (!recurrence_rules) recurrence_rules = []

		let fancy_rules = []
		for (const rule of recurrence_rules) {
			const { text, next_apps } = this.getRRule(rule)

			fancy_rules.push({
				appointment_id: rule.appointment_id,
				id: rule.id,
				text,
				next_apps,
			})
		}

		this.setState({
			fancy_rules
		})
	}

	toggleSchedule(id) {
		let { showSchedules } = this.state;

		if (showSchedules.includes(id)) {
			showSchedules = showSchedules.filter(x => x !== id)
		} else {
			showSchedules.push(id)
		}
		this.setState({
			showSchedules,
		})
	}

	startDeleteRule = id => {
		const result = window.confirm('sure?')

		result && this.props.deleteRule(id);
	}

	formatTime = (appointment) => {
		const { datetime, corporate_metadata: { duration } } = appointment;

		const start_time = Moment(datetime).clone().format('h:mma')

		const end_time = Moment(datetime).clone().add(duration, 'm').format('h:mma')

		return `${start_time} - ${end_time}`
	}

	getRRule = (rule) => {
		const { frequency, interval, week_day, nth_week_day, start_datetime, } = rule;

		let { end_date } = rule;

		const hour = Moment(start_datetime).format('h')
		const minute = Moment(start_datetime).format('m')

		let byweekday = null;
		if (week_day) {
			if (nth_week_day) {
				byweekday = RRule[week_day.slice(0, 2).toUpperCase()].nth(nth_week_day);
			} else {
				byweekday = RRule[week_day.slice(0, 2).toUpperCase()];
			}
		}

		const dtstart = Moment(start_datetime).utc().hour(hour).minute(minute).toDate();

		end_date = end_date ? Moment(end_date).endOf('day').utc().toDate() : null;


		try {
			const rule = new RRule({
				freq: RRule[frequency],
				interval,
				byweekday,
				dtstart,
				until: end_date,
			});

			const next_utc_appointments = rule.all((date, i) => { return i < 5; });

			const next_apps = next_utc_appointments.map(datetime => {
				const stripped = datetime.toISOString().replace(/Z/g, '');
				return Moment(stripped).format();
			});


			return {
				text: rule.toText(),
				next_apps,
			};
		} catch (error) {
			console.error('Ah shucks :(', error)
			return {}
		}

	}

	render() {
		const { recurrence_rules } = this.props;
		const { fancy_rules, showSchedules } = this.state;
		if (!recurrence_rules) return null;
		if (!fancy_rules.length) return null;

		return (
			<div className="Card RecurrenceRulesCard">
				{
					fancy_rules.map(rule => {

						const toggleClassNames = ['toggle']
						const datesClassNames = ["dates"]

						if (showSchedules.includes(rule.id)) {
							toggleClassNames.push('collapsed')
							datesClassNames.push("collapsed")
						}

						const appointment = recurrence_rules.find(r => r.appointment_id === rule.appointment_id).appointment;

						if (!appointment) return null;

						return (
							<Fragment key={rule.id}>
								<div className={toggleClassNames.join(" ")} onClick={() => this.toggleSchedule(rule.id)}>
									<p>
										E{rule.text.slice(1)}
									</p>
									<UserLabel user={appointment && appointment.professional} shortName />
								</div>

								<div className={datesClassNames.join(" ")}>
									<div>
										<Table className="split-view">
											<TBody>
												<TRow>
													<TCol>Customer</TCol>
													<TCol><UserLabel user={appointment.customer} /></TCol>
												</TRow>
												<TRow>
													<TCol>Location</TCol>
													<TCol>{appointment.location.address}</TCol>
												</TRow>
												<TRow>
													<TCol>Time</TCol>
													<TCol>{this.formatTime(appointment)}</TCol>
												</TRow>
												<TRow>
													<TCol>Salonette</TCol>
													<TCol>
														{
															appointment.professional
																? <UserLabel user={appointment.professional} />
																: <i>Any Salonette</i>
														}
													</TCol>
												</TRow>
												<TRow>
													<TCol>Attendees choose slots</TCol>
													<TCol>
														{
															appointment.corporate_metadata && appointment.corporate_metadata.flexible_slots
																? 'Yes'
																: 'No'
														}
													</TCol>
												</TRow>
												<TRow>
													<TCol>Vouchers</TCol>
													<TCol>
														{
															appointment.corporate_metadata && appointment.corporate_metadata.vouchers_allowed
																? 'Yes'
																: 'No'
														}
													</TCol>
												</TRow>
												<TRow>
													<TCol>Company Pays</TCol>
													<TCol>
														{
															appointment.corporate_metadata && appointment.corporate_metadata.company_pays
																? 'Yes'
																: 'No'
														}
													</TCol>
												</TRow>
											</TBody>
										</Table>
									</div>
									<div>
										<ul>
											<p>Next Appointments</p>
											{rule.next_apps.map(date => <li key={date} >{Moment(date).format('LLLL')}</li>)}
										</ul>
										<button onClick={() => this.startDeleteRule(rule.id)}>Delete</button>
									</div>
								</div>
							</Fragment>
						)
					})
				}
			</div >
		)
	}
}