import DataModel from '../../_DataModel';

export default class NewHomeAppointment extends DataModel {
	constructor() {
		super();

		this.state = {
			// user lists
			customers: [],
			professionals: [],

			// secondary information
			services: [],
			locations: [],

			customer_id: null,
			professional_id: null,
			location_id: null,
			date: null,
			start_time: null,
			basket: [],
			submitted: false,
			customer_cards: [],
			credit_card_id: null,
			selected_payment_source: null,
			payment_sources: ['TREATWELL', 'LESALON'],
		};

		this.available_dates = [];

		for (
			let date_iterator = this.moment().startOf('day').subtract(6, 'months');
			date_iterator.isBefore(this.moment().add(6, 'months'));
			date_iterator.add(1, 'day')
		) {
			this.available_dates.push(date_iterator.format('DD/MM/YYYY'));
		}

		this.available_times = [];

		for (
			let time_iterator = this.moment().startOf('day').add(8, 'hours');
			time_iterator.hour() < 23;
			time_iterator.add(30, 'minutes')
		) {
			this.available_times.push(time_iterator.format('HH:mm'));
		}

		this.onCustomerChange = this.onCustomerChange.bind(this);
		this.onSalonetteChange = this.onSalonetteChange.bind(this);
		this.onLocationChange = this.onLocationChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onBasketChange = this.onBasketChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	resetData() {
		this.setState({
			// user lists
			customers: [],
			professionals: [],

			// secondary information
			services: [],
			locations: [],
			cards: [],

			customer_id: null,
			professional_id: null,
			location_id: null,
			date: this.moment().format('DD/MM/YYYY'),
			start_time: null,
			basket: [],
			customer_cards: [],
			selected_payment_source: null,
			credit_card_id: null,
		});
	}

	loadCustomers() {
		return this.api.request('get', '/users?where={"type":"CUSTOMER","activated":true}&load=credit_transactions').then(result => {
			this.setState({
				customers: result.data,
			});

			return result.data;
		}).catch(error => this.notification.error(error.message));
	}

	loadServices() {
		return this.api.request('get', '/services').then(result => {
			this.setState({
				services: result.data.filter(s => s.type === 'HOME'),
			});

			return result.data;
		}).catch(error => this.notification.error(error.message));
	}

	loadProfessionals() {
		return this.api.request('get', '/users?where={"type":"PROFESSIONAL","activated":true}').then(result => {
			this.setState({
				professionals: result.data,
			});

			return result.data;
		}).catch(error => this.notification.error(error.message));
	}

	loadAddresses(customer_id) {
		if (!customer_id) return this.setState({
			locations: [],
		});

		return this.api.request('get', '/users/' + customer_id + '/locations').then(result => {
			this.setState({
				locations: result.data.filter(location => !location.deleted_at),
			});

			return result.data;
		});
	}

	async fetchPriceModifiers() {
		try {
			const result = await this.api.request(
				'get',
				'/price_modifiers',
			);

			if (!result.success) throw new Error('Could not fetch price modifiers, so could not create any extra payments (eg PPE Fee)');

			return result.data;
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async fetchCustomerCards(customer_id) {
		try {
			const cards_result = await this.api.request(
				'get',
				`/users/${customer_id}/cards`,
			);

			if (!cards_result.success) {
				throw new Error('Could not fetch the cards for the customer :(');
			}

			this.setState({
				customer_cards: cards_result.data.filter(
					card => !card.deleted_at,
				).filter(
					card => card.tokenization_method !== 'PAYPAL',
				),
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onCustomerChange(customer_id) {
		if (this.state.customer_id === customer_id) return;

		this.setState({
			customer_id,
		});

		this.loadAddresses(customer_id);
		this.fetchCustomerCards(customer_id);
	}

	onSalonetteChange(professional_id) {
		if (this.state.professional_id === professional_id) return;

		this.setState({
			professional_id,
		});
	}

	onLocationChange(location_id) {
		if (this.state.location_id === location_id) return;

		this.setState({
			location_id,
		});
	}

	onCardChange = (credit_card_id) => {
		if (this.state.credit_card_id === credit_card_id) return;

		this.setState({
			credit_card_id,
		});
	}

	onPaymentMethodChange = (selected_payment_source) => {
		if (this.state.selected_payment_source === selected_payment_source) return;

		// unless we need it, reset credit_card_id
		if (selected_payment_source === 'TREATWELL') {
			this.setState({
				credit_card_id: null,
			});
		}

		this.setState({
			selected_payment_source,
		});
	}

	onDateChange(date) {
		if (this.state.date === date) return;

		this.setState({
			date,
		});
	}

	getUserCredit(customer_id) {
		const { customers } = this.state;

		const customer = customers.find(u => u.id === customer_id);

		return customer.credit_transactions.reduce((amount, txn) => amount + txn.amount, 0); // in pennies
	}

	onStartTimeChange(start_time) {
		if (this.state.start_time === start_time) return;

		this.setState({
			start_time,
		});
	}

	onBasketChange(basket) {
		this.setState({
			basket,
		});
	}

	onSubmit() {
		this.setState({
			submitted: true,
		});
	}

	getBasketValue(basket) {
		const { services } = this.state;

		return basket.map((basketItem => {
			const service = services.find(s => s.id === basketItem.service_id);

			if (!service) return 0;

			return service.price;
		})).reduce((total, price) => total + price, 0);
	}

	async onConfirm() {
		const {
			customer_id, professional_id, location_id, basket, selected_payment_source, credit_card_id,
		} = this.state;

		const start_hour = parseInt(this.state.start_time.split(':')[0]);
		const start_minute = parseInt(this.state.start_time.split(':')[1]);

		const date = this.moment(this.state.date, 'DD/MM/YYYY').set('hour', start_hour).set('minute', start_minute);

		const payload = {
			datetime: date.format(),
			customer_id,
			professional_id,
			location_id,
			source: 'web-admin-app',
			status: 'PENDING', // payment is created and waiting to be processed.
			type: 'HOME',
		};

		try {
			// create appointment on backend
			const booking_result = await this.api.request(
				'post',
				'/appointments',
				payload,
			);

			if (!booking_result.success) throw new Error(booking_result.error);

			const booking = booking_result.data;

			// now, associate the basket details with this booking.
			const basket_result = await this.api.request(
				'post',
				`/appointments/${booking.id}/basket`,
				basket,
			);

			if (!basket_result.success) throw new Error(basket_result.error);

			// store the data locally to make sure visually we have all the deps we need.
			booking.services = basket_result.data;

			// now try to make a payment
			// we require appointment_id, amount and user_id

			const amount = this.getBasketValue(basket);

			const payment = {
				appointment_id: booking.id,
				user_id: customer_id,
				amount,
				payment_processor: selected_payment_source === 'TREATWELL' ? 'TREATWELL' : null,
				credit_card_id,
				processed: selected_payment_source === 'TREATWELL', // dont process treatwell payments or shall we block this on the api ?
			};

			const payment_result = await this.api.request(
				'post',
				'/payments',
				payment,
			);

			if (!payment_result.success) throw new Error(payment_result.error);

			// price modifiers
			const modifiers = await this.fetchPriceModifiers();

			const activeModifiers = modifiers.filter(m => {
				if (m.end_date === null) return true;
				if (this.moment(m.end_date).isBefore(this.moment())) return false;
				return true;
			});

			for (const modifier of activeModifiers) {
				const modifier_payment = {
					appointment_id: booking.id,
					user_id: customer_id,
					amount: modifier.amount,
					payment_processor: selected_payment_source === 'TREATWELL' ? 'TREATWELL' : null,
					credit_card_id,
					processed: selected_payment_source === 'TREATWELL', // dont process treatwell payments or shall we block this on the api ?
					description: modifier.title,
				};

				const modifier_payment_result = await this.api.request(
					'post',
					'/payments',
					modifier_payment,
				);

				if (!modifier_payment_result.success) throw new Error(modifier_payment_result.error);
			}

			this.notification.success("All done! That booking has been created. Remember to change the booking status from Pending if necessary.");

			// send data to parent
			this.props.onNewData && this.props.onNewData(booking);
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}
