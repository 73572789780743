import React from 'react';

import PageModel from '../_PageModel';

import UserCreditModal from '../../modals/UserCredit';
import Autocomplete from '../../forms/Autocomplete';
import JSONPanel from '../../misc/JSONPanel';
import Title from '../../misc/Title';

import './style.less';

export default class PaymentDetailsPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;
		this.onCardSelect = this.onCardSelect.bind(this);

	}

	state = {
		creditBalance: 0,
		modalOpen: false,
		selectedCard: null,
	}

	async getCoreData() {
		try {
			const result = await this.api.request('get', '/payments/' + this.id);

			if (!result.success) throw new Error(result.error);

			const payment = result.data;

			this.setState({
				payment,
				selectedCardId: result.data.credit_card_id
			})

		} catch (error) {
			this.notification.error(error.message);
		}

		try {
			const result = await this.api.request('get', '/appointments/' + this.state.payment.appointment_id);

			if (!result.success) throw new Error(result.error);

			const appointment = result.data;

			this.setState({
				appointment
			})

		} catch (error) {
			this.notification.error(error.message);
		}

		try {
			const user_relations = [
				'cards',
				'credit_transactions.reason'
			];

			const result = await this.api.request('get', `/users/${this.state.payment.user_id}?load=${user_relations.join(',')}`)

			if (!result.success) throw new Error(result.error);

			const user = result.data;
			const creditCards = result.data.cards ? result.data.cards.filter(card => {
				//only show cards which haven't been deleted.
				if (card.deleted_at) return false;

				//hide expired cards
				if (this.moment(card.expiry_date).isBefore(this.moment())) return false;

				// filter out payment methods that we are currently not supporting here.
				switch (card.tokenization_method) {
					case 'PAYPAL':
						// case 'EXAMPLE':
						return false;
				}

				return true;
			}) : [];

			this.setState({
				user,
				creditCards
			})

			if (!creditCards.length) {
				throw new Error('No valid credit card supplied')
			}

			if (!creditCards.filter(card => card.id === this.state.selectedCardId).length) {
				if (creditCards.length) {
					this.setState({ selectedCardId: creditCards[0].id })
				} else {
					this.setState({ selectedCardId: null })
				}
			}
		} catch (error) {
			this.notification.error(error.message);
		}

		try {
			const creditBalance = await this.api.request('get', `/users/${this.state.payment.user_id}/credit_balance`).then(result => result.data);

			this.setState({
				creditBalance
			})

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	componentDidMount() {
		try {
			this.getCoreData()
		} catch (error) {
			console.error(error);
		}
	}

	async onConfirmRecharge(payment) {

		const appointmentId = payment.appointment_id;
		const cardId = this.state.selectedCardId;

		try {

			const updatedPaymentResult = await this.api.request('put', `/payments/${payment.id}`, {
				credit_card_id: cardId,
			})

			if (!updatedPaymentResult.success) throw new Error(updatedPaymentResult.error);

			const chargeResult = await this.api.request('post', `/payments/${payment.id}/charge`);

			if (!chargeResult.success) throw new Error(chargeResult.error);

			this.notification.success(`This payment has been charged! You can now confirm the booking.`);

			this.setState({
				payment: chargeResult.data
			})

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onClickRecharge(payment) {
		if (window.confirm("Are you sure you want to recharge the chosen card and confirm the booking?")) {
			this.onConfirmRecharge(payment);
		} else return;
	}

	shouldShowRechargeButton() {
		return (
			this.state.payment
			&& !this.state.payment.processed
			&& this.state.appointment
			&& this.state.appointment.status === "CHARGE_FAILED"
		);
	}

	rechargeDisabledReason() {
		const { payment, appointment } = this.state;
		const { charge_id, processed } = payment;
		const { status } = appointment;

		if (charge_id) return `This payment has already been charged.`;
		if (processed) return `This payment has already been manually marked as processed.`;
		if (status !== 'CHARGE_FAILED') return `The booking linked to this payment has not been marked as CHARGE_FAILED.`;

		return `You can't currently charge this payment.`;
	}

	onCardSelect(selectedCardId) {

		if (this.state.selectedCardId === selectedCardId) return;

		this.setState({
			selectedCardId,
		})
	}

	render() {
		let { user, payment, selectedCardId, appointment, creditCards, creditBalance, modalOpen } = this.state;

		if (!payment || !appointment || !creditCards) return null;

		const { payment_processor, charge_id, amount } = payment;

		let selectedCard = null;
		let dropDownOptions = [{ value: '', label: 'No valid cards' }]
		if (creditCards.length) {
			selectedCard = creditCards.find(card => card.id === selectedCardId);

			dropDownOptions = creditCards.map(card => {
				let label_components = [];

				switch (card.tokenization_method) {
					case 'APPLE_PAY':
						label_components.push(`🍎`);
						break;
					case 'MANUAL_ENTRY':
						label_components.push(`💳`);
						break;
				}

				label_components.push(card.brand);
				label_components.push(card.last4);

				return {
					value: card.id,
					label: label_components.join(' ')
				}
			});
		}

		let action = null;

		switch (payment_processor) {
			case 'STRIPE':
				action = (
					<a
						className="button"
						href={`https://dashboard.stripe.com/payments/${charge_id}`}
						target="_blank"
					>
						Open payment in Stripe
					</a>
				)
				break;
			case 'BRAINTREE':
				action = (
					<a
						className="button"
						href={`https://www.braintreegateway.com/merchants/wdd3bbqr3bnmcrxb/transactions/${charge_id}`}
						target="_blank"
					>
						Open payment in Braintree
					</a>
				)
				break;
			case 'CREDIT_BALANCE':
				action = (
					<a
						className="button"
						onClick={() => this.setState({ modalOpen: true })}
						target="_blank"
					>
						Edit user's credit balance
					</a>
				)
				break;
			default:
				action = <span>This payment can't currently be modified.</span>
				break;
		}

		return (
			<div className="Page PaymentDetails">
				<Title h1 text="Payment" />

				<div className="split-view bordered">
					<div flex={2}>
						<Title h3 text="Details" />
						<div className="Card">
							<JSONPanel data={payment} />
						</div>
					</div>
					<div>
						<Title h3 text="Manage" />

						<div className="Card">
							{action}
						</div>

						<Title h3 text="Charge" />

						<div className="Card">
							{
								this.shouldShowRechargeButton()
									? (
										<form className="alternativeCardForm" onSubmit={event => {
											event.preventDefault();
											this.onClickRecharge(payment);
										}}>
											<p>
												This payment hasn't been processed yet. You can manually take the money here.
									</p>
											<Autocomplete
												options={dropDownOptions}
												clearable={true}
												placeholder={"Choose a card..."}
												value={selectedCardId}
												onChange={this.onCardSelect}
											/>
											<p>
												{
													selectedCard
														? `The card ending ${selectedCard.last4}`
														: `The customer`
												} will be charged £{(amount / 100).toFixed(2)}.
									</p>
											{
												selectedCard
													? <button>
														Attempt recharge
										</button>
													: <button disabled>
														Attempt recharge
										</button>
											}
										</form>
									)
									: <span>
										{this.rechargeDisabledReason()}
									</span>
							}
						</div>
					</div>
				</div>
				{user && <UserCreditModal
					user={user}
					creditBalance={creditBalance}
					show={modalOpen}
					onHide={() => this.setState({ modalOpen: false })}
					onUpdate={creditBalance => this.setState({ creditBalance })}
				/>}
			</div>
		);
	}
}