import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import FormModel from '../_FormModel';

export default class Autocomplete extends FormModel {
	constructor(props) {
		super(props);

		this.state = {
			value: null
		};

		this.handleSelect = this.handleSelect.bind(this);
		this.updateValue = this.updateValue.bind(this);
	}

	componentDidMount() {
		if (this.props.value) this.updateValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value) this.updateValue(nextProps.value);
	}

	handleSelect(option) {
		let value = null;

		if (option) {
			if (this.props.multiple) {
				value = option.map(item => item.value);
			} else if (option.value) {
				value = option.value;
			}
		}

		this.updateValue(value);
	}

	updateValue(value) {
		if (value === this.state.value) return;

		this.setState({
			value
		});

		if (this.props.onChange) this.props.onChange(value);
	}

	render() {
		return (
			<Select
				name="autocomplete"
				value={this.state.value}
				searchable={true}
				clearable={this.props.clearable || false}
				multi={this.props.multiple || false}
				onChange={this.handleSelect}
				placeholder={this.props.placeholder}
				options={this.props.options} />
		)
	}
}

Autocomplete.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	clearable: PropTypes.bool,
	multiple: PropTypes.bool
};