import React, { Component } from 'react';

import UsersTable from '../tables/Users';
import CreateResource from '../buttons/CreateResource';
import Title from '../misc/Title';

import PageModel from './_PageModel';

export default class UsersPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);

		this.state = {
			users: [],
			showDeactivated: false,
		};
	}

	componentDidMount() {
		this.api.request("get", "/users", null, data => {
			this.setState({
				users: [
					...this.state.users,
					...data,
				],
			});
		});
	}

	onUpdate(data) {
		const users = this.state.users;

		// add newly created appointment to appointments list.
		users.push(data);

		this.setState({
			users,
		});
	}

	toggleDeactivated = () => {
		this.setState({
			showDeactivated: !this.state.showDeactivated,
		});
	}

	render() {
		return (
			<div>
				<Title h1 text="Users">
					<form className="toggle-deactivated">
						<input
							id="UsersPageToggleDeactivated"
							type="checkbox"
							checked={this.state.showDeactivated}
							onChange={this.toggleDeactivated}
						/>
						<label htmlFor="UsersPageToggleDeactivated">
							Inactive
						</label>
					</form>

					<CreateResource
						title="New User"
						resource="users"
						onNewData={this.onUpdate}
					/>
				</Title>
				<div className="Card">
					<UsersTable
						data={this.state.users.filter(user => {
							if (!this.state.showDeactivated) {
								return !!user.activated;
							}

							return true;
						})}
						infinite
						interactive
						filterable
					/>
				</div>
			</div>
		);
	}
}
