import React from 'react';

import DataModel from '../../_DataModel';
import GenericModal from '../Generic';
import Title from '../../misc/Title';
import Tabs from '../../misc/Tabs';

import './style.less';
import { THead, TRow, Table, TBody, TCol } from '../../tables/Generic';

export default class Refund extends DataModel {
	constructor(props) {
		super(props)
	}

	state = {
		refundType: null,
		amount: null,
		destination: null,
		dataLoading: false,
	}

	async componentDidUpdate(prevProps, prevState){
		const { destination, amount } = this.state;

		if(
			destination && amount &&
			(
				destination !== prevState.destination
				|| amount !== prevState.amount
			)
		) {
			this.setState({
				dataLoading: true,
			})
			await this.getRefundInformation()
		}
					
	}

	getRefundInformation = async () => {
		const { appointment } = this.props;

		try {
			const result = await this.api.request(
				'get',
				`/appointments/${appointment.id}/refund?destination=${this.state.destination}&amount=${this.state.amount}`,
			);

			if (!result.success) throw new Error(result.error);

			const refund_map = result.data;

			this.setState({
				refund_map,
				dataLoading: false,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	getFullAmount = () => {
		const { payments } = this.props;

		// even including previous refunds, this will always return the full amount left to refund.
		// TODO, processed? Do we show them at all in this modal?
		return payments.reduce((a,b) => a + b.amount, 0)
	}

	getPayments = () => {
		const { payments } = this.props;

		return payments.filter(p => p.amount > 0 && p.processed)
	}

	formatRefundTypeTabs = () => {
		return [
			{
				value: 'partial',
				label: 'Partial Refund'
			}, {
				value: 'full',
				label: 'Full Refund'
			}
		]
	}

	formatDestinationTabs = () => {
		return [
			{
				value: 'CREDIT',
				label: 'Credit'
			}, {
				value: 'ORIGINAL_SOURCE',
				label: 'Original Source'
			}
		]
	}

	getRefunds = () => {
		const { payments } = this.props;

		return payments.filter(p => p.amount < 0 && p.processed)
	}

	setRefundType = (type) => {
		this.setState({
			refundType: type,
		})

		if(type === 'full') this.setState({
			amount: this.getFullAmount()
		})
	}

	setDestinationType = (destination) => {
		this.setState({
			destination,
		})
	}

	onChangeAmount = (amount) => {
		this.setState({
			amount: amount.target.value * 100,
		})
	}

	onHide = () => {
		this.props.onHide()
	}

	submitRefund = async (e) => {
		e.preventDefault()

		this.setState({
			dataLoading: true,
		})

		await this.props.confirmRefund(this.state.destination, this.state.amount)

		this.setState({
			dataLoading: false,
		})
	}

	formatAmount = (amount) => {
		return '£' + (amount/100).toFixed(2)
	}

	getPaymentRefundDestination = (payment_id) => {
		const { payments } = this.props;
		const { destination } = this.state;

		if(destination === 'CREDIT') return 'CREDIT_BALANCE';

		const payment = payments.find(p => p.id === parseInt(payment_id,10))
		
		return payment.payment_processor;
	}

	render() {
		return (
			<GenericModal className="Refund" show={this.props.show} onHide={this.onHide}>
				<Title h1 text="Refund Booking" />
				<div className="split">
					<div>
						{
							<Table>
								<THead>
									<TRow>
										<TCol>
										ID
										</TCol>
										<TCol>
										Processor
										</TCol>
										<TCol>
										Amount
										</TCol>
										<TCol>
										Description
										</TCol>
									</TRow>
								</THead>
								<Title h3 text="Payments" />
								<TBody>
									{	
										this.getPayments().map(payment => (
											<TRow>
												<TCol>
													{payment.id}
												</TCol>
												<TCol>
													{payment.payment_processor}
												</TCol>
												<TCol>
													{this.formatAmount(payment.amount)}
												</TCol>
												<TCol>
													{payment.description}
												</TCol>
											</TRow>
										))
									}
									{this.getRefunds().length ? <Title h3 text="Existing Refunds"/> : null}
									{	
										this.getRefunds().map(payment => (
											<TRow>
												<TCol>
													{payment.id}
												</TCol>
												<TCol>
													{payment.payment_processor}
												</TCol>
												<TCol>
													{this.formatAmount(payment.amount)}
												</TCol>
												<TCol>
													{payment.description}
												</TCol>
											</TRow>
										))
									}
								</TBody>
							</Table> 
						}

						<Title h2 text="Build refund" />
						<Tabs
							tabs={this.formatRefundTypeTabs()}
							selectedTab={this.state.refundType}
							onSelect={this.setRefundType}
						/>

						{
							this.state.refundType && (
								<div className="refundType">
									<form>
										{ this.state.refundType === 'partial' ? (
											<input type="text" className="amount" placeholder="Amount to refund (£)" onChange={this.onChangeAmount}/>
										) : <p>{this.formatAmount(this.getFullAmount())}</p>
									}
									</form>
								</div>
							)
						}
						{
							this.state.amount && (
								<div>
									<Tabs
										tabs={this.formatDestinationTabs()}
										selectedTab={this.state.destination}
										onSelect={this.setDestinationType}
									/>
								</div>
							)
						}
					</div>
					<div>
						<Title h3 text="Details" />
						<div className="details">
							{
								!this.state.amount && (
									<p>
										Choose the amount to refund to the customer.
									</p>
								)
							}
							{
								this.state.amount && !this.state.destination && (
									<p>
										Now, select the refund destination. Choosing "Original Source" will send the funds back to the payment processor on the main payment. It will prioritise fiat payments before credit.
									</p>
								)
							}
							{
								this.state.refund_map && (
									this.state.dataLoading
									? <p>
										<i>
											Loading...
										</i>
									</p> :
									<div>
										<div>
											{
												Object.keys(this.state.refund_map).map(payment_id => (
													<p>Payment #{payment_id} will be refunded {this.formatAmount(this.state.refund_map[payment_id])} to {this.getPaymentRefundDestination(payment_id)}.</p>
												))
											}
										</div>
										<form onSubmit={this.submitRefund}>
											<button className="button">
												Submit Refund
											</button>
										</form>
									</div>
								)
							}
						</div>
					</div>
				</div>
				
			</GenericModal>
		)
	}
}