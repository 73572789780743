import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import GenericModal from '../Generic';
import UserLabel from '../../labels/UserLabel'
import Title from '../../misc/Title';
import UserCreditTransactionsTable from '../../tables/UserCreditTransactions';

import './style.less';

export default class UserCreditModal extends DataModel {
	constructor() {
		super();

		this.state = {
			customValue: null,
			creditReasons: [],
			creditReason: 'MISC',
			comments: null,
		}

		this.notification = new Notification();

		this.customValueChange = this.customValueChange.bind(this);
		this.customValueSubmit = this.customValueSubmit.bind(this);
	}

	componentDidMount() {
		this.formatCreditReasons();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.setState({
				customValue: null
			})
		}
	}

	formatCreditReasons = () => {
		const allowed_credit_reasons = [
			'COMPENSATION_LESALON',
			'COMPENSATION_SALONETTE',
			'VIP',
			'TREATWELL',
			'REFUND',
			'MISC',
			'PRODUCT_PURCHASE',
		];

		const reasons = allowed_credit_reasons.map(reason => ({
			value: reason,
			label: reason,
		}))

		this.setState({
			creditReasons: reasons
		})
	}

	customValueChange(e) {
		let value = null;

		try {
			if (e.target.value) value = parseFloat(e.target.value).toFixed(2);

			this.setState({
				customValue: value
			});
		} catch (e) { }
	}

	customValueSubmit(e) {
		e.preventDefault();

		const value = this.state.customValue;

		if (!value) return;

		const pennies_value = Math.floor(parseFloat(value) * 100);

		this.updateBalance(pennies_value);
	}

	async updateBalance(to_add_in_pennies) {
		let user = this.props.user;

		const { creditReason, comments } = this.state;

		const existing_balance = user.credit_balance || 0;
		const new_balance = existing_balance + to_add_in_pennies;

		if(!comments) this.notification.error('You must add comments for this credit transaction.')

		try {
			const result = await this.api.request(
				'put',
				'/users/' + user.id + '/credit_balance',
				{
					amount: to_add_in_pennies,
					reason: creditReason,
					comments
				}
			);

			if (result.success) {
				this.notification.success("All done! " + user.first_name + "'s new balance is £" + (result.data / 100).toFixed(2) + ".");

				if (this.props.onUpdate) this.props.onUpdate(result.data);

				return;
			}

			throw new Error(result.error);

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onCommentsChange = (comments) => {
		this.setState({
			comments: comments.target.value || null // turn empty string to null, comments are required.
		})
	}

	onCreditReasonChange = (reason) => {
		this.setState({
			creditReason: reason.value,
		})
	}

	render() {
		let user = this.props.user;

		let credit_balance = this.props.creditBalance || 0;
		credit_balance = (credit_balance / 100).toFixed(2);

		const { creditReason } = this.state;

		return (
			<GenericModal className="UserCredit" show={this.props.show} onHide={this.props.onHide}>
				<Title h1 text="Manage User Credit" />
				<div className="Card info">
					<UserLabel user={user} /> currently has a credit balance of £{credit_balance}.
				</div>
				<Title h3 text="Credit reason" />
				<div className="Card">
					<Select
						name="credit_reason"
						defaultValue="ADMIN"
						value={creditReason}
						searchable={false}
						clearable={false}
						onChange={this.onCreditReasonChange}
						options={this.state.creditReasons}
					/>
				</div>
				<Title h3 text="Comments" />
				<div className="comments">
					<form>
						<input
							onChange={this.onCommentsChange}
							placeholder="Comments (internally visible)"
							required />
					</form>
				</div>
				<Title h3 text="Custom amount" />
				<div className="custom_add">
					<form onSubmit={this.customValueSubmit}>
						<input
							onChange={this.customValueChange}
							placeholder="Custom amount"
							required />
						<button>
							Add {this.state.customValue ? "£" + this.state.customValue : null}
						</button>
					</form>
				</div>
				<Title h3 text="History" />
				<div className="Card">
					<UserCreditTransactionsTable
						data={user.credit_transactions || []}
						infinite={false}
						interactive={false}
						filterable={false}
						defaultLimit={undefined}
					/>
				</div>
			</GenericModal>
		)
	}
}

UserCreditModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	user: PropTypes.any.isRequired,
	creditBalance: PropTypes.number,
	onUpdate: PropTypes.func.isRequired
}