import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import Moment from 'moment';
import QueryString from 'qs';
import GenericModal from '../../modals/Generic';
import Notification from '../../misc/Notification';
import Autocomplete from '../../forms/Autocomplete';

import Title from '../../misc/Title';

import DataModel from '../../_DataModel';

import './style.less';

export default class ResourceModal extends DataModel {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
		};

		this.notification = new Notification();

		this.fieldRelationships = [{
			key: 'professional_id',
			label: user => (user.first_name + " " + user.last_name),
			relation_url: '/users?where={"type":"PROFESSIONAL"}',
		}, {
			key: 'customer_id',
			label: user => (user.first_name + " " + user.last_name),
			relation_url: '/users?where={"type":"CUSTOMER"}',
		}, {
			key: 'user_id',
			label: user => (user.first_name + " " + user.last_name),
			relation_url: '/users',
		}, {
			key: 'service_id',
			label: service => (service.option),
			relation_url: '/services',
		}, {
			key: 'location_id',
			label: location => (location.postcode + ", " + location.address),
			relation_url: '/locations',
		}, {
			key: 'category_id',
			label: category => (() => {
				let text = category.name;

				if (category.user_id) {
					text += ` (owned by User #${category.user_id})`;
				}

				return text;
			})(),
			relation_url: '/service_categories',
		}, {
			key: 'dependent_category_id',
			label: category => category.name,
			relation_url: '/service_categories',
		}];

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.autofillForm = this.autofillForm.bind(this);

		this.method = "create"; // this can be overriden
	}

	componentDidMount() {
		if (!this.props.resource) return;

		if (this.method === "delete") return;

		this.api.request('get', '/blueprints/' + this.props.resource + '/' + this.method.toLowerCase()).then(result => {
			if (result.success) {
				const schema = JSON.parse(JSON.stringify(result.data));

				Object.keys(schema.properties).forEach(key => {
					// if the parent component has requested that certain keys be hidden, we delete them from the schema here.
					if (this.props.excludeKeys && this.props.excludeKeys.indexOf(key) > -1) {
						if (schema.required && schema.required.length) {
							const required_index = schema.required.indexOf(key);

							if (required_index > -1) schema.required.splice(required_index, 1);
						}

						return delete schema.properties[key];
					}

					const property = schema.properties[key];

					if (property.type instanceof Array) {
						const null_index = property.type.indexOf("null");

						// remove null from schema (unsupported by react-jsonschema-form)
						if (null_index > -1) schema.properties[key].type.splice(null_index, 1);
					}
				});

				this.setState({
					originalSchema: result.data,
					schema,
				});

				// discard additional properties in existing data
				if (this.props.existingData) this.formatExistingData(this.props.existingData);

				return schema;
			}

			throw new Error(result.error);
		}).then(schema => {
			const relation_promises = [];

			// format schema and test relationships
			Object.keys(schema.properties).forEach(key => {
				const overrides = this.props.relationshipUrlOverrides;

				this.fieldRelationships.forEach(relation => {
					if (relation.key === key) {
						let url = relation.relation_url;

						// we override the url if an optional one exists.
						// i.e. adding a where clause to the user_id relation
						if (overrides && overrides[relation.key]) url = overrides[relation.key];

						relation_promises.push(this.api.request('get', url).then(result => {
							if (result.success) {
								const state = {};
								state["relation_" + relation.key] = result.data;
								this.setState(state);
							}
						}));
					}
				});
			});

			return Promise.all(relation_promises);
		}).then(results => {
			// console.log(this.state);

		}).catch(error => {
			console.error(error);
		});
	}

	componentWillReceiveProps(nextProps) {
		// discard additional properties in existing data
		if (nextProps.existingData) this.formatExistingData(nextProps.existingData);
	}

	formatExistingData(data) {
		const schema = this.state.schema;

		if (!schema) return;

		// set resource id in state (in case we need to make a PUT)
		this.setState({
			resourceId: this.props.existingData.id,
		});

		const formData = {};

		Object.keys(data).forEach(key => {
			if (schema.properties[key]) {
				let datum = data[key];

				if (key === "datetime") {
					// format the date as UTC because react-jsonschema-form can only display UTC.
					// we need to remove any timestamp information from the date.
					datum = this.removeTimeZone(datum);
				}

				formData[key] = datum;

				// convert null values to empty strings. is this appropriate or over-reaching?
				if (formData[key] === null) {
					delete formData[key];
				}
			}
		});

		this.setState({
			formData,
		});
	}

	openModal() {
		this.setState({
			modalOpen: true,
		});
	}

	closeModal() {
		this.setState({
			modalOpen: false,
		});
	}

	handleSubmit() {
		// OVERRIDE THIS METHOD
	}

	render() {
		if (!this.state.schema) return null;

		const uiSchema = {};

		const startYear = Moment().subtract(5, 'years').year();
		const endYear = Moment().add(10, 'years').year();

		['date', 'datetime', 'expiry_date'].forEach(key => {
			uiSchema[key] = {
				"ui:widget": "alt-datetime",
				"ui:options": {
					yearsRange: [startYear, endYear],
					hideNowButton: true,
				},
				classNames: "expanded-date",
			};
		});

		['qualifications', 'experience', 'about_me', 'description'].forEach(key => {
			uiSchema[key] = {
				"ui:widget": "textarea",
				"ui:options": {
					rows: 4,
				},
			};
		});

		this.fieldRelationships.forEach(relation => {
			uiSchema[relation.key] = {
				"ui:widget": (props) => this.autofillForm(relation, props),
			};
		});

		const classNames = [
			'Resource',
			this.method,
			this.props.className,
		];

		return (
			<div className="ResourceModal">
				<button onClick={this.openModal}>
					{this.props.title}
				</button>
				<GenericModal className={classNames.join(' ')} show={this.state.modalOpen} onHide={this.closeModal}>
					<Title h1 text={this.props.title} />
					<Form
						schema={this.state.schema}
						uiSchema={uiSchema}
						formData={this.state.formData}
						onSubmit={this.handleSubmit}
					/>
				</GenericModal>
			</div>
		);
	}

	// a helper for showing an interactive input for ID-based values
	autofillForm(relation, props) {
		const raw_data = this.state["relation_" + relation.key] || [];

		const options = raw_data.map(datum => ({
			value: datum.id,
			label: relation.label(datum),
		}));

		return (
			<div>
				<Autocomplete value={props.value} options={options} clearable={!props.required} onChange={props.onChange} />
			</div>
		);
	}

	// this function uses regex to strip a date of any time zone information without converting the time.
	// we do this for a seamless experience when using react-jsonschema-form which cannot understand TZ.
	// returns an ISO-8601 string.
	removeTimeZone(datetime) {
		// first use moment to format the date as ISO-8601.
		datetime = this.moment(datetime).format();

		// console.log('ResourceModal datetime before:', datetime)

		// now remove any time zone information.
		datetime = datetime.replace(/\+.*/g, 'Z');

		// console.log('ResourceModal datetime after: ', datetime)

		return datetime;
	}

	// to be used in conjunction with this.removeTimeZone - this function will restore a previously removed time zone and return an ISO-8601 string.
	restoreTimeZone(datetime) {
		const original_datetime = this.moment.utc(datetime).format();
		const parsed_datetime = this.moment(datetime).format();

		// now we need to strip the timezone information from these two date strings.
		// the possible formats should be `Z`, `+00:00`, `+01:00` etc.
		const original_stripped = original_datetime.replace(/\+.*/g, '').replace(/Z.*/g, '');
		const parsed_stripped = parsed_datetime.replace(/\+.*/g, '').replace(/Z.*/g, '');

		console.log(`Visual comparison:`, original_stripped, parsed_stripped);

		// if the two stripped times match, then we don't need to add any time zone info to this date.
		if (original_stripped === parsed_stripped) {
			console.log(`We don't need to add any TZ info to this date string.`, original_datetime);
			return original_datetime;
		}

		console.log(`We have to modify the time zone on this date string.`, original_datetime);
		// if they don't match, that means that when moment parsed the time it converted it to local time.
		// let's extract the time zone and append it to the stripped orginal date.

		// remove everything up to the first occurence of `+` from the parsed string. This just gives us the time zone.
		const time_zone = parsed_datetime.split('+')[1];

		console.log('Extracted time zone:', time_zone);

		// bring it all together...
		const concat_datetime = `${original_stripped}+${time_zone}`;

		console.log(`Converted ${original_datetime} to ${concat_datetime}.`);

		return concat_datetime;
	}
}

ResourceModal.propTypes = {
	title: PropTypes.string.isRequired,
	resource: PropTypes.string.isRequired,
	existingData: PropTypes.any,
	onNewData: PropTypes.func,
	excludeKeys: PropTypes.array,
	className: PropTypes.string,
};
