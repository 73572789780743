import React from 'react';

import Autocomplete from "../Autocomplete";

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state,
	}

	render() {
		const { services, basket } = this.props;
		const { service_id } = this.state;

		const service_options = Object.values(services).map(service => ({
			value: service.id,
			label: service.option,
		}));

		return (
			<form className="Basket">
				<div className="add">
					<div className="product">
						<Autocomplete
							value={service_id}
							options={service_options}
							clearable
							placeholder="Add service..."
							onChange={this.onServiceChange}
						/>
					</div>
					{service_id && (
						<button onClick={this.addToBasket}>
							Add
						</button>
					)}
				</div>
				{basket.length > 0 && (
					<div className="contents">
						{basket.map((item, index) => {
							const { service_id, quantity } = item;

							const parts = [
								services[service_id].option,
							];

							parts.push(`(${quantity} ${quantity === 1 ? 'person' : 'people'})`);

							let price = 0;

							price += services[service_id].price;

							// multiply price by quantity.
							price *= quantity;

							return (
								<div className="item" key={index}>
									<div>
										💅 {parts.join(' ')}
									</div>
									<div>
										£{(price / 100).toFixed(2)}
									</div>
								</div>
							);
						})}
					</div>
				)}
			</form>
		);
	}
}
