import React, { Component } from 'react';

import Moment from 'moment';

import TableModel from '../_TableModel';
import PaymentTypeLabel from '../../labels/PaymentTypeLabel';
import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class PaymentsTable extends TableModel {
	constructor() {
		super();

		this.className = "PaymentsTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Payment ID"
		}, {
			title: 'Type',
			description: "Payment Type"
		}, {
			title: 'Amount (£)',
			type: String,
			description: "Amount (£)"
		}, {
			title: 'Date',
			type: Date,
			description: "Date"
		}, {
			title: 'User',
			type: Number,
			description: "User ID"
		}, {
			title: 'Processed',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Processed'
		}, {
			title: 'Description',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Description'
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let payment = datum;
			payment.amount = payment.amount || 0;

			const { id, payment_processor, amount, created_at, user_id, processed, description } = payment;

			let columns = [
				id,
				(
					payment_processor
						? <PaymentTypeLabel type={payment_processor.toLowerCase()} />
						: <i>N/A</i>
				),
				"£" + (amount / 100).toFixed(2),
				Moment(created_at).unix(),
				user_id ? <UserLabel id={user_id} fetch shortName /> : <i>N/A</i>,
				processed ? "Yes" : "No",
				description || '',
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/payments/' + data[0];
	}
}