import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Breadcrumbs from 'react-breadcrumbs';
import FontAwesome from 'react-fontawesome';

import UserInteractiveLabel from '../../labels/UserInteractiveLabel';
import Loader from '../../misc/Loader';

import './style.less';

export default class Navbar extends Component {
	render() {
		return (
			<div className="Navbar">
				<div>
					<Breadcrumbs routes={this.props.routes} params={this.props.params} separator={<FontAwesome name="chevron-right" />} />
				</div>
				<div>
					<Link to="/">
						<svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 148.5">
							<g>
								<path class="fill" d="M476.5,127.5c-5.6,0-8.9-2-8.9-12.1V77.5c0-23.4-15.1-36.8-41.5-36.8c-23.4,0-40.9,11.3-49.2,31.9l-0.5,1.1
									l21.6,6.9l0.4-1c4.8-13.4,13.3-19.6,26.8-19.6c12.5,0,19.4,4.5,19.4,12.8c0,6.5-5.2,9.4-23,13.2c-17.1,3.6-26.6,6.1-33.9,10.7
									c-9,5.7-13.2,13.2-13.2,23.8c0,16.4,12.2,26.1,32.5,26.1c16.9,0,30.8-8.2,38-22.2c1.2,15.4,8.4,22,23.6,22c8,0,13.5-2.7,15.6-3.9
									l0.6-0.3v-16.8l-1.5,0.6C481,127.1,478.8,127.5,476.5,127.5z M445.4,96.8c0,17-13.8,31.4-30.1,31.4c-7.6,0-16.7-1.9-16.7-11.2
									c0-9.5,7.9-12.9,25.5-16.8c11.6-2.5,17.7-4.7,21.3-7.7V96.8z"></path>
								<path class="fill" d="M623.1,40.7c-31.3,0-52.4,21.3-52.4,53.1c0,32.1,20.6,52.9,52.4,52.9c15.6,0,28.9-5.3,38.3-15.4
									c8.9-9.5,13.9-22.8,13.9-37.5C675.3,62,654.3,40.7,623.1,40.7z M623.1,126.1c-23.1,0-28-17.6-28-32.3c0-20.5,10.5-32.8,28-32.8
									c17.9,0,27.8,11.7,27.8,32.8C650.9,114.6,641,126.1,623.1,126.1z"></path>
								<path class="fill" d="M762.3,40.7c-15.6,0-27.4,7.4-34.4,21.5V42.7H704v102.2h23.8V94.5c0-21,9.4-33.5,25.1-33.5
									c15.7,0,23,8.7,23,27.3v56.6h24V82.7C800,56.8,785.5,40.7,762.3,40.7z"></path>
								<path class="fill" d="M159.3,40.7c-15.5,0-28.8,5.5-38.4,16c-9,9.8-13.9,23.1-13.9,37.7c0,32.3,20.3,52.4,53.1,52.4
									c20.3,0,35.7-8.2,45.6-24.5l0.5-0.8l-21.9-8c-5.9,9.1-13.4,13-24.5,13c-17,0-27.3-9.1-29.3-25.6h77.9v-8.6c0-14.5-4.2-26.9-12-36.1
									C187.6,46,174.8,40.7,159.3,40.7z M130.6,85c2.8-15.6,12.9-24.2,28.5-24.2c15.2,0,24.3,8.6,25.6,24.2H130.6z"></path>
								<polygon class="fill" points="24,122.7 24,10.1 0,24 0,144.9 84.3,144.9 84.3,122.7 	"></polygon>
								<polygon class="fill" points="514.7,144.9 538.6,144.9 538.6,0 514.7,13.8 	"></polygon>
								<path class="fill" d="M288.7,32.6c16.9,0,27.7,5.9,37.1,20.2l0.7,1l20.9-15.7l-0.7-0.9c-14-18.7-31.3-27.1-55.9-27.1
									c-33.8,0-49,19.8-49,39.3c0,27,22.6,33,45.7,36.8l0.7,0.1c17.6,3.1,34.2,6,34.2,19.7c0,11.2-9.4,18.2-24.4,18.2
									c-16.8,0-28.3-5.4-38.8-18.5c-3,1.7-22.6,13.1-22.6,13.1l0.5,0.7c13,18.1,32.9,27.2,59.3,27.2c29.6,0,48.8-15.9,48.8-40.4
									c0-29-27.7-34-46.1-37.3l-0.8-0.1c-18.9-3.2-33.8-5.8-33.8-19.1C264.6,39.4,274.1,32.6,288.7,32.6z"></path>
							</g>
						</svg>
					</Link>
					<Loader />
				</div>
				<div>
					{this.props.user && <UserInteractiveLabel user={this.props.user} />}
				</div>
			</div>
		)
	}
}

Navbar.propTypes = {
	routes: PropTypes.any.isRequired,
	params: PropTypes.any.isRequired,
	user: PropTypes.object
}