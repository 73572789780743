import React, { Component } from 'react';

export default class TableCol extends Component {
	render() {
		return (
			<div {...this.props} className={["col", this.props.className].join(" ")}>
				{this.props.children}
			</div>
		);
	}
}
