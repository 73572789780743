import Local from '../storage/Local';
import APIClient from './APIClient';

export default class AuthToken{
	constructor(reference){
		this.local = new Local();
		this.reference = reference || "access_token";

	}

	get token() {
        return this.local.get(this.reference);
    }

    set token(new_token){
        if(!new_token){
			return this.local.remove(this.reference);
		}

        this.local.set(this.reference, new_token);
    }
}