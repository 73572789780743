import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import PageModel from './_PageModel';
import DeleteResource from '../buttons/DeleteResource';
import Title from '../misc/Title';

export default class ZonePage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;
	}

	componentDidMount() {
		this.api.request('get', '/zones/' + this.id).then(result => {
			if (result.success) {
				this.setState({
					zone: result.data
				})
			}

		}).catch(error => {
			console.error(error);
		})
	}

	render() {
		let zone = this.state.zone;

		if (!zone) return null;

		let classes = ["Page", "ZonePage"];

		return (
			<div className={classes.join(" ")}>
				<Title h1 text={zone.post_code}>
					<DeleteResource
						title="Delete Zone"
						resource="zones"
						existingData={zone}
						onNewData={browserHistory.goBack} />
				</Title>
			</div>
		);
	}
}