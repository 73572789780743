import React, { Fragment } from 'react';
import PageModel from './_PageModel';

import Table from '../tables/Searches';
import Title from '../misc/Title';

export default class SearchesPage extends PageModel {
	constructor() {
		super();
	}

	state = {
		searches: []
	}

	async componentDidMount() {
		try {
			const result = await this.api.request("get", "/professional_searches", null, data => {
				this.setState({
					searches: [
						...this.state.searches,
						...data
					]
				})
			});

			if (!result.success) throw new Error(result.error);

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	render() {
		const { searches } = this.state;

		return (
			<Fragment>
				<Title h1 text="Searches" />
				<div className="Card">
					<Table
						data={searches}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</Fragment>
		);
	}
}