export default {
	LOGGED_IN: "LOGGED_IN",
	LOGGED_OUT: "LOGGED_OUT",

	LOAD_START: "LOAD_START",
	LOAD_END: "LOAD_END",

	WORKING_PERIOD_DELETED: "WORKING_PERIOD_DELETED",

	PROFILE_UPDATED: "PROFILE_UPDATED"
}