import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class GenericModal extends Component {
	render() {
		let classNames = ["Modal"];
		if (this.props.className) classNames.push(this.props.className)

		return (
			<Modal className={classNames.join(" ")} show={this.props.show} onHide={this.props.onHide}>
				<Modal.Body>
					{this.props.children}
				</Modal.Body>
			</Modal>
		)
	}
}

GenericModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired
}