import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import FormModel from '../_FormModel';

import './style.less';

export default class FilterForm extends FormModel {
	constructor(props) {
		super(props);

		this.state = {
			filterCategory: 0,
			filterText: "",
		};

		if (props && props.options && props.options.length) this.state.filterCategory = props.options[0].value;

		this.handleSelect = this.handleSelect.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}

	componentDidMount() {
		if (this.props.onChange) {
			this.props.onChange(this.state.filterCategory, this.state.filterText);
		}
	}

	handleSelect(category) {
		const value = category.value;

		this.setState({
			filterCategory: value,
			filterText: "",
		});

		if (this.props.onChange) {
			this.props.onChange(value, "");
		}

		if (this.props.onSelect) {
			this.props.onSelect(this.props.options.find(o => o.value === value));
		}
	}

	handleInput(event) {
		this.setState({
			filterText: event.target.value,
		});

		if (this.props.onChange) {
			this.props.onChange(this.state.filterCategory, event.target.value);
		}
	}

	render() {
		const classNames = [
			"Filter",
		];

		const { filterCategory } = this.state;

		if (filterCategory) classNames.push(filterCategory);

		return (
			<form className={classNames.join(' ')} onSubmit={this.handleSubmit}>
				<Select
					name="filterCategory"
					value={filterCategory}
					searchable={false}
					clearable={false}
					onChange={this.handleSelect}
					options={this.props.options}
				/>

				<input name="filterText" value={this.state.filterText} onChange={this.handleInput} placeholder="Type here to filter..." />
			</form>
		);
	}
}

FilterForm.propTypes = {
	onSelect: PropTypes.func,
	onChange: PropTypes.func,
};
