import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import EventEmitter from 'events';

import Events from './Events';
import Router from './Router';
import * as serviceWorker from './serviceWorker';

//stylesheets
import './stylesheets/style.less';

//for global events
window.EventSystem = new EventEmitter();
window.EventName = Events;

window.moment = Moment;

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// I enabled it
// NF 2019-09-16
// I disabled it again - ppl don't get updates until u restart Chrome
// NF 2019-09-27
serviceWorker.unregister();
