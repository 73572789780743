import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import DataModel from '../../_DataModel';
import { Table, TBody, TRow, TCol } from '../../tables/Generic';

import './style.less';

export default class UserMetricsCard extends DataModel {
	constructor() {
		super();

		this.supported_metrics = {
			//universal
			rating: {
				label: 'Current rating',
				formatter: rating => rating.toFixed(2)
			},
			total_bookings: {
				label: 'Total bookings',
			},
			total_bookings_this_month: {
				label: 'Bookings this month',
			},

			//customer
			total_spend: {
				label: 'Total £ spent',
				formatter: total => (total / 100).toFixed(2)
			},
			upcoming_appointments: {
				label: 'Number of upcoming treatments'
			},
			days_since_last_appointment: {
				label: 'Time since last treatment',
				formatter: days => days !== null ? `${days} day${days === 1 ? '' : 's'}` : <i>N/A</i>
			},
			days_since_signup: {
				label: 'Time since signup'
			},
			total_vouchers_used: {
				label: 'Total vouchers used'
			},

			//professional
			total_completed_bookings: {
				label: 'Number of completed treatments'
			},
			total_reviews: {
				label: 'Number of reviews received'
			},
			capacity_total: {
				label: 'Total time uploaded this week',
				formatter: duration => {
					//moment duration is a bit buggy, so here's a working example for each line that happens here...
					//if we have a duration of 1530, this is 25 hours 30 minutes.
					//The unit of input to this formatter is minutes.
					const parsed = this.moment.duration(duration, 'minutes');

					//asHours gives us 25.5, which we then floor to get 25.
					const hours = Math.floor(parsed.asHours());
					//asMinutes will give us 1530, which we then floor (just in case a couple of seconds of) and then subtract the rounded hours from it to give us the minute remainder.
					//We need to multiply the hours by 60 to convert them to minutes.
					const minutes = Math.floor(parsed.asMinutes()) - (hours * 60);

					//So why do this instead of parsed.hours() and parsed.minutes()?
					//Well, turns out that as soon as you go over 24 hours these functions don't work properly any more and just give you the duration since the last midnight - in this case 1 hour 30 mins.

					return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
				}
			}
		}
	}

	render() {
		const { metrics } = this.props;

		if (!metrics) return null;

		let rows = [];

		for (const key in this.supported_metrics) {
			const value = metrics[key];

			//ignore any undefined metrics...
			if (value === undefined) continue;

			const { label, formatter } = this.supported_metrics[key];

			rows.push(<TRow key={key}>
				<TCol>{label}</TCol>
				<TCol>{formatter ? formatter(value) : value}</TCol>
			</TRow>)
		}

		return (
			<div className="Card UserMetricsCard">
				<Table>
					<TBody>
						{rows}
					</TBody>
				</Table>
			</div>
		)
	}
}

UserMetricsCard.propTypes = {
	metrics: PropTypes.object.isRequired
}