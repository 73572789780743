import React from 'react';

import PageModel from '../_PageModel';

import Table from '../../tables/Categories';
import CreateResource from '../../buttons/CreateResource';
import Autocomplete from '../../forms/Autocomplete';
import Title from '../../misc/Title';

import './style.less';

export default class CategoriesPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);
	}

	state = {
		categories: [],

		// for filtering by category type
		types: [],
		typeFilter: null,
	}

	async getCoreData() {
		const relations = [];

		try {
			const categories = await this.api.request(
				"get",
				`/service_categories?load=${relations.join(',')}`,
			).then(result => {
				if (!result.success) throw new Error(result.error);

				return result.data;
			});

			this.setState({
				categories,
			});
		} catch (error) {
			this.notification.error(error.message);
		}

		// get update blueprint from API, and then find a list of allowed statuses for filtering.
		try {
			const schema = await this.api.request(
				"get",
				"/blueprints/service_categories/update",
			).then(result => {
				if (!result.success) throw new Error(result.error);

				return result.data;
			});

			// get list of valid values from deep inside the schema.
			let types = schema.properties.type.enum;

			const typeFilter = (
				types.includes('HOME')
					? 'HOME'
					: null
			);

			// convert to autocomplete format
			types = types.map(type => ({
				label: (
					type[0].toUpperCase()
						+ type.slice(1, type.length).toLowerCase().split('_').join(' ')
				),
				value: type,
			}));

			this.setState({
				types,
				typeFilter,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	componentDidMount() {
		return this.getCoreData();
	}

	onUpdate(data) {
		return this.getCoreData();
	}

	onTypeFilterChange = (typeFilter) => {
		if (this.state.typeFilter === typeFilter) return;

		this.setState({
			typeFilter,
		});
	}

	render() {
		const { categories, typeFilter, types } = this.state;

		let relationshipUrlOverrides;

		switch (typeFilter) {
			case 'HOME':
			case 'CORPORATE':
				relationshipUrlOverrides = { user_id: `/users?where=${JSON.stringify({ type: "PROFESSIONAL" })}` };
				break;
			case 'STOREFRONT':
				relationshipUrlOverrides = { user_id: `/users?whereIn=${JSON.stringify({ type: ["STOREFRONT", "PROFESSIONAL"] })}` };
				break;
			default:
				break;
		}

		return (
			<div className="Page CategoriesPage">
				<Title h1 text="Categories">
					<form className="type-filter">
						<Autocomplete
							value={typeFilter}
							options={types}
							onChange={this.onTypeFilterChange}
							multiple={false}
							placeholder="All types"
						/>
					</form>

					<CreateResource
						key={typeFilter} // important to remount the component when the filter changes, which will trigger new fieldRelationships to be fetched.
						title="New Category"
						resource="service_categories"
						onNewData={this.onUpdate}
						relationshipUrlOverrides={relationshipUrlOverrides}
					/>
				</Title>
				<div className="Card">
					<Table
						data={
							// filter categories to the selected type
							categories.filter(
								category => {
									if (!typeFilter || !typeFilter.length) return true;

									return category.type === typeFilter;
								},
							)
						}
						infinite
						interactive
						filterable
					/>
				</div>
			</div>
		);
	}
}
