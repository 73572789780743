import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabelModel from '../_LabelModel';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class UserLabel extends LabelModel {
	componentDidMount() {
		this._mounted = true;

		if (this.props.fetch && this.props.id) {
			this.api.request('get', '/users/' + this.props.id).then(result => {
				if (!this._mounted) return;

				if (result.success) {
					this.setState({
						user: result.data
					})
				} else {
					this.setState({
						error: true
					})
				}
			})
		}
	}

	renderAvatar() {
		//show avatar, default to true
		const show = this.props.avatar !== undefined ? this.props.avatar : true;

		if(!show) return null;

		const user = this.props.user || this.state.user;

		if (!user) return null;

		const { avatar } = user;

		return <Avatar url={avatar} />;
	}

	renderBannedStatus() {
		const user = this.props.user || this.state.user;

		if (!user) return null;

		const { banned_at } = user;
		if (!banned_at) return null;

		return <span className="Label Badge banned">BANNED</span>;
	}


	renderVIPStatus() {
		const user = this.props.user || this.state.user;

		if (!user) return null;

		const { is_vip } = user;
		if (!is_vip) return null;

		return <span className="Label Badge vip">VIP</span>;
	}

	render() {
		if (this.state.error) return <i>N/A</i>

		const user = this.props.user || this.state.user;

		if (!user) return null

		const name_components = [
			user.first_name
		];

		if (this.props.shortName) {
			//trim to just the initial.
			const initial = (user.last_name || '').slice(0, 1);

			if (initial.length > 0) name_components.push(
				`${initial}.`
			)
		} else {
			name_components.push(user.last_name);
		}

		const name = name_components.join(' ');

		return (
			<div className="Label User">
				{this.renderAvatar()}<span>{name}</span>{this.renderBannedStatus()}{this.renderVIPStatus()}
			</div>
		)
	}
}

UserLabel.propTypes = {
	user: PropTypes.object,
	id: PropTypes.number,
	fetch: PropTypes.bool,
	avatar: PropTypes.bool,
	shortName: PropTypes.bool
};
