import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router'

import Navbar from './misc/Navbar';

export default class CoreUI extends Component {
	constructor(){
		super();

		this.state = {};
	}

	componentDidMount(){
		this.loginSubscription = window.EventSystem.on(window.EventName.LOGGED_IN, user => {
			this.setState({
				user: user
			})
		})

		this.logoutSubscription = window.EventSystem.on(window.EventName.LOGGED_OUT, () => {
			this.setState({
				user: null
			})
		})

		//when a user's profile is updated
		this.profileUpdateSubscription = window.EventSystem.on(window.EventName.PROFILE_UPDATED, user => {
			//ignore any profile updates that don't relate to the logged in user.
			if(!user) return;
			if(!this.state.user) return;
			if(user.id !== this.state.user.id) return;

			this.setState({
				user
			})
		})
	}

	render() {
		return (
			<div className="CoreUI">
				<Navbar routes={this.props.routes} params={this.props.params} user={this.state.user}/>
				{this.props.children}
			</div>
		);
	}
}