import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import DataModel from '../../_DataModel';
import { Table, TBody, TRow, TCol } from '../../tables/Generic';

import './style.less';

export default class UserMetadataCard extends DataModel {
	constructor() {
		super();
	}

	render() {
		const { keys, values } = this.props;

		if (!keys) return null;
		if (!values || !values.length) return null;

		let rows = [];

		for (const entry of values) {
			const { key_id, value } = entry;

			const name = keys[key_id];

			//ignore any undefined metadata...
			if (!name) continue;
			if (!value) continue;

			rows.push(<TRow key={name}>
				<TCol>{name}</TCol>
				<TCol>{value}</TCol>
			</TRow>)
		}

		if (!rows.length) return null;

		return (
			<div className="Card UserMetadataCard">
				<Table>
					<TBody>
						{rows}
					</TBody>
				</Table>
			</div>
		)
	}
}

UserMetadataCard.propTypes = {
	//{ id: "NAME" }
	keys: PropTypes.object.isRequired,
	values: PropTypes.array.isRequired
}