import React, { Component } from 'react';

import TableModel from '../_TableModel';
import Moment from 'moment';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class SearchesTable extends TableModel {
	constructor() {
		super();

		this.className = "SearchesTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [
		{
			title: '',
			type: String,
			sortable: false,
			searchable: false,
			description: 'Type'
		},
		{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Search ID"
		},		
		{
			title: 'Searcher',
			type: Number,
			searchable: false,
			sortable: true,
			description: "Searcher"
		}, {
			title: 'Appointment ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Appointment ID"
		}, {
			title: 'Source',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Source'
		}, {
			title: 'Searched at',
			type: Date,
			description: 'Searched at'
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let search = datum;
			const isRobot = search.source.charAt(0) !== '/' && !search.searcher_id;

			let columns = [
				isRobot ? '🤖' : '💁',
				search.id,
				search.searcher_id ? <UserLabel id={search.searcher_id} fetch={true} /> : null,
				search.appointment_id,
				search.source,
				Moment(search.searched_at).unix(),
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/searches/' + data[1];
	}
}