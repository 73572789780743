import React from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import GenericModal from '../Generic';
import Title from '../../misc/Title';
import Select from 'react-select';

import './style.less';

export default class CorporateDetailsModal extends DataModel {
	constructor() {
		super();

		this.state = {
			duration: '',
			revenue: '',
			hourly_rate: '',
			vouchers_allowed: '',
			company_pays: '',
			po_number: '',
		}

		this.onSubmit = this.onSubmit.bind(this);

		this.onRateChange = this.onRateChange.bind(this);
		this.onRevenueChange = this.onRevenueChange.bind(this);
		this.onDurationChange = this.onDurationChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { metadata } = nextProps;

		if (!metadata) return null;

		const { duration, revenue, hourly_rate, vouchers_allowed, company_pays, po_number } = metadata;

		this.setState({
			duration, revenue, hourly_rate, vouchers_allowed, company_pays, po_number
		})
	}

	onDurationChange(e) {
		const duration = Math.floor(parseInt(e.target.value));

		this.setState({
			duration
		});
	}

	onRevenueChange(e) {
		const revenue = Math.floor(e.target.value * 100);

		this.setState({
			revenue
		});
	}

	onRateChange(e) {
		const hourly_rate = Math.floor(e.target.value * 100);

		this.setState({
			hourly_rate
		});
	}

	onVouchersAllowedChange = (vouchers_allowed) => {
		if (this.state.vouchers_allowed === vouchers_allowed) return;

		this.setState({
			vouchers_allowed
		})
	}

	onCompanyPaysChange = (company_pays) => {
		if (this.state.company_pays === company_pays) return;

		this.setState({
			company_pays
		})
	}

	onPoNumberChange = (e) => {
		const po_number = e.target.value;
		if (this.state.po_number === po_number) return;

		this.setState({
			po_number
		})
	}

	async onSubmit(e) {
		e.preventDefault();

		const { metadata, onUpdate } = this.props;

		const id = metadata.appointment_id;

		try {
			const result = await this.api.request('put', `/appointments/${id}/corporate_metadata`, this.state);

			if (!result.success) throw new Error(result.error);

			this.notification.success('Corporate details successfully updated.')

			onUpdate && onUpdate(result.data);

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	render() {
		const { show, onHide } = this.props;

		const { duration, hourly_rate, revenue, po_number } = this.state;

		return (
			<GenericModal className="CorporateDetails" show={show} onHide={onHide}>
				<Title h1 text="Update Corporate Details" />
				<form onSubmit={this.onSubmit}>
					<div className="duration">
						<input
							type="number"
							value={duration || null}
							onChange={this.onDurationChange}
							placeholder="Duration"
							required />
						<div>
							Duration {duration} minutes
						</div>
					</div>
					<div className="revenue">
						<input
							type="number"
							value={revenue / 100 || null}
							onChange={this.onRevenueChange}
							placeholder="Revenue"
							required />
						<div>
							£{(revenue / 100).toFixed(2)} paid to LeSalon
						</div>
					</div>
					<div className="rate">
						<input
							type="number"
							value={hourly_rate / 100 || null}
							onChange={this.onRateChange}
							placeholder="Hourly rate"
							required />
						<div>
							Pay pro £{(hourly_rate / 100).toFixed(2)} per hour
						</div>
					</div>
					<div className="vouchers">
						<Select value={this.state.vouchers_allowed} options={[{
							value: true,
							label: 'Yes'
						}, {
							value: false,
							label: 'No'
						}]} clearable={false} onChange={(option) => this.onVouchersAllowedChange(option.value)} />
						<div>
							Allow vouchers?
						</div>
					</div>
					<div className="payment">
						<Select value={this.state.company_pays} options={[{
							value: false,
							label: 'Employees'
						}, {
							value: true,
							label: 'Company'
						}]} clearable={false} onChange={(option) => this.onCompanyPaysChange(option.value)} />
						<div>
							Who pays?
						</div>
					</div>
					{this.state.company_pays && <div className="po-number">
						<input
							value={po_number}
							onChange={this.onPoNumberChange}
							placeholder="PO Number"
							/>
						<div>
							PO Number for invoice (optional)
						</div>
					</div>}
					<button>
						Save
					</button>
				</form>
			</GenericModal>
		)
	}
}

CorporateDetailsModal.propTypes = {
	metadata: PropTypes.object.isRequired,
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired
}