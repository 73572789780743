import Alert from 'react-s-alert';

import './style.less';

export default class Notification {
	constructor() {
		this.timeout = 5000;
	}

	show(type, message) {
		type = type.toLowerCase();

		return Alert[type](message, {
			position: 'top-right',
			effect: 'slide',
			timeout: this.timeout
		})
	}

	info(message) {
		return this.show("info", "💇 " + message)
	}

	warning(message) {
		return this.show("warning", "👮 " + message)
	}

	error(message) {
		return this.show("error", "🙅 " + message)
	}

	success(message) {
		return this.show("success", "👯 " + message)
	}
}