const environments = {
	development: {
		"app_name": "web-admin-app-dev",
		"environment": "development",
		"api": "http://localhost:9091/v1"
	},

	staging: {
		"app_name": "web-admin-app-staging",
		"environment": "staging",
		"api": "https://api-staging.lesalon.com/v1"
	},

	production: {
		"app_name": "web-admin-app-production",
		"environment": "production",
		"api": "https://api.lesalon.com/v1"
	},
}

let config = environments[process.env.REACT_APP_ENVIRONMENT];

if (!config) throw new Error(`Invalid environment specified. Must be one of: ${Object.keys(environments).join(', ')}. You sent ${process.env.REACT_APP_ENVIRONMENT}.`);

export default {
	...config
}