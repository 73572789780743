import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router'

import APIClient from '../network/APIClient'

import Notification from './misc/Notification';
import Auth from '../network/Auth';

export default class Authenticated extends Component {
	constructor() {
		super();

		this.api = new APIClient();
		this.alert = new Notification();
		this.auth = new Auth();

		this.state = {};
	}

	componentDidMount() {
		//check if user is logged in
		this.auth.check().then(user => {
			this.setState({
				user: user
			})

		}).catch(error => {
			this.alert.info(error.message);

			return browserHistory.push('/login');
		})

		window.EventSystem.on(window.EventName.LOGGED_OUT, () => {
			if (this.state.user) {
				this.alert.info("You have been logged out. See you next time!");
			}

			return browserHistory.push('/login');
		})
	}

	render() {
		if (!this.state.user) return null;

		return (
			<div>
				{this.props.children}
			</div>
		);
	}
}