import React, { Fragment } from 'react';

import PageModel from './_PageModel';

import Table from '../tables/Cards';
import Title from '../misc/Title';

export default class CardsPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);
	}

	state = {
		cards: []
	}

	async componentDidMount() {
		try {
			const result = await this.api.request("get", "/cards", null, data => {
				this.setState({
					cards: [
						...this.state.cards,
						...data
					]
				})
			});

			if (!result.success) throw new Error(result.error);

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onUpdate(data) {
		let cards = this.state.cards;

		//add newly created appointment to appointments list.
		cards.push(data);

		this.setState({
			cards
		})
	}

	render() {
		const { cards } = this.state;

		return (
			<Fragment>
				<Title h1 text="Cards" />
				<div className="Card">
					<Table
						data={cards}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</Fragment>
		);
	}
}