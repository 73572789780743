import LeView from './view';

import './style.less';

export default class AppointmentsPage extends LeView {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.getCoreData();
	}
}