import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import UserLabel from '../../labels/UserLabel';
import Autocomplete from "../Autocomplete";

import './style.less';

import Moment from 'moment';

Moment.locale('uk', { week: { dow: 1 } });

export default class NewCorporateAppointment extends DataModel {
	constructor() {
		super();

		this.state = {
			// user lists
			customers: [],
			professionals: [],

			// secondary information
			locations: [],
			cards: [],

			customer_id: null,
			location_id: null,
			professional_id: null,
			date: null,
			start_time: null,
			end_time: null,
			payee: null,
			voucher_rule: null,
			company_pays: null,
			flexible_slots: null,
			vouchers_allowed: false,
			submitted: false,
		};

		this.notification = new Notification();

		this.onCustomerChange = this.onCustomerChange.bind(this);
		this.onLocationChange = this.onLocationChange.bind(this);
		this.onProfessionalChange = this.onProfessionalChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
		this.onPayeeChange = this.onPayeeChange.bind(this);
		this.onAllocationChange = this.onAllocationChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	resetData() {
		this.setState({
			customer_id: null,
			location_id: null,
			professional_id: null,
			date: this.moment().format('DD/MM/YYYY'),
			start_time: null,
			end_time: null,
			payee: null,
			voucher_rule: null,
			company_pays: null,
			allocation: null,
			flexible_slots: null,
			vouchers_allowed: false,
		});
	}

	loadCorporates() {
		return this.api.request('get', '/users?where={"type":"CORPORATE","activated":true}').then(result => {
			this.setState({
				customers: result.data,
			});

			return result.data;
		}).catch(error => this.notification.error(error.message));
	}

	loadProfessionals() {
		return this.api.request('get', '/users?where={"type":"PROFESSIONAL","activated":true}').then(result => {
			this.setState({
				professionals: result.data,
			});

			return result.data;
		}).catch(error => this.notification.error(error.message));
	}

	loadAddresses(customer_id) {
		if (!customer_id) return this.setState({
			locations: [],
		});

		return this.api.request('get', '/users/' + customer_id + '/locations').then(result => {
			const locations = result.data.filter(location => !location.deleted_at);

			this.setState({
				locations,
			});

			return locations;
		});
	}

	componentDidMount() {
		this.resetData();
		this.loadCorporates();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.resetData();
			this.loadCorporates();
		}
	}

	onCustomerChange(customer_id) {
		if (this.state.customer_id === customer_id) return;

		this.setState({
			customer_id,
		});

		return this.loadAddresses(customer_id);
	}

	onLocationChange(location_id) {
		if (this.state.location_id === location_id) return;

		this.setState({
			location_id,
		});

		return this.loadProfessionals();
	}

	onProfessionalChange(professional_id) {
		if (this.state.professional_id === professional_id) return;

		this.setState({
			professional_id,
		});
	}

	onDateChange(date) {
		if (this.state.date === date) return;

		this.setState({
			date,
		});
	}

	onStartTimeChange(start_time) {
		if (this.state.start_time === start_time) return;

		this.setState({
			start_time,
		});
	}

	onEndTimeChange(end_time) {
		if (this.state.end_time === end_time) return;

		this.setState({
			end_time,
		});
	}

	onPayeeChange(payee) {
		if (this.state.payee === payee) return;

		const company_pays = payee === 'COMPANY';

		this.setState({
			payee,
			company_pays,
		});
	}

	onAllocationChange(allocation) {
		if (this.state.allocation === allocation) return;

		const flexible_slots = allocation === 'EMPLOYEE';

		this.setState({
			allocation,
			flexible_slots,
		});
	}

	onVouchersAllowedChange = (voucher_rule) => {
		if (this.state.voucher_rule === voucher_rule) return;

		const vouchers_allowed = voucher_rule === 'ALLOWED';

		this.setState({
			voucher_rule,
			vouchers_allowed,
		});
	}

	onSubmit() {
		this.setState({
			submitted: true,
		});
	}

	onConfirm() {
		const {
			customer_id, location_id, professional_id, company_pays, flexible_slots, vouchers_allowed,
		} = this.state;

		const start_hour = parseInt(this.state.start_time.split(':')[0]);
		const start_minute = parseInt(this.state.start_time.split(':')[1]);
		const end_hour = parseInt(this.state.end_time.split(':')[0]);
		const end_minute = parseInt(this.state.end_time.split(':')[1]);

		const start_date = Moment(this.state.date, 'DD/MM/YYYY').set('hour', start_hour).set('minute', start_minute);
		const end_date = Moment(this.state.date, 'DD/MM/YYYY').set('hour', end_hour).set('minute', end_minute);

		const event_length = end_date.diff(start_date, 'minutes');

		const payload = {
			datetime: start_date.format(),
			professional_id,
			customer_id,
			location_id,
			source: 'web-admin-app',
			status: professional_id ? 'CONFIRMED' : 'PENDING',
			type: 'CORPORATE',
		};

		return this.api.request(
			'post',
			'/appointments',
			payload,
		).then(result => {
			if (!result.success) throw new Error(result.error);

			// the corproate metadata field is automatically generated immediately after creating the booking.
			// so we can do a put to this relation and overwrite its defaults with the settings chosen in the form.
			return this.api.request(
				'put',
				'/appointments/' + result.data.id + '/corporate_metadata',
				{
					duration: event_length,
					company_pays,
					flexible_slots,
					vouchers_allowed,
				},
			);
		}).then(result => {
			if (!result.success) throw new Error(result.error);

			const relations = [
				'customer',
				'professional',
				'location',
				'services.service',
			];

			return this.api.request("get", "/appointments/" + result.data.appointment_id + "?load=" + relations.join(','));
		}).then(result => {
			let message = 'All done! The corporate event has been created. ';
			if (result.data.professional) {
				message = message + result.data.professional.first_name + ' has been sent a calendar invite.';
			} else {
				message += 'As there was no professional added, the event will be offered out to the Salonettes.';
			}
			this.notification.success(message);

			// send data to parent
			this.props.onNewData && this.props.onNewData(result.data);
		}).catch(error => {
			this.notification.error(error.message);
		});
	}

	getSelectedProfessional() {
		const professional_id = this.state.professional_id;

		if (!professional_id) return;

		let found = null;

		this.state.professionals.some(pro => {
			if (pro.id === professional_id) found = pro;

			return found;
		});

		return found;
	}

	getSelectedCustomer() {
		const customer_id = this.state.customer_id;

		if (!customer_id) return;

		let found = null;

		this.state.customers.some(customer => {
			if (customer.id === customer_id) found = customer;

			return found;
		});

		return found;
	}

	getSelectedLocation() {
		const location_id = this.state.location_id;

		if (!location_id) return;

		let found = null;

		this.state.locations.some(location => {
			if (location.id === location_id) found = location;

			return found;
		});

		return found;
	}

	getBillingAddress() {
		const { locations, location_id } = this.state;

		if (!locations || !locations.length || !location_id) return;

		let billing;

		const appointment_location = locations.find(location => location.id === location_id);
		const home = locations.find(location => location.is_default === true);

		if (appointment_location.is_default === true) {
			billing = 'same as above';
		} else if (home) {
			billing = `${home.address}, ${home.postcode}`;
		} else {
			billing = 'same as above';
		}

		return billing;
	}

	render() {
		const available_times = [];

		for (
			let time_iterator = Moment().startOf('day').add(8, 'hours');
			time_iterator.hour() < 23;
			time_iterator.add(30, 'minutes')
		) {
			available_times.push(time_iterator.format('HH:mm'));
		}

		const available_dates = [];

		for (
			let date_iterator = Moment().startOf('day').subtract(6, 'months');
			date_iterator.isBefore(Moment().add(6, 'months'));
			date_iterator.add(1, 'day')
		) {
			available_dates.push(date_iterator.format('DD/MM/YYYY'));
		}

		let form_complete = false;

		if (
			this.state.customer_id
			&& this.state.location_id
			&& this.state.date
			&& this.state.start_time
			&& this.state.end_time
			&& this.state.payee
			&& this.state.allocation
			&& (
				this.state.company_pays ? true : this.state.voucher_rule
			)
		) {
			form_complete = true;
		}

		const billing_address = this.getBillingAddress();

		return (
			<form className="Form NewCorporateAppointment">
				{form_complete && this.state.submitted
					? (
						<div>
							<div className="Card">
								<div className="people">
									{this.getSelectedProfessional()
										? (
											<div>
												<UserLabel user={this.getSelectedProfessional()} shortName />
											</div>
										)
										:								(
											<div>
												No professional added!
											</div>
										)
									}
									<div>
										<FontAwesome name="arrow-right" />
									</div>
									<div>
										<UserLabel user={this.getSelectedCustomer()} shortName />
									</div>
								</div>

								<div className="details">
									<div>
										{Moment(this.state.date, 'DD/MM/YYYY').format('dddd, Do MMM YYYY')}
										<br />
										{this.state.start_time} - {this.state.end_time}
									</div>
									<div>
										{(location => {
											if (!location) return null;

											return location.address + ', ' + location.postcode;
										})(this.getSelectedLocation())}
									</div>
								</div>
							</div>
							<button type="button" className="confirm" onClick={this.onConfirm}>
								Confirm
							</button>
						</div>
					)
					: (
						<div>
							<div>
								<p>
									Which company do you want to create a booking for?
								</p>
								<Autocomplete
									value={this.state.customer_id}
									options={this.state.customers.map(customer => ({
										value: customer.id,
										label: customer.first_name + ' ' + customer.last_name,
									}))}
									clearable={false}
									onChange={this.onCustomerChange}
								/>
							</div>
							{this.state.customer_id && (
								<div>
									<p>
										Which address?
									</p>
									<Autocomplete
										value={this.state.location_id}
										options={this.state.locations.map(location => ({
											value: location.id,
											label: location.address + ', ' + location.postcode,
										}))}
										clearable={false}
										onChange={this.onLocationChange}
									/>
								</div>
							)}
							{this.state.location_id && (
								<div>
									<p>
										Do you want to set a professional?
									</p>
									<Autocomplete
										value={this.state.professional_id || null}
										options={this.state.professionals.map(professional => ({
											value: professional.id,
											label: professional.first_name + ' ' + professional.last_name,
										}))}
										placeholder="A.I. match"
										clearable
										onChange={this.onProfessionalChange}
									/>
								</div>
							)}
							{this.state.location_id && (
								<div>
									<p>
										Set the date & time for this event.
									</p>
									<div className="dates">
										<div>
											<Autocomplete
												value={this.state.date}
												options={available_dates.map(date => ({
													value: date,
													label: date,
												}))}
												clearable={false}
												onChange={this.onDateChange}
												placeholder="Date..."
											/>
										</div>
										<div>
											{this.state.date && (
												<Autocomplete
													value={this.state.start_time}
													options={available_times.map(time => ({
														value: time,
														label: time,
													}))}
													clearable={false}
													onChange={this.onStartTimeChange}
													placeholder="Start time..."
												/>
											)}
										</div>
										<div>
											{this.state.start_time && (
												<Autocomplete
													value={this.state.end_time}
													options={available_times.filter(time => time > this.state.start_time).map(time => ({
														value: time,
														label: time,
													}))}
													clearable={false}
													onChange={this.onEndTimeChange}
													placeholder="End time..."
												/>
											)}
										</div>
									</div>
								</div>
							)}
							{this.state.end_time && (
								<div>
									<p>
										Who will pay for the treatments at this event?
									</p>
									<Autocomplete
										value={this.state.payee}
										options={[{
											value: 'COMPANY',
											label: 'Company',
										}, {
											value: 'EMPLOYEE',
											label: 'Employees',
										}]}
										clearable={false}
										onChange={this.onPayeeChange}
									/>
									{this.state.payee === 'COMPANY' && (
										<div>
											<p>Billing address: {billing_address}</p>
											<p className="billing-note">Xero invoices will state this address. To use a different one, add it as the customer's HOME address before generating.</p>
										</div>
									)
									}
								</div>
							)}
							{this.state.payee && (
								<div>
									<p>
										How do you want time allocation to work for this event?
									</p>
									<Autocomplete
										value={this.state.allocation}
										options={[{
											value: 'AUTOMATIC',
											label: 'Automatic allocation',
										}, {
											value: 'EMPLOYEE',
											label: 'Employee can choose',
										}]}
										clearable={false}
										onChange={this.onAllocationChange}
									/>
								</div>
							)}
							{this.state.payee && !this.state.company_pays && this.state.allocation
						&& (
							<div>
								<p>
									Do you want to allow corporate vouchers for this event?
								</p>
								<Autocomplete
									value={this.state.voucher_rule}
									options={[{
										value: 'ALLOWED',
										label: 'Vouchers allowed',
									}, {
										value: 'NOT_ALLOWED',
										label: 'Vouchers not allowed',
									}]}
									clearable={false}
									onChange={this.onVouchersAllowedChange}
								/>
							</div>
						)}
							{form_complete && (
								<button type="button" className="confirm" onClick={this.onSubmit}>
									Submit
								</button>
							)}
						</div>
					)}
			</form>
		);
	}
}

NewCorporateAppointment.propTypes = {
	onNewData: PropTypes.func.isRequired,
};
