import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router'

import Notification from '../../misc/Notification';
import Auth from '../../../network/Auth';

import LoginForm from '../../forms/Login';

import APIClient from '../../../network/APIClient';

import './style.less';

export default class LoginPage extends Component {
	constructor() {
		super();

		this.state = {};
		this.api = new APIClient();
		this.alert = new Notification();
		this.auth = new Auth();

		this.login = this.login.bind(this);
	}

	componentDidMount() {
		this.auth.check().then(() => {
			return browserHistory.push('/');

		}).catch(error => {
			//do nothing - they need to log in.
		})
	}

	login(credentials) {
		this.auth.login(credentials).then(() => {
			this.alert.success("You have successfully logged in. Welcome back! 🙋")

			return browserHistory.push('/');

		}).catch(error => {
			this.alert.error(error.message);
		})
	}

	render() {
		return (
			<div className="Page LoginPage">
				<div className="split-view">
					<div />
					<div flex={2}>
						<div className="innerLoginForm">
							<div>
								Enter your LeSalon email address and password below to login.
							</div>
							<LoginForm onSubmit={this.login} />
						</div>
					</div>
					<div />
				</div>
			</div>
		);
	}
}