import React, { Component } from 'react';

export default class TableBody extends Component{
	render(){
		return (
			<div className={["body", this.props.className].join(" ")}>
				{this.props.children}
			</div>
		)
	}
}