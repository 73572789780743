import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VoucherInfoCard from '../VoucherInfoCard';
import DataModel from '../../_DataModel';

import './style.less';

export default class AppointmentDiscountsCard extends DataModel {
	render() {
		const { appointment, accounts, voucher } = this.props;

		if (!appointment) return null;
		if (!accounts) return null;

		const { created_at } = appointment;
		const { rrp, revenue, discount } = accounts;

		if (!discount) return <div className="Card">
			<i>None</i>
		</div>;

		//assume by default that the entire discount is user credit.
		let credit = discount;

		//but what if we have a voucher?
		if (voucher && voucher.id) {
			//this is a bit complicated because on 6th October 2017 we fixed a bug in the API where the discounts were being applied the wrong way round.
			//it was: apply percentage first, then apply user credit
			//it should have been: apply user credit first, then apply percentage discount

			//so, we have to apply different behaviour based on the cutoff.
			//this is when the change went live.
			const cutoff = this.moment('2017-10-06T16:30:00+01:00');

			const { amount, percentage } = voucher;

			if (amount) {
				//easy! we're just subtracting whole numbers here.
				credit = rrp - amount - revenue;

			} else if (voucher.percentage) {
				//hard! we need to apply the cutoff logic.

				//get the fraction version of this discount to multiply/divide the amount by.
				const fraction = (100 - percentage) / 100;

				if (this.moment(created_at).isBefore(cutoff)) {
					//OLD BEHAVIOUR
					credit = (rrp * fraction) - revenue
				} else {
					//NEW BEHAVIOUR
					credit = rrp - (revenue / fraction)
				}

			} else {
				//there's something wrong with this voucher, so do nothing.
			}
		}

		return (
			<div className="Card AppointmentDiscountsCard">
				{credit > 0 && (
					<div className="credit">
						<div className="title">
							From Credit Balance
						</div>
						£{(credit / 100).toFixed(2)}
					</div>
				)}
				{voucher && voucher.id && (
					<div className="vouchers">
						<VoucherInfoCard voucher={voucher} />
					</div>
				)}
			</div>
		);
	}
}

AppointmentDiscountsCard.propTypes = {
	appointment: PropTypes.object.isRequired,
	accounts: PropTypes.object.isRequired,
	voucher: PropTypes.object
}