import React, { Component } from 'react';

import CreateResource from '../../buttons/CreateResource';
import Title from '../../misc/Title';
import FilterProfessionals from '../../modals/FilterProfessionals';

import LeModel from './model';
import StorefrontsTable from '../../tables/Storefronts';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	render() {
		const { filtered_professionals, metrics } = this.state;

		const formatted_professionals = filtered_professionals.map(pro => {
			if (metrics) {
				const { id } = pro;

				pro.metrics = metrics[id];
			}

			return pro;
		});

		return (
			<div className="Page ProfessionalsPage">
				<Title h1 text="Storefront Therapists">
					<form className="toggle-deactivated">
						<input
							id="UsersPageToggleDeactivated"
							type="checkbox"
							checked={this.state.showDeactivated}
							onChange={this.toggleDeactivated}
						/>
						<label htmlFor="UsersPageToggleDeactivated">
							Inactive
						</label>
					</form>

					<CreateResource
						title="New Storefront Therapist"
						resource="users"
						existingData={{ type: "STOREFRONT" }}
						onNewData={this.onUpdate}
					/>
				</Title>
				<div className="Card">
					<StorefrontsTable
						data={formatted_professionals.filter(user => {
							if (!this.state.showDeactivated) {
								return !!user.activated;
							}

							return true;
						})}
						infinite
						interactive
						filterable
					/>
				</div>
				<FilterProfessionals
					show={this.state.showFilterModal}
					onHide={this.hideFilterModal}
					services={this.state.services}
					categories={this.state.categories}
					filterResults={this.filterResults}
					resetFilters={this.resetFilters}
					addToSelected={this.addToSelected}
					removeFromSelected={this.removeFromSelected}
					selectedCategories={this.state.selectedCategories}
					selectedServices={this.state.selectedServices}
				/>
			</div>
		);
	}
}
