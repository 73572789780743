import _ from 'lodash';
import React from 'react';
import { Alert } from 'react-bootstrap'

import Commission from './components/Commission';
import ProfessionalFilterMenu from '../../misc/ProfessionalFilterMenu';
import IssuePayoutModal from '../../modals/IssuePayout';

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state
	}

	shouldShowAutoPayout() {
		const { filteredProfessional, payouts } = this.state;

		//only show auto payout when a therapist is selected.
		if (!filteredProfessional) return false;

		//don't show the payout button until existing payouts have been initialised for the current user.
		if (!payouts[filteredProfessional]) return false;

		return true;
	}

	hasSentPayout() {
		const { filteredProfessional, payouts, start, end } = this.state;

		if (payouts[filteredProfessional]) {
			for (const payout of payouts[filteredProfessional]) {
				const scope_start = this.moment(start).format('YYYY-MM-DD');
				const scope_end = this.moment(end).subtract(1, 'minute').format('YYYY-MM-DD');

				const payout_start = this.moment(payout.start_date).format('YYYY-MM-DD');
				const payout_end = this.moment(payout.end_date).format('YYYY-MM-DD');

				if (
					scope_start === payout_start
					&& scope_end === payout_end
				) {
					return true;
				}
			}
		}

		return false;
	}

	canIssuePayout() {
		const { start, end } = this.state;

		//future dates can't auto payout.
		const start_moment = this.moment(start);
		const end_moment = this.moment(end);

		const now = this.moment();
		if (now.isBefore(start_moment) || now.isBefore(end_moment)) return false;

		if (this.hasSentPayout()) return false;

		return true;
	}

	render() {
		const {
			//dependencies
			professionals,
			filteredProfessional,

			//booking list
			bookings,

			//misc adjustments,
			adjustments,

			//range
			start,
			end,

			//ui
			payoutModalVisible
		} = this.state;

		//don't render yet if dates have not been initialized.
		if (!start || !end) return null;

		const start_moment = this.moment(start);
		const end_moment = this.moment(end);

		//filter bookings to date range and user (if any)
		const filtered_bookings = _.orderBy(
			bookings,
			['datetime', 'id'],
			['desc', 'desc']
		).filter(booking => {
			const { datetime, professional_id } = booking;

			const date_moment = this.moment(datetime);

			if (date_moment.isBefore(start_moment)) return false;
			if (end_moment.isBefore(date_moment)) return false;

			if (filteredProfessional && professional_id !== filteredProfessional) return false;

			return true;
		})

		//filter adjustments to date range and user (if any)
		const filtered_adjustments = adjustments.filter(adjustment => {
			const { datetime, user_id } = adjustment;

			const date_moment = this.moment(datetime);

			if (date_moment.diff(start_moment) < 0) return false;
			if (end_moment.diff(date_moment) <= 0) return false;

			if (filteredProfessional && user_id !== filteredProfessional) return false;

			return true;
		})

		let table_name = 'Earnings_';

		if (filteredProfessional) {
			const professional = this.professionalById(filteredProfessional);

			if (professional) {
				table_name += [professional.first_name, professional.last_name].join('_');
			}
		} else {
			table_name += 'Everyone';
		}

		table_name += `_${this.moment(start).format('YYYY-MM-DD')}_${this.moment(end).format('YYYY-MM-DD')}`

		let warning = null;
		const now = this.moment();

		if (now.isBefore(start)) {
			warning = 'This is a future week, which means the list below will probably change.'
		} else if (now.isBefore(end)) {
			warning = 'This week is not finished yet, which means the data might not be final.'
		}

		return (
			<div className="Page PaymentsPage">
				<div className="menu">
					<ProfessionalFilterMenu
						professionals={professionals}
						selectedId={filteredProfessional}
						onSelect={filteredProfessional => {
							this.setState({ filteredProfessional });

							this.getPayouts(filteredProfessional);
						}}
					/>
				</div>
				<div className="main">
					{warning && <Alert bsStyle="warning">
						⚠️ {warning}
					</Alert>}
					<form className="toolbar">
						<div>
							<div className="info">
								{this.moment(start).format('dddd DD/MM HH:mm')} to {this.moment(end).subtract(1, 'minute').format('dddd DD/MM HH:mm')}
							</div>
							<button type="button" onClick={this.previousWeek}>
								Previous
							</button>
							<button type="button" onClick={this.nextWeek}>
								Next
							</button>
							{
								this.shouldShowAutoPayout()
								&& <div className="auto-payout">
									<button type="button" onClick={this.showPayoutModal}>
										Manage payout
									</button>
								</div>
							}
						</div>
					</form>
					<div className="content">
						{
							filtered_bookings.length
								? <Commission
									tableName={table_name}
									bookings={filtered_bookings}
									adjustments={filtered_adjustments}
								/>
								: <div className="empty">
									No bookings in this time range.
								</div>
						}
					</div>
				</div>
				{filteredProfessional && <IssuePayoutModal
					show={payoutModalVisible}
					onHide={this.hidePayoutModal}

					startDate={start}
					endDate={end}
					canIssuePayout={this.canIssuePayout()}

					user={this.professionalById(filteredProfessional)}
					bookings={filtered_bookings}
					adjustments={filtered_adjustments}

					onAddAdjustment={this.onAddAdjustment}
					onDeleteAdjustment={this.onDeleteAdjustment}
					onIssuePayout={this.sendAutoPayout}
				/>}
			</div>
		);
	}
}