import Axios from 'axios';
import AsyncPool from 'tiny-async-pool';

import Config from '../Config';
import AuthToken from './AuthToken'

export default class APIClient {
	constructor() {
		this.endpoint = Config.api;
		this.name = Config.app_name;
		this.supported_methods = ['get', 'delete', 'head', 'post', 'put', 'patch']

		this.auth = new AuthToken();
	}

	async call(params) {
		params.method = params.method.toLowerCase();

		if (this.supported_methods.indexOf(params.method) < 0) {
			throw new Error("Invalid method supplied. Please supply one of: ", this.supported_methods);
		}

		if (params.path[0] !== "/") {
			throw new Error("Please start the path with a `/` character. You supplied: ", params.path);
		}

		var payload = {
			method: params.method,
			url: this.endpoint + params.path,
			headers: {
				'From': this.name
			}
		}

		//optional params
		if (params.auth) {
			payload.headers['Authorization'] = params.auth;
		}

		if (params.data) {
			payload.data = params.data;
		}

		try {
			const response = await Axios(payload);

			return {
				success: true,
				data: response.data,
				headers: response.headers,
			}
		} catch (error) {
			console.error(error);

			if (error.response) {
				return error.response.data;
			}

			throw error;
		}
	}

	PAGE_SIZE = 200
	PAGE_CONCURRENCY = 10

	// 1) method: 'get', 'post', 'put', etc.
	// 2) path: '/users', '/auth/token', etc.
	// 3) data: null, {}, { hello: 'world' }, etc.
	// 4) onPageLoaded: null, (data_array, page_number) => { this.setState({ data: [...this.state.data, ...data_array] }) }
	// 5) start_page: 1, 2, 3, 4, etc.
	// 6) end_page: 1, 2, 3, 4, etc.
	async request(method, path, data, onPageLoaded, start_page = 1, end_page = Infinity) {
		method = method.toLowerCase();

		// we store the start url to cancel pending network requests when the page has changed.
		const initial_url = window.location.pathname;

		try {
			window.EventSystem.emit(window.EventName.LOAD_START);

			let initial_path = path + '';

			if (method === 'get') {
				// add a query if necessary.
				if (!initial_path.includes('?')) initial_path += '?';

				// add pagination to all get requests.
				initial_path += `&page=${start_page}&page_size=${this.PAGE_SIZE}`;
			}

			const initial_request = await this.call({
				method,
				path: initial_path,
				data,
				auth: this.auth.token
			});

			let result = initial_request;

			const { headers } = result;

			// if the system told us that there are more pages, then we will iterate through the remaining pages to build the final result.
			if (result.success && headers) {
				// if a page loaded function was supplied, we send the partial data to it here.
				if (onPageLoaded) {
					onPageLoaded(result.data, start_page);
				}

				const total_pages = parseInt(headers['x-total-pages'], 10);

				// we only want to run through pagination if there are actually more pages.
				if (total_pages && total_pages > start_page) {
					const paginator = async page_number => {
						const current_url = window.location.pathname;

						// skip any pending pages if the page has changed.
						// for example, an admin quickly found the booking and clicked through to another page. It's pointless to load any more.
						if (current_url !== initial_url) return;

						// make a new path that fetches the new page number.
						let new_path = initial_path.replace(/page=\d+/g, `page=${page_number}`)

						window.EventSystem.emit(window.EventName.LOAD_START);

						const new_result = await this.call({
							method,
							path: new_path,
							data,
							auth: this.auth.token
						})

						window.EventSystem.emit(window.EventName.LOAD_END);

						if (!new_result.success) throw new Error(
							`Pagination failed because page number ${page_number} triggered an error after an initial success:\n\n${JSON.stringify(new_result)}\n\nThe API told us there were ${total_pages} pages to fetch.\nYou can debug this by pinging ${new_path}`
						);

						// concatenate the new page data into the final result.
						result = {
							...result,
							data: [
								...result.data,
								...new_result.data
							]
						}

						// if a page loaded function was supplied, we send the partial data to it here.
						if (onPageLoaded) {
							onPageLoaded(new_result.data, page_number);
						}

						// console.log(`${path}: ${page_number}/${total_pages} complete. Number of items: ${result.data.length}`);
					};

					// build a list of remaining pages to fetch.
					// note: we do +1 the start_page in here because we already fetched it.
					// Math.min takes the lowest value of total_pages or end_page.
					let pages = [];
					for (let i = start_page + 1; i <= Math.min(total_pages, end_page); i++) {
						pages.push(i);
					}

					// async pool iterates through the list of pages we build, and triggers the paginator function that we declared above. It throttles the concurrency for us.
					// async pool actually returns the list of result just like Promise.all - I could have used concat here but decided to build the result on the fly with the spread operator.
					await AsyncPool(
						this.PAGE_CONCURRENCY,
						pages,
						paginator
					);
				}
			}

			if (!result.success) console.warn(path, result);

			window.EventSystem.emit(window.EventName.LOAD_END);

			return result;
		} catch (error) {
			// this should only trigger if the API is down.
			console.error(error);

			window.EventSystem.emit(window.EventName.LOAD_END);

			return {
				success: false,
				error: error.message
			}
		}
	}
}