import React, { Component } from 'react';

import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor(){
		super();

		this.state = {
			full_list: [],
			selected_list: []
		}
	}

	async loadBadges(){
		try{
			const result = await this.api.request('get', '/badges');

			if(!result.success) throw new Error(result.error);

			this.setState({
				full_list: result.data
			});

		} catch(error) {
			this.notification.error(error.message);
		}
	}

	async loadActivatedBadges(props){
		props = props || this.props;

		const { user_id } = props;

		if(!user_id) return;

		
		try{
			const result = await this.api.request('get', `/users/${user_id}?load=badges`);
			
			if(!result.success) throw new Error(result.error);

			let selected = result.data.badges;
			let formatted = {};

			selected.forEach(item => {
				formatted[item.badge_id] = item;
			})

			this.setState({
				selected_list: formatted
			});

		} catch(error) {
			this.notification.error(error.message);
		}
	}
}