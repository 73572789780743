import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabelModel from '../_LabelModel';

import './style.less';

export default class AppointmentStatusLabel extends LabelModel {
	render() {
		const { status } = this.props;

		if (!status || !status.length) return null;

		return (
			<div className="Label Pill AppointmentStatus">
				<span className={this.props.status}>
					{
						status[0].toUpperCase()
						+ status.slice(1, status.length).split('_').join(' ').toLowerCase()
					}
				</span>
			</div>
		)
	}
}

AppointmentStatusLabel.propTypes = {
	status: PropTypes.string.isRequired
}