import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabelModel from '../_LabelModel';

import './style.less';

export default class PaymentTypeLabel extends LabelModel {
	render() {
		let type = this.props.type;

		if (!type || !type.length) return null;

		return (
			<div className="Label Pill PaymentType">
				<span className={type}>
					{
						type[0].toUpperCase() +
						type.slice(1, type.length).toLowerCase().split('_').join(' ')
					}
				</span>
			</div>
		)
	}
}

PaymentTypeLabel.propTypes = {
	type: PropTypes.string.isRequired
}