import React from 'react';

import { Alert } from 'react-bootstrap';
import CreateResource from '../buttons/CreateResource';
import Title from '../misc/Title';

import PageModel from './_PageModel';
import UpdateResource from '../buttons/UpdateResource';
import {
	Table, TBody, TRow, TCol,
} from '../tables/Generic';
import UpdateImageCard from '../cards/UpdateImageCard';
import ProfessionalServicesCard from '../cards/ProfessionalServicesCard';
import ProfessionalPortfolioCard from '../cards/ProfessionalPortfolioCard';
import Avatar from '../misc/Avatar';

export default class UsersPage extends PageModel {
	constructor() {
		super();

		this.state = {
			user: null,
		};
	}

	componentDidMount() {
		const { id } = this.props.params;

		const relations = [
			'website',
		];

		this.api.request("get", `/users?where={"id":${id}}&load=${relations.join(',')}`, null, data => {
			this.setState({
				user: data[0],
			});
		});
	}

	syncWebsite = async () => {
		const { id } = this.props.params;

		const { user } = this.state;

		try {
			const result = await this.api.request("post", `/user_websites/${id}/publish`, null);

			if (!result.success) throw new Error(result.error);

			this.notification.success(`Success! ${user.first_name}'s website will be synced soon!`);
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	isProfessional(user) {
		user = user || this.state.user;

		if (!user) return null;

		return ['PROFESSIONAL', 'STOREFRONT'].includes(user.type);
	}

	onLogoImageUpdate = async (base64) => {
		const { user } = this.state;

		// get user id.
		const { id } = user;

		try {
			const result = await this.api.request('post', `/user_websites/${id}/logo`, {
				image: base64.split(';base64,').pop(),
			});

			if (!result.success) throw new Error(result.error);

			const { logo_image } = result.data;

			user.website.logo_image = logo_image;

			this.setState({
				user,
			});

			this.notification.success('Nice! A new logo image has been uploaded.');
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onAvatarUpdate = async (image) => {
		const { user } = this.state;

		// get user id.
		const { id } = user;

		try {
			const result = await this.api.request('post', `/users/${id}/avatar`, {
				image,
			});

			if (!result.success) throw new Error(result.error);

			const { avatar } = result.data;

			user.avatar = avatar;

			this.setState({
				user,
			});

			window.EventSystem.emit(window.EventName.PROFILE_UPDATED, user);
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onUserUpdate = (data) => {
		const user = this.state.user;

		user.website = data;

		this.setState({
			user,
		});
	}

	renderWebsiteInfo(website) {
		return (
			<div>
				<div className="Card">
					<Table>
						<TBody>
							<TRow>
								<TCol>Domain</TCol>
								<TCol>{<a target="blank" href={`https://${website.domain}`}>{website.domain}</a> || <i>Not set</i>}</TCol>
							</TRow>
							<TRow>
								<TCol>Active</TCol>
								<TCol>{website.active ? "Yes" : "No"}</TCol>
							</TRow>
							<TRow>
								<TCol>Cloudfront ID</TCol>
								<TCol>{website.cloudfront_distribution_id || <i>Not set</i>} </TCol>
							</TRow>
							<TRow>
								<TCol>S3 Bucket</TCol>
								<TCol>
									{website.s3_bucket_url || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Heading</TCol>
								<TCol>
									{website.heading || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Logo Image URL</TCol>
								<TCol>
									{website.logo_image || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Description</TCol>
								<TCol>
									{website.description || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>About Me</TCol>
								<TCol>
									{website.about_me || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Qualifications</TCol>
								<TCol>
									{website.qualifications || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Experience</TCol>
								<TCol>
									{website.experience || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Instagram</TCol>
								<TCol>
									{website.instagram || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Facebook</TCol>
								<TCol>
									{website.facebook || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Whatsapp</TCol>
								<TCol>
									{website.whatsapp || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Email</TCol>
								<TCol>
									{website.email || <i>Not set</i>}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Other (areas & reviews)</TCol>
								<TCol>
									{JSON.stringify(website.complex_items) || <i>Not set</i>}
								</TCol>
							</TRow>
						</TBody>
					</Table>
				</div>
			</div>
		);
	}

	render() {
		const { user } = this.state;

		if (!user) return null;

		const { website } = user;

		const excludeKeys = ["complex_items", "created_at", "updated_at"];

		return (
			<div className="bordered">
				{
					!user.website || !user.website.domain && (
						<Alert key="missing domain" bsStyle="danger">
							⚠️ {user.first_name} is missing a domain. You must add a domain before the website can be created.
						</Alert>
					)}
				<Title h1 text={`Manage Website - ${user.first_name} ${user.last_name}`}>
					{
						user.website && user.website.domain && (
							<button className="button" onClick={this.syncWebsite}>
								Sync
							</button>
						)
					}
					{
						user.website && user.website.domain && (
							<UpdateResource
								title="Edit Website"
								resource="user_websites"
								existingData={user.website}
								onNewData={this.onUserUpdate}
								excludeKeys={excludeKeys}
							/>
						)
					}
					{
						user.website && !user.website.id && (
							<CreateResource
								title="Create Website"
								resource="user_websites"
								existingData={{ user_id: user.id }}
								onNewData={this.onUserUpdate}
								excludeKeys={excludeKeys}
							/>
						)
					}
				</Title>
				<div className="split-view bordered">
					<div>
						<Title h3 text="Current Website Info" />
						{website && this.renderWebsiteInfo(website)}
					</div>
					<div>

						<div className="split-view bordered">
							<div>
								<Title h3 text="Profile Image" />
								<div className="Card UserProfileCard">
									<Avatar url={user.avatar} />
								</div>
								<Title h3 text="Upload New Profile Image" toggle />
								<UpdateImageCard
									width={800}
									height={800}
									onImageUpdate={this.onAvatarUpdate}
								/>
							</div>
							<div>
								<Title h3 text="Logo Image" />
								<div className="Card UserProfileCard">
									{
										website.logo_image ? (
											<Avatar url={user.website.logo_image} square />
										) : <i>Not Set</i>
									}
								</div>
								{
									user.website && user.website.domain && (
										<div>
											<Title h3 text="Upload New Logo" toggle />
											<UpdateImageCard
												width={800}
												height={800}
												onImageUpdate={this.onLogoImageUpdate}
											/>
										</div>
									)
								}
							</div>
						</div>
					</div>

				</div>
				<Title h3 text="Storefront services" toggle />
				<ProfessionalServicesCard type="STOREFRONT" professional_id={user.id} />
				<Title h3 text="Portfolio" toggle />
				<ProfessionalPortfolioCard professional_id={user.id} />
			</div>
		);
	}
}
