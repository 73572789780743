import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import FormModel from '../_FormModel';

export default class UserNotificationForm extends FormModel {
	constructor() {
		super();
		this.state = {
			text: ""
		};
	}

	handleChange = (event) => {
		const { value } = event.target;
		this.setState({ text: value });
	}

	sendNotification = (event) => {
		event.preventDefault(); // no reloading when submit
		const { user } = this.props;
		const { text } = this.state;
		if (!user || !user.id || !text.length) return;
		this.api.request('post', '/users/broadcast', { user_id: user.id, message: text })
			.then((result) => {
				if (!result.success) throw new Error(result.error);
				this.notification.success("Notification successfully sent");
				this.setState({ text: "" })
			})
			.catch(error => {
				this.notification.error(error.message);
			});
	}

	render() {
		return (
			<form className="UserDescription">
				<Textarea value={this.state.text} onChange={this.handleChange} placeholder="Write a push notification..." />
				<button onClick={this.sendNotification}>Send notification</button>
			</form>
		)
	}
}

UserNotificationForm.propTypes = {
	user: PropTypes.object,
}