import Config from '../Config';

export default class Local {
	constructor() {
		this.prefix = Config.app_name + "_";
	}

	get(key) {
		let result = window.localStorage.getItem(this.prefix + key);

		try {
			return JSON.parse(result)
		} catch (e) {
			return result;
		}
	}

	set(key, value) {
		return window.localStorage.setItem(this.prefix + key, JSON.stringify(value));
	}

	remove(key) {
		return window.localStorage.removeItem(this.prefix + key);
	}
}