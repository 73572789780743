import React, { Component } from 'react';
import {
	Router, Route, Link, browserHistory,
} from 'react-router';

import LeView from './view';

import './style.less';

export default class UserPage extends LeView {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;
	}

	async componentDidMount() {
		await this.getCoreData();
		await this.getAdditionalData();

		window[`gdprDeleteUser${this.id}`] = async () => {
			const { user } = this.state;

			if (!user) return;

			const { id, first_name, email } = user;

			// eslint-disable-next-line no-alert
			if (!window.confirm(
				`You are about to delete ${first_name}'s account which has the User ID ${id} and the email address ${email}.\n\nTheir personal data will be erased permanently.\n\nTheir bookings and activity will be kept but moved to a "Deleted user" account.\n\nIf this is NOT correct, PRESS CANCEL NOW. THERE IS NO UNDO.\n\nIf you are sure this user is to be deleted, press OK.`,
			)) return;

			const result = await this.api.request(
				'delete',
				`/users/${this.id}`,
			);

			console.log(`GDPR delete result:`, result);

			if (result.success) {
				window.location = "/";
			} else {
				// eslint-disable-next-line no-alert
				window.alert(result.error);
			}
		};
	}
}
