import LeView from './view';

import './style.less';

export default class PaymentsPage extends LeView {
	constructor(props) {
		super(props);

		this.previousWeek = this.previousWeek.bind(this);
		this.nextWeek = this.nextWeek.bind(this);
		this.sendAutoPayout = this.sendAutoPayout.bind(this);
		this.showPayoutModal = this.showPayoutModal.bind(this);
		this.hidePayoutModal = this.hidePayoutModal.bind(this);
	}

	componentDidMount() {
		this.setDateRange(0);

		this.getCoreData();
	}

	previousWeek() {
		this.setDateRange(-1);
	}

	nextWeek() {
		this.setDateRange(1);
	}

	async sendAutoPayout() {
		const { start, end, filteredProfessional } = this.state;

		const start_date = this.moment(start).format('YYYY-MM-DD');
		const end_date = this.moment(end).subtract(1, 'minute').format('YYYY-MM-DD');
		const user_id = filteredProfessional;

		try {
			const result = await this.api.request('POST', `/users/${user_id}/payouts`, {
				start_date,
				end_date
			});

			if (!result.success) throw new Error(result.error);

			const payout = result.data;

			let { payouts } = this.state;

			//add payout to state (to update UI).
			payouts[user_id].push(payout);

			this.setState({
				payouts
			});

			this.notification.success(`Done! Remember to manually send £${(payout.amount / 100).toFixed(2)} via bank transfer to this Salonette.`);

			//hide modal to reset the process
			this.hidePayoutModal();

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	showPayoutModal() {
		this.setState({
			payoutModalVisible: true
		})
	}

	hidePayoutModal() {
		this.setState({
			payoutModalVisible: false
		})
	}
}