import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VoucherInfoCard extends Component {
	render() {
		if (!this.props.voucher) return null;

		let voucher = this.props.voucher;

		return (
			<div className="Card UserReferralVoucherCard">
				<div className="flex">
					{voucher.user_id && <div className="title">
						Personal Referral Voucher
					</div>}
					<div className="code">
						<span>{voucher.code}</span>
					</div>
				</div>
				<div className="amount">
					{voucher.amount ? <span>£{(voucher.amount / 100).toFixed(2)}</span> : null}
					{voucher.percentage ? <span>{voucher.percentage}%</span> : null}
				</div>
			</div>
		)
	}
}

VoucherInfoCard.propTypes = {
	voucher: PropTypes.object.isRequired
}