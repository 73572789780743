import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageModel from '../../../_PageModel';
import CommissionTable from '../../../../tables/Commission';

import './style.less';

export default class Commission extends PageModel {
	constructor(props) {
		super(props);
	}

	state = {

	}

	getService(id) {
		const services = this.props.services || {};

		return services[id];
	}

	getUser(id) {
		const users = this.props.users || {};

		return users[id];
	}

	getRRP(booking) {
		let price = 0;

		for (const x of booking.services) {
			const service = this.getService(x.service_id);

			if (!service) continue;

			let basket_item_price = service.price * x.quantity;

			price += basket_item_price;
		}

		//add 20% to RRP if surge pricing is active
		if (booking.surge) price *= 1.2;

		return price;
	}

	getCommission(booking) {
		let commission = 0;

		for (const x of booking.services) {
			const service = this.getService(x.service_id);

			if (!service) continue;

			let basket_item_commission = service.commission * x.quantity;

			commission += basket_item_commission;
		}

		return commission;
	}

	render() {
		let { bookings, tableName } = this.props;

		bookings = bookings || [];

		let results = [];

		let total = 0;

		for (const booking of bookings) {
			if (!booking.professional_id) continue;

			const accounting_data = booking.accounts;

			//this booking has no accounting data so we have to skip it.
			if (!accounting_data) continue;

			//increment total so we can display it at the bottom of the table.
			total += accounting_data.earnings;

			//push to table data.
			results.push(accounting_data);
		}

		return <div className="Commission">
			<CommissionTable
				name={tableName}
				data={results}
				infinite={false}
				filterable={false}
				exportable={true}
				interactive={true}
			/>
			<div className="total">
				<div className="key">
					TOTAL
				</div>
				<div className="value">
					{(total / 100).toFixed(2)}
				</div>
			</div>
		</div>;
	}
}

Commission.propTypes = {
	tableName: PropTypes.string.isRequired,

	//bookings list.
	bookings: PropTypes.array.isRequired
}