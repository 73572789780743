import React, { Component } from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class CardsTable extends TableModel {
	constructor() {
		super();

		this.className = "CardsTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Card ID"
		}, {
			title: 'Brand',
			type: String,
			sortable: true,
			searchable: true,
			description: "Brand"
		}, {
			title: 'Last 4',
			type: String,
			searchable: true,
			sortable: true,
			description: "Last 4 Digits"
		}, {
			title: 'User',
			type: String,
			sortable: false,
			searchable: false,
			description: "User"
		}, {
			title: 'Expired',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Expired'
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let card = datum;

			let columns = [
				card.id,
				card.brand,
				card.last4,
				<UserLabel id={card.user_id} fetch={true} />,
				this.moment(card.expiry_date).isBefore(this.moment()) ? 'Yes' : 'No'
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/cards/' + data[0];
	}
}