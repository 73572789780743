import React, { Component } from 'react';

import TableModel from '../_TableModel';

export default class ZonesTable extends TableModel {
	constructor() {
		super();

		this.className = "ZonesTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Zone ID"
		}, {
			title: 'Postcode',
			type: String,
			sortable: true,
			searchable: true,
			description: "Postcode"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let voucher = datum;

			let columns = [
				voucher.id,
				voucher.post_code
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/zones/' + data[0];
	}
}