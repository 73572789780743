import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import LeComponent from '../../_DataModel';
import {
	Table, TBody, TRow, TCol,
} from '../../tables/Generic';
import ReviewDetailsModal from '../../modals/ReviewDetails';
import RatingLabel from '../../labels/RatingLabel';
import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class ReviewsCard extends LeComponent {
	constructor() {
		super();
	}

	groupAnswers() {
		const { reviews } = this.props;

		const sorted = reviews.sort((a, b) => {
			if (a.created_at < b.created_at) return -1;
			if (a.created_at > b.created_at) return 1;
			return 0;
		});

		const grouped = {};

		for (const review of sorted) {
			const { user_id } = review;

			grouped[user_id] = grouped[user_id] || [];

			grouped[user_id].push(review);
		}

		return grouped;
	}

	findOverallRating(answers) {
		return answers.find(answer => {
			switch (answer.question.key) {
				case 'CUSTOMER_OVERALL':
				case 'SALONETTE_OVERALL':
					return answer;
			}
		});
	}

	findComments(user_id) {
		const { comments } = this.props;

		return comments.find(comment => comment.user_id === user_id);
	}

	findAnswers(user_id) {
		const grouped = this.groupAnswers();

		return grouped[user_id];
	}

	render() {
		const { reviews, onReviewUpdate } = this.props;
		const { selectedUser } = this.state;

		if (!reviews || !reviews.length) return (
			<div className="Card">
				<i>No reviews yet</i>
			</div>
		);

		const grouped = this.groupAnswers();

		return (
			<div className="Card ReviewsCard">
				<Table>
					<TBody>
						{Object.values(grouped).map(answers => {
							const rating = this.findOverallRating(answers);

							if (!rating) return null;

							const star_classes = ['stars'];
							if (
								rating
								&& rating.ignore
							) star_classes.push('ignored');

							const comments = this.findComments(rating.user_id);

							return (
								<TRow>
									<TCol
										onClick={() => this.setState({
											selectedUser: rating.user_id,
										})}
									>
										{
											rating
											&& (
												<div className={star_classes.join(' ')}>
													<RatingLabel rating={rating.option.order} />
													<div className="ignore">
														{
															rating.ignore === true
															&& (
																<span>
																	Won't affect rating
																</span>
															)
														}
													</div>
												</div>
											)
										}
										{
											comments
											&& comments.text
											&& comments.text.length > 0
											&& (
												<div className="comments">
													"{comments.text}"
												</div>
											)
										}
										{
											rating
											&& (
												<UserLabel id={rating.user_id} fetch />
											)
										}
									</TCol>
								</TRow>
							);
						})}
					</TBody>
				</Table>
				{
					selectedUser
					&& (
						<ReviewDetailsModal
							answers={this.findAnswers(selectedUser)}
							show
							onHide={() => this.setState({
								selectedUser: null,
							})}
							onReviewUpdate={onReviewUpdate}
						/>
					)}
			</div>
		);
	}
}

ReviewsCard.propTypes = {
	reviews: PropTypes.array.isRequired,
	comments: PropTypes.array.isRequired,
	onReviewUpdate: PropTypes.func.isRequired,
};
