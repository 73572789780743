import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import CorporateDetailsModal from '../../modals/CorporateDetails';

import DataModel from '../../_DataModel';
import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class AppointmentActionsCard extends DataModel {
	constructor() {
		super();

		this.state = {
			modalOpen: false
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onCorporateUpdate = this.onCorporateUpdate.bind(this); this.triggerEmail = this.triggerEmail.bind(this);
	}

	triggerEmail(target) {
		const appointment_id = this.props.appointment.id;

		if (!appointment_id) throw new Error("Appointment ID is not set.");

		this.api.request('post', '/appointments/' + appointment_id + '/trigger/email/' + target).then(result => {
			if (result.success) {
				return this.notification.success("Done! That email has been sent out.");
			}
		}).catch(error => {
			this.notification.error(error.message);
		})
	}

	async generateInvoice() {
		const { appointment, onCorporateMetadataUpdate } = this.props;

		try {
			if (!appointment.id) throw new Error("Appointment ID is not set.");
			const result = await this.api.request('post', '/appointments/' + appointment.id + '/corporate_invoice')
			if (result.success) {
				onCorporateMetadataUpdate && onCorporateMetadataUpdate();
				return this.notification.success('Done! That invoice has been drafted. View in Xero to approve and send.')
			} else {
				throw new Error(result.error);
			}
		} catch(error) {
			this.notification.error(error.message);
		}
	}

	showUnassignLink() {
		const { hasBeenUnassigned, isDirectBook } = this.props;

		if (!isDirectBook) return false;

		if (hasBeenUnassigned) return false

		return true
	}

	getInvoiceUrl(invoice_id){
		// this is just a unique code for our organisation from xero, which we can use to construct the deep link
		// https://developer.xero.com/documentation/api-guides/deep-link-xero
		const lesalon_xero_shortcode = `!tSydN`;
		return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${lesalon_xero_shortcode}&redirecturl=/AccountsReceivable/Edit.aspx?InvoiceID=${invoice_id}`
	}

	openModal() {
		this.setState({
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	onCorporateUpdate(corporate_metadata) {
		const { onCorporateMetadataUpdate } = this.props;

		this.closeModal();

		onCorporateMetadataUpdate && onCorporateMetadataUpdate(corporate_metadata)
	}

	getInvoiceId = () => {
		const { corporateMetadata } = this.props;
		if (!corporateMetadata || !corporateMetadata.invoice_id) {
			return null;
		}
		return corporateMetadata.invoice_id;

	}

	renderInvoiceAction = (appointment) => {
		const invoice_id = this.getInvoiceId();
		if (invoice_id) {
			return (
				<div className="action">
					<a href={this.getInvoiceUrl(invoice_id)} target="_blank">
						📄 View Xero invoice
						<br />
						<div className="note">
							(to <UserLabel id={appointment.customer_id} avatar={false} fetch={true} />)
						</div>
					</a>
				</div>
			)
		} else {
			return (
				<div className="action">
					<a onClick={ () => { this.generateInvoice() }}>
						📄 Generate draft Xero invoice
						<br />
						<div className="note">
							(to <UserLabel id={appointment.customer_id} avatar={false} fetch={true} />)
						</div>
					</a>
				</div>
			)
		}

	}

	render() {
		const { appointment, corporateMetadata, onCorporateMetadataUpdate } = this.props;
		const { modalOpen } = this.state;

		if (!appointment) return null;

		return <div className="Card AppointmentActionsCard ActionsCard">
			<div className="actions">
				{appointment.professional_id ? <div className="action">
					<a onClick={() => { this.triggerEmail("professional_confirmation") }}>
						📩 Send professional confirmation email
						<br />
						<div className="note">
							(to <UserLabel id={appointment.professional_id} avatar={false} fetch={true} />)
						</div>
					</a>
				</div> : null}

				{appointment.customer_id ? <div className="action">
					<a onClick={() => { this.triggerEmail("customer_confirmation") }}>
						📩 Send customer confirmation email
						<br />
						<div className="note">
							(to <UserLabel id={appointment.customer_id} avatar={false} fetch={true} />)
						</div>
					</a>
				</div> : null}

				{corporateMetadata && corporateMetadata.company_pays && appointment.customer_id ? this.renderInvoiceAction(appointment) : null}

				{corporateMetadata && <div className="action">
					<a onClick={this.openModal}>
						✏️ Update corporate details
					</a>
				</div>}

				{this.showUnassignLink() && <div className="action">
					<a onClick={() => this.props.unassignProfessional()}>
						Unassign Professional
				</a>
				</div>}
			</div>
			{!appointment.professional_id && <Alert bsStyle="warning">
				You need to assign a professional to this booking before sending any emails.
			</Alert>}
			{corporateMetadata && <CorporateDetailsModal metadata={corporateMetadata} show={modalOpen} onHide={this.closeModal} onUpdate={this.onCorporateUpdate} />}
		</div>
	}
}

AppointmentActionsCard.propTypes = {
	unassignProfessional: PropTypes.func.isRequired,
	appointment: PropTypes.object.isRequired,
	corporateMetadata: PropTypes.object,
	onCorporateMetadataUpdate: PropTypes.func.isRequired
}