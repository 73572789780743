import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class ProfessionalFilterMenu extends Component {
	render() {
		const { professionals, selectedId, onSelect, colors } = this.props;

		let list = [
			//first value is for an "all pros" option
			<div
				className={selectedId === null && "selected"}
				key={0}
				onClick={() => onSelect(null)}
			>
				<div className="fake_avatar"></div> Everyone
			</div>
		]

		const list_additional_items = professionals.map(pro => {
			const color = (colors && colors[pro.id]) || null;

			return <div
				className={selectedId === pro.id ? "selected" : null}
				key={pro.id}
				onClick={() => onSelect(pro.id)}
			>
				<UserLabel user={pro} />
				{color && <div className="color" style={{ backgroundColor: color }} />}
			</div>
		})

		return <div className="ProfessionalFilterMenu">
			{list.concat(list_additional_items)}
		</div>
	}
}

ProfessionalFilterMenu.propTypes = {
	colors: PropTypes.object,
	professionals: PropTypes.array.isRequired,
	selectedId: PropTypes.number,
	onSelect: PropTypes.func.isRequired
}