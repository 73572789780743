import APIClient from './APIClient';
import AuthToken from './AuthToken';

export default class Auth{
	constructor(){
		this.api = new APIClient();

		//AuthToken is directly linked to local storage when setting .token.
		this.auth = new AuthToken();
	}

	check(){
		window.EventSystem.emit(window.EventName.LOAD_START);

		return this.api.request('get', '/me').then(result => {
			window.EventSystem.emit(window.EventName.LOAD_END);

			if(!result.success){
				window.EventSystem.emit(window.EventName.LOGGED_OUT);

				throw new Error("You are not currently authorized. Please login.")
			}

			window.EventSystem.emit(window.EventName.LOGGED_IN, result.data);

			return result.data;
		})
	}

	login(credentials){
		window.EventSystem.emit(window.EventName.LOAD_START);

		return this.api.request('post', '/auth/token', credentials).then(result => {
			window.EventSystem.emit(window.EventName.LOAD_END);

			if(!result.success){
				throw new Error(result.error);
			}

			this.auth.token = result.data.token;
		})
	}

	logout(){
		window.EventSystem.emit(window.EventName.LOAD_START);

		//emit logout event and destroy auth token optimistically for speed.
		window.EventSystem.emit(window.EventName.LOGGED_OUT);
		this.auth.token = null;

		return this.api.request('delete', '/auth/token').then(result => {
			window.EventSystem.emit(window.EventName.LOAD_END);

			if(!result.success){
				throw new Error(result.error);
			}
		})
	}
}