import React, { Component } from 'react';

import LeView from './view';

import './style.less';

export default class AppointmentPage extends LeView {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.showChargeModal = this.showChargeModal.bind(this);
		this.hideChargeModal = this.hideChargeModal.bind(this);
		this.showAlternativeTimeModal = this.showAlternativeTimeModal.bind(this);
		this.hideAlternativeTimeModal = this.hideAlternativeTimeModal.bind(this);
		this.showRegularCorporateModal = this.showRegularCorporateModal.bind(this);
		this.hideRegularCorporateModal = this.hideRegularCorporateModal.bind(this);
		this.showRefundModal = this.showRefundModal.bind(this);
		this.hideRefundModal = this.hideRefundModal.bind(this);
	}

	async componentDidMount() {
		const appointment = await this.getCoreData();
		await this.getAdditionalData(appointment);
	}

	showChargeModal() {
		this.setState({
			chargeModalOpen: true
		})
	}

	showRefundModal() {
		this.setState({
			refundModalOpen: true,
		})
	}

	hideChargeModal() {
		this.setState({
			chargeModalOpen: false
		})
	}

	showAlternativeTimeModal() {
		this.setState({
			alternativeTimeModalOpen: true
		})
	}

	hideRefundModal() {
		this.setState({
			refundModalOpen: false,
		})
	}

	hideAlternativeTimeModal() {
		this.setState({
			alternativeTimeModalOpen: false
		})
	}

	showRegularCorporateModal() {
		this.setState({
			regularCorporateModalOpen: true
		})
	}

	hideRegularCorporateModal() {
		this.setState({
			regularCorporateModalOpen: false
		})
	}
}