import React, { Component } from 'react';
import _ from 'lodash';

import PageModel from '../_PageModel';

export default class LeModel extends PageModel {
	constructor(props) {
		super(props);

		this.state = {
			// bookings is used to filter out bookings with different statuses
			bookings: [],

			// this data includes misc payout adjustments such as late fees, tips, bonuses, redos etc.
			adjustments: [],

			// this list is used to work out whether a Salonette can be issued a payout for the current week
			// key: value ----- user_id: [payouts]
			payouts: {},

			// professionals is used for the menu.
			professionals: [],
			filteredProfessional: null,

			start: null,
			end: null,

			payoutModalVisible: false,
		};

		this.onAddAdjustment = this.onAddAdjustment.bind(this);
		this.onDeleteAdjustment = this.onDeleteAdjustment.bind(this);
	}

	setDateRange(increment) {
		// intiailize increment.
		increment = increment || 0;

		let start = this.moment(this.state.start);
		let end = this.moment(this.state.end);

		switch (increment) {
			// initialize dates if they don't already exist.
			case 0:
				// set start to this week (we'll set the day further down)
				if (!start.isValid()) start = this.moment().startOf('week');
				// set end to next week (we'll set the day further down)
				if (!end.isValid()) end = this.moment().startOf('week').add(1, 'week');
				break;
			// subtract dates
			case -1:
				start.startOf('week').subtract(1, 'week');
				end.startOf('week').subtract(1, 'week');
				break;
			// add dates
			case 1:
				start.startOf('week').add(1, 'week');
				end.startOf('week').add(1, 'week');
				break;
			default:
				throw new Error('You must send an integer (either -1, 0 or 1) for `increment`.');
		}

		// back in the day, payday used to be on a Friday.
		start.day(5); // fri
		end.day(5); // fri

		// on 2018-03-22 we changed our payment schedule from:
		// Fri 00:00 to Thurs 23:59
		// to:
		// Thurs 00:00 to Wed 23:59
		// this means that for full weeks before the cutoff the dates need to be shifted 1 day later.
		// for the single affected week over this change, just the start date needs to be shifted back.
		// for future full weeks, no change is necessary.
		const friday_to_thursday_cutoff = this.moment('2018-03-16T23:59:59Z');

		if (
			start.isBefore(friday_to_thursday_cutoff)
			&& friday_to_thursday_cutoff.isBefore(end)
		) {
			start.day(5); // fri
			end.day(4); // thurs
		} else if (
			friday_to_thursday_cutoff.isBefore(start)
			&& friday_to_thursday_cutoff.isBefore(end)
		) {
			start.day(4); // thurs
			end.day(4); // thurs
		}

		// on 2019-02-18 we changed our payment schedule from:
		// Thurs 00:00 to Wed 23:59
		// to:
		// Mon 00:00 to Sun 23:59
		// for the single affected week over this change, Salonettes will be paid a short week.
		// for future full weeks, no change is necessary.
		const thursday_to_monday_cutoff = this.moment('2019-02-18T23:59:59Z');

		if (
			start.isBefore(thursday_to_monday_cutoff)
			&& thursday_to_monday_cutoff.isBefore(end)
		) {
			start.day(4); // thurs
			end.day(1); // mon
		} else if (
			thursday_to_monday_cutoff.isBefore(start)
			&& thursday_to_monday_cutoff.isBefore(end)
		) {
			start.day(1); // mon
			end.day(1); // mon
		}

		this.setState({
			start,
			end,
		});
	}

	convertListToKeyValue(list) {
		const result = {};

		if (list && list.length) {
			for (const item of list) {
				result[item.id] = item;
			}
		}

		return result;
	}

	getCoreData() {
		// load bookings, with the bare minimum relations
		this.api.request("get", "/appointments?load=accounts", null, data => {
			this.setState({
				bookings: [
					...this.state.bookings,
					...data.filter(booking => {
						switch (booking.status) {
							case 'UNPAID':
							case 'REFUNDED':
								return false;
						}

						switch (booking.type) {
							case 'PLATFORM':
							case 'STOREFRONT':
								return false;
						}

						return true;
					}),
				],
			});
		});

		// load adjustments data
		this.api.request('get', '/user_earnings_adjustments').then(result => {
			this.setState({
				adjustments: result.data,
			});
		});

		// load salonettes with experiments relation

		const relations = ['experiments'];

		this.api.request('get', `/users?where={"type":"PROFESSIONAL"}&load=${relations.join(',')}`).then(result => {
			let professionals = [];

			for (const user of result.data) {
				professionals.push(user);
			}

			// Filter out professionals who have the experients 'hourly' INCEPTION FILTER
			professionals = professionals.filter(professional => professional.experiments.filter(experiment => experiment.name === 'hourly').length === 0);

			professionals = _.orderBy(professionals, [
				user => (user.first_name || '').toLowerCase(),
				user => (user.last_name || '').toLowerCase(),
			], ['asc', 'asc']);

			this.setState({
				professionals,
			});
		});
	}

	async getPayouts(user_id) {
		if (!user_id) return null;

		// skip if payouts for this user have already been loaded.
		if (this.state.payouts[user_id]) return null;

		try {
			const result = await this.api.request("get", `/users/${user_id}/payouts`);

			if (!result.success) throw new Error(result.error);

			const { payouts } = this.state;

			payouts[user_id] = result.data;

			this.setState({
				payouts,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	professionalById(id) {
		const { professionals } = this.state;

		const filtered = professionals.filter(pro => pro.id === id);

		if (!filtered.length) return null;

		return filtered[0];
	}

	async onAddAdjustment(payload) {
		try {
			const result = await this.api.request("post", `/user_earnings_adjustments`, payload);

			if (!result.success) throw new Error(result.error);

			const { adjustments } = this.state;

			adjustments.push(result.data);

			this.setState({
				adjustments,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async onDeleteAdjustment(id) {
		try {
			const result = await this.api.request("delete", `/user_earnings_adjustments/${id}`);

			if (!result.success) throw new Error(result.error);

			const { adjustments } = this.state;

			for (const [index, value] of adjustments.entries()) {
				if (value.id === id) {
					// remove from array and break the loop
					adjustments.splice(index, 1);
					break;
				}
			}

			this.setState({
				adjustments,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}
