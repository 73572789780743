import React, { Component } from 'react';

import Resource from '../Resource';

export default class UpdateResource extends Resource {
	constructor() {
		super();

		this.handleSubmit = this.handleSubmit.bind(this);

		this.method = "update";
	}

	handleSubmit(data) {
		const payload = { ...data.formData };

		const originalSchema = this.state.originalSchema;

		const final_payload = {};

		Object.keys(payload).forEach(key => {
			const schema = originalSchema.properties[key];

			// don't send this value to the server if it is not in the schema.
			if (!schema) return;

			if (payload[key] === "") payload[key] = null;
			if (payload[key] === undefined) payload[key] = null;

			// set zero values to null if the schema says this prop is a nullable number.
			// NB: this is probably gonna cause us problems in the future. there needs to be a better solution to this.
			if (
				schema.type instanceof Array
				&& schema.type.indexOf("number") > -1
				&& schema.type.indexOf("null") > -1
			) {
				if (payload[key] === 0) payload[key] = null;
			}

			// react-jsonschema-form force casts everything to UTC.
			// this.restoreTimeZone makes it ISO-8601, and converts it back to BST (if necessary).
			if (key === "datetime") {
				payload[key] = this.restoreTimeZone(payload[key]);
			}

			final_payload[key] = payload[key];
		});

		// now we have patched our original data with the modified changes.
		this.api.request(
			'put',
			'/' + this.props.resource + '/' + this.state.resourceId,
			final_payload,
		).then(result => {
			if (!result.success) throw new Error(result.error);

			this.notification.success("All done! You have updated this record.");
			this.closeModal();

			if (result.data) Object.keys(result.data).forEach(key => {
				payload[key] = result.data[key];
			});

			if (this.props.onNewData) return this.props.onNewData(payload);
		}).catch(error => {
			this.notification.error(error.message);
		});
	}
}
