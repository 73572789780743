import PageModel from '../_PageModel';
import Moment from 'moment'; Moment.locale('uk', { week: { dow: 1 } });

export default class LeModel extends PageModel {
	constructor(props) {
		super(props);

		this.onUpdate = this.onUpdate.bind(this);
		this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
	}

	state = {
		appointments: [],

		//dependencies
		users: {},
		services: {},
		locations: {},

		//for filtering by booking status
		statuses: [],
		statusFilter: null
	}

	convertListToKeyValue(list) {
		let result = {};

		if (list && list.length) {
			for (const item of list) {
				result[item.id] = item;
			}
		}

		return result;
	}

	getCoreData() {
		let relations = [
			'services',
			'events.name'
		]

		//load bookings, with the bare minimum relations
		this.api.request("get", "/appointments?load=" + relations.join(','), null, (data, page_number) => {
			this.setState({
				appointments: [
					...this.state.appointments,
					...data
				]
			})
		});

		relations = [
			'credit_transactions',
		]

		//load users and arrange them in a key: value format for performance.
		this.api.request("get", "/users?load=" + relations.join(','), null, (data, page_number) => {
			this.setState({
				users: {
					...this.state.users,
					...this.convertListToKeyValue(data)
				}
			})
		})

		//load services and arrange them in a key: value format for performance.
		this.api.request("get", "/services", null, (data, page_number) => {
			this.setState({
				services: {
					...this.state.services,
					...this.convertListToKeyValue(data)
				}
			})
		})

		//load locations and arrange them in a key: value format for performance.
		this.api.request("get", "/locations", null, (data, page_number) => {
			this.setState({
				locations: {
					...this.state.locations,
					...this.convertListToKeyValue(data)
				}
			})
		})

		//get update blueprint from API, and then find a list of allowed statuses for filtering.
		this.api.request("get", "/blueprints/appointments/update").then(result => {
			try {
				//get list of valid values from deep inside the schema.
				let statuses = result.data.properties.status.enum;

				//convert to autocomplete format
				statuses = statuses.map(status => {
					return {
						label: (
							status[0].toUpperCase()
							+ status.slice(1, status.length).toLowerCase().split('_').join(' ')
						),
						value: status
					}
				})

				this.setState({
					statuses
				});

			} catch (error) {
				console.error(error);
			}
		})
	}

	onUpdate(data) {
		let appointments = this.state.appointments;

		//add newly created appointment to appointments list.
		appointments.push(data);

		this.setState({
			appointments: appointments
		})
	}

	onStatusFilterChange(statusFilter) {
		if (this.state.statusFilter === statusFilter) return;

		this.setState({
			statusFilter
		})
	}
}