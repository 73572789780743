import React from 'react';
import { browserHistory } from 'react-router';

import Title from '../../misc/Title';
import Tabs from '../../misc/Tabs';
import NewHomeAppointment from '../../forms/NewHomeAppointment';
import NewCorporateAppointment from '../../forms/NewCorporateAppointment';

import LeComponent from '../_PageModel';

import './style.less';

export default class extends LeComponent {
	state = {
		selectedTab: 'CORPORATE',
		tabs: [{
			value: 'CORPORATE',
			label: 'Corporate event'
		}, {
			value: 'HOME',
			label: 'Home booking'
		}]
	}

	async loadCoreData() {

	}

	componentDidMount() {
		this.loadCoreData();
	}

	onNewData = (appointment) => {
		const { id } = appointment;

		return browserHistory.push(`/appointments/${id}`);
	}

	renderForm() {
		const { selectedTab } = this.state;

		switch (selectedTab) {
			case 'HOME':
				return <NewHomeAppointment onNewData={this.onNewData} />
			case 'CORPORATE':
				return <NewCorporateAppointment onNewData={this.onNewData} />
			default:
				return null;
		}
	}

	render() {
		const { tabs, selectedTab } = this.state;

		return <div className="Page NewAppointment">
			<Title h1 text="New Appointment" />
			<Tabs
				tabs={tabs}
				selectedTab={selectedTab}
				onSelect={selectedTab => this.setState({ selectedTab })}
			/>
			{this.renderForm()}
		</div>
	}
}