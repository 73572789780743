import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel'

import './style.less';

export default class BannedRibbon extends DataModel {
	render() {
		const { user } = this.props;
		if (!user) return null;

		const { banned_at } = user;
		if (!banned_at) return null;

		return <div className="BannedRibbon">
			This customer was banned on {this.moment(banned_at).format('dddd Do MMMM YYYY [at] HH:mm')}.
			<ul>
				<li>Do not tell them they are banned.</li>
				<li>Do not assign bookings to them, or contact them about failed matches.</li>
				<li>If they ask why their bookings are not matching, suggest that they try a different time or basket.</li>
			</ul>
		</div>
	}
}

BannedRibbon.propTypes = {
	user: PropTypes.any
}