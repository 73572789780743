import React, { Fragment } from 'react';
import { Link } from 'react-router';

import UserLabel from '../../labels/UserLabel';

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	render() {
		const professionals = this.state.professionals.map((user, index) => <Link key={user.id} to={"/users/" + user.id}>
			<UserLabel user={user} shortName />
			<div className="Label Badge">
				{index + 1}
			</div>
		</Link>)

		return <div className="Card RankedProfessionalsList">
			{
				professionals.length
					? <div className="pro-list">
						{professionals}
					</div>
					: <i>None</i>
			}
		</div>;
	}
}