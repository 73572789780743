import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import PageModel from '../_PageModel';

import JSONPanel from '../../misc/JSONPanel';
import UpdateResource from '../../buttons/UpdateResource';
import DeleteResource from '../../buttons/DeleteResource';
import UpdateImageCard from '../../cards/UpdateImageCard';
import ServiceDependencies from '../../modals/ServiceDependencies';
import Title from '../../misc/Title';

import './style.less';

export default class ServicePage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.onUpdate = this.onUpdate.bind(this);
		this.onImageUpdate = this.onImageUpdate.bind(this);

		this.state = {
			service: null,
			showServiceDependenciesModal: false,
			selectedDependencies: [],
			originalSelectedDependencies: [],
		}
	}

	// TODO 
	// when you close the modal without saving, reset the selectedDependencies to the original ones. 
	// allow users to click select all on a category to select all those services.
	// style n that

	getCoreData() {
		return this.api.request(
			'get',
			'/services/'
			+ this.id
			+ "?load=dependent_service_ids,dependent_service_ids.dependent_service"
		).then(result => {
			if (result.success) {
				const selectedDependencies = result.data.dependent_service_ids.map(service => service.dependent_service);

				this.setState({
					service: result.data,
					selectedDependencies,
					originalSelectedDependencies: result.data.dependent_service_ids,
				})

				return result.data;
			}

			throw new Error(result.error);
		})
	}

	toggleServiceDependenciesModal = async () => {
		const { showServiceDependenciesModal } = this.state;

		try {
			await this.getCoreData()
		} catch (error) {
			this.notification.error(`Shucks. ${error.message}`)
		}

		this.setState({
			showServiceDependenciesModal: !showServiceDependenciesModal
		})
	}

	handleServiceClick = (service) => {
		let { selectedDependencies } = this.state;

		const found = selectedDependencies.find(selectedService => service.id === selectedService.id);

		if (!!found) {
			selectedDependencies = selectedDependencies.filter(selectedService => selectedService.id !== service.id)
		} else {
			selectedDependencies.push(service)
		}

		this.setState({
			selectedDependencies
		})
	}

	onSaveDependencies = async () => {
		const { selectedDependencies, originalSelectedDependencies, service } = this.state

		// if a service is in selectedDeps but not in original dependencies then we add it
		// if a service is in the original dependencies but not selectedDeps then we delete it

		// so lets maps the OGS into an array of service_ids 
		const originalSelectedDependencyIds = originalSelectedDependencies.map(dependency => dependency.dependent_service_id)
		// and we will map the selectedDeps to an array of service Ids 
		const newSelectedDependencyIds = selectedDependencies.map(dependency => dependency.id);

		const dependenciesToAdd = selectedDependencies.filter(dependency => !originalSelectedDependencyIds.includes(dependency.id));
		const dependenciesToRemove = originalSelectedDependencies.filter(dependency => !newSelectedDependencyIds.includes(dependency.dependent_service_id));

		const promises = []

		// we loop through add/remove separately because to they are formatted different. To add a service we need the service_id and the dependent_service_id
		for (const service of dependenciesToAdd) {
			const dependent_service_id = service.id;
			const service_id = this.id

			promises.push(
				this.api.request('post', '/service_dependencies', {
					service_id,
					dependent_service_id,
				}).then(result => {
					if (!result.success) {
						throw new Error(result.error);
					}
				})
			)
		}

		// to delete one we need the id of the service_dependency
		for (const service_dependency of dependenciesToRemove) {
			const id = service_dependency.id;

			promises.push(
				this.api.request('delete', `/service_dependencies/${id}`).then(result => {
					if (!result.success) {
						throw new Error(result.error);
					}
				}),
			)
		}

		try {
			await Promise.all(promises)

			this.notification.success(`Woop! You have successfully updated the service dependencies for ${service.option}.`)
		} catch (error) {
			this.notification.error(`Shucks. ${error.message}`)
		}

		await this.toggleServiceDependenciesModal()
	}

	componentDidMount() {
		this.getCoreData().then(service => {


		}).catch(error => {
			console.error(error);
		})
	}

	onUpdate(data) {
		let service = this.state.service;

		service = Object.assign(service, data);

		this.setState({
			service: service
		})
	}

	async onImageUpdate(image) {
		let { service } = this.state;

		//get service id.
		const { id } = service;

		try {
			const result = await this.api.request('post', `/services/${id}/image`, {
				image
			})

			if (!result.success) throw new Error(result.error);

			const { image_url } = result.data;

			service.image_url = image_url;

			this.setState({
				service
			});

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	render() {
		const { service, showServiceDependenciesModal, selectedDependencies } = this.state;

		if (!service) return null;

		return (
			<div className="Page ServicePage">
				<Title h1 text="Service Details">
					<UpdateResource
						title={"Edit Service"}
						resource={"services"}
						existingData={service}
						onNewData={this.onUpdate} />

					<DeleteResource
						title="Delete Service"
						resource="services"
						existingData={service}
						onNewData={browserHistory.goBack} />

					<button className="button" onClick={this.toggleServiceDependenciesModal}>
						Edit Dependencies
					</button>
				</Title>

				<div className="split-view bordered">
					<div flex={2}>
						<div className="Card">
							<JSONPanel data={service} />
						</div>
					</div>
					<div>
						<Title h3 text="Dependencies" />
						<div className="Card">
							<ul>
								{selectedDependencies.length ? selectedDependencies.map(service =>
									<li>{service.option}</li>
								) : <p>This service is not dependent on any other service.</p>}
							</ul>
						</div>

						<Title h3 text="Current image" />
						<div className="Card">
							{
								service.image_url
									? <img className="image-preview" src={service.image_url} />
									: <i>None</i>
							}
						</div>

						<Title h3 text="Update image" />
						<UpdateImageCard
							width={1000}
							height={625}
							onImageUpdate={this.onImageUpdate}
						/>
					</div>
				</div>
				<ServiceDependencies
					show={showServiceDependenciesModal}
					onHide={this.toggleServiceDependenciesModal}
					currentService={service}
					selectedDependencies={selectedDependencies}
					handleServiceClick={this.handleServiceClick}
					handleSubmit={this.onSaveDependencies}
				/>
			</div>
		);
	}
}