import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';

import TableModel from '../_TableModel';

import Modal from '../../modals/Generic';

import './style.less';

export default class UserCommsTable extends TableModel {
	constructor() {
		super();

		this.className = "UserCommsTable";

		this.UNIQUE_INDEX = 5;

		this.titles = [{
			title: 'Date',
			type: Date,
			searchable: true,
			sortable: true,
			description: "Date"
		}, {
			title: 'Type',
			type: String,
			searchable: true,
			sortable: true,
			description: "Type"
		}, {
			title: 'Message',
			type: String,
			searchable: true,
			sortable: true,
			description: "Message"
		}, {
			title: 'Extra',
			searchable: false,
			sortable: false,
			description: "Extra"
		}, {
			title: 'Type',
			searchable: false,
			sortable: false,
			description: "Type"
		}, {
			title: 'ID',
			type: String,
			searchable: true,
			sortable: true,
			description: "ID"
		}, {
			title: 'Delivered',
			type: Date,
			searchable: true,
			sortable: true,
			description: "Delivered"
		}, {
			title: 'Read',
			type: Date,
			searchable: true,
			sortable: true,
			description: "Read"
		}]
	}

	dataForRows(raw_data) {
		if (!raw_data) return;

		let rows = [];

		let metadata_formatted;
		for (const item of raw_data) {
			const { type, data } = item;

			//METADATA
			// might be modifiable in future, so make a copy.
			if (data.metadata) {
				let metadata_display = {
					...data.metadata
				}

				// in case we put times in 
				if (metadata_display.datetime) {
					metadata_display.datetime = this.moment(metadata_display.datetime).format('DD/MM/YY HH:mm:ss');
				}

				metadata_formatted = JSON.stringify(metadata_display, null, 2);
			}

			rows.push([
				this.moment(data.created_at).unix(),
				type,
				data.subject ? data.subject : data.message,
				type === "EMAIL" ? <EmailPreviewModal html={data.message} /> : <pre>{metadata_formatted}</pre>,
				data.type ? data.type : null,
				`${type}_${data.id}`,
				data.delivered_at ? this.moment(data.delivered_at).unix() : null,
				data.read_at ? this.moment(data.read_at).unix() : null
			])
		}
		return rows;
	}
}

class EmailPreviewModal extends Component {
	constructor() {
		super();

		this.state = {
			modalOpen: false
		}

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	render() {
		const { html } = this.props;
		const { modalOpen } = this.state;

		if (!html) return <i>No message</i>;

		return <div>
			<a onClick={this.openModal}>
				View contents
			</a>
			{
				modalOpen
				&& <Modal className="UserCommsModal" show={modalOpen} onHide={this.closeModal}>
					<Frame initialContent={html} />
				</Modal>
			}
		</div>
	}
}

EmailPreviewModal.propTypes = {
	html: PropTypes.string
};