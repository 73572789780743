import React, { Component } from 'react';

import './style.less';

export default class Loader extends Component {
	constructor() {
		super();

		this.state = {
			loadingCounter: 0
		}

		this.incrementLoadingCounter = this.incrementLoadingCounter.bind(this);
		this.decrementLoadingCounter = this.decrementLoadingCounter.bind(this);
	}

	incrementLoadingCounter() {
		let counter = this.state.loadingCounter + 1;

		this.setState({
			loadingCounter: counter
		})
	}

	decrementLoadingCounter() {
		let counter = this.state.loadingCounter - 1;

		if (counter < 0) counter = 0;

		this.setState({
			loadingCounter: counter
		})
	}

	componentDidMount() {
		window.EventSystem.on(window.EventName.LOAD_START, this.incrementLoadingCounter);
		window.EventSystem.on(window.EventName.LOAD_END, this.decrementLoadingCounter);
	}

	componentWillUnmount() {
		window.EventSystem.removeListener(window.EventName.LOAD_START, this.incrementLoadingCounter);
		window.EventSystem.removeListener(window.EventName.LOAD_END, this.decrementLoadingCounter);
	}

	render() {
		if (this.state.loadingCounter <= 0) return null;

		return (
			<div className="Loader">
				<div className="inner">
					<div className="one" />
					<div className="two" />
					<div className="three" />
				</div>
			</div>
		)
	}
}