import React, { Fragment } from 'react';
import { Link } from 'react-router';
import UserLabel from '../../labels/UserLabel';
import LeModel from './model';
export default class LeView extends LeModel {
	render() {
		const zone = this.getZone();
		const { professionals } = this.state;

		if (!zone) return <i>N/A</i>

		return <div className="Card ProfessionalsInPostcodeCard">
			{
				professionals.length
					? <Fragment>
						<div className="pro-list">
							{
								professionals.map(user => <Link key={user.id} to={"/users/" + user.id}>
									<UserLabel user={user} shortName />
									{user.offer_preferences.map(pref =>
										<span key={pref.name} className="Label Badge">{pref.name}</span>
									)}
								</Link>)
							}
						</div>

						<div className="count">
							{professionals.length} Salonettes in {zone} offering this basket
						</div>
					</Fragment>
					: <i>None</i>
			}
		</div>;
	}
}