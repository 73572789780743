import React from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class ServicesTable extends TableModel {
	constructor() {
		super();

		this.className = "ServicesTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Service ID",
		}, {
			title: 'Name',
			type: String,
			sortable: true,
			searchable: true,
			description: "Name",
		}, {
			title: 'Price',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Price (£)",
		}, {
			title: 'Category',
			type: String,
			sortable: true,
			searchable: true,
			description: "Category",
		}, {
			title: 'Type',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Type',
		}, {
			title: 'Description',
			type: String,
			sortable: true,
			searchable: true,
			description: "Description",
		}, {
			title: 'Belongs To',
			type: Number,
			sortable: true,
			searchable: false,
			description: 'Belongs To',
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const service = datum;

			const columns = [
				service.id,
				service.option,
				service.price,
				service.category.name,
				(([initial, ...rest]) => [initial.toUpperCase(), ...rest].join(""))(service.type.toLowerCase()), // hench capitalization of string
				service.description,
				service.user_id ? <UserLabel id={service.user_id} fetch shortName /> : <i>Not set</i>,
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/services/' + data[0];
	}
}
