import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../_DataModel';

export default class LabelModel extends DataModel {

}

LabelModel.propTypes = {
	id: PropTypes.number,
	fetch: PropTypes.bool
}