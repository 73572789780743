import React, { Component } from 'react';

import FontAwesome from 'react-fontawesome';
import Moment from 'moment';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';
import ServicesLabel from '../../labels/ServicesLabel';
import AppointmentSourceLabel from '../../labels/AppointmentSourceLabel';
import AppointmentStatusLabel from '../../labels/AppointmentStatusLabel';

import './style.less';

export default class AppointmentsTable extends TableModel {
	constructor() {
		super();

		this.className = "AppointmentsTable";

		this.UNIQUE_INDEX = 1;

		this.titles = [{
			title: '',
			searchable: false,
			sortable: false,
			description: "Type",
		}, {
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Appointment ID",
		}, {
			title: 'Date',
			type: Date,
			sortable: true,
			searchable: true,
			description: "Date",
		}, {
			title: 'Customer',
			type: Number,
			description: "Customer ID",
		}, {
			title: 'Professional',
			type: Number,
			description: "Professional ID",
		}, {
			title: 'Treatments',
			description: 'Treatments',
			type: String,
			searchable: true,
			sortable: false,
		}, {
			title: 'Address',
			description: "Address",
			type: String,
			sortable: true,
			searchable: true,
		}, {
			title: 'Status',
			description: "Status",
		}, {
			title: <FontAwesome name="mobile" />,
			description: "Device Type",
		}, {
			title: <FontAwesome name="star" />,
			type: Number,
			sortable: true,
			searchable: true,
			description: 'Average Rating',
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const appointment = datum;

			switch (appointment.type) {
				case 'PLATFORM':
				case 'STOREFRONT':
					return;
			}

			const columns = [
				(appointment => {
					const tags = [];

					switch (appointment.type) {
						case 'HOME':
							tags.push(<span key="type">🏠</span>);
							break;
						case 'CORPORATE':
							tags.push(<span key="type">🌆</span>);
							break;
						default:
							break;
					}

					if (appointment.events) {
						// start direct / rebook tags
						const placed_event = appointment.events.find(event => {
							if (event.name) {
								return event.name.label === 'PLACED';
							}

							return false;
						});

						const unassigned_event = appointment.events.find(event => {
							if (event.name) {
								return event.name.label === 'UNASSIGNED';
							}

							return false;
						});

						if (placed_event) {
							if (placed_event.metadata.professional_id && !unassigned_event) {
								tags.push(
									<div key="direct" className="Label Badge">
										Direct
									</div>,
								);
							}

							if (appointment.professional_id) {
								if (appointment.professional_id === placed_event.user_id) {
									tags.push(
										<div key="rebook" className="Label Badge">
											Rebook
										</div>,
									);
								}
							}
						}
						// end direct / rebook tags

						// start match failed tag
						const match_failed_event = appointment.events.find(event => {
							if (event.name) {
								return event.name.label === 'MATCH_FAILED';
							}

							return false;
						});

						if (
							match_failed_event
							&& appointment.status === 'PENDING'
						) {
							tags.push(
								<div key="match_failed" className="Label Badge">
									Match failed
								</div>,
							);
						}
						// end match failed tag
					}

					return (
						<div className="tags">
							{tags}
						</div>
					);
				})(appointment),
				appointment.id,
				Moment(appointment.datetime).unix(),
				appointment.customer && appointment.customer.id ? <UserLabel user={appointment.customer} shortName /> : <i>N/A</i>,
				appointment.professional && appointment.professional.id ? <UserLabel user={appointment.professional} shortName /> : <i>Not assigned</i>,
				(
					appointment.services && appointment.services.length
						? appointment.services.map(s => (s.service ? s.service.option : null)).filter(Boolean).join('\n')
						: 'None'
				),
				appointment.location && appointment.location.id ? `${appointment.location.address}, ${appointment.location.postcode}` : null,
				<AppointmentStatusLabel status={appointment.status} />,
				<AppointmentSourceLabel source={appointment.source} />,
				(reviews => {
					if (!reviews || !reviews.length) return null;

					const ratings = reviews.map(r => (r ? r.rating : null)).filter(r => !!r);
					const sum = ratings.reduce((a, b) => a + b, 0);

					const rating = Math.floor(sum / ratings.length);

					return rating || null;
				})([appointment.customer_review, appointment.professional_review]),
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/appointments/' + data[1];
	}
}
