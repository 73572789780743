import _ from 'lodash';
import React, { Component } from 'react';

import UserLabel from '../../labels/UserLabel';
import Autocomplete from "../Autocomplete";
import Basket from "../Basket";

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state,
	}

	formIsComplete() {
		const {
			customer_id, location_id, date, start_time, basket, selected_payment_source, credit_card_id,
		} = this.state;

		if (
			customer_id
			&& location_id
			&& date
			&& start_time
			&& selected_payment_source
			&& basket.length > 0
			// 1. not treatwell and credit_card_id is selected
			// 2. is treatwell
			// 3. not treatwell, but has more credit than basket value
			&& ((this.showInputCards() && credit_card_id)
				|| !this.showInputCards()
				|| (this.showInputCards() && (this.getUserCredit(customer_id) >= this.getBasketValue(basket))))
		) {
			return true;
		}

		return false;
	}

	renderInputCustomer() {
		const { customers, customer_id } = this.state;

		// sort customers alphabetically.
		const users_list = _.orderBy(customers, [
			user => {
				if (!user.first_name) return null;
				return user.first_name.toLowerCase();
			},
			user => {
				if (!user.last_name) return null;
				return user.last_name.toLowerCase();
			},
		], ['asc', 'asc']);

		// we need to convert the key-value list to a store.
		const options = users_list.map(user => {
			const { id, first_name, last_name } = user;

			// filter out unset names.
			const names = [first_name, last_name, id].filter(name => !!name);

			if (!names.length) return null;

			return {
				value: id,
				label: names.join(' '),
			};
		}).filter(item => !!item);

		return (
			<div>
				<p>
					Who do you want to create a booking for?
				</p>
				<Autocomplete
					value={customer_id}
					options={options}
					clearable={false}
					onChange={this.onCustomerChange}
				/>
			</div>
		);
	}

	renderInputLocation() {
		const { customer_id, location_id, locations } = this.state;
		if (!customer_id) return null;

		// only display addresses for the selected user.
		const locations_list = locations.filter(item => item.user_id === customer_id);

		return (
			<div>
				<p>
					Which address?
				</p>
				<Autocomplete
					value={location_id}
					options={locations_list.map(location => ({
						value: location.id,
						label: location.address + ', ' + location.postcode,
					}))}
					clearable={false}
					onChange={this.onLocationChange}
				/>
			</div>
		);
	}

	renderInputSalonette() {
		const { professionals, professional_id } = this.state;

		// sort customers alphabetically.
		const users_list = _.orderBy(professionals, [
			user => {
				if (!user.first_name) return null;
				return user.first_name.toLowerCase();
			},
			user => {
				if (!user.last_name) return null;
				return user.last_name.toLowerCase();
			},
		], ['asc', 'asc']);

		// we need to convert the key-value list to a store.
		const options = users_list.map(user => {
			const { id, first_name, last_name } = user;

			// filter out unset names.
			const names = [first_name, last_name, id].filter(name => !!name);

			if (!names.length) return null;

			return {
				value: id,
				label: names.join(' '),
			};
		}).filter(item => !!item);

		return (
			<div>
				<p>
					Assign a Salonette?
				</p>
				<Autocomplete
					value={professional_id}
					options={options}
					clearable
					placeholder="Offer booking out"
					onChange={this.onSalonetteChange}
				/>
			</div>
		);
	}

	renderPaymentSource() {
		const { customer_id, selected_payment_source, payment_sources } = this.state;

		if (!customer_id) return null;

		return (
			<div>
				<p>
					Which payment source?
				</p>
				<Autocomplete
					value={selected_payment_source}
					options={payment_sources.map(payment_source => ({
						value: payment_source,
						label: payment_source,
					}))}
					clearable={false}
					onChange={this.onPaymentMethodChange}
				/>
			</div>
		);
	}

	showInputCards() {
		const { selected_payment_source } = this.state;
		// Do not need for complimentary, treatwell or credit_balance, only STRIPE
		if (selected_payment_source === 'LESALON') return true;

		return false;
	}

	renderInputCards() {
		const {
			customer_id, customer_cards, credit_card_id, basket,
		} = this.state;

		if (!customer_id) return null;

		const user_credit = this.getUserCredit(customer_id);

		const basketPrice = this.getBasketValue(basket);

		if (user_credit >= basketPrice) {
			return (
				<div>
					<p>
						The client has enough credit to cover the entire booking!
					</p>
				</div>
			);
		}

		return (
			<div>
				<p>
					Set the payment card for the booking.
				</p>
				<Autocomplete
					value={credit_card_id}
					options={customer_cards.map(card => ({
						value: card.id,
						label: card.brand + ', ' + card.last4,
					}))}
					clearable={false}
					onChange={this.onCardChange}
				/>
			</div>
		);
	}

	renderInputDateTime() {
		const { date, start_time } = this.state;

		const dates = this.available_dates;
		const times = this.available_times;

		return (
			<div>
				<p>
					Set the date & time for this treatment.
				</p>
				<div className="dates">
					<div>
						<Autocomplete
							value={date}
							options={dates.map(date => ({
								value: date,
								label: date,
							}))}
							clearable={false}
							onChange={this.onDateChange}
							placeholder="Date..."
						/>
					</div>
					<div>
						{date && (
							<Autocomplete
								value={start_time}
								options={times.map(time => ({
									value: time,
									label: time,
								}))}
								clearable={false}
								onChange={this.onStartTimeChange}
								placeholder="Start time..."
							/>
						)}
					</div>
				</div>
			</div>
		);
	}

	renderBasket() {
		const {
			customers, services, basket, customer_id,
		} = this.state;

		const customer = customers.find(u => u.id === customer_id);

		// convert to hashmap (compatible with basket component)
		const filtered_services = {};

		// filter out non-home services.
		for (const service of services) {
			if (service.type !== 'HOME') continue;

			filtered_services[service.id] = service;
		}

		const start_hour = parseInt(this.state.start_time.split(':')[0]);
		const start_minute = parseInt(this.state.start_time.split(':')[1]);

		const date = this.moment(this.state.date, 'DD/MM/YYYY').set('hour', start_hour).set('minute', start_minute);

		// future bookings
		let copy = `Which treatments will ${customer.first_name} be having?`;

		// past bookings
		if (date.isBefore(this.moment())) {
			copy = `Which treatments did ${customer.first_name} have?`;
		}

		return (
			<div>
				<p>
					{copy}
				</p>
				<Basket
					basket={basket}
					onBasketChange={this.onBasketChange}

					services={filtered_services}
				/>
			</div>
		);
	}

	renderButtonSubmit() {
		return (
			<button type="button" className="confirm" onClick={this.onSubmit}>
				Submit
			</button>
		);
	}

	renderConfirm() {
		const {
			customers, professionals, locations, customer_id, professional_id, location_id, basket, selected_payment_source,
		} = this.state;

		const customer = customers.find(u => u.id === customer_id);
		const professional = professionals.find(u => u.id === professional_id);
		const location = locations.find(l => l.id === location_id);

		return (
			<div>
				<div className="Card">
					<UserLabel user={customer} shortName />

					<div className="details">
						<div>
							<div className="icon">⌚</div>
							<div className="text">
								{this.moment(this.state.date, 'DD/MM/YYYY').format('dddd, Do MMM YYYY')}
								<br />
								{this.state.start_time}
							</div>
						</div>
						<div>
							<div className="icon">📍</div>
							<div className="text">
								{`${location.address}, ${location.postcode}`}
							</div>
						</div>
						<div>
							<div className="icon">💅</div>
							<div className="text">
								{basket.length} treatment{basket.length === 1 ? '' : 's'}
							</div>
						</div>
						<div>
							<div className="icon">💃</div>
							<div className="text">
								{
									professional
										? `${professional.first_name} ${professional.last_name}`
										: 'Match with a Salonette'
								}
							</div>
						</div>
						<div>
							<div className="icon">💰</div>
							<div className="text">
								Payment source <div className="Label Badge">{selected_payment_source}</div>
							</div>
						</div>
					</div>
				</div>
				<button type="button" className="confirm" onClick={this.onConfirm}>
					Confirm
				</button>
			</div>
		);
	}

	render() {
		const {
			customer_id, location_id, professional_id, date, start_time, basket,
		} = this.state;

		return (
			<form className="Form NewHomeAppointment">
				{this.formIsComplete() && this.state.submitted
					? this.renderConfirm()
					: (
						<div>
							{this.renderInputCustomer()}
							{customer_id && this.renderInputLocation()}
							{location_id && this.renderInputDateTime()}
							{date && start_time && this.renderBasket()}
							{basket.length > 0 && this.renderInputSalonette()}
							{basket.length > 0 && this.renderPaymentSource()}
							{this.showInputCards() && this.renderInputCards()}
							{this.formIsComplete() && this.renderButtonSubmit()}
						</div>
					)}
			</form>
		);
	}
}
