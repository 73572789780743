import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.less';

export default class Title extends Component {
	state = {}

	componentDidMount() {
		// if toggle was specified on the component, this title becomes a control for its next sibling.
		const { toggle } = this.props;

		if (toggle) {
			this.setState({
				collapsed: true
			})
		}
	}

	toggle() {
		const { toggle } = this.props;

		// don't allow toggling if has not been enabled on this component
		if (!toggle) return;

		this.setState({
			collapsed: !this.state.collapsed
		})
	}

	render() {
		const { h1, h2, h3, text, toggle, children } = this.props;
		const { collapsed } = this.state;

		if (!h1 && !h2 && !h3) throw new Error('Tag type was not supplied.');

		let TitleTag = null;

		if (h1) TitleTag = 'h1';
		else if (h2) TitleTag = 'h2';
		else if (h3) TitleTag = 'h3';

		const title_classnames = ['Title'];

		if (toggle) {
			title_classnames.push('toggle');

			if (collapsed) {
				title_classnames.push('collapsed');
			}
		}

		return (
			<TitleTag className={title_classnames.join(' ')}>
				<span className="text" onClick={() => this.toggle()}>
					{text}
				</span>
				{children}
			</TitleTag>
		)
	}
}

Title.propTypes = {
	text: PropTypes.string.isRequired,
	toggle: PropTypes.bool
}