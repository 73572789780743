import React, { Component } from 'react';

import './style.less';
import * as d3 from 'd3';
import PageModel from '../_PageModel';

import Title from '../../misc/Title';

import VoucherTree from './VoucherMap';

export default class VoucherMapPage extends PageModel {
	constructor() {
		super();
	}

	render() {
		return (
			<div className="Page VoucherMapPage">
				<Title h3>All Users created after 1st Jan 2021</Title>
				<div className="voucher-maps">
					<VoucherTree />
				</div>
			</div>
		);
	}
}
