import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import LabelModel from '../_LabelModel';

import './style.less';

export default class RatingLabel extends LabelModel {
	componentDidMount() {
		this._mounted = true;
	}

	render() {
		let rating = this.props.rating;

		if (!rating) return null;

		let stars = [];

		for (let i = 1; i <= rating; i++) {
			stars.push(<FontAwesome className="full" key={i + "full"} name="star" />);
		}

		for (let i = rating + 1; i <= 5; i++) {
			stars.push(<FontAwesome className="empty" key={i + "empty"} name="star" />);
		}

		return (
			<div className="Label Rating">
				{stars}
			</div>
		)
	}
}

RatingLabel.propTypes = {
	rating: PropTypes.number
};