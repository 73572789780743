import React from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import {
	Table, TBody, TRow, TCol,
} from '../../tables/Generic';

export default class ProfessionalServicesCard extends DataModel {
	componentDidMount() {
		this.getCoreData(this.props);
	}

	componentWillReceiveProps(props) {
		this.getCoreData(props);
	}

	getCoreData(props) {
		props = props || this.props;

		const { type, professional_id } = props;

		Promise.all([
			this.api.request('get', '/services').then(result => {
				if (result.success) {
					return this.setState({
						full_list: result.data.filter(service => {
							if (service.type !== type) return false;

							// for storefront, the user must own the treatments for them to appear.
							if (type === 'STOREFRONT') {
								if (service.user_id !== professional_id) return false;
							}

							return true;
						}),
					});
				}

				throw new Error(result.error);
			}),

			this.api.request('get', '/users/' + professional_id + '/professional_services').then(result => {
				if (result.success) {
					const selected = result.data;
					const formatted = {};

					selected.forEach(item => {
						formatted[item.service_id] = item;
					});

					return this.setState({
						selected_list: formatted,
					});
				}

				throw new Error(result.error);
			}),
		]).catch(error => {
			this.notification.error(error.message);
		});
	}

	toggle(current_state, model) {
		switch (current_state) {
			case true:
				const record_id = this.state.selected_list[model.id].id;

				this.api.request('delete', '/professional_services/' + record_id).then(result => {
					if (result.success) {
						const selected_list = this.state.selected_list;

						delete selected_list[model.id];

						this.notification.info("Done! This professional is no longer offering that service.");

						return this.setState({
							selected_list,
						});
					}

					throw new Error(result.error);
				}).catch(error => {
					this.notification.error(error.message);
				});
				break;
			case false:
				this.api.request('post', '/professional_services/', {
					user_id: this.props.professional_id,
					service_id: model.id,
				}).then(result => {
					if (result.success) {
						const selected_list = this.state.selected_list;

						selected_list[model.id] = result.data;

						this.notification.success("Done! This professional is now offering that service.");

						return this.setState({
							selected_list,
						});
					}

					throw new Error(result.error);
				}).catch(error => {
					this.notification.error(error.message);
				});
				break;
		}
	}

	render() {
		const full_list = this.state.full_list || [];
		const selected_list = this.state.selected_list || {};
		const rows = [];

		full_list.forEach(item => {
			const isSelected = !!selected_list[item.id]; // convert to boolean

			rows.push(
				<TRow key={item.id} className={isSelected ? 'selected' : null}>
					<TCol>{item.option}</TCol>
					<TCol><input type="checkbox" checked={isSelected} onChange={() => { this.toggle(isSelected, item); }} /></TCol>
				</TRow>,
			);
		});

		return (
			<div className="Card ProfessionalServicesCard CheckboxListCard">
				<Table>
					<TBody>
						{rows}
					</TBody>
				</Table>
			</div>
		);
	}
}

ProfessionalServicesCard.propTypes = {
	professional_id: PropTypes.number.isRequired,
};
