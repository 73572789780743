import React, { Component } from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class LocationsTable extends TableModel {
	constructor() {
		super();

		this.className = "LocationsTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Location ID",
		}, {
			title: 'Address',
			type: String,
			sortable: true,
			searchable: true,
			description: "Address",
		}, {
			title: 'Postcode',
			type: String,
			sortable: true,
			searchable: true,
			description: "Postcode",
		}, {
			title: 'Default',
			type: String,
			sortable: true,
			searchable: false,
			description: "Default",
		}, {
			title: 'Type',
			type: String,
			sortable: true,
			searchable: true,
			description: "Type",
		}, {
			title: 'User',
			searchable: false,
			sortable: false,
			description: "User",
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const location = datum;

			const columns = [
				location.id,
				location.address,
				location.postcode,
				location.is_default ? 'Yes' : 'No',
				location.type,
				<UserLabel id={location.user_id} fetch />,
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/locations/' + data[0];
	}
}
