import React from 'react';

import DataModel from '../../_DataModel';
import GenericModal from '../Generic';
import Title from '../../misc/Title';

import './style.less';

export default class FilterProfessionals extends DataModel {
	constructor(props) {
		super(props);
	}

	state = {
		searchValue: '',
	}

	formatServicesInCategories() {
		const { services } = this.props;
		const servicesMap = new Map();
		for (const service of services) {
			// skip any services that are not home-enabled.
			if (service.type !== 'HOME') continue;

			if (servicesMap.has(service.category_id)) {
				servicesMap.get(service.category_id).push(service);
			} else {
				servicesMap.set(service.category_id, [service]);
			}
		}
		return servicesMap;
	}

	handleCategoryClick = (categoryId) => {
		const { services, selectedCategories } = this.props;
		const categoryServices = services.filter(service => service.category_id === categoryId)
			.filter(service => service.type === 'HOME'); // only check home services
		const categoryServicesIds = categoryServices.map(service => service.id);

		if (selectedCategories.indexOf(categoryId) !== -1) {
			// exists
			this.props.removeFromSelected({ categoryId });
		} else {
			// nah
			this.props.addToSelected({ serviceIds: categoryServicesIds, categoryId });
		}
	}

	handleServiceClick = (serviceId) => {
		const { selectedServices } = this.props;
		if (selectedServices.indexOf(serviceId) !== -1) {
			// exists
			this.props.removeFromSelected({ serviceId });
		} else {
			// nah
			this.props.addToSelected({ serviceIds: [serviceId] });
		}
	}

	getCategoryName = (key) => {
		const { categories } = this.props;

		const category = categories.filter(cat => cat.id === key)[0];

		if (category) return category.name;
	}

	formatServiceCheckboxs(servicesMap) {
		const items = [];
		for (const [categoryId, services] of servicesMap) {
			if (!services || services.length === 0) continue;
			items.push(
				<div className="filter-section">
					<div className="checkbox">
						<input
							type="checkbox"
							id={categoryId}
							onClick={() => this.handleCategoryClick(categoryId)}
							checked={this.props.selectedCategories.includes(categoryId)}
						/>
						<label onClick={() => this.handleCategoryClick(categoryId)}>{this.getCategoryName(categoryId)}</label>
					</div>
					<div className="checkbox-container">
						{services.map(service => (
							<div key={service.id} className="checkbox" onClick={() => this.handleServiceClick(service.id)}>
								<input
									type="checkbox"
									id={service.id}
									checked={this.props.selectedServices.includes(service.id)}
								/>
								<label>{service.option}</label>
							</div>
						))}
					</div>
				</div>,
			);
		}
		return items;
	}

	onHide = () => {
		this.resetServicesMap();
		this.resetFilter();
		this.props.onHide();
	}

	resetFilters = () => {
		this.resetServicesMap();
		this.resetFilter();
		this.props.resetFilters();
	}

	resetServicesMap = () => {
		this.setState({
			servicesMap: null,
		});
	}

	resetFilter = () => {
		this.setState({
			searchValue: '',
		});
	}

	filterBySearch = event => {
		const searchValue = event.target.value;

		this.setState({
			searchValue,
		});

		const searchStr = searchValue.toLowerCase().trim();

		if (searchStr.length < 2) {
			this.resetServicesMap();
			return;
		}

		const servicesMap = this.formatServicesInCategories();

		const newServicesMap = new Map();

		for (const [categoryId, services] of servicesMap) {
			const filteredServices = services.filter(service => service.option.toLowerCase().indexOf(searchStr) > -1);

			newServicesMap.set(categoryId, filteredServices);
		}

		this.setState({
			servicesMap: newServicesMap,
		});
	}

	render() {
		if (!this.props.services || !this.props.services.length) return null;
		if (!this.props.categories || !this.props.categories.length) return null;

		// filtered (by search) servicesMap stored in state
		let { servicesMap } = this.state;

		// means there is nothing searched, so show them all
		if (!servicesMap) {
			servicesMap = this.formatServicesInCategories();
		}
		const items = this.formatServiceCheckboxs(servicesMap);

		return (
			<GenericModal className="FilterProfessionals" show={this.props.show} onHide={this.onHide}>
				<Title h1 text="Filter Professionals">
					<form>
						<input
							type="text"
							id="searchBar"
							placeholder="Search..."
							value={this.state.searchValue}
							onChange={value => this.filterBySearch(value)}
						/>
					</form>
					<button onClick={this.onHide}>
						Dismiss
					</button>
					<button onClick={this.resetFilters}>
						Reset Filters
					</button>
				</Title>
				<div className="filter-list">
					{items.map(item => item)}
				</div>
			</GenericModal>
		);
	}
}
