import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import GenericModal from '../Generic';
import Title from '../../misc/Title';
import Autocomplete from '../../forms/Autocomplete';

import './style.less';
import {
	Table, TBody, TCol, THead, TRow,
} from '../../tables/Generic';

export default class BulkScheduleModal extends DataModel {
	constructor() {
		super();

		this.DAYS = [
			'MONDAY',
			'TUESDAY',
			'WEDNESDAY',
			'THURSDAY',
			'FRIDAY',
			'SATURDAY',
			'SUNDAY',
		];

		this.state = {
			MONDAY: null,
			TUESDAY: null,
			WEDNESDAY: null,
			THURSDAY: null,
			FRIDAY: null,
			SATURDAY: null,
			SUNDAY: null,
			// could also be [] or [{ start: null, end: null }]

			// to hold draft information when creating a new time range
			drafts: {},
		};

		this.START_HOUR = 6;
		this.END_HOUR = 23;

		this.notification = new Notification();
	}

	toggleDay = day => {
		if (!this.DAYS.includes(day)) {
			throw new Error(`Unrecognised day. Needs to be one of: ${this.DAYS.join('|')}`);
		}

		const enabled = !!this.state[day];

		if (enabled) {
			// disable it
			this.setState({
				[day]: null,
			});
		} else {
			this.setState({
				// enable it
				[day]: [],

				// reset draft data
				drafts: {
					...this.state.drafts,
					[day]: {
						start: null,
						end: null,
					},
				},
			});
		}
	}

	setDraftStartTime = (day, slot) => this.setState({
		drafts: {
			...this.state.drafts,
			[day]: {
				// i did a cheeky state initializer because why not... although state[day] should always exist if UX is done right.
				...(this.state.drafts[day] || { start: null, end: null }),
				start: slot,
			},
		},
	})

	setDraftEndTime = (day, slot) => {
		this.setState({
			drafts: {
				...this.state.drafts,
				[day]: {
					// i did a cheeky state initializer because why not... although state[day] should always exist if UX is done right.
					...(this.state.drafts[day] || { start: null, end: null }),
					end: slot,
				},
			},
		}, () => {
			// callback after state is saved.
			// save the slot as a complete range.
			this.saveSlotRange(day, this.state.drafts[day].start, this.state.drafts[day].end);
		});
	}

	saveSlotRange = (day, start, end) => {
		if (!day) return;
		if (!start) return;
		if (!end) return;

		// first check if this range is already in the times, because the autocompletes might fire duplicates while re-rendering.
		const times = this.state[day] || [];

		for (const range of times) {
			if (range.start === start && range.end === end) {
				return;
			}
		}

		this.setState({
			[day]: [
				...times,
				{ start, end },
			],
		}, () => {
			// callback after saving slot range. reset drafts.
			this.setState({
				drafts: {
					...this.state.drafts,
					[day]: {
						start: null,
						end: null,
					},
				},
			});
		});
	}

	deleteSlotRange = (day, index) => this.setState({
		[day]: (this.state[day] || []).filter((range, i) => index !== i),
	})

	setUntil = date => this.setState({
		until: date,
	})

	getSlots = start => {
		if (start) {
			// this will only match 24 hour times on the hour or on the 30. e.g. 00:00, 12:30, etc.
			const SLOT_REGEX = /([01]\d|2[0-3]):[03]0/;

			if (SLOT_REGEX.test(start) === false) {
				throw new Error(`start time supplied to getSlots isn't valid.`);
			}
		}

		const slots = [];

		const time = this.moment().startOf('day').hour(this.START_HOUR);
		const before = this.moment().startOf('day').hour(this.END_HOUR).add(1, 'hour');

		for (time; time.isBefore(before); time.add(30, 'minutes')) {
			const slot = time.format('HH:mm');

			// skip any times earlier or equal to any set start time.
			if (start && slot <= start) {
				continue;
			}

			slots.push({
				value: slot,
				label: slot,
			});
		}

		return slots;
	}

	getFutureDates() {
		const tomorrow = this.moment().add(1, 'day');
		const future = this.moment().add(1, 'year').add(1, 'month'); // extra month to be safe with autofills

		const dates = [];

		for (
			const date = tomorrow.clone();
			date.isBefore(future);
			date.add(1, 'day')
		) {
			dates.push({
				value: date.format('YYYY-MM-DD'),
				label: date.format('MMM Do YYYY'),
			});
		}

		return dates;
	}

	canSubmit() {
		let found_day = false;

		for (const day of this.DAYS) {
			const times = this.state[day];

			if (times) { // empty array is ok too
				found_day = true;
				break;
			}
		}

		// if not a single day is set, can't submit.
		if (!found_day) return false;

		// until needs to be set.
		if (!this.state.until) return false;

		return true;
	}

	submitSchedule = async (force_dates = []) => {
		if (!this.canSubmit()) {
			return this.notification.error(`Some fields are missing.`);
		}

		const { until } = this.state;

		const weekdays = {};

		for (const day of this.DAYS) {
			const times = this.state[day];

			if (!times) continue;

			weekdays[day] = times;
		}

		try {
			const result = await this.api.request(
				'post',
				`/users/${this.props.user_id}/bulk_availability`,
				{
					weekdays,
					until,
					force_dates,
				},
			);

			if (!result.success) throw new Error(result.error);

			this.setState({
				results: result.data,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onSubmit = async event => {
		event.preventDefault();

		this.submitSchedule();
	}

	renderForm() {
		const { until, drafts } = this.state;

		return (
			<Fragment>
				<div className="Card">
					Activate the days you want to set bulk times for below and choose a start and end time for each one. If any single day matching your rules has already been set by the therapist or an admin, it will be skipped.
				</div>
				<form onSubmit={this.onSubmit}>
					{
						this.DAYS.map(day => {
							// quick explanation of how this works.
							// loops through days from class array (order is important and should be week sequence)
							// if there is a truthy object (e.g. { start, end } it will be enabled, validated and included in payload.)
							// if it is null in state it will be unchecked and excluded from payload.
							const times = this.state[day];

							const enabled = !!times;

							// this funny syntax lets us do a blank string when inner key is null, or not defined at all.
							const draft_start = (drafts[day] || {}).start || '';
							const draft_end = (drafts[day] || {}).end || '';

							// find the earliest draft slot by getting the latest end time in the times range, if any
							let earliest_draft_slot = null;

							if (enabled) {
								for (const time of times) {
									if (!time.end) continue;

									if (
										!earliest_draft_slot
										|| time.end > earliest_draft_slot
									) {
										earliest_draft_slot = time.end;
									}
								}
							}

							return (
								<div className="weekday">
									<input
										id={`BulkScheduleModal_${day}`}
										type="checkbox"
										value={enabled}
										onChange={() => this.toggleDay(day)}
									/>
									<label htmlFor={`BulkScheduleModal_${day}`}>
										{day[0] + day.toLowerCase().slice(1)}
									</label>
									{
										enabled
										&& (
											<Fragment>
												{
													// existing ranges
													times.map((range, index) => {
														// this funny syntax lets us do a blank string when inner key is null, or not defined at all.
														const start = (range || {}).start || '';
														const end = (range || {}).end || '';

														return (
															<div className="split-view">
																<input type="text" disabled value={start} />
																<span>to</span>
																<input type="text" disabled value={end} />
																<a onClick={() => this.deleteSlotRange(day, index)}>
																	Remove
																</a>
															</div>
														);
													})
												}
												{/* new range */}
												<div className="split-view">
													<Autocomplete
														key={`draft_start_${draft_start}`}
														value={draft_start}
														onChange={value => this.setDraftStartTime(day, value)}
														options={this.getSlots(earliest_draft_slot)}
														required
													/>
													<span>to</span>
													<Autocomplete
														key={`draft_end_${draft_end}`}
														value={draft_end}
														onChange={value => this.setDraftEndTime(day, value)}
														options={this.getSlots(draft_start)}
														required
													/>
												</div>
											</Fragment>
										)
									}
								</div>
							);
						})
					}
					<p>
						Create availability until:
					</p>
					<Autocomplete
						value={until}
						onChange={value => this.setUntil(value)}
						options={this.getFutureDates()}
					/>
					<p className="shortcuts">
						<span>
							Shortcuts
						</span>
						<a onClick={() => this.setUntil(this.moment().add(1, 'month').format('YYYY-MM-DD'))}>
							1 month
						</a>
						<a onClick={() => this.setUntil(this.moment().add(6, 'months').format('YYYY-MM-DD'))}>
							6 months
						</a>
						<a onClick={() => this.setUntil(this.moment().endOf('year').format('YYYY-MM-DD'))}>
							End of year
						</a>
					</p>
					<button type="submit" disabled={!this.canSubmit()}>
						Submit
					</button>
				</form>
			</Fragment>
		);
	}

	renderResults() {
		const { results } = this.state;

		if (!results) return null;

		const { set_dates, skipped_dates } = results;

		const skipped_matching_rules = skipped_dates.filter(
			date => !!this.state[this.moment(date, 'YYYY-MM-DD').format('dddd').toUpperCase()],
		);
		const skipped_outside_rules = skipped_dates.filter(
			date => !skipped_matching_rules.includes(date),
		);

		return (
			<div className="results">
				<div className="Card">
					All done! From the rules you set, {set_dates.length} date{set_dates.length === 1 ? '' : 's'} were successfully set, and {skipped_dates.length} were skipped.
				</div>
				{
					set_dates.length > 0
					&& (
						<Fragment>
							<Table>
								<THead className="titles">
									<TRow>
										<TCol>
											Set Date 🟢
										</TCol>
										<TCol>
											Day of Week
										</TCol>
									</TRow>
								</THead>
								<TBody>
									{set_dates.map(date => (
										<TRow>
											<TCol>
												{date}
											</TCol>
											<TCol>
												{this.moment(date, 'YYYY-MM-DD').format('dddd')}
											</TCol>
										</TRow>
									))}
								</TBody>
							</Table>
						</Fragment>
					)
				}
				{
					skipped_matching_rules.length > 0
					&& (
						<Fragment>
							<div className="Card">
								These dates were skipped because they have already been set in the system.
							</div>
							<Table>
								<THead className="titles">
									<TRow>
										<TCol>
											Skipped Date 🟡
										</TCol>
										<TCol>
											Day of Week
										</TCol>
									</TRow>
								</THead>
								<TBody>
									{skipped_matching_rules.map(date => (
										<TRow>
											<TCol>
												{date}
											</TCol>
											<TCol>
												{this.moment(date, 'YYYY-MM-DD').format('dddd')}
											</TCol>
										</TRow>
									))}
									<TRow>
										<TCol>
											<button
												type="button"
												onClick={() => this.submitSchedule([
													...set_dates,
													...skipped_matching_rules,
												])}
											>
												Set these {skipped_matching_rules.length} dates as well
											</button>
										</TCol>
									</TRow>
								</TBody>
							</Table>
						</Fragment>
					)
				}
				{
					skipped_outside_rules.length > 0
					&& (
						<Fragment>
							<div className="Card">
								These dates were skipped because no rules matched that weekday.
							</div>
							<Table>
								<THead className="titles">
									<TRow>
										<TCol>
											Skipped Date 🔴
										</TCol>
										<TCol>
											Day of Week
										</TCol>
									</TRow>
								</THead>
								<TBody>
									{skipped_outside_rules.map(date => (
										<TRow>
											<TCol>
												{date}
											</TCol>
											<TCol>
												{this.moment(date, 'YYYY-MM-DD').format('dddd')}
											</TCol>
										</TRow>
									))}
								</TBody>
							</Table>
						</Fragment>
					)
				}
			</div>
		);
	}

	render() {
		const { show, onHide } = this.props;

		if (!show) return null;

		let content = null;

		if (this.state.results) {
			content = this.renderResults();
		} else {
			content = this.renderForm();
		}

		return (
			<GenericModal className="BulkSchedule" show={show} onHide={onHide}>
				<Title h1 text="Bulk schedule" />
				{content}
			</GenericModal>
		);
	}
}

BulkScheduleModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};
