import React, { Component } from 'react';
import Moment from 'moment';
import PageModel from '../_PageModel';

Moment.locale('uk', { week: { dow: 1 } });

export default class LeModel extends PageModel {
	constructor(props) {
		super(props);

		this.onUpdate = this.onUpdate.bind(this);
		this.onCorporateUpdate = this.onCorporateUpdate.bind(this);
		this.confirmAppointment = this.confirmAppointment.bind(this);
		this.onRemoveService = this.onRemoveService.bind(this);
		this.onAddService = this.onAddService.bind(this);
		this.onRescheduleService = this.onRescheduleService.bind(this);
		this.onExtraCharge = this.onExtraCharge.bind(this);
		this.getLocationTagLabels = this.getLocationTagLabels.bind(this);
	}

	state = {
		appointment: null,
		appointment_service_id_rescheduling: null,
		addable_services: {},
	}

	async getCoreData() {
		const relations = [
			"customer",
			"professional",
			"location",
			"services.service",
			"payments",
			"voucher_item.voucher",
			"events.name",
			"events.user",
			"chats.user",
			"location",
			"professional_searches",
			"review_answers.question",
			"review_answers.option",
			"review_comments",
		];

		const result = await this.api.request(
			'get',
			'/appointments/'
			+ this.id
			+ "?load="
			+ relations.join(','),
		);

		if (!result.success) throw new Error(result.error);

		const appointment = result.data;

		// fix empty objects fetched by the API (Bookshelf bug)
		if (appointment.voucher_item && !Object.keys(appointment.voucher_item).length) {
			appointment.voucher_item = null;
		}
		if (appointment.location && !Object.keys(appointment.location).length) {
			appointment.location = null;
		}
		if (appointment.customer && !Object.keys(appointment.customer).length) {
			appointment.customer = null;
		}
		if (appointment.professional && !Object.keys(appointment.professional).length) {
			appointment.professional = null;
		}

		this.setState({
			appointment: {
				...result.data,
			},
		});
	}

	async getAdditionalData(appointment) {
		appointment = appointment || this.state.appointment;

		await this.getAccountingData(appointment);

		// load services that can be added to this booking.
		try {
			const result = await this.api.request('get', '/services');

			if (!result.success) throw new Error(result.error);

			// only allow home services.
			const services_list = result.data.filter(s => s.type === 'HOME');

			// convert service list to a key-value list.
			const addable_services = {};

			for (const service of services_list) {
				addable_services[service.id] = service;
			}

			this.setState({
				addable_services,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	confirmRefund = async (destination, amount) => {
		const { appointment } = this.state;

		try {
			const result = await this.api.request(
				'post',
				`/appointments/${appointment.id}/refund?destination=${destination}&amount=${amount}`,
			);

			if (!result.success) throw new Error(result.error);

			this.onUpdate();

			this.hideRefundModal();
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	getAccountingData(appointment) {
		appointment = appointment || this.state.appointment;

		const promise_list = [
			this.api.request('get', '/appointments/' + appointment.id + '/accounting').then(result => {
				if (result.success) {
					return this.setState({
						accounts: result.data,
					});
				}

				// throw new Error(result.error);
			}),
		];

		switch (appointment.type) {
			case 'CORPORATE':
				promise_list.push(this.api.request('get', '/appointments/' + appointment.id + '/corporate_metadata').then(result => {
					if (result.success) {
						return this.setState({
							corporate_metadata: result.data,
						});
					}

					// throw new Error(result.error);
				}));
				break;
		}

		return Promise.all(promise_list);
	}

	getLocationTagLabels() {
		// TODO tags dead
		return [];

		const { tags } = this.state.appointment.location;
		// set up array to count the number of true/false for each label
		const labelValueCounters = [];
		// loop through tags
		for (const tag of tags) {
			// skip if tag value is null
			if (tag.value === null) continue;
			// check if we already started counting for this tag
			const existingCounter = labelValueCounters.find(counter => counter.label === tag.name.label);
			if (existingCounter) {
				// if we did, increment counter for tag value (true/false)
				existingCounter[tag.value] = existingCounter[tag.value] + 1;
			} else {
				// otherwise, add a counter for this tag
				labelValueCounters.push({
					label: tag.name.label,
					[tag.value]: 1,
					[!tag.value]: 0,
				});
			}
		}
		// filter labelValueCounters for labels where true was more common than false
		// then get the label
		const valid_labels = labelValueCounters.filter(counter => {
			if (counter.true > counter.false) return true;
			return false;
		}).map(count => count.label);

		// return only deduplicated, valid labels
		return valid_labels;
	}

	async onUpdate() {
		this.getCoreData();
		this.getAdditionalData();
	}

	onCorporateUpdate(corporate_metadata) {
		// reload accounting data
		this.getAccountingData();

		this.setState({
			corporate_metadata,
		});
	}

	onReviewUpdate = (newReview) => {
		const { appointment } = this.state;

		appointment.review_answers = (appointment.review_answers || []).filter(review => review.id !== newReview.id);
		appointment.review_answers.push(newReview);

		this.setState({
			appointment,
		});
	}

	async confirmAppointment() {
		try {
			const appointment = this.state.appointment;

			if (!appointment.professional_id) throw new Error("There is no professional set for this appointment yet.");

			const result = await this.api.request('put', '/appointments/' + appointment.id, {
				status: 'CONFIRMED',
			});

			if (!result.success) throw new Error(result.error);

			appointment.status = 'CONFIRMED';

			this.setState({
				appointment,
			});

			this.notification.success("Done! This appointment is now confirmed. Remember to send out the confirmation emails.");
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async unassignProfessional() {
		const { appointment } = this.state;

		try {
			if (!appointment.id) throw new Error("Appointment ID is not set.");

			const result = await this.api.request('post', '/appointments/' + appointment.id + '/unassign');

			if (!result.success) throw new Error(result.error);

			this.notification.success("Done! The professional has been unassigned from the booking.");

			appointment.events = result.data.events;

			return this.setState({
				appointment,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async onRemoveService(appointment_service_id) {
		if (!window.confirm('Are you sure you want to remove this treatment?\n\nRemember, you will need to refund any money manually.')) return;
		const { appointment } = this.state;
		const appointment_service = appointment.services.find(service => service.id === appointment_service_id);

		try {
			const services_result = await this.api.request('delete', `/appointment_services/${appointment_service_id}`);

			if (!services_result.success) throw new Error(services_result.error);

			// code for updating state / ui
			for (let i = 0; i < appointment.services.length; i++) {
				const service = appointment.services[i];

				if (service.id === appointment_service_id) {
					// remove treatment from appointment state.
					appointment.services.splice(i, 1);
					break;
				}
			}

			this.setState({
				appointment,
			});

			this.notification.success('Done! That treatment has been removed from this booking. You can send an updated confirmation email now.');
		} catch (error) {
			return this.notification.error(error.message);
		}

		try {
			if (appointment.status !== 'PENDING') throw new Error('We could not update the payment for the booking as it is not PENDING. You will need to manually adjust it.');

			// update the payment amount for pending bookings
			// get all payments for the appointment
			const payments_result = await this.api.request('get', `/appointments/${appointment.id}/payments/`);
			if (!payments_result.success) throw new Error(payments_result.error);

			const to_subtract = appointment_service.service.price;

			// now we need to find an appropriate payment to edit.
			// it needs to not be processed...
			// and it also needs to be greater than the price we are going to deduct.
			const valid_payments = payments_result.data.filter(payment => (
				payment.processed === false
				&& payment.amount >= to_subtract
			));

			// if payments_result.data.length !== 1 do nothing
			if (!valid_payments.length) {
				throw new Error(`We could not reduce the payment for the booking as there aren't any unprocessed payments that are large enough to subtract from. You will need to manually deduct £${(appointment_service.service.price / 100).toFixed(2)}.`);
			}

			// if there's more than one valid payment no biggie, we'll just grab the first one.
			const payment = valid_payments[0];

			// get price of the service and work out new booking amount
			const amount = payment.amount - appointment_service.service.price;

			// now ping the API and alter the payment.
			const alter_payments_result = await this.api.request('put', `/payments/${payment.id}`, { amount });

			if (!alter_payments_result.success) throw new Error(payments_result.error);

			// code for updating state / ui
			appointment.payments = appointment.payments.map(p => {
				if (p.id !== payment.id) return p;

				return {
					...p,
					amount,
				};
			});

			this.setState({
				appointment,
			});

			this.notification.success(`You don't need to worry about the payment, we automatically reduced it by £${(to_subtract / 100).toFixed(2)}.`);
		} catch (error) {
			this.notification.error(error.message);
		}

		try {
			// refresh accounting data
			this.getAccountingData();
		} catch (error) {
			console.error(error);
		}
	}

	async onAddService(service_id) {
		if (!service_id) return;

		const { appointment, addable_services } = this.state;
		const { id: appointment_id, customer_id: user_id } = appointment;

		try {
			const result = await this.api.request('post', `/appointment_services`, {
				appointment_id,
				service_id,
				quantity: 1,
				user_id,
			});

			if (!result.success) throw new Error(result.error);

			const item = result.data;

			// add the service data to this new item.
			item.service = addable_services[service_id];

			// add to existing appointment data for UI update
			appointment.services.push(item);

			this.setState({
				appointment,
			});

			this.notification.success('Done! That treatment has been added to this booking. You can send an updated confirmation email now.');
		} catch (error) {
			return this.notification.error(error.message);
		}

		try {
			const { appointment } = this.state;
			const new_service = appointment.services.find(service => service.service.id === service_id);

			if (appointment.status !== 'PENDING') throw new Error(`You will have to add a new payment of £${(new_service.service.price / 100).toFixed(2)} manually as the booking is not PENDING.`);

			const amount = new_service.service.price;

			// get credit_Card_id. Get first one where not null (eg when user used credit)
			const payment_with_card = appointment.payments.find(payment => payment.credit_card_id !== null);

			if (!payment_with_card) {
				const credit_balance = await this.api.request(
					'get',
					`/users/${appointment.customer_id}/credit_balance`,
				);

				if (credit_balance.data < amount) {
					throw new Error(`You will have to add the payment £${(new_service.service.price / 100).toFixed(2)} manually as we cannot find a credit card from the user\'s booking and they don't have enough credit.`);
				}
			}

			const payment_result = await this.api.request('post', '/payments', {
				amount: new_service.service.price,
				user_id: appointment.customer_id,
				credit_card_id: payment_with_card ? payment_with_card.credit_card_id : null,
				appointment_id: appointment.id,
				description: "Upgrade",
			});

			if (!payment_result.success) throw new Error(payment_result.error);

			this.notification.success(`A new payment of £${(new_service.service.price / 100).toFixed(2)} has been created for the booking.`);

			// ui update
			const payment = payment_result.data;

			appointment.payments.push(payment);

			this.setState({
				appointment,
			});
		} catch (error) {
			this.notification.error(error.message);
		}

		try {
			// refresh accounting data
			this.getAccountingData();
		} catch (error) {
			console.error(error);
		}
	}

	onRescheduleService(appointment_service_id_rescheduling) {
		this.setState({
			appointment_service_id_rescheduling,
		});
	}

	async onExtraCharge(amount) {
		if (!amount) return;

		const { appointment } = this.state;

		try {
			const result = await this.api.request('post', `/appointments/${appointment.id}/charge`, {
				amount,
				currency: 'GBP',
			});

			if (!result.success) throw new Error(result.error);

			// ui update
			const payment = result.data;

			appointment.payments.push(payment);

			this.setState({
				appointment,
			});

			this.hideChargeModal();

			this.notification.success(`Done! The customer has been charged an additional £${(amount / 100).toFixed(2)}.`);
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	canRefundBooking() {
		const { payments } = this.state.appointment;

		const processed_payments = payments.filter(payment => payment.processed);

		// if no payments have been processed, can't refund
		if (processed_payments.length === 0) return false;

		// if total refunds = total processed payments, can't refund
		if (processed_payments.reduce((a, b) => a + b.amount, 0) <= 0) return false;

		return true;
	}

	getAlternativeTimeEvents() {
		const { events } = this.state.appointment;

		return events.filter(event => event.name.label === 'OTHER_TIME_SUGGESTED');
	}

	onNewAlternativeTime = async (payload) => {
		const { appointment } = this.state;

		try {
			const result = await this.api.request(
				'post',
				`/appointments/${appointment.id}/alternative_times`,
				payload,
			);

			if (!result.success) throw new Error(result.error);

			const { events } = appointment;

			events.push(result.data);

			appointment.events = events;

			this.setState({
				appointment,
			});

			this.notification.success("All done! The alternative time has been created.");
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	onDeleteAlternativeTime = async (id) => {
		const { appointment } = this.state;
		const { id: appointment_id } = appointment;

		if (!window.confirm(`Are you sure you want to delete this alternative time? There is no going back.`)) return;

		try {
			const result = await this.api.request(
				'delete',
				`/appointments/${appointment_id}/alternative_times/${id}`,
			);

			if (!result.success) throw new Error(result.error);

			// code for updating state / ui
			for (let i = 0; i < appointment.events.length; i++) {
				const event = appointment.events[i];

				if (event.id === id) {
					appointment.events.splice(i, 1);
					break;
				}
			}

			this.setState({
				appointment,
			});

			this.notification.success(`Successfully deleted alternative time ${id}`);
		} catch (error) {
			this.notification.error(`Failed to delete alternative time ${id}: ${error}`);
		}
	}

	isDirectBook() {
		const { appointment } = this.state;
		const { events } = appointment;
		const placedEvent = events.find(event => event.name.label === 'PLACED');
		return placedEvent && placedEvent.metadata.professional_id;
	}

	hasBeenUnassigned() {
		const { appointment } = this.state;
		const { events } = appointment;

		const unassignedEvent = events.find(event => event.name.label === 'UNASSIGNED');
		if (unassignedEvent) return true;
	}
}
