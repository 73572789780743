import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeView from './view';

export default class ProfessionalZonesCard extends LeView {
	constructor(props) {
		super(props);
	}

	async componentWillReceiveProps(props) {
		await this.loadActivatedZones(props);
	}

	async componentDidMount() {
		await this.loadZones();
		await this.loadActivatedZones();
	}

	async toggle(current_state, zone) {
		let { selected_list } = this.state;

		try {
			switch (current_state) {
				case true:
					const professional_zone_id = this.state.selected_list[zone.id].id;

					//delete existing professional_zone
					const delete_result = await this.api.request('delete', '/professional_zones/' + professional_zone_id);

					if (!delete_result.success) throw new Error(delete_result.error);

					delete selected_list[zone.id];

					this.notification.info("Done! This professional is no longer working in that zone.")
					break;
				case false:
					//create new professional_zone
					const create_result = await this.api.request('post', '/professional_zones/', {
						user_id: this.props.professional_id,
						zone_id: zone.id
					})

					if (!create_result.success) throw new Error(create_result.error);

					selected_list[zone.id] = create_result.data;

					this.notification.success("Done! This professional is now working in that zone.")
					break;
			}

			this.setState({
				selected_list
			})
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}

ProfessionalZonesCard.propTypes = {
	professional_id: PropTypes.number.isRequired
}