import React, { Component } from 'react';
import {
	Router, Route, Link, browserHistory,
} from 'react-router';

import LeView from './view';

export default class StorefrontsPage extends LeView {
	constructor() {
		super();
	}

	componentDidMount() {
		this.getCoreData();
	}
}
