import React, { Component } from 'react';

import TableModel from '../_TableModel';

import './style.less';

export default class UserCreditTransactionsTable extends TableModel {
	constructor() {
		super();

		this.className = "UserCreditTransactionsTable";

		this.UNIQUE_INDEX = 3;

		this.titles = [{
			title: 'Date',
			type: Date,
			searchable: true,
			sortable: true,
			description: "Date"
		}, {
			title: 'Amount (£)',
			type: String,
			searchable: true,
			sortable: true,
			description: "Amount"
		}, {
			title: 'Type',
			type: String,
			searchable: true,
			sortable: true,
			description: "Type"
		}, {
			title: 'ID',
			type: Number,
			searchable: true,
			sortable: true,
			description: "ID"
		}]
	}

	dataForRows(raw_data) {
		if (!raw_data) return;

		let rows = [];

		for (const item of raw_data) {
			const { id, amount, reason, created_at } = item;

			rows.push([
				this.moment(created_at).unix(),
				(amount / 100).toFixed(2),
				reason ? reason.key : null,
				id
			])
		}

		return rows;
	}
}