import React from 'react';

import TableModel from '../_TableModel';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class CustomersTable extends TableModel {
	constructor() {
		super();

		this.className = "CustomersTable";

		this.UNIQUE_INDEX = 1;

		this.titles = [{
			title: '',
			searchable: false,
			sortable: false,
			description: "Avatar"
		}, {
			title: 'ID',
			type: Number,
			searchable: false,
			sortable: true,
			description: "Customer ID"
		}, {
			title: 'Name',
			type: String,
			searchable: true,
			sortable: true,
			description: "Name"
		}, {
			title: 'Email',
			type: String,
			searchable: true,
			sortable: true,
			description: "Email Address"
		}, {
			title: 'Phone',
			type: String,
			searchable: true,
			sortable: true,
			description: "Phone Number"
		}, {
			title: 'Description',
			type: String,
			searchable: true,
			sortable: true,
			description: "Description"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let customer = datum;

			let columns = [
				<Avatar url={customer.avatar} />,
				customer.id,
				customer.first_name + " " + customer.last_name,
				customer.email,
				customer.phone_number,
				customer.description
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/customers/' + data[1];
	}
}