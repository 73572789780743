import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

export default class AppointmentCorporateDurationCard extends Component {
	render() {
		if (!this.props.date) return null;

		const start_date = Moment(this.props.date);
		const end_date = start_date.clone().add(this.props.duration, 'minutes');

		return (
			<div className="Card AppointmentCorporateDuration">
				This corporate event will begin at {start_date.format('HH:mm')} and end at {end_date.format('HH:mm')}.
			</div>
		);
	}
}

AppointmentCorporateDurationCard.propTypes = {
	date: PropTypes.string.isRequired,
	duration: PropTypes.number.isRequired
}