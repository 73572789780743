import React from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class CategoriesTable extends TableModel {
	constructor() {
		super();

		this.className = "CategoriesTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Category ID",
		}, {
			title: 'Name',
			type: String,
			sortable: true,
			searchable: true,
			description: "Name",
		}, {
			title: 'Description',
			type: String,
			sortable: true,
			searchable: true,
			description: "Description",
		}, {
			title: 'Type',
			type: String,
			sortable: true,
			searchable: true,
			description: 'Type',
		}, {
			title: 'Code',
			type: String,
			sortable: true,
			searchable: true,
			description: "Code",
		}, {
			title: 'Belongs To',
			type: Number,
			sortable: true,
			searchable: false,
			description: 'Belongs To',
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const category = datum;

			const columns = [
				category.id,
				category.name,
				category.description,
				(([initial, ...rest]) => [initial.toUpperCase(), ...rest].join(""))(category.type.toLowerCase()), // hench capitalization of string
				category.code,
				category.user_id ? <UserLabel id={category.user_id} fetch shortName /> : <i>Not set</i>,
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/categories/' + data[0];
	}
}
