import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import FormModel from '../_FormModel';

import './style.less';

export default class UserDescriptionForm extends FormModel {
	constructor() {
		super();

		this.state = {
			text: "",
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		const user = this.props.user || {};
		const { columnToEdit } = this.props;

		this.setText(user[columnToEdit]);
	}

	componentWillReceiveProps(next) {
		const user = next.user || {};
		const { columnToEdit } = this.props;

		this.setText(user[columnToEdit]);
	}

	handleChange(event) {
		this.setText(event.target.value);
	}

	setText(text) {
		text = text || "";

		this.setState({
			text,
		});
	}

	handleSubmit(event) {
		event.preventDefault();

		const user = this.props.user;

		if (!user) return;

		let text = this.state.text;
		if (!text.length) text = null;

		const { columnToEdit } = this.props;

		user[columnToEdit] = text;

		this.api.request('put', '/users/' + this.props.user.id, {
			[columnToEdit]: user[columnToEdit],
		}).then(result => {
			if (!result.success) throw new Error(result.error);

			this.notification.success(`${columnToEdit} successfully updated! Your changes are now live.`);

			if (this.props.onUpdate) this.props.onUpdate(user);
		}).catch(error => {
			this.notification.error(error.message);
		});
	}

	render() {
		return (
			<form className="UserDescription" onSubmit={this.handleSubmit}>
				<Textarea rows={4} value={this.state.text} onChange={this.handleChange} />
				<button type="submit">
					Save {this.props.columnToEdit}
				</button>
			</form>
		);
	}
}

UserDescriptionForm.propTypes = {
	user: PropTypes.object,
	onUpdate: PropTypes.func,
	columnToEdit: PropTypes.string,
};
