import React, { Component } from 'react';

import FormModel from '../_FormModel';

export default class LoginForm extends FormModel {
	constructor() {
		super();

		this.state = {
			email: "",
			password: ""
		}
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<input name="email"
					type="text"
					required
					placeholder="Email address"
					value={this.state.email}
					onChange={this.handleInput}
				/>
				<input name="password"
					type="password"
					required
					placeholder="Password"
					value={this.state.password}
					onChange={this.handleInput}
				/>
				<button type="submit">
					Submit
				</button>
			</form>
		)
	}
}