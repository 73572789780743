import React, { Component } from 'react';

import Title from '../../misc/Title';
import GenericModal from '../../modals/Generic';


import Resource from '../Resource';

import './style.less';

export default class DeleteResource extends Resource {
	constructor() {
		super();

		this.handleDelete = this.handleDelete.bind(this);

		this.method = "delete";
	}

	handleDelete() {
		let delete_path = "/" + this.props.resource + "/" + this.props.existingData.id;

		this.api.request('delete', delete_path).then(result => {
			if (result.success) {
				this.notification.success("Done! That record has been deleted. 💣")

				if (this.props.onNewData) this.props.onNewData(null);

				return;
			}

			throw new Error(result.error);

		}).catch(error => {
			this.notification.error(error.message);
		})
	}

	render() {
		return (
			<div>
				<button onClick={this.openModal}>
					{this.props.title}
				</button>
				<GenericModal className="Resource delete" show={this.state.modalOpen} onHide={this.closeModal}>
					<Title h1 text={this.props.title} />
					<form>
						<p>
							Remember what Uncle Ben said... <em>"With great power, comes great responsibility"</em>.
						</p>
						<p>
							Are you absolutely sure you want to delete this?
						</p>

						<p>
							<button onClick={this.handleDelete}>Yes! Delete this record</button>
						</p>
						<p>
							<button onClick={this.closeModal}>Cancel</button>
						</p>
					</form>
				</GenericModal>
			</div>
		)
	}
}