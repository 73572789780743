import React, { Component } from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class AppointmentChatTable extends TableModel {
	constructor() {
		super();

		this.className = "AppointmentChatTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'Date',
			type: Date,
			sortable: true,
			searchable: true,
			description: "Date"
		}, {
			title: 'User'
		}, {
			title: 'Message',
			type: String,
			sortable: true,
			searchable: true,
			description: "Message"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		return data.map(chat => [
			this.moment(chat.created_at).unix(),
			<UserLabel user={chat.user} shortName />,
			chat.message
		])
	}
}