import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import GenericModal from '../Generic';
import Title from '../../misc/Title';

import './style.less';

export default class AppointmentChargeModal extends DataModel {
	constructor() {
		super();

		this.state = {
			value: null
		}

		this.notification = new Notification();

		this.valueChange = this.valueChange.bind(this);
		this.valueSubmit = this.valueSubmit.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.setState({
				value: null
			})
		}
	}

	valueChange(e) {
		let value = null;

		try {
			if (e.target.value) value = parseFloat(e.target.value).toFixed(2);

			this.setState({
				value: value
			});
		} catch (e) { }
	}

	valueSubmit(e) {
		e.preventDefault();

		const value = this.state.value;

		if (!value) return;

		const pennies_value = Math.floor(parseFloat(value) * 100);

		if (pennies_value < 0) return this.notification.error(`You can't charge a negative amount.`);

		const { onSubmit } = this.props;

		if (!window.confirm(`Definitely charge the customer £${value}?`)) return;

		onSubmit && onSubmit(pennies_value);
	}

	render() {
		const { show, onHide } = this.props;

		return (
			<GenericModal className="AppointmentCharge" show={show} onHide={onHide}>
				<Title h1 text="Extra payment" />
				<div className="Card">
					Enter an amount to charge for this booking. The system will use the same card as the customer's last payment.
				</div>
				<form onSubmit={this.valueSubmit}>
					<input
						onChange={this.valueChange}
						placeholder="0.00"
						required />
					<button>
						Charge {this.state.value ? "£" + this.state.value : null}
					</button>
				</form>
			</GenericModal>
		)
	}
}

AppointmentChargeModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}