import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../_DataModel';

export default class FormModel extends DataModel {
	constructor() {
		super();

		this.state = {};

		this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInput(event) {
		let state_extension = {};

		state_extension[event.target.name] = event.target.value;

		this.setState(state_extension);
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.props.onSubmit) {
			this.props.onSubmit(this.state);
		}
	}
}

FormModel.propTypes = {
	onSubmit: PropTypes.func
}