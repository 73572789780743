import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import {
	Table, TBody, TRow, TCol,
} from '../../tables/Generic';

export default class AppointmentCorporateServicesCard extends DataModel {
	componentDidMount() {
		this.getCoreData(this.props);
	}

	componentWillReceiveProps(next) {
		const prev = this.props;

		if (next.appointment_id !== prev.appointment_id) {
			this.getCoreData(next);
		}
	}

	getCoreData(props) {
		props = props || this.props;

		if (!props.appointment_id) return;

		Promise.all([
			this.api.request('get', '/services').then(result => {
				if (result.success) {
					return this.setState({
						full_list: result.data.filter(service => service.type === 'CORPORATE'),
					});
				}

				throw new Error(result.error);
			}),

			this.api.request('get', '/appointments/' + this.props.appointment_id + '/corporate_services').then(result => {
				if (result.success) {
					const selected = result.data;
					const formatted = {};

					selected.forEach(item => {
						formatted[item.service_id] = item;
					});

					return this.setState({
						selected_list: formatted,
					});
				}

				throw new Error(result.error);
			}),
		]).catch(error => {
			this.notification.error(error.message);
		});
	}

	toggle(current_state, model) {
		switch (current_state) {
			case true:
				const record_id = this.state.selected_list[model.id].id;

				this.api.request('delete', '/appointment_corporate_services/' + record_id).then(result => {
					if (result.success) {
						const selected_list = this.state.selected_list;

						delete selected_list[model.id];

						this.notification.info("Done! This service has been deactivated.");

						return this.setState({
							selected_list,
						});
					}

					throw new Error(result.error);
				}).catch(error => {
					this.notification.error(error.message);
				});
				break;
			case false:
				this.api.request('post', '/appointment_corporate_services/', {
					appointment_id: this.props.appointment_id,
					service_id: model.id,
				}).then(result => {
					if (result.success) {
						const selected_list = this.state.selected_list;

						selected_list[model.id] = result.data;

						this.notification.success("Done! This service is now activated.");

						return this.setState({
							selected_list,
						});
					}

					throw new Error(result.error);
				}).catch(error => {
					this.notification.error(error.message);
				});
				break;
		}
	}

	render() {
		const full_list = this.state.full_list || [];
		const selected_list = this.state.selected_list || {};
		const rows = [];

		full_list.forEach(item => {
			const isSelected = !!selected_list[item.id]; // convert to boolean

			rows.push(
				<TRow key={item.id}>
					<TCol>{item.option}</TCol>
					<TCol><input type="checkbox" checked={isSelected} onChange={() => { this.toggle(isSelected, item); }} /></TCol>
				</TRow>,
			);
		});

		return (
			<div className="Card CorporateServicesCard CheckboxListCard">
				<Table>
					<TBody>
						{rows}
					</TBody>
				</Table>
			</div>
		);
	}
}

AppointmentCorporateServicesCard.propTypes = {
	appointment_id: PropTypes.number.isRequired,
};
