import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { browserHistory } from 'react-router';

import LabelModel from '../_LabelModel';
import UserLabel from '../UserLabel';

import Auth from '../../../network/Auth';

import './style.less';

export default class UserInteractiveLabel extends LabelModel {
	constructor() {
		super();

		this.auth = new Auth();

		this.handleInteraction = this.handleInteraction.bind(this);
	}

	handleInteraction(key) {
		switch (key) {
			case "LOGOUT":
				return this.auth.logout();
				break;
			case "PROFILE":
				return browserHistory.push('/users/' + this.props.user.id);
				break;
		}
	}

	render() {
		let user = this.props.user;

		if (!user) return null

		return (
			<div className="Label UserInteractive">
				<UserLabel user={user} shortName />
				<DropdownButton onSelect={this.handleInteraction} title={""} bsStyle={"link"} pullRight id={"UserInteractiveLabel_dropdown"}>
					<MenuItem eventKey={"PROFILE"}>
						My Profile
					</MenuItem>
					<MenuItem eventKey={"LOGOUT"}>
						Logout
					</MenuItem>
				</DropdownButton>
			</div>
		)
	}
}

UserInteractiveLabel.propTypes = {
	user: PropTypes.object,
	id: PropTypes.number
};