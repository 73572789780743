import React, { Component } from 'react';

import APIClient from '../network/APIClient';
import Notification from './misc/Notification';
import Moment from 'moment-timezone';
Moment.tz.setDefault("Europe/London");
Moment.locale('uk', { week: { dow: 1 } });

// Simple data model
export default class Model extends Component {
	constructor(){
		super();

		this.state = {};
		this.api = new APIClient();
		this.notification = new Notification();
	}

	componentDidMount(){
		this._mounted = true;
	}

	componentWillUnmount(){
		this._mounted = false;
	}

	getData(path){
		if(!path) throw new Error('No path supplied.');

		return this.api.request(
			'get',
			path,
			null,
			data => {
				this.setState({
					data: {
						...(this.state.data || []),
						...data
					}
				});
			}
		).then(result => {
			// don't do anything further if it was successful.
			if(result.success) return;

			throw new Error(result.error);

		})
	}

	moment = Moment
}