import React from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import GenericModal from '../Generic';
import Title from '../../misc/Title';

import Tabs from '../../misc/Tabs';
import './style.less';

export default class ServiceDependenciesModal extends DataModel {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			selectedCategory: null,
		};
	}

	async componentDidMount() {
		const { currentService: { type } } = this.props;
		try {
			const categories_result = await this.api.request('get', `/service_categories?load=services`);

			const filtered = categories_result.data.filter(c => c.user_id === null && type === c.type);

			this.setState({
				categories: filtered,
			});
		} catch (error) {
			this.notification.error('Ah shucks :(', error);
		}
	}

	checkAllServices = () => {
		const { handleServiceClick, selectedDependencies } = this.props;

		const services = this.formatServices();

		for (const service of services) {
			const found = selectedDependencies.find(selectedService => service.id === selectedService.id);
			if (found) continue;

			handleServiceClick(service);
		}
	}

	showSelectAllLink = () => {
		const { selectedCategory } = this.state;
		const { selectedDependencies } = this.props;

		if (!selectedCategory) return false;

		const allCatServices = this.formatServices();
		const selectedCatServices = selectedDependencies.filter(dependency => selectedCategory.id === dependency.category_id);

		return allCatServices.length !== selectedCatServices.length;
	}

	setCategory = (category) => {
		this.setState({
			selectedCategory: category,
		});
	}

	formatServices = () => {
		const { selectedCategory } = this.state;
		const { currentService: { type } } = this.props;

		console.log(selectedCategory);

		const home = type === 'HOME';
		const corporate = type === "CORPORATE";

		if (!selectedCategory) return [];

		// only allow services that could work with the selected service.
		// ie. if gel removal is selected, which works for home treatments, allow all home treatments. etc.
		return selectedCategory.services.filter(service => {
			if (home && service.type === 'HOME') return true;
			if (corporate && service.type === 'CORPORATE') return true;
			return false;
		});
	}

	render() {
		const {
			show, onHide, currentService, handleServiceClick, selectedDependencies, handleSubmit,
		} = this.props;
		const { selectedCategory, categories } = this.state;

		// we filter out addons here
		const categoriesTabs = categories.map(category => ({ label: category.name, value: category }))
			.filter(category => category.label !== 'Addons');

		return (
			<GenericModal className="ServiceDependencies" show={show} onHide={onHide}>
				<Title h1 text="Edit Service Dependencies" />
				<div>
					<div>
						<Title h3 text={currentService.option} />
						<div className="checkbox-town">
							<Tabs
								tabs={categoriesTabs}
								selectedTab={selectedCategory}
								onSelect={this.setCategory}
							/>
							<div className="checkboxes">
								{this.formatServices().map(service => (
									<div onClick={() => handleServiceClick(service)}>
										<input
											type="checkbox"
											id={service.id}
											checked={!!selectedDependencies.find(selectedService => service.id === selectedService.id)}
										/>
										<span>
											{service.option}
										</span>
									</div>
								))}
							</div>
							{this.showSelectAllLink() && (
								<div className="link-container">
									<a className="inline-link" onClick={() => this.checkAllServices()} href="#">
										Select all {selectedCategory.name} services
									</a>
								</div>
							)
							}
						</div>
					</div>
					<div>
						<Title h3 text="Current dependencies" />
						<ul>
							{selectedDependencies.map(service => (
								<li>
									{service.option}
								</li>
							))}
						</ul>
						<p>
							A {currentService.option} can only be booked if the basket contains one of the listed treatments.
						</p>
					</div>
				</div>
				<button className="submit" onClick={handleSubmit}>
					Save
				</button>
			</GenericModal>
		);
	}
}

ServiceDependenciesModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};
