import React from 'react';
import { browserHistory } from 'react-router'

import PageModel from '../_PageModel';

import JSONPanel from '../../misc/JSONPanel';
import UpdateResource from '../../buttons/UpdateResource';
import DeleteResource from '../../buttons/DeleteResource';
import UpdateImageCard from '../../cards/UpdateImageCard';
import Title from '../../misc/Title';

import './style.less';

export default class GiftPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.onUpdate = this.onUpdate.bind(this);
		this.onImageUpdate = this.onImageUpdate.bind(this);
	}

	getCoreData() {
		return this.api.request(
			'get',
			'/gifts/'
			+ this.id
			+ "?load="
		).then(result => {
			if (result.success) {
				this.setState({
					gift: result.data
				})

				return result.data;
			}

			throw new Error(result.error);
		})
	}

	componentDidMount() {
		this.getCoreData().then(gift => {


		}).catch(error => {
			console.error(error);
		})
	}

	onUpdate(data) {
		let gift = this.state.gift;

		gift = Object.assign(gift, data);

		this.setState({
			gift
		})
	}

	async onImageUpdate(image) {
		let { gift } = this.state;

		//get gift id.
		const { id } = gift;

		try {
			const result = await this.api.request('post', `/gifts/${id}/image`, {
				image
			})

			if (!result.success) throw new Error(result.error);

			const { image_url } = result.data;

			gift.image_url = image_url;

			this.setState({
				gift
			});

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	render() {
		let gift = this.state.gift;

		if (!gift) return null;

		return (
			<div className="Page GiftPage">
				<Title h1 text="Gift Details">
					<UpdateResource
						title={"Edit Gift"}
						resource={"gifts"}
						existingData={gift}
						onNewData={this.onUpdate} />

					<DeleteResource
						title="Delete Gift"
						resource="gifts"
						existingData={gift}
						onNewData={browserHistory.goBack} />
				</Title>

				<div className="split-view bordered">
					<div flex={2}>
						<div className="Card">
							<JSONPanel data={gift} />
						</div>
					</div>
					<div>
						<Title h3 text="Current image" />
						<div className="Card">
							{
								gift.image_url
									? <img className="image-preview" src={gift.image_url} />
									: <i>None</i>
							}
						</div>

						<Title h3 text="Update image" />
						<UpdateImageCard
							width={1000}
							height={625}
							onImageUpdate={this.onImageUpdate}
						/>
					</div>
				</div>
			</div>
		);
	}
}