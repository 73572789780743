import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import LabelModel from '../_LabelModel';

export default class AppointmentSourceLabel extends LabelModel {
	render() {
		let source = this.props.source;

		if (!source) return null;

		let icon_name = null;

		source = source.toLowerCase();
		if (source.indexOf("web") > -1) {
			icon_name = "desktop";

		} else if (source.indexOf("ios") > -1) {
			icon_name = "apple"

		} else if (source.indexOf("android") > -1) {
			icon_name = "android"

		} else {
			icon_name = "question"
		}

		return (
			<div className="Label AppointmentSource">
				<FontAwesome name={icon_name} />
			</div>
		)
	}
}

AppointmentSourceLabel.propTypes = {
	source: PropTypes.string
}