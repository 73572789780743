import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import GenericModal from '../../modals/Generic';
import Autocomplete from '../../forms/Autocomplete';
import Title from '../../misc/Title';

import DataModel from '../../_DataModel';

import './style.less';

export default class NewAlternativeTime extends DataModel {
	constructor() {
		super();

		this.state = {
			users: [],
			user_id: null,
			date: null,
			time: null,
		}

		this.available_dates = [];

		for (
			let date_iterator = this.moment();
			date_iterator.isBefore(this.moment().add(6, 'months'));
			date_iterator.add(1, 'day')
		) {
			this.available_dates.push(date_iterator.format('DD/MM/YYYY'))
		}

		this.available_times = [];

		for (
			let hour = 6;
			hour <= 23;
			hour ++
		) {
			const padded = hour.toString().padStart(2, '0');

			this.available_times.push(
				`${padded}:00`,
				`${padded}:30`
			);
		}
	}

	componentDidMount() {
		this.resetData();
		this.fetchUsers();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.resetData();
		}
	}

	fetchUsers = async () => {
		const result = await this.api.request('get', '/users?where={"type":"PROFESSIONAL","activated":true}');

		const users = result.data;

		this.setState({
			users
		})
	}

	resetData = () => {
		this.setState({
			user_id: null,
			date: null,
			time: null,
		})
	}

	formatLabel = (user_id) => {
		const { users } = this.state;

		if (!users.length) return user_id

		const user = users.find(user => user.id === user_id)

		if (!user) return `Unknown user`;

		return `${user.first_name} ${user.last_name}`
	}

	onBehalfOfChange = (user_id) => {
		if (this.state.user_id === user_id) return;

		this.setState({
			user_id
		})
	}

	onDateChange = (date) => {
		if (this.state.date === date) return;

		this.setState({
			date
		})
	}

	onTimeChange = (time) => {
		if (this.state.time === time) return;

		this.setState({
			time
		})
	}

	getOfferedUsers() {
		const { events } = this.props

		const offers = events.filter(event => event.name.label === 'WAS_OFFERED')

		return offers.map(offer => offer.user_id)
	}

	onConfirm = async () => {
		const { user_id } = this.state;

		const start_hour = parseInt(this.state.time.split(':')[0])
		const start_minute = parseInt(this.state.time.split(':')[1])

		let date = this.moment(this.state.date, 'DD/MM/YYYY').set('hour', start_hour).set('minute', start_minute);

		const payload = {
			datetime: date.format(),
			user_id
		}

		this.props.onSubmit && this.props.onSubmit(payload);

		this.props.onHide && this.props.onHide();
	}

	formIsComplete() {
		const { user_id, date, time } = this.state;

		if (user_id && date && time) return true

		return false;
	}

	renderOnBehalfOf() {
		let user_ids = this.getOfferedUsers()
		const { user_id } = this.state;
		const { customer_id } = this.props;

		let options = [{
			value: customer_id,
			label: 'The Customer',
		}]

		options = options.concat([...new Set(user_ids)].map(user_id => ({
			value: user_id,
			label: this.formatLabel(user_id)
		})))

		return <Fragment>
			<p>
				Who do you want to propose a time on behalf of?
			</p>
			<Autocomplete
				value={user_id}
				options={options}
				clearable={false}
				onChange={this.onBehalfOfChange}
			/>
			<p class="small">
				Only Salonettes who have been offered the booking will appear in this list.
			</p>
		</Fragment>
	}

	behalfOfIsComplete() {

	}

	renderInputDateTime = () => {
		const { date, start_time } = this.state;
		
		const now = this.moment();
		
		const dates = this.available_dates;
		const times = this.available_times.filter(time => {
			if(!date) return true;
			
			// show all times if it's not today.
			if(!this.moment(date, 'DD/MM/YYYY').isSame(now, 'd')){
				return true;
			}

			const hour = time.split(':')[0];
			const minute = time.split(':')[1];

			const comparator = now.clone().startOf('day').hour(hour).minute(minute);

			// only show future times when the current date is selected.
			return now.isBefore(comparator);
		});


		return <Fragment>
			<p>
				When is the alternative time?
			</p>
			<div className="dates">
				<div>
					<Autocomplete
						value={date}
						options={dates.map(date => {
							return {
								value: date,
								label: date
							};
						})}
						clearable={false}
						onChange={this.onDateChange}
						placeholder={'Date...'}
					/>
				</div>
				<div>
					{date && <Autocomplete
						value={start_time}
						options={times.map(time => {
							return {
								value: time,
								label: time
							};
						})}
						clearable={false}
						onChange={this.onTimeChange}
						placeholder={'Start time...'}
					/>}
				</div>
			</div>
		</Fragment>
	}

	renderForm() {
		const { events } = this.props;

		if (!events) {
			console.warn('Incorrectly set props.');
			return null
		}

		const { user_id } = this.state;

		return <form className="alternative-time">
			{this.renderOnBehalfOf()}
			{user_id && this.renderInputDateTime()}
			{this.formIsComplete() &&
				<button type="button" className="confirm" onClick={this.onConfirm}>
					Submit
				</button>
			}
		</form >
	}

	render() {
		return (
			<GenericModal className="NewAlternativeTime" show={this.props.show} onHide={this.props.onHide}>
				<Title h1 text="New alternative time" />
				{this.renderForm()}
			</GenericModal>
		)
	}
}

NewAlternativeTime.propTypes = {
	events: PropTypes.object.isRequired,

	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
}