import React, { Component } from 'react';

import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor() {
		super();

		this.state = {
			professionals: []
		}
	}

	async loadProfessionals() {
		const { appointmentId } = this.props;

		try {
			const result = await this.api.request('get', `/appointments/${appointmentId}/ranked_professionals`);

			if (!result.success) throw new Error(result.error);

			const professionals = result.data;

			this.setState({
				professionals
			});

		} catch (error) {
			this.notification.error(error.message);
		}
	}
}