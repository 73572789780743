import _ from 'lodash';
import React, { Component, Fragment } from 'react';

import TableModel from '../_TableModel';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class ProfessionalsTable extends TableModel {
	constructor() {
		super();

		this.className = "ProfessionalsTable";

		this.UNIQUE_INDEX = 1;

		this.titles = [{
			title: '',
			searchable: false,
			sortable: false,
			description: "Avatar",
		}, {
			title: 'ID',
			type: Number,
			searchable: false,
			sortable: true,
			description: "Professional ID",
		}, {
			title: 'Name',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Email',
			type: String,
			searchable: true,
			sortable: true,
			description: "Email Address",
		}, {
			title: 'Phone',
			type: String,
			searchable: true,
			sortable: true,
			description: "Phone Number",
		}, {
			title: 'Description',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Rating',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Postcode',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Treatment',
			type: String,
			searchable: true,
			sortable: true,
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const professional = datum;

			const {
				id,
				avatar,
				first_name,
				last_name,
				email,
				phone_number,
				description,
				metrics,
				instant_book_enabled,
				regions,
				professional_services,
			} = professional;

			const avatarColumn = (
				<Fragment>
					<Avatar url={avatar} />
					{
						instant_book_enabled && (
							<div className="Label Badge">
								<span>Auto Accept</span>
							</div>
						)
					}
				</Fragment>
			);

			const columns = [
				avatarColumn,
				id,
				`${first_name} ${last_name}`,
				email,
				phone_number,
				description,
				metrics ? metrics.rating.toFixed(2) : null,
				regions.map(region => region.zone.post_code).sort().join(', '),
				_.uniq(
					professional_services.map(s => {
						try {
							return s.service.category.name;
						} catch (error) {
							return null;
						}
					}).filter(Boolean).sort(),
				).join(', '),
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/professionals/' + data[1];
	}
}
