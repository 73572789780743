import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.less';

export default class Tabs extends Component {
	state = {}

	componentDidMount() {
		// if toggle was specified on the component, this title becomes a control for its next sibling.
		const { toggle } = this.props;

		if (toggle) {
			this.setState({
				collapsed: true,
			});
		}
	}

	render() {
		const { tabs, selectedTab, onSelect } = this.props;

		const items = [];

		if (!tabs || !tabs.length) return null;

		for (const { value, label } of tabs) {
			const className = (
				selectedTab === value
					? 'selected'
					: ''
			);

			items.push(<div
				key={label}
				className={className}
				onClick={() => onSelect && onSelect(value)}
			>
				{label}
              </div>);
		}

		return (
			<div className="Tabs">
				{items}
			</div>
		);
	}
}

Tabs.propTypes = {
	// { value: '', label: '' }
	tabs: PropTypes.array.isRequired,
	// value of above
	selectedTab: PropTypes.string,
	// function that sends selected value
	onSelect: PropTypes.bool,
};
