import React, { Component } from 'react';

import Resource from '../Resource';

import './style.less';

export default class CreateResource extends Resource {
	constructor() {
		super();

		this.handleSubmit = this.handleSubmit.bind(this);

		this.method = "create";
	}

	handleSubmit(data) {
		let payload = data.formData;

		//don't send any empty values, allowing the system to use its defaults (or null)
		Object.keys(payload).forEach(key => {
			if (payload[key] === "" || !payload[key] === undefined) {
				delete payload[key];
				return;
			}

			//react-jsonschema-form force casts everything to UTC.
			//this.restoreTimeZone makes it ISO-8601, and converts it back to BST (if necessary).
			if (key === "datetime") {
				payload[key] = this.restoreTimeZone(payload[key]);
			}
		})

		//now we have patched our original data with the modified changes.
		this.api.request(
			'post',
			'/' + this.props.resource,
			payload
		).then(result => {
			if (!result.success) throw new Error(result.error)

			this.notification.success("All done! You have successfully created a new record.");
			this.closeModal();

			if (this.props.onNewData) return this.props.onNewData(result.data);

		}).catch(error => {
			this.notification.error(error.message);
		})
	}
}