import React from 'react';

import TableModel from '../_TableModel';

export default class GiftsTable extends TableModel {
	constructor() {
		super();

		this.className = "GiftsTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Gift ID"
		}, {
			title: 'Name',
			type: String,
			sortable: true,
			searchable: true
		}, {
			title: 'Price',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Price (£)"
		}, {
			title: 'Code',
			type: String,
			sortable: true,
			searchable: true
		}, {
			title: 'Description',
			type: String,
			sortable: true,
			searchable: true
		}, {
			title: 'Purchase Count',
			type: Number,
			sortable: true,
			searchable: true
		}];
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		data.forEach(datum => {
			let gift = datum;

			let columns = [
				gift.id,
				gift.name,
				gift.price,
				gift.code,
				gift.description,
				gift.payments ? gift.payments.length : null
			];

			rows.push(columns);
		})

		return rows;
	}

	hyperlink(data) {
		return '/gifts/' + data[0];
	}
}