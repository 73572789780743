import React, { Component } from 'react';
import _ from 'lodash';

import { Link } from 'react-router';

import LeModel from './model';

import UserLabel from '../../labels/UserLabel';
import Title from '../../misc/Title'
import Autocomplete from '../../forms/Autocomplete';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state
	}

	slotsHeader() {
		const slots = this.makeSlots();

		return <div className="slots header">
			{slots.map(slot => {
				let should_show_text = false;

				//if it's on the hour, let's show the time.
				if (slot.indexOf(':00') > -1) {
					should_show_text = true;
				}

				return <div className="slot" key={slot}>
					{should_show_text && <div className="slot-text">
						{slot}
					</div>}
				</div>
			})}
		</div>
	}

	slotsFromSchedule(user_id, date, slots) {
		if (!slots) slots = this.makeSlots();

		//get list of keys and order a-z
		const keys = Object.keys(slots).sort();

		const { colors, showBookingOverlay } = this.state;

		return <div className="slots" key={`slots_${user_id}`}>
			{keys.map(slot => {
				let classNames = ['slot']

				let style = {};

				//if this schedule is activated, highlight the cell.
				if (slots[slot] === true) {
					classNames.push('highlighted');

					if (colors) {
						const custom_color = colors[user_id];

						if (custom_color) {
							style.backgroundColor = custom_color;
							style.borderColor = custom_color;
						}
					}
				}

				if (showBookingOverlay) {
					const { has_booking, is_platform } = this.hasBooking(user_id, date, slot)

					if (has_booking) {
						if(is_platform) {
							classNames.push('has-platform-booking')
						} else {
							classNames.push('has-booking');
						}
					}
				}

				return <div className={classNames.join(' ')} key={slot} style={style}>
					<div className="slot-text hide-by-default">
						{slot}
					</div>
				</div>
			})}
		</div>
	}

	renderSalonette(user) {
		return <div className="salonette" key={`salonette_${user.id}`}>
			<Link to={`/users/${user.id}`}>
				<UserLabel user={user} />
			</Link>
		</div>
	}

	formatDate(date) {
		date = this.moment(date).startOf('day');

		const now = this.moment().startOf('day');

		if (date.isSame(now, 'd')) return 'Today';
		if (date.diff(now, 'days') === 1) return 'Tomorrow';
		if (date.diff(now, 'days') === -1) return 'Yesterday';

		return date.format('dddd Do MMM');
	}

	shouldShowTodayButton() {
		const { selectedDate } = this.state;

		const today = this.moment();

		//if it's already on today, don't show.
		if (this.moment(selectedDate).isSame(today, 'd')) {
			return false;
		}

		return true;
	}

	render() {
		const { professionals, availability, selectedDate, dates, showBookingOverlay } = this.state;

		if (!availability) return null;

		const entries = availability[selectedDate] || []; //might be null

		// add a flag saying if the user has availability on this day or not.
		let sorted_professionals = professionals.map(user => {
			user.has_availability = !!entries.find(entry => entry.user_id === user.id)
		});

		// then order availability salonettes at the top, and then non availability below.
		// both ordered by name.
		sorted_professionals = _.orderBy(professionals, ['has_availability', 'first_name', 'last_name'], ['desc', 'asc', 'asc'])

		return (
			<div className="Page SchedulePage">
				<Title h1 text={this.formatDate(selectedDate)}>
					<form className="toggle-bookings">
						<input
							id="SchedulePageToggleBookingOverlay"
							type="checkbox"
							checked={showBookingOverlay}
							onChange={this.toggleBookings}
						/>
						<label htmlFor="SchedulePageToggleBookingOverlay">
							Bookings
						</label>
					</form>

					{this.shouldShowTodayButton() && <form>
						<button type="button" onClick={this.today}>
							Today
						</button>
					</form>}

					<form>
						<button type="button" onClick={this.prevDay}>
							Prev
						</button>

						<Autocomplete
							value={selectedDate}
							options={dates}
							onChange={this.onDateChange}
							clearable={false}
							multiple={false}
							placeholder={"Jump to date..."}
						/>

						<button type="button" onClick={this.nextDay}>
							Next
						</button>
					</form>

				</Title>
				<div className="split-view">
					<div className="salonette-list">
						{sorted_professionals.map(
							user => this.renderSalonette(user)
						)}
					</div>
					<div className="availability-table">
						{this.slotsHeader()}
						{sorted_professionals.map(
							user => this.slotsFromSchedule(
								user.id,
								selectedDate,
								(
									entries.find(entry => entry.user_id === user.id)
									|| {}
								).slots
							)
						)}
					</div>
				</div>
				<div className="results-count">
					{
						entries
							? `${entries.length} Salonette${entries.length !== 1 ? 's' : ''} working`
							: `No data available`
					}
				</div>
			</div>
		);
	}
}