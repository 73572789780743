import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeView from './view';

export default class ProfessionalBadgesCard extends LeView {
	constructor(props) {
		super(props);
	}

	async componentWillReceiveProps(props) {
		await this.loadActivatedBadges(props);
	}

	async componentDidMount() {
		await this.loadBadges();
		await this.loadActivatedBadges();
	}

	async toggle(current_state, badge) {
		let { selected_list } = this.state;

		try {
			switch (current_state) {
				case true:
					const professional_badge_id = this.state.selected_list[badge.id].id;

					//delete existing professional_badge
					const delete_result = await this.api.request('delete', '/user_badges/' + professional_badge_id);

					if (!delete_result.success) throw new Error(delete_result.error);

					delete selected_list[badge.id];

					this.notification.info("Done! This professional is no longer the owner of that badge.")
					break;
				case false:
					//create new professional_zone
					const create_result = await this.api.request('post', '/user_badges/', {
						user_id: this.props.user_id,
						badge_id: badge.id
					})

					if (!create_result.success) throw new Error(create_result.error);

					selected_list[badge.id] = create_result.data;

					this.notification.success("Done! This professional is now the proud owner of that badge.")
					break;
			}

			this.setState({
				selected_list
			})
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}

ProfessionalBadgesCard.propTypes = {
	user_id: PropTypes.number.isRequired
}