import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class UserProfileCard extends Component {
	constructor() {
		super();

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		if (!this.props.interactive) return;

		browserHistory.push('/users/' + this.props.user.id);
	}

	render() {
		if (!this.props.user) return null;

		let user = this.props.user;

		let classes = ["Card", "UserProfileCard"];

		if (this.props.interactive) classes.push("interactive");

		return (
			<div className={classes.join(" ")} onClick={this.handleClick}>
				<Avatar url={user.avatar} />
				<div className="name">
					{user.first_name} {user.last_name}
				</div>
				<div className="type">
					<div className="Label Pill">
						<span>{user.type}</span>
					</div>
					{user.admin ? <div className="Label Pill">
						<span>Admin</span>
					</div> : ""}
					{user.is_vip ? <div className="Label Pill">
						<span>VIP</span>
					</div> : ""}
					{user.instant_book_enabled ? <div className="Label Pill">
						<span>Auto Accept</span>
					</div> : ""}
				</div>
			</div>
		)
	}
}

UserProfileCard.propTypes = {
	user: PropTypes.object.isRequired
}