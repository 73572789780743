import React from 'react';
import { Link } from 'react-router';

import {
	Table, TBody, TRow, TCol, THead,
} from '../../tables/Generic';
import ServiceDetailsCard from '../../cards/ServiceDetailsCard';
import PaymentDetailsCard from '../../cards/PaymentDetailsCard';
import ReviewsCard from '../../cards/ReviewsCard';
import AppointmentStatusLabel from '../../labels/AppointmentStatusLabel';
import LimitedAvailabilityLabel from '../../labels/LimitedAvailabilityLabel';
import SurgeLabel from '../../labels/SurgeLabel';
import UserLabel from '../../labels/UserLabel';
import UpdateResource from '../../buttons/UpdateResource';
import AppointmentActionsCard from '../../cards/AppointmentActionsCard';
import AppointmentAccountingDetailsCard from '../../cards/AppointmentAccountingDetailsCard';
import AppointmentDiscountsCard from '../../cards/AppointmentDiscountsCard';
import AppointmentCorporateDurationCard from '../../cards/AppointmentCorporateDurationCard';
import AppointmentCorporateServicesCard from '../../cards/AppointmentCorporateServicesCard';
import AppointmentDescription from '../../forms/AppointmentDescription';
import AppointmentChargeModal from '../../modals/AppointmentCharge';
import RegularCorporateModal from '../../modals/RegularCorporate';
import RescheduleCorporateTreatment from '../../modals/RescheduleCorporateTreatment';
import NewAlternativeTime from '../../modals/NewAlternativeTime';
import Title from '../../misc/Title';
import AppointmentChatTable from '../../tables/AppointmentChat';
import AlternativeTimesTable from '../../tables/AlternativeTimes';
import AppointmentTimelineTable from '../../tables/AppointmentTimeline';
import ProfessionalSearchesTables from '../../tables/Searches';
import RankedProfessionalsList from '../../cards/RankedProfessionalsList';
import BannedRibbon from '../../misc/BannedRibbon';
import RefundModal from '../../modals/RefundModal';

import LeModel from './model';
import ProfessionalsInPostcodeCard from '../../cards/ProfessionalsInPostcodeCard';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state,

		chargeModalOpen: false,
		showRegularCorporateModal: false,
		refundModalOpen: false,
	}

	renderInformation() {
		const { appointment } = this.state;

		if (!appointment) return null;

		const {
			status, location, comments, customer, professional, quiet_mode, professional_searches,
		} = appointment;
		let { datetime, created_at, updated_at } = appointment;

		datetime = this.moment(datetime);
		created_at = this.moment(created_at);
		updated_at = this.moment(updated_at);

		return (
			<div>
				{appointment.type === 'CORPORATE' && (
					<div className="Card corporate-link">
						Attendees of this corporate event can reserve their slot with the following link.
						<a href={`https://corporate.lesalon.com/event/${appointment.id}`} target="_blank" rel="noreferrer">
							https://corporate.lesalon.com/event/{appointment.id}
						</a>
					</div>
				)}

				<div className="Card information">
					<Table>
						<TBody>
							<TRow>
								<TCol>Status</TCol>
								<TCol><AppointmentStatusLabel status={status} /></TCol>
							</TRow>
							<TRow>
								<TCol>Date & Time</TCol>
								<TCol>
									{datetime.format('dddd, Do MMMM YYYY, HH:mm')}
									<br />
									<i>
										({datetime.fromNow()})
									</i>
									<div className="date-meta">
										<LimitedAvailabilityLabel events={appointment.events} />
										<SurgeLabel events={appointment.events} />
										{this.isDirectBook() && !this.hasBeenUnassigned() && (
											<div key="direct" className="Label Badge">
												Direct
											</div>
										)}
									</div>
								</TCol>
							</TRow>
							<TRow>
								<TCol>Professional</TCol>
								<TCol>
									{
										professional
											? (
												<Link to={"/users/" + professional.id}>
													<UserLabel user={professional} />
												</Link>
											)
											: <i>Not assigned</i>
									}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Customer</TCol>
								<TCol>
									{
										customer
											? (
												<Link to={"/users/" + customer.id}>
													<UserLabel user={customer} />
												</Link>
											)
											: <i>Not assigned</i>
									}
								</TCol>
							</TRow>
							{location && location.id && (
								<TRow>
									<TCol>Address</TCol>
									<TCol>
										{location.address}, {location.postcode}
										<div className="address-tags">
											{this.getLocationTagLabels().map(label => (
												<div className="Label Badge tag">
													<span>{label}</span>
												</div>
											))}
										</div>
									</TCol>

								</TRow>
							)}
							{appointment.type === 'HOME'
							&& (
								<TRow>
									<TCol>Comments</TCol>
									<TCol>{comments || <i>None</i>}</TCol>
								</TRow>
							)
							}
							{appointment.type === 'HOME'
							&& (
								<TRow>
									<TCol>Personalisation</TCol>
									<TCol>{quiet_mode ? `${customer.first_name} has requested a quiet session.` : <i>None</i>}</TCol>
								</TRow>
							)
							}
							<TRow>
								<TCol>Created At</TCol>
								<TCol>
									{created_at.format('dddd, Do MMMM YYYY, HH:mm')}
									<br />
									<i>
										({created_at.fromNow()})
									</i>
								</TCol>
							</TRow>
							<TRow>
								<TCol>Last Modified</TCol>
								<TCol>
									{updated_at.format('dddd, Do MMMM YYYY, HH:mm')}
									<br />
									<i>
										({updated_at.fromNow()})
									</i>
								</TCol>
							</TRow>
						</TBody>
					</Table>
				</div>
			</div>
		);
	}

	renderAccounting() {
		const { appointment, accounts } = this.state;

		if (!accounts) return null;

		return (
			<AppointmentAccountingDetailsCard
				appointment={appointment}
				accounts={accounts}
			/>
		);
	}

	renderEvents() {
		const {
			appointment,
		} = this.state;

		if (!appointment) return null;

		const { events } = appointment;

		if (!events || !events.length) return (
			<div className="Card">
				<i>No events yet</i>
			</div>
		);

		return (
			<div className="Card">
				<AppointmentTimelineTable
					data={events}
					infinite={false}
					interactive={false}
					filterable
					defaultLimit={5}
				/>
			</div>
		);
	}

	renderSearches() {
		const { appointment } = this.state;
		if (!appointment) return null;
		const { professional_searches } = appointment;
		if (!professional_searches) return null;
		return (
			<>
				<Title h3 text="Professional Searches" toggle />
				<div className="Card">
					<ProfessionalSearchesTables
						data={professional_searches}
						infinite={false}
						interactive
						filterable
						defaultLimit={3}
					/>
				</div>
			</>
		);
	}

	renderExtra() {
		const { appointment } = this.state;

		if (!appointment) return null;

		const { type, review_answers, review_comments } = appointment;

		const has_reviews = (
			review_answers.length
			|| review_comments.length
		);

		switch (type) {
			case 'HOME':
				const { accounts } = this.state;
				const voucher = appointment.voucher_item ? appointment.voucher_item.voucher : null;

				return (
					<div>
						<Title h3 text="Chat" />
						<div className="Card">
							<AppointmentChatTable
								data={appointment.chats}
								infinite={false}
								interactive={false}
								filterable
								defaultLimit={4}
							/>
						</div>

						<Title h3 text="Alternative times">
							<button onClick={() => this.showAlternativeTimeModal()}>
								New alternative time
							</button>
						</Title>

						{this.getAlternativeTimeEvents().length
							? (
								<div className="Card">
									<AlternativeTimesTable
										data={this.getAlternativeTimeEvents()}
										onDeleteAlternativeTime={this.onDeleteAlternativeTime}
									/>
								</div>
							)
							:						(
								<div className="Card">
									<i>No alternative times yet</i>
								</div>
							)
						}

						{
							!!has_reviews
							&& (
								<div className="split-view extra">
									{
										!!accounts
										&& (
											<div>
												<Title h3 text="Discounts" />
												<AppointmentDiscountsCard
													appointment={appointment}
													accounts={accounts}
													voucher={voucher}
												/>
											</div>
										)
									}
									{
										!!has_reviews
										&& (
											<div>
												<Title h3 text="Reviews" />
												<ReviewsCard
													reviews={review_answers}
													comments={review_comments}
													onReviewUpdate={this.onReviewUpdate}
												/>
											</div>
										)
									}
								</div>
							)
						}
					</div>
				);
			case 'CORPORATE':
				const { corporate_metadata } = this.state;
				const { id, comments, datetime } = appointment;

				return (
					<div>
						<Title h3 text="Corporate event" />

						{corporate_metadata && <AppointmentCorporateDurationCard date={datetime} duration={corporate_metadata.duration} />}

						<Title h3 text="Services" toggle />
						<AppointmentCorporateServicesCard appointment_id={id} />

						{
							has_reviews
						&& (
							<div>
								<Title h3 text="Reviews" />
								<ReviewsCard
									reviews={review_answers}
									comments={review_comments}
									onCustomerReviewUpdate={this.onCustomerReviewUpdate}
									onProfessionalReviewUpdate={this.onProfessionalReviewUpdate}
								/>
							</div>
						)
						}
					</div>
				);
			default: return null;
		}
	}

	render() {
		const {
			appointment,
			accounts,
			corporate_metadata,
			addable_services,
		} = this.state;

		if (!appointment) return null;

		// exclude details from edit appt as it is on the main appt page
		const excludeKeys = [
			'details',
		];

		return (
			<div className="Page AppointmentPage">
				<Title h1 text="Appointment Details">
					{
						this.canRefundBooking() && (
							<button className="button" onClick={this.showRefundModal}>
								Refunds
							</button>
						)
					}
					{appointment.status === 'PENDING' && (
						<button className="success" onClick={this.confirmAppointment}>
							Confirm Appointment
						</button>
					)}
					{appointment.type === 'CORPORATE' && (
						<button className="button" onClick={this.showRegularCorporateModal}>
							Make Regular
						</button>
					)
					}
					<UpdateResource
						title="Edit Appointment"
						resource="appointments"
						excludeKeys={excludeKeys}
						existingData={appointment}
						onNewData={this.onUpdate}
					/>
				</Title>

				{
					appointment.customer
					&& appointment.customer.banned_at
					&& <BannedRibbon user={appointment.customer} />
				}

				<div className="split-view bordered">
					<div flex={2}>
						<Title h3 text="Information" />
						{this.renderInformation()}
					</div>
					<div>
						<Title h3 text="Actions" />
						<AppointmentActionsCard
							unassignProfessional={() => this.unassignProfessional()}
							isDirectBook={this.isDirectBook()}
							hasBeenUnassigned={this.hasBeenUnassigned()}
							appointment={appointment}
							corporateMetadata={corporate_metadata}
							onCorporateMetadataUpdate={this.onCorporateUpdate}
						/>

						<Title h3 text="Salonettes with availability" />
						<RankedProfessionalsList
							appointmentId={appointment.id}
						/>

						<Title h3 text="Alternative therapists in area" toggle />
						{
							(appointment.location && appointment.location.postcode)
								? (
									<ProfessionalsInPostcodeCard
										postcode={appointment.location.postcode}
										appointmentId={appointment.id}
										serviceIds={appointment.services.map(
											s => s.service_id,
										)}
									/>
								)
								: <div className="Card"><i>N/A</i></div>
						}

						<Title h3 text="Internal notes (staff-only)" toggle />
						{
							appointment
							&& (
								<div className="Card">
									<AppointmentDescription
										appointment={appointment}
										onUpdate={this.onUpdate}
									/>
								</div>
							)
						}
					</div>
				</div>
				<Title h3 text={appointment.type === "CORPORATE" ? "Schedule" : "Treatments"} />
				<ServiceDetailsCard
					items={appointment.services || []}
					type={appointment.type}
					onRemoveItem={this.onRemoveService}
					onAddItem={this.onAddService}
					onRescheduleItem={this.onRescheduleService}
					addableServices={addable_services}
				/>

				<Title h3 text="Timeline" />
				{this.renderEvents()}

				{this.renderSearches()}

				<Title h3 text="Payments">
					{
						appointment.type === "HOME"
						&& (
							<button onClick={this.showChargeModal}>
								New charge
							</button>
						)
					}
				</Title>

				{
					appointment.payments
					&& appointment.payments.length > 0
					&& (
						<PaymentDetailsCard
							payments={appointment.payments}
						/>
					)
				}

				{this.renderExtra()}

				<Title h3 text="Accounting" />
				{this.renderAccounting()}

				<AppointmentChargeModal
					show={this.state.chargeModalOpen}
					onHide={this.hideChargeModal}
					onSubmit={this.onExtraCharge}
				/>

				{
					this.state.regularCorporateModalOpen
					&& (
						<RegularCorporateModal
							show={this.state.regularCorporateModalOpen}
							onHide={this.hideRegularCorporateModal}
							onSubmit={this.createRegularCorporate}
							appointment={appointment}
						/>
					)
				}
				{
					this.state.alternativeTimeModalOpen
					&& (
						<NewAlternativeTime
							customer_id={appointment.customer_id}
							events={appointment.events}
							show={this.state.alternativeTimeModalOpen}
							onHide={this.hideAlternativeTimeModal}
							onSubmit={this.onNewAlternativeTime}
						/>
					)
				}
				{
					this.state.refundModalOpen
					&& (
						<RefundModal
							show={this.state.refundModalOpen}
							onHide={this.hideRefundModal}
							payments={this.state.appointment.payments.filter(p => p.processed)} // only use processed payments for refunds.
							appointment={this.state.appointment}
							confirmRefund={this.confirmRefund}
						/>
					)
				}

				<RescheduleCorporateTreatment
					appointment_service_id={this.state.appointment_service_id_rescheduling}
					show={this.state.appointment_service_id_rescheduling}
					onHide={() => {
						this.setState({ appointment_service_id_rescheduling: null });

						// reload booking (lazy way of updating UI)
						this.getCoreData();
					}}
				/>
			</div>
		);
	}
}
