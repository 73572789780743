import React, { Fragment } from 'react';
import { browserHistory } from 'react-router'

import PageModel from './_PageModel';
import UserProfileCard from '../cards/UserProfileCard';
import CreditCardGraphic from '../misc/CreditCardGraphic';
import DeleteResource from '../buttons/DeleteResource';
import Title from '../misc/Title';

export default class CardPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;
	}

	getCoreData() {
		return this.api.request('get', '/cards/' + this.id).then(result => {
			if (result.success) {
				this.setState({
					card: result.data
				})

				return result.data;
			}

			throw new Error(result.error);

		})
	}

	getAdditionalData(card) {
		card = card || this.state.card;

		return Promise.all([
			this.api.request('get', '/users/' + card.user_id).then(result => {
				if (result.success) {
					return this.setState({
						user: result.data
					})
				}

				throw new Error(result.error);
			}),

			//load a list of payments with this credit card here...
		]);
	}

	componentDidMount() {
		this.getCoreData().then(appointment => {
			return this.getAdditionalData(appointment);

		}).catch(error => {
			console.error(error);
		})
	}

	render() {
		const card = this.state.card;
		const user = this.state.user;

		if (!card) return null;
		if (!user) return null;

		const { brand, last4, expiry_date } = card;
		const { first_name, last_name, stripe_customer_id } = user;

		return (
			<Fragment>
				<Title h1 text="Card Details">
					<DeleteResource
						title="Delete Card"
						resource="cards"
						existingData={card}
						onNewData={browserHistory.goBack}
					/>
				</Title>

				<div className="split-view bordered">
					<div>
						<Title h3 text="Information" />
						{
							card
							&& user
							&& <CreditCardGraphic
								brand={brand}
								last4={card.last4}
								name={first_name + " " + last_name}
								expiryDate={expiry_date}
							/>
						}
					</div>
					<div>
						<Title h3 text="User" />
						<UserProfileCard
							interactive={true}
							user={user}
						/>
					</div>
					<div>
						<Title h3 text="Actions" />
						<div className="Card">
							<a className="button" href={`https://dashboard.stripe.com/customers/${stripe_customer_id}`} target="_blank">
								View customer in Stripe
							</a>
							<p>
								In the user's Stripe profile, you can find this payment card and see if the CVC and postcode checks passed.
							</p>
							<p>
								Please note, some banks ignore postcodes and mark the check as a success so you can't rely on it for fraud detection.
							</p>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}