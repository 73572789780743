import React, { Component } from 'react';

export default class TableHead extends Component{
	render(){
		return (
			<div className={["head", this.props.className].join(" ")}>
				{this.props.children}
			</div>
		)
	}
}