import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeComponent from '../../_DataModel';

import './style.less';

export default class CreditCardGraphic extends LeComponent {
	render() {
		const { brand, last4, name, expiryDate } = this.props;

		let classes = [
			`jp-card`,
			`jp-card-identified`
		];
		switch (brand) {
			case 'american express':
				classes.push('jp-card-amex');
				break;
			default:
				classes.push(`jp-card-${brand.toLowerCase()}`)
				break;
		}

		const date = this.moment(expiryDate);

		const expiry_text = (
			date.isValid()
				? date.format('MM/YY')
				: `••/••`
		)

		return (
			<div className="CreditCardGraphic">
				<div className="jp-card-container">
					<div className={classes.join(" ")}>
						<div className="jp-card-front">
							<div className="jp-card-logo jp-card-elo">
								<div className="e">e</div>
								<div className="l">l</div>
								<div className="o">o</div>
							</div>
							<div className="jp-card-logo jp-card-visa">visa</div>
							<div className="jp-card-logo jp-card-mastercard">MasterCard</div>
							<div className="jp-card-logo jp-card-maestro">Maestro</div>
							<div className="jp-card-logo jp-card-amex"></div>
							<div className="jp-card-logo jp-card-discover">discover</div>
							<div className="jp-card-logo jp-card-dankort">
								<div className="dk">
									<div className="d"></div>
									<div className="k"></div>
								</div>
							</div>
							<div className="jp-card-lower">
								<div className="jp-card-shiny"></div>
								<div className="jp-card-cvc jp-card-display">•••</div>
								<div className="jp-card-number jp-card-display">•••• •••• •••• {last4}</div>
								<div className="jp-card-name jp-card-display">{name}</div>
								<div className="jp-card-expiry jp-card-display" data-before="month/year" data-after="valid thru">{expiry_text}</div>
							</div>
						</div>
						<div className="jp-card-back">
							<div className="jp-card-bar"></div>
							<div className="jp-card-cvc jp-card-display">•••</div>
							<div className="jp-card-shiny"></div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

CreditCardGraphic.propTypes = {
	brand: PropTypes.string.isRequired,
	last4: PropTypes.any.isRequired,
	name: PropTypes.string,
	expiryDate: PropTypes.any.isRequired
}