import React, { Component } from 'react';
import * as d3 from 'd3';
import Autocomplete from '../../forms/Autocomplete';
import {
	Table, THead, TBody, TRow, TCol,
} from '../../tables/Generic';
import Title from '../../misc/Title';
import PageModel from '../_PageModel';

class VoucherTree extends PageModel {
	constructor(props) {
		super(props);
		this.createVoucherTree = this.createVoucherTree.bind(this);

		this.width = 1000;
		this.radius = 500;

		this.state = {
			rotation: 0,
			scale: 70,
			parentMin: 1,
			data: {},
			posX: 0,
			posY: 0,
			dragging: false,
			startX: 0,
			startY: 0,
		};
	}

	fetchVoucherData = async () => {
		const { parentMin } = this.state;
		const sendParentMin = parentMin === 0 ? null : parentMin;

		const url = (
			`/vouchers/referral_map?parent_min=${sendParentMin}`
		);

		try {
			const result = await this.api.request('get', url, {});

			if (!result.success) throw new Error(result.error);

			this.setState({
				data: result.data,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	formatForD3 = (data) => {
		const hierarchy = d3.hierarchy(data).sort((a, b) => d3.ascending(a.data.name, b.data.name));

		return hierarchy;
	}

	async componentDidMount() {
		await this.fetchVoucherData();
		this.createVoucherTree();
	}

	async componentDidUpdate(prevProps, prevState) {
		const { parentMin } = this.state;

		if (parentMin !== prevState.parentMin) {
			await this.fetchVoucherData();
		}

		this.createVoucherTree();
	}

	getNodeColour(id) {
		const { data } = this.state;

		const { user_map } = data;

		// map undefined (center nodes) to 0
		const count = user_map[id] || 0;

		switch (count) {
			case 0:
				return 'grey';
			case 1:
				return 'green';
			case 2:
				return 'orange';
			case 3:
				return 'red';
			default:
				return 'red';
		}
	}

	createVoucherTree() {
		const {
			rotation, scale, posX, posY,
		} = this.state;
		let { data } = this.state;

		d3.select(this.refs.node).selectAll('g').remove();

		data = this.formatForD3(data);

		const tree = d3.tree(data)
			.size([2 * Math.PI, this.radius])
			.separation((a, b) => (a.parent == b.parent ? 1 : 2) / a.depth);

		const root = tree(data);

		const svgGroup = d3.select(this.refs.node)
			.attr('width', '100%')
			.attr('height', '100%');

		svgGroup.append("g")
			.attr("fill", "none")
			.attr("stroke", "#555")
			.attr("stroke-opacity", 0.4)
			.attr("stroke-width", 1.5)
			.selectAll("path")
			.data(root.links())
			.join("path")
			.attr("d", d3.linkRadial()
				.angle(d => d.x)
				.radius(d => d.y));

		svgGroup.append("g")
			.selectAll("circle")
			.data(root.descendants())
			.join("circle")
			.attr("transform", d => `
			rotate(${d.x * 180 / Math.PI - 90})
			translate(${d.y},0)
			`)
			.attr("fill", d => this.getNodeColour(d.data.name)) // determined by voucher use
			.attr("r", 2.5);

		svgGroup.append("g")
			.attr("font-family", "sans-serif")
			.attr("font-size", 10)
			.attr("stroke-linejoin", "round")
			.attr("stroke-width", 3)
			.selectAll("text")
			.data(root.descendants())
			.join("text")
			.attr("transform", d => `
			rotate(${d.x * 180 / Math.PI - 90}) 
			translate(${d.y},0) 
			rotate(${d.x >= Math.PI ? 180 : 0})
			`)
			.attr("dy", "0.31em")
			.attr("x", d => (d.x < Math.PI === !d.children ? 6 : -6))
			.attr("text-anchor", d => (d.x < Math.PI === !d.children ? "start" : "end"))
			.text(d => d.data.name)
			.clone(true).lower()
			.attr("stroke", "white");

		svgGroup.attr("viewBox", this.autoBox()).node();

		d3.select(this.refs.node)
			.selectAll('g')
			.attr("transform", `scale(${scale / 100}) translate(${posX} ${posY}) rotate(${rotation})`);
	}

	autoBox = () => {
		if (!this.refs.node) return [200, 0, 1000, 1000];

		const {
			x, y, width, height,
		} = this.refs.node.getBBox();

		return [x, y, height, width];
	}

	rotate = () => {
		const { rotation } = this.state;

		this.setState({
			rotation: rotation + 10,
		});
	}

	zoomIn = () => {
		const { scale } = this.state;

		this.setState({
			scale: scale + 20,
		});
	}

	zoomOut = () => {
		const { scale } = this.state;

		this.setState({
			scale: scale - 20,
		});
	}

	getOptions() {
		const MAX_HEIGHT = 3;
		const options = [];

		for (let i = 1; i <= MAX_HEIGHT; i++) {
			options.push({
				value: i,
				label: i,
			});
		}

		return options;
	}

	onChangeMap = async (newParentMin) => {
		const { parentMin } = this.state;

		if (newParentMin === parentMin) return;

		this.setState({
			parentMin: newParentMin,
		});
	}

	startDrag = (e) => {
		this.setState({
			dragging: true,
			startX: e.clientX,
			startY: e.clientY,
		});
	}

	dragStop = (e) => {
		const {
			clientX, clientY, posX, posY,
		} = this.state;
		this.setState({
			dragging: false,
			startX: posX,
			startY: posY,
		});
	}

	dragging = (e) => {
		const { dragging, startX, startY } = this.state;
		const { clientX, clientY } = e;

		if (!dragging) return;

		this.setState({
			posX: clientX - startX,
			posY: clientY - startY,
		});
	}

	render() {
		const { data, data: { enrolled } } = this.state;
		const { parentMin } = this.state;

		return (
			<div className="map-outer-container">
				<Table className="controlPanel">
					<THead>
						<TRow>
							<TCol>
								{data.title || 'Loading...'}
							</TCol>
							<TCol>
								{enrolled || 0} customers
							</TCol>
						</TRow>
						<TRow>
							<TCol>
								Min referrals for starting group
							</TCol>
							<TCol>
								<form>
									<Autocomplete
										value={parentMin}
										options={this.getOptions()}
										clearable={false}
										onChange={this.onChangeMap}
									/>
								</form>
							</TCol>
						</TRow>
					</THead>
				</Table>
				<svg style={{ display: 'block', margin: 'auto' }} ref="node" onMouseDown={this.startDrag} onMouseMove={this.dragging} onMouseUp={this.dragStop} />
				<div className="controlButtons">
					<button onClick={this.rotate}>
						Rotate
					</button>
					<button onClick={this.zoomIn}>
						Zoom in
					</button>
					<button onClick={this.zoomOut}>
						Zoom out
					</button>
				</div>
			</div>
		);
	}
}

export default VoucherTree;
