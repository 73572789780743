import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import PageModel from '../_PageModel';

import JSONPanel from '../../misc/JSONPanel';
import UpdateResource from '../../buttons/UpdateResource';
import DeleteResource from '../../buttons/DeleteResource';
import Title from '../../misc/Title';

import './style.less';

export default class LocationPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.onUpdate = this.onUpdate.bind(this)
	}

	getCoreData() {
		return this.api.request(
			'get',
			'/locations/'
			+ this.id
			+ "?load="
		).then(result => {
			if (result.success) {
				this.setState({
					location: result.data
				})

				return result.data;
			}

			throw new Error(result.error);
		})
	}

	onUpdate(data) {
		let { location } = this.state;

		location = Object.assign(location, data);

		this.setState({
			location,
		})
	}

	componentDidMount() {
		this.getCoreData()
	}

	render() {
		let { location } = this.state;

		if (!location) return null;

		return (
			<div className="Page AddressPage">
				<Title h1 text="Address Details">
					<UpdateResource
						title="Edit Address"
						resource="locations"
						existingData={location}
						onNewData={this.onUpdate}
					/>
					<DeleteResource
						title="Delete Address"
						resource="locations"
						existingData={location}
						onNewData={browserHistory.goBack}
					/>
				</Title>

				<div className="Card">
					<JSONPanel data={location} />
				</div>
			</div>
		);
	}
}