import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeView from './view';

import './style.less';

export default class ProfessionalPortfolioCard extends LeView {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		await this.loadPortfolioPics();
	}

	showModal() {
		this.setState({
			showModal: true,
		});
	}

	hideModal() {
		this.setState({
			showModal: false,
		});
	}

	toggleSelectItem(id) {
		const { selectedItemIds } = this.state;
		// if it's selected, de-select it
		if (selectedItemIds.includes(id)) {
			this.setState({
				selectedItemIds: selectedItemIds.filter(item => item !== id),
			});
			// if two are already selected, do nothing
		} else if (selectedItemIds.length === 2) {

			// otherwise, select the item
		} else {
			this.setState({
				selectedItemIds: [...selectedItemIds, id],
			});
		}
	}
}

ProfessionalPortfolioCard.propTypes = {
	professional_id: PropTypes.number.isRequired,
};
