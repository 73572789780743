import React, { Component } from 'react';

import LeView from './view';

import './style.less';

export default class SchedulePage extends LeView {
	constructor(props) {
		super(props);

		this.prevDay = this.prevDay.bind(this);
		this.nextDay = this.nextDay.bind(this);
		this.today = this.today.bind(this);
		this.toggleBookings = this.toggleBookings.bind(this);
	}

	async getCoreData() {
		try {
			await this.loadSalonettes();
		} catch (error) {
			this.notification.error(error.message)
		}

		try {
			await this.loadSchedules(this.state.selectedDate);
		} catch (error) {
			this.notification.error(error.message)
		}

		try {
			await this.loadBookings(this.state.selectedDate);
		} catch (error) {
			this.notification.error(error.message)
		}
	}

	componentDidMount() {
		this.getCoreData();
	}

	prevDay() {
		const { selectedDate } = this.state;

		this.onDateChange(
			this.moment(selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
		)
	}

	nextDay() {
		const { selectedDate } = this.state;

		this.onDateChange(
			this.moment(selectedDate).add(1, 'day').format('YYYY-MM-DD')
		)
	}

	today() {
		this.onDateChange(
			this.moment().format('YYYY-MM-DD')
		)
	}

	toggleBookings() {
		const { showBookingOverlay } = this.state;

		this.setState({
			showBookingOverlay: !showBookingOverlay
		});
	}
}