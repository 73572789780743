import React, { Component } from 'react';
import Shuffle from 'shuffle-array';
import RandomColor from 'randomcolor';

import PageModel from '../_PageModel';

import getAppointmentLength from '../../../helpers/getAppointmentLength';

export default class LeModel extends PageModel {
	constructor(props) {
		super(props);

		this.state = {
			professionals: [],
			availability: {},
			bookings: {},
			dates: [],
			selectedDate: this.moment().format('YYYY-MM-DD'),
			colors: null,
			showBookingOverlay: true,
		};

		// build dates...
		const start_date = this.moment().subtract(90, 'days');
		const end_date = this.moment().add(90, 'days');

		for (let date = start_date.clone(); date.isBefore(end_date); date.add(1, 'day')) {
			this.state.dates.push({
				label: date.format('DD/MM/YYYY'),
				value: date.format('YYYY-MM-DD'),
			});
		}

		this.onDateChange = this.onDateChange.bind(this);
	}

	SLOTS_START_HOUR = 6

	SLOTS_END_HOUR = 23

	SLOTS_MINUTES = ['00', '30']

	makeSlots() {
		const slots = [];

		for (let hour = this.SLOTS_START_HOUR; hour <= this.SLOTS_END_HOUR; hour++) {
			for (const minute of this.SLOTS_MINUTES) {
				const slot = hour.toString().padStart(2, '0') + ':' + minute;

				slots.push(slot);
			}
		}

		return slots;
	}

	async loadSalonettes() {
		const result = await this.api.request("get", '/users?where={"type":"PROFESSIONAL"}');

		if (!result.success) throw new Error(result.error);

		this.setState({
			professionals: result.data.filter(user => !user.is_test),
		});
	}

	async loadSchedules(date) {
		date = this.moment(date);

		if (!date.isValid()) throw new Error('Invalid date supplied.');

		date = date.format('YYYY-MM-DD');

		const where = `{"date":"${date}"}`;

		const url = `/user_availabilities?where=${where}`;

		const result = await this.api.request('get', url);

		if (!result.success) throw new Error(result.error);

		const { availability } = this.state;

		availability[date] = result.data.filter(entry =>
			// only allow slots which have at least 1 activated time for the day
			 Object.values(entry.slots).indexOf(true) > -1);

		this.setState({
			availability,
		});

		// generate colors for any users in this list
		this.generateColors(result.data.map(entry => entry.user_id));
	}

	async loadBookings(date) {
		date = this.moment(date);

		if (!date.isValid()) throw new Error('Invalid date supplied.');

		date = date.format('YYYY-MM-DD');

		const url = `/appointments/date/${date}`;

		const result = await this.api.request('get', url);

		if (!result.success) throw new Error(result.error);

		const { bookings } = this.state;

		bookings[date] = result.data;

		this.setState({
			bookings,
		});
	}

	hasBooking(user_id, date, slot) {
		date = this.moment(date);

		// set the slot hour and minute on this date.
		date.hour(slot.split(':')[0]);
		date.minute(slot.split(':')[1]);

		const bookings = this.state.bookings[date.format('YYYY-MM-DD')];

		if (!bookings) return {
			has_booking: false,
		};

		for (const booking of bookings) {
			const { datetime, professional_id, status } = booking;

			// skip bookings for other pros.
			if (user_id !== professional_id) continue;

			// skip unconfirmed bookings
			switch (status) {
				case 'UNPAID': case 'PENDING': case 'CANCELLED': case 'REFUNDED':
					continue;
			}

			const duration = getAppointmentLength(booking);

			const is_platform = ['PLATFORM', 'STOREFRONT'].includes(booking.type);

			const start_time = this.moment(datetime);
			const end_time = start_time.clone().add(duration, 'minutes');

			// if slot within this booking return true.
			if (
				date.diff(start_time) >= 0
				&& end_time.diff(date) >= 0
			) {
				return {
					has_booking: true,
					is_platform,
				};
			}
		}

		return false;
	}

	onDateChange(selectedDate) {
		// update state
		this.setState({
			selectedDate,
		});

		// load availabilities for this day.
		this.loadSchedules(selectedDate).catch(
			error => this.notification.error(error.message),
		);

		// load bookings for this day.
		this.loadBookings(selectedDate).catch(
			error => this.notification.error(error.message),
		);
	}

	defaultColors = [
		'#AD725C',
		'#D26A62',
		'#FB380E',
		'#FD5633',
		'#FF752A',
		'#FFAE38',
		'#38D790',
		'#00A863',
		'#78D33D',
		'#B2DE65',
		'#FBD25B',
		'#8FE2BF',
		'#9EC5C9',
		'#4583EA',
		'#9999FF',
		'#B997FF',
		'#CABDBF',
		'#CDA6AC',
		'#F890B2',
		'#CE70E9',
		'#A577E5',
	]

	generateColors(ids) {
		if (!ids) return;

		// load previously saved colors from local storage. Initialize if first time.
		const colors = this.state.colors || JSON.parse(window.localStorage.getItem('schedule_colors')) || {};

		ids.forEach(id => {
			// ignore blank ids
			if (!id) return;

			if (!colors[id]) {
				// this user needs to get a color assigned to them.
				let selected_color = null;

				const default_colors = Shuffle([...this.defaultColors]);

				// try and assign one of the default colors to this professional (no duplicates allowed).
				default_colors.some(color => {
					const existing_results = Object.keys(colors).filter(id => colors[id] === color);

					if (existing_results.length === 0) selected_color = color;

					return selected_color;
				});

				// fall back to a random color if all other options are exhausted.
				selected_color = selected_color || RandomColor({
					luminosity: 'bright',
				});

				colors[id] = selected_color;
			}
		});

		// set colors into local storage for next time.
		window.localStorage.setItem('schedule_colors', JSON.stringify(colors));

		// load the colors into state so the component can use it.
		this.setState({
			colors,
		});
	}
}
