import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';

import Tree from 'react-d3-tree';
import UserRecurrenceRulesCard from '../../cards/UserRecurrenceRulesCard';
import UserProfileCard from '../../cards/UserProfileCard';
import UserMetricsCard from '../../cards/UserMetricsCard';
import UserMetadataCard from '../../cards/UserMetadataCard';
import UserPreferencesCard from '../../cards/UserPreferencesCard';
import UserActionsCard from '../../cards/UserActionsCard';
import UpdateImageCard from '../../cards/UpdateImageCard';
import VoucherInfoCard from '../../cards/VoucherInfoCard';
import UpdateResource from '../../buttons/UpdateResource';
import CreateResource from '../../buttons/CreateResource';
import AppointmentsTable from '../../tables/Appointments';
import UserCommsTable from '../../tables/UserComms';
import LocationsTable from '../../tables/Locations';
import ProfessionalSearchesTables from '../../tables/Searches';
import CardsTable from '../../tables/Cards';
import UserDescription from '../../forms/UserDescription';
import UserNotification from '../../forms/UserNotification';
import ProfessionalServicesCard from '../../cards/ProfessionalServicesCard';
import ProfessionalZonesCard from '../../cards/ProfessionalZonesCard';
import ProfessionalBadgesCard from '../../cards/ProfessionalBadgesCard';
import SalonetteScheduleCard from '../../cards/SalonetteScheduleCard';
import ProfessionalPortfolioCard from '../../cards/ProfessionalPortfolioCard';
import Title from '../../misc/Title';
import BannedRibbon from '../../misc/BannedRibbon';
import BulkScheduleModal from '../../modals/BulkSchedule';
import {
	Table, THead, TBody, TRow, TCol,
} from '../../tables/Generic';

import LeModel from './model';

export default class LeView extends LeModel {
	constructor() {
		super();
	}

	openBulkSchedule = () => this.setState({
		bulkSheduleModalVisible: true,
	})

	hideBulkSchedule = () => this.setState({
		bulkSheduleModalVisible: false,
	})

	renderMetadata() {
		const { metadata_keys, metadata_values } = this.state;

		if (!metadata_values.length) return null;

		return (
			<Fragment>
				<Title h3 text="Metadata" toggle />
				<UserMetadataCard keys={metadata_keys} values={metadata_values} />
			</Fragment>
		);
	}

	renderMessages() {
		const messages = [];

		const user = this.state.user;

		if (!user.phone_number) {
			messages.push(
				<Alert key="missing_phone" bsStyle="danger">
					⚠️ {user.first_name} is missing a <strong>phone number</strong>. Fix this as soon as possible!
				</Alert>,

			);
		}

		if (this.isProfessional() && !user.description) {
			messages.push(
				<Alert key="missing_description" bsStyle="warning">
					⚠️ {user.first_name}'s <strong>description</strong> is blank. Please write a couple of sentences describing what this professional is known for.
				</Alert>,
			);
		}

		if (!messages.length) return null;

		return (
			<Fragment>
				<Title h3 text="Messages" />
				{messages}
			</Fragment>
		);
	}

	renderSearches() {
		const { user } = this.state;
		if (!user) return null;
		const { professional_searches } = user;
		if (!professional_searches) return null;
		return (
			<>
				<Title h3 text="Professional Searches" toggle />
				<div className="Card">
					<ProfessionalSearchesTables
						data={professional_searches}
						infinite={false}
						interactive
						filterable
						defaultLimit={3}
					/>
				</div>
			</>
		);
	}

	render() {
		const {
			user, credit_balance, personal_voucher, metrics, recurrence_rules,
		} = this.state;

		if (!user) return null;

		const classes = ["Page", "UserPage"];

		if (user.type) {
			classes.push(user.type.toLowerCase());
		}

		const excludeKeys = [
			'credit_balance',
			'description',
		];

		if (user.type === "CORPORATE") {
			excludeKeys.push('password');
			excludeKeys.push('admin');
		}

		return (
			<div className={classes.join(" ")}>
				<Title h1 text="User Details">
					{
						this.isProfessional() && (
							<a href={`/users/${user.id}/website`}>
								<button className="button">Manage Website</button>
							</a>
						)
					}
					<UpdateResource
						title="Edit User"
						resource="users"
						excludeKeys={excludeKeys}
						existingData={user}
						onNewData={this.onUserUpdate}
					/>
				</Title>

				{user.banned_at && <BannedRibbon user={user} />}

				<div className="split-view bordered">
					<div flex={user.type === 'CORPORATE' ? 2 : 1}>
						<Title h3 text="Profile" />
						<UserProfileCard user={user} />
						{personal_voucher && <VoucherInfoCard voucher={personal_voucher} />}
						<Title h3 text="Preferences" />
						<UserPreferencesCard instant_book_enabled={user.instant_book_enabled} marketing_allowed={user.marketing_allowed} push_allowed={user.push_allowed} />
					</div>
					{user.type !== 'CORPORATE' && (
						<div>
							<Title h3 text="Metrics" />
							{metrics && <UserMetricsCard metrics={metrics} />}
						</div>
					)}
					<div>
						<Title h3 text="Actions" />
						<UserActionsCard user={user} creditBalance={credit_balance} onCreditUpdate={this.onCreditUpdate} />

						<Title h3 text="Private notes" />
						<div className="Card description_box">
							<UserDescription user={user} onUpdate={this.onUserUpdate} columnToEdit="notes" />
						</div>

						{this.isProfessional()
							&& (
								<div>
									<Title h3 text="Public description" />
									<div className="description_box">
										<UserDescription user={user} onUpdate={this.onUserUpdate} columnToEdit="description" />
									</div>
								</div>
							)
						}

					</div>
				</div>

				<div className="split-view bordered">
					<div flex={user.type === 'CORPORATE' ? 2 : 1}>
						{this.renderMetadata()}
					</div>
					{user.type !== 'CORPORATE' && (
						<div>
							<Title h3 text="Active experiments" toggle />
							{
								user.experiments.length > 0
									? (
										<div className="Card">
											<Table>
												<THead className="titles">
													<TRow>
														<TCol>Name</TCol>
														<TCol>Time enrolled</TCol>
													</TRow>
												</THead>
												<TBody>
													{user.experiments.map(experiment => (
														<TRow key={experiment.id}>
															<TCol>{experiment.name}</TCol>
															<TCol>{this.moment(experiment.created_at).fromNow()}</TCol>
														</TRow>
													))}
												</TBody>
											</Table>
										</div>
									)
									: <i>None</i>
							}
						</div>
					)}
					<div>
						<Title h3 text="Profile picture" toggle />
						<UpdateImageCard
							width={800}
							height={800}
							onImageUpdate={this.onAvatarUpdate}
						/>

						<Title h3 text="Send notification" toggle />
						<div className="Card description_box">
							<UserNotification user={user} />
						</div>
					</div>
				</div>

				{this.renderMessages()}

				<div className="split-view bordered">
					<div>
						<Title h3 text="Locations">
							<CreateResource
								title="New Location"
								resource="locations"
								onNewData={this.onLocationUpdate}
								existingData={{
									user_id: user.id,
								}}
							/>
						</Title>
						<div className="Card">
							<LocationsTable
								data={user.locations.filter(address => !address.deleted_at)}
								infinite={false}
								interactive
								defaultLimit={4}
							/>
							{
								// If they've deleted an address...
								user.locations.filter(address => address.deleted_at).length > 0
									? (
										<p>
											{user.first_name} also has {user.locations.filter(address => address.deleted_at).length} deleted addresses.
										</p>
									)
									: null
							}
						</div>
					</div>
					{this.isCustomer() && (
						<div>
							<Title h3 text="Cards" />
							<div className="Card">
								<CardsTable
									data={user.cards.filter(card => {
									// only show cards which haven't been deleted.
										if (card.deleted_at) return false;
										// only show manually added cards.
										if (card.tokenization_method !== 'MANUAL_ENTRY') return false;

										return true;
									})}
									infinite={false}
									interactive
									defaultLimit={4}
								/>
								{
								// If they've deleted a card...
									user.cards.filter(card => card.deleted_at).length > 0
									// Or they've used an Apple/Google Pay card...
									|| user.cards.filter(card => card.tokenization_method !== 'MANUAL_ENTRY').length > 0
									// Then show the additional information.
										? (
											<ul>
												<li>
													{user.first_name} also has {user.cards.filter(card => card.deleted_at).length} deleted cards.
												</li>
												<li>
													They've used Apple Pay {user.cards.filter(card => card.tokenization_method === 'APPLE_PAY').length} times.
												</li>
												<li>
													And {user.cards.filter(card => card.tokenization_method === 'PAYPAL').length} uses of PayPal.
												</li>
											</ul>
										)
										: null
								}
							</div>
						</div>
					)}
				</div>

				{
					user.type === 'CORPORATE'
					&& (
						<Fragment>
							<Title h3 toggle text="Repeat bookings" />
							<UserRecurrenceRulesCard recurrence_rules={recurrence_rules} deleteRule={this.deleteRule} />
						</Fragment>
					)
				}

				<Title h3 text={"Appointments (as " + user.type.toLowerCase() + ")"} />
				<div className="Card">
					<AppointmentsTable
						data={this.isProfessional() ? this.state.appointments_as_professional : this.state.appointments_as_customer}
						infinite={false}
						interactive
						filterable
						defaultLimit={4}
					/>
				</div>

				{user.type === 'CUSTOMER'
				&& (
					<>
						<Title h3 toggle text="Referral tree" />
						<div className="treeMapContainer">
							{this.state.vouchersData && <Tree zoom={0.4} translate={{ x: 0, y: 600 }} data={this.state.vouchersData} />}
						</div>
					</>
				)
				}

				<Title h3 text="Comms" />
				<div className="Card">
					<UserCommsTable
						data={this.commsData()}
						infinite={false}
						interactive={false}
						filterable
						defaultLimit={5}
					/>
				</div>

				{this.renderSearches()}

				{
					this.isProfessional() && (
						<Fragment>
							<Title h3 text="Schedule">
								<a onClick={this.openBulkSchedule}>
									<button className="button">Bulk Schedule</button>
								</a>
								<BulkScheduleModal
									user_id={this.id}
									show={this.state.bulkSheduleModalVisible}
									onHide={this.hideBulkSchedule}
								/>
							</Title>
							<SalonetteScheduleCard user={user} editable />

							<Title h3 text="Storefront services" toggle />
							<ProfessionalServicesCard type="STOREFRONT" professional_id={user.id} />

							{
								user.type === 'PROFESSIONAL'
								&& (
									<Fragment>
										<Title h3 text="Home services" toggle />
										<ProfessionalServicesCard type="HOME" professional_id={user.id} />

										<Title h3 text="Corporate services" toggle />
										<ProfessionalServicesCard type="CORPORATE" professional_id={user.id} />

										<Title h3 text="Areas" toggle />
										<ProfessionalZonesCard professional_id={user.id} />

										<Title h3 text="Badges" toggle />
										<ProfessionalBadgesCard user_id={user.id} />
									</Fragment>
								)
							}

							<Title h3 text="Portfolio" />
							<ProfessionalPortfolioCard professional_id={user.id} />
						</Fragment>
					)
				}
			</div>
		);
	}
}
