import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, THead, TBody, TRow, TCol } from '../../tables/Generic';

import './style.less';

export default class AppointmentAccountingDetailsCard extends Component {
	render() {
		const appointment = this.props.appointment;
		const accounting = this.props.accounts;

		if (!accounting) return null;

		return (
			<div className="Card AppointmentAccountingDetailsCard">
				<Table>
					<TBody>
						<TRow>
							<TCol>
								Revenue
							</TCol>
							<TCol>
								£{(accounting.revenue / 100).toFixed(2)}
							</TCol>
						</TRow>
						<TRow>
							<TCol>
								<ul>
									<li>
										List Price: £{(accounting.rrp / 100).toFixed(2)}
									</li>
									<li>
										Discount: -£{(accounting.discount / 100).toFixed(2)}
									</li>
								</ul>
							</TCol>
						</TRow>
						<TRow>
							<TCol>
								Professional Fee
							</TCol>
							<TCol>
								-£{(accounting.costs.contractor / 100).toFixed(2)}
							</TCol>
						</TRow>
						<TRow>
							<TCol>
								Net Commission
							</TCol>
							<TCol>
								£{((accounting.profit) / 100).toFixed(2)}
							</TCol>
						</TRow>
						<TRow>
							<TCol>
								<ul>
									<li>
										Gross Commission: £{(accounting.commission / 100).toFixed(2)}
									</li>
									<li>
										VAT: -£{(accounting.tax / 100).toFixed(2)}
									</li>
								</ul>
							</TCol>
						</TRow>
						<TRow className={accounting.profit >= 0 ? "black" : "red"}>
							<TCol>
								Profit
							</TCol>
							<TCol>
								£{(accounting.profit / 100).toFixed(2)}
							</TCol>
						</TRow>
					</TBody>
				</Table>
			</div>
		);
	}
}

AppointmentAccountingDetailsCard.propTypes = {
	appointment: PropTypes.object.isRequired,
	accounts: PropTypes.object.isRequired
}