import React from 'react';
import { browserHistory } from 'react-router'
import Moment from 'moment';

import PageModel from './_PageModel';
import { Table, TBody, TRow, TCol } from '../tables/Generic';
import AppointmentsTable from '../tables/Appointments'
import UpdateResource from '../buttons/UpdateResource';
import DeleteResource from '../buttons/DeleteResource';
import Title from '../misc/Title';

export default class VoucherPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.onVoucherUpdate = this.onVoucherUpdate.bind(this);
	}

	componentDidMount() {
		let relations = [
			'voucher_items.appointment.customer',
			'voucher_items.appointment.professional',
			'voucher_items.appointment.services.service',
			'voucher_items.appointment.location',
		];

		return this.api.request(
			'get',
			'/vouchers/'
			+ this.id
			+ "?load="
			+ relations.join(',')
		).then(result => {
			if (result.success) {
				this.setState({
					voucher: result.data
				})
			}

		}).catch(error => {
			console.error(error);
		})
	}

	onVoucherUpdate(data) {
		let voucher = this.state.voucher;

		voucher = Object.assign(voucher, data);

		if (voucher.channel) voucher.channel = voucher.channel.toUpperCase().trim()

		this.setState({
			voucher
		});
	}

	render() {
		let voucher = this.state.voucher;

		if (!this.state.voucher) return null;

		let classes = ["Page", "UserPage"];

		return (
			<div className={classes.join(" ")}>
				<Title h1 text="Voucher Details">
					<UpdateResource
						title={"Edit Voucher"}
						resource={"vouchers"}
						className={"Voucher"}
						existingData={voucher}
						onNewData={this.onVoucherUpdate} />
					<DeleteResource
						title="Delete Voucher"
						resource="vouchers"
						existingData={voucher}
						onNewData={browserHistory.goBack} />
				</Title>

				<div className="split-view">
					<div>
						<Title h3 text='Information' />
						<div className="Card">
							<Table>
								<TBody>
									<TRow>
										<TCol>Code</TCol>
										<TCol>{voucher.code}</TCol>
									</TRow>
									<TRow>
										<TCol>Amount</TCol>
										<TCol>
											{voucher.percentage ? voucher.percentage + '%' : '£' + (voucher.amount / 100).toFixed(2)}
										</TCol>
									</TRow>
									<TRow>
										<TCol>Customer Limit</TCol>
										<TCol>
											{voucher.customer_limit}
										</TCol>
									</TRow>
									<TRow>
										<TCol>Global Limit</TCol>
										<TCol>
											{voucher.global_limit}
										</TCol>
									</TRow>
									<TRow>
										<TCol>Number of Uses</TCol>
										<TCol>
											{voucher.voucher_items.length}
										</TCol>
									</TRow>
									<TRow>
										<TCol>Expiry Date</TCol>
										<TCol>
											{voucher.expiry_date ? Moment(voucher.expiry_date).format('DD/MM/YYYY hh:mm') : 'Not set'}
										</TCol>
									</TRow>
									<TRow>
										<TCol>Channel</TCol>
										<TCol>
											{voucher.channel ? voucher.channel : 'Not set'}
										</TCol>
									</TRow>
								</TBody>
							</Table>
						</div>
					</div>
				</div>
				<Title h3 text='Bookings' />
				<div className="Card">
					<AppointmentsTable
						data={voucher.voucher_items.filter(voucher_item => voucher_item.appointment).map(voucher => voucher.appointment)}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</div>
		);
	}
}
