import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import { Table, TBody, TRow, TCol, THead } from '../../tables/Generic';
import GenericModal from '../Generic';
import UserLabel from '../../labels/UserLabel'
import Title from '../../misc/Title';

import './style.less';

export default class IssuePayoutModal extends DataModel {
	constructor() {
		super();

		this.state = {
			inputDescription: null,
			inputAmount: null,
			inputAmountRaw: null,
			inputBookingId: null
		}

		this.inputDescriptionChange = this.inputDescriptionChange.bind(this);
		this.inputAmountChange = this.inputAmountChange.bind(this);
		this.inputBookingIdChange = this.inputBookingIdChange.bind(this);

		this.onAddAdjustment = this.onAddAdjustment.bind(this);
		this.onDeleteAdjustment = this.onDeleteAdjustment.bind(this);
		this.onIssuePayout = this.onIssuePayout.bind(this);
	}

	resetForm() {
		this.setState({
			inputDescription: null,
			inputAmount: null,
			inputAmountRaw: null,
			inputBookingId: null
		})
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			//reset the view.
			this.resetForm()
		}
	}

	inputDescriptionChange(e) {
		let inputDescription = null;

		if (e.target.value && e.target.value.length) {
			inputDescription = e.target.value;
		}

		this.setState({
			inputDescription
		});
	}

	inputAmountChange(e) {
		let inputAmount = null;
		let inputAmountRaw = null;

		try {
			if (e.target.value) {
				inputAmountRaw = e.target.value;
				inputAmount = Math.floor(parseFloat(
					inputAmountRaw.replace(/£/g, '')
				).toFixed(2) * 100); //convert to pennies
			}
		} catch (e) { }

		this.setState({
			inputAmount,
			inputAmountRaw
		});
	}

	inputBookingIdChange(e) {
		let inputBookingId = null;

		if (e.target.value && e.target.value.length) {
			inputBookingId = parseInt(e.target.value);

			//ensure it's a number
			if (isNaN(inputBookingId)) inputBookingId = null;
		}

		this.setState({
			inputBookingId
		});
	}

	onAddAdjustment(e) {
		e.preventDefault();

		const { user, startDate, onAddAdjustment } = this.props;
		const { inputDescription, inputAmount, inputBookingId } = this.state;

		let payload = {
			user_id: user.id,
			datetime: this.moment(startDate).format(),
			description: inputDescription,
			amount: inputAmount
		}

		//optional appointment_id
		if (inputBookingId) payload.appointment_id = inputBookingId

		onAddAdjustment && onAddAdjustment(payload)

		this.resetForm();
	}

	onDeleteAdjustment(id) {
		const { onDeleteAdjustment } = this.props;

		onDeleteAdjustment && onDeleteAdjustment(id);
	}

	onIssuePayout() {
		const { onIssuePayout } = this.props;

		if (!window.confirm(`Are you sure you want to issue this payout?\n\n✍️ Payouts are final and can't be modified.\n\nPress OK to send.`)) return;

		onIssuePayout && onIssuePayout();
	}

	formatPrice(amount) {
		return `${amount < 0 ? '-' : ''}£${(Math.abs(amount) / 100).toFixed(2)}`;
	}

	render() {
		let { startDate, endDate, onHide } = this.props;
		const { user, bookings, adjustments, canIssuePayout } = this.props;

		const { inputDescription, inputAmount, inputAmountRaw, inputBookingId } = this.state;

		if (!user) return null;
		if (!startDate || !endDate) return null;

		startDate = this.moment(startDate);
		if (!startDate.isValid()) throw new Error('You need to supply a valid `startDate`.')

		endDate = this.moment(endDate);
		if (!endDate.isValid()) throw new Error('You need to supply a valid `endDate`.')

		let bookings_earnings = 0;

		for (const booking of bookings) {
			const { accounts } = booking;

			if (!accounts) continue;

			bookings_earnings += accounts.earnings;
		}

		let adjustments_earnings = 0;

		for (const adjustment of adjustments) {
			const { amount } = adjustment;

			adjustments_earnings += amount;
		}

		return (
			<GenericModal className="IssuePayout" show={this.props.show} onHide={this.props.onHide}>
				<Title h1 text="Issue a Payout" />
				<p>
					<UserLabel user={user} />
				</p>
				<p>
					A payout will be issued for all activity between {startDate.format('DD/MM/YYYY')} to {endDate.format('DD/MM/YYYY')}.
				</p>
				<Title h3 text="Add adjustment" />
				<p>
					These adjustments are added or subtracted from the final payout amount. Put bonuses, penalties and other things here.
				</p>
				<form className="add-adjustment" onSubmit={this.onAddAdjustment}>
					<input
						value={inputDescription || ''}
						onChange={this.inputDescriptionChange}
						placeholder="Description"
						required />
					<input
						value={inputAmountRaw}
						onChange={this.inputAmountChange}
						placeholder="0.00"
						required />
					<input
						value={inputBookingId}
						onChange={this.inputBookingIdChange}
						placeholder="Booking ID (optional)"
					/>
					{
						inputDescription && inputAmount
							? <button>
								Save {this.formatPrice(inputAmount || 0)}
							</button>
							: <button disabled>
								Save {this.formatPrice(inputAmount || 0)}
							</button>
					}
				</form>
				<Title h3 text="Breakdown" />
				<Table>
					<THead className="titles">
						<TRow>
							<TCol>Description</TCol>
							<TCol>Amount</TCol>
							<TCol></TCol>
						</TRow>
					</THead>
					<TBody>
						<TRow>
							<TCol>{bookings.length} bookings</TCol>
							<TCol>{this.formatPrice(bookings_earnings)}</TCol>
							<TCol></TCol>
						</TRow>
						{adjustments.map(adjustment => {
							const { id, description, appointment_id } = adjustment;

							let text = description;

							if (appointment_id) text += ` (Booking ${appointment_id})`;

							return <TRow key={id}>
								<TCol>{text}</TCol>
								<TCol>{this.formatPrice(adjustment.amount)}</TCol>
								<TCol><a onClick={() => this.onDeleteAdjustment(id)}>Remove</a></TCol>
							</TRow>
						})}
						<TRow>
							<TCol>TOTAL</TCol>
							<TCol>{this.formatPrice(bookings_earnings + adjustments_earnings)}</TCol>
							<TCol></TCol>
						</TRow>
					</TBody>
				</Table>
				{
					canIssuePayout
						? <button onClick={this.onIssuePayout}>
							Issue Payout for {this.formatPrice(bookings_earnings + adjustments_earnings)}
						</button>
						: <button disabled>
							Issue Payout for {this.formatPrice(bookings_earnings + adjustments_earnings)}
						</button>
				}
				<p>
					<a onClick={onHide}>Send payout later</a>
				</p>
			</GenericModal>
		)
	}
}

IssuePayoutModal.propTypes = {
	show: PropTypes.bool.isRequired,

	//config
	startDate: PropTypes.any.isRequired,
	endDate: PropTypes.any.isRequired,
	canIssuePayout: PropTypes.bool,

	//deps
	user: PropTypes.any.isRequired,
	bookings: PropTypes.array.isRequired,
	adjustments: PropTypes.array.isRequired,

	onHide: PropTypes.func.isRequired,
	onAddAdjustment: PropTypes.func.isRequired,
	onDeleteAdjustment: PropTypes.func.isRequired,
	onIssuePayout: PropTypes.func.isRequired,
}