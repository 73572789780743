import React, { Component } from 'react';

import PageModel from './_PageModel';

import Table from '../tables/Gifts';
import CreateResource from '../buttons/CreateResource';
import Title from '../misc/Title';

export default class GiftsPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);
	}

	state = {
		gifts: []
	}

	async getCoreData() {
		let relations = [
			'payments',
		]

		try {
			const gifts = await this.api.request(
				"get",
				`/gifts?load=${relations.join(',')}`
			).then(result => {
				if (!result.success) throw new Error(result.error);

				return result.data;
			})

			this.setState({
				gifts
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	componentDidMount() {
		return this.getCoreData();
	}

	onUpdate(data) {
		return this.getCoreData();
	}

	render() {
		const { gifts } = this.state;

		return (
			<div className="Page GiftsPage">
				<Title h1 text="Gifts">
					<CreateResource
						title={"New Gift"}
						resource={"gifts"}
						onNewData={this.onUpdate} />
				</Title>
				<div className="Card">
					{gifts.length && <Table
						data={gifts}
						infinite={true}
						interactive={true}
						filterable={true}
					/>}
				</div>
			</div>
		);
	}
}