import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import GenericModal from '../Generic';
import Autocomplete from '../../forms/Autocomplete';
import Title from '../../misc/Title';

import './style.less';

export default class RescheduleCorporateTreatment extends DataModel {
	constructor() {
		super();

		this.state = {
			loading: false,
			slots: [],
			selectedSlot: null,
		}

		this.onSave = this.onSave.bind(this);
	}

	componentDidMount() {
		this.loadSlots(this.props.appointment_service_id);
	}

	componentWillReceiveProps(next) {
		const prev = this.props;

		if (next.appointment_service_id !== prev.appointment_service_id) {
			this.loadSlots(next.appointment_service_id);
		}
	}

	async loadSlots(appointment_service_id) {
		if (!appointment_service_id) return;

		this.setState({
			loading: true
		})

		try {
			const appointment_service_result = await this.api.request(
				'GET',
				`/appointment_services/${appointment_service_id}`
			)

			if (!appointment_service_result.success) throw new Error(appointment_service_result.error);

			// extract relations.
			const { appointment_id, service_id } = appointment_service_result.data;

			// now get slots...
			const slots_result = await this.api.request(
				'GET',
				`/appointments/${appointment_id}/corporate_timeslots?service_id=${service_id}`,
			);

			if (!slots_result.success) throw new Error(slots_result.error);

			const slots = slots_result.data;

			this.setState({
				slots
			});

		} catch (error) {
			this.notification.error(error.message);
		}

		this.setState({
			loading: false
		})
	}

	async onSave() {
		const { appointment_service_id, onHide } = this.props;
		const { selectedSlot } = this.state;

		if (!selectedSlot) return this.notification.info('You need to choose a slot first.');

		try {
			const result = await this.api.request(
				'PUT',
				`/appointment_services/${appointment_service_id}`,
				{ time: selectedSlot }
			);

			if (!result.success) throw new Error(result.error);

			this.notification.success(`Done! This treatment has been rescheduled to ${selectedSlot}.`);

			onHide && onHide();

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	renderForm() {
		const { loading, slots, selectedSlot } = this.state;

		return <div className="reschedule">
			<p>
				Choose the time you'd like to reschedule to.
			</p>
			{
				loading
					? <p>
						<i>
							Loading...
						</i>
					</p>
					: (
						slots.length > 0
							? (
								<div className="slots">
									{slots.map(
										slot => <div
											className={[
												'slot',
												(
													selectedSlot === slot
														? 'selected'
														: ''
												)
											].join(' ')}
											key={slot}
											onClick={() => this.setState({
												selectedSlot: slot
											})}
										>
											{slot}
										</div>
									)}
								</div>
							)
							: <p>
								<i>
									No other slots left.
								</i>
							</p>
					)
			}
			<button disabled={!selectedSlot} onClick={this.onSave}>
				Save
			</button>
		</div>
	}

	render() {
		return (
			<GenericModal className="RescheduleCorporateTreatment" show={this.props.show} onHide={this.props.onHide}>
				<Title h1 text="Reschedule Corporate Treatment" />
				{this.renderForm()}
			</GenericModal>
		)
	}
}

RescheduleCorporateTreatment.propTypes = {
	appointment_service_id: PropTypes.number.isRequired,
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
}