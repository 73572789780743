import React, { Component } from 'react';
import PropTypes from 'prop-types';

import lodash from 'lodash';

import getAppointmentLength from '../../../helpers/getAppointmentLength';

import DataModel from '../../_DataModel';

import './style.less';

export default class SalonetteScheduleCard extends DataModel {
	constructor() {
		super();

		this.state = {
			availability: {},
			bookings: {},
			mouseDown: false,
			lastEdited: null,
			raw_availability_data: null,
			slots_start_hour: null,
			slots_end_hour: null,
			slots_minutes: [],
		};
	}

	formatAvailabilityMap() {
		let { availability } = this.state;

		for (
			let date_iterator = this.moment().startOf('day');
			date_iterator.isBefore(this.moment().add(13, 'days'));
			date_iterator.add(1, 'day')
		) {
			const day = this.findAvailabiltyRow(date_iterator);

			if (day) {
				availability = {
					...availability,
					[this.moment(date_iterator).startOf('day').format()]: { ...day.slots }, // VERY important to make a copy here. The raw_availability data will be dynamically modified if we don't, because JS objects are crazy. AF.
				};
			} else {
				availability = {
					...availability,
					[this.moment(date_iterator).startOf('day').format()]: {}, // an empty object will initialise the db with a fully falsified availabilites object
				};
			}
		}

		return availability;
	}

	async loadSchedule(user_id) {
		if (!user_id) throw new Error('No user ID supplied.');

		const where = `{"user_id":"${user_id}"}`;

		const url = `/user_availabilities?where=${where}`;

		const result = await this.api.request('get', url);

		if (!result.success) throw new Error(result.error);

		this.setState({
			raw_availability_data: result.data,
		});

		const availability = this.formatAvailabilityMap();

		if (!this.state.slots_start_hour) {
			this.getStartAndEndHours();
		}

		this.setState({
			availability,
		});
	}

	getStartAndEndHours() {
		const { raw_availability_data } = this.state;
		const day = raw_availability_data[0];

		if (!day) return;

		const slots = Object.keys(day.slots);

		slots.sort();

		const slots_start_hour = this.moment(slots[0], 'hh:mm').format('H');
		const slots_end_hour = this.moment(slots[slots.length - 1], 'hh:mm').format('H');

		const all_slots_minutes = slots.map(slot => this.moment(slot, 'hh:mm').format('mm'));

		const slots_minutes = [...new Set(all_slots_minutes)];

		this.setState({
			slots_start_hour: parseInt(slots_start_hour, 10),
			slots_end_hour: parseInt(slots_end_hour, 10),
			slots_minutes,
		});
	}

	async loadBookings(user_id) {
		const where = `{"professional_id":"${user_id}"}`;

		const url = `/appointments?where=${where}&load=services,services.service,corporate_metadata,platform_metadata`;

		const result = await this.api.request('get', url);

		if (!result.success) throw new Error(result.error);

		const { bookings } = this.state;

		for (const booking of result.data) {
			const date = this.moment(booking.datetime).startOf('day');
			const date_iso = date.format();

			bookings[date_iso] = bookings[date_iso] || [];

			bookings[date_iso].push(booking);
		}

		this.setState({
			bookings,
		});
	}

	hasBooking(date, slot) {
		date = this.moment(date);

		// set the slot hour and minute on this date.
		date.hour(slot.split(':')[0]);
		date.minute(slot.split(':')[1]);

		const bookings = this.state.bookings[date.clone().startOf('day').format()];

		if (!bookings) return false;

		for (const booking of bookings) {
			const { datetime, status } = booking;

			// skip unconfirmed bookings
			switch (status) {
				case 'UNPAID': case 'PENDING': case 'CANCELLED': case 'REFUNDED':
					continue;
			}

			const duration = getAppointmentLength(booking);

			const start_time = this.moment(datetime);
			const end_time = start_time.clone().add(duration, 'minutes');

			const is_platform = ['PLATFORM', 'STOREFRONT'].includes(booking.type);

			// if slot within this booking return true.
			if (
				date.diff(start_time) >= 0
				&& end_time.diff(date) >= 0
			) {
				return {
					has_booking: true,
					is_platform,
				};
			}
		}

		return false;
	}

	async componentDidMount() {
		document.addEventListener('mousedown', this.handleMouseDown);
		document.addEventListener('mouseup', this.handleMouseUp);

		const { user } = this.props;

		if (!user) return;

		await this.loadSchedule(user.id).catch(console.error);
		this.loadBookings(user.id).catch(console.error);
		await this.uploadSchedule().catch(console.error);
		await this.loadSchedule(user.id).catch(console.error);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleMouseDown);
		document.removeEventListener('mouseup', this.handleMouseUp);
	}

	uploadSchedule = async () => {
		const { availability } = this.state;
		const { user } = this.props;

		for (const key in availability) {
			if (availability.hasOwnProperty(key)) {
				const date = this.moment(key).format('YYYY-MM-D');
				const row = this.findAvailabiltyRow(key);
				let req = 'post';
				let url = '/user_availabilities';

				if (row) {
					if (lodash.isEqual(row.slots, availability[key])) {
						continue;
					}
					req = 'put';
					url = `/user_availabilities/${row.id}`;
				}

				const result = await this.api.request(req, url, {
					user_id: user.id,
					slots: availability[key],
					date: this.moment(date).format('YYYY-MM-D'),
				});

				if (!result.success) {
					throw new Error(result.error.message);
				}
			}
		}
	}

	updateAvailability = async () => {
		const { user } = this.props;

		try {
			await this.uploadSchedule();
		} catch (error) {
			this.notification.error('Could not update availability', error.message);
		}

		this.setState({
			lastEdited: null,
		});

		this.loadSchedule(user.id);

		this.notification.success(`Successfully updated ${user.first_name}'s schedule!`);
	}

	handleMouseUp = async () => {
		const { lastEdited } = this.state;

		this.setState({
			mouseDown: false,
		});

		if (!lastEdited) return;

		this.updateAvailability();
	}

	handleMouseDown = () => {
		this.setState({
			mouseDown: true,
		});
	}

	componentWillReceiveProps(next) {
		const previous = this.props;

		if (previous.user && next.user) {
			if (previous.user.id !== next.user.id) {
				this.loadSchedule(next.user.id).catch(console.error);
				this.loadBookings(next.user_id).catch(console.error);
			}
		}
	}

	makeSlots() {
		const { slots_start_hour, slots_end_hour, slots_minutes } = this.state;
		const slots = [];

		for (let hour = slots_start_hour; hour <= slots_end_hour; hour++) {
			for (const minute of slots_minutes) {
				const slot = hour.toString().padStart(2, '0') + ':' + minute;

				slots.push(slot);
			}
		}

		return slots;
	}

	slotsHeader() {
		const slots = this.makeSlots();

		return (
			<div className="slots header">
				{slots.map(slot => {
					let should_show_text = false;

					// if it's on the hour, let's show the time.
					if (slot.indexOf(':00') > -1) {
						should_show_text = true;
					}

					return (
						<div className="slot" key={slot}>
							{should_show_text && (
								<div className="slot-text">
									{slot}
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	}

	findAvailabiltyRow = (date) => {
		const { raw_availability_data } = this.state;

		return raw_availability_data.find(day => this.moment(day.date).isSame(this.moment(date), 'day'));
	}

	editSlotClick = (e) => {
		const { availability } = this.state;

		if (!e.target) return;
		if (!e.target.dataset) return;

		const date = e.target.dataset.date;
		const time = e.target.dataset.slot;

		if (!availability[date]) throw new Error('Availability not initialised yet');

		this.editAvailabilityDay(date, time);

		this.updateAvailability();
	}

	editSlotsDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { availability } = this.state;
		const { mouseDown, lastEdited } = this.state;
		const { editable } = this.props;

		const date = e.target.dataset.date;
		const time = e.target.dataset.slot;

		// should be editable, mousedown and dont trigger the last one edited.
		if (!editable || !mouseDown || (`${date} ${time}` === lastEdited)) return;

		// this will edit the initial slot we drag over for a nice smooth experience.
		if (!lastEdited) {
			const previousDate = e.relatedTarget.dataset.date;
			const previousTime = e.relatedTarget.dataset.slot;

			if (!availability[previousDate]) throw new Error('Availability not initialised yet');

			this.editAvailabilityDay(previousDate, previousTime);
		}

		// set the last edited
		this.setState({
			lastEdited: `${date} ${time}`,
		});

		if (!availability[date]) throw new Error('Availability not initialised yet');

		this.editAvailabilityDay(date, time);
	}

	editAvailabilityDay = (date, time) => {
		const { availability } = this.state;

		availability[date][time] = !availability[date][time];

		this.setState({
			availability,
		});
	}

	slotsFromSchedule(schedule, date) {
		// We create an array with all timeslots
		const keys = this.makeSlots();

		return (
			<div className="slots" key={`slots_${date}`}>
				{keys.map(slot => {
					const classNames = ['slot'];

					const style = {};

					// if we have a schedule and this schedule is activated, highlight the cell.
					if (schedule && schedule[slot] === true) {
						classNames.push('highlighted');
					}

					const { has_booking, is_platform } = this.hasBooking(date, slot);

					if (has_booking) {
						if (is_platform) {
							classNames.push('has-platform-booking');
						} else {
							classNames.push('has-booking');
						}
					}

					return (
						<div className={classNames.join(' ')} key={slot} style={style} data-date={date} data-slot={slot} onClick={this.editSlotClick} onMouseOver={this.editSlotsDrag}>
							<div className="slot-text hide-by-default" data-date={date} data-slot={slot}>
								{slot}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	renderDateColumn(date) {
		const today = this.moment();
		const tomorrow = this.moment().add(1, 'day');

		const date_moment = this.moment(date);

		let formatted = date_moment.format('dddd DD/MM');

		if (date_moment.isSame(today, 'd')) {
			formatted = 'Today';
		} else if (date_moment.isSame(tomorrow, 'd')) {
			formatted = 'Tomorrow';
		}

		return (
			<div className="date" key={`date_${date}`}>
				{formatted}
			</div>
		);
	}

	render() {
		if (!this.props.user) return <i>N/A</i>;
		if (!this.state.slots_start_hour) return <i>Loading!!</i>;

		const classes = ["Card", "SalonetteScheduleCard"];

		const { availability } = this.state;

		const schedule_days = [];

		for (
			let date_iterator = this.moment().startOf('day');
			date_iterator.isBefore(this.moment().add(13, 'days'));
			date_iterator.add(1, 'day')
		) {
			// if there's availablility set, add it to the iterator - otherwise null (so the array is the correct length)
			schedule_days.push({
				date: date_iterator.format(),
				schedule: availability[date_iterator.format()] || null,
			});
		}

		return (
			<div className={classes.join(" ")}>
				<div className="split-view">
					<div className="date-list">
						{schedule_days.map(
							entry => this.renderDateColumn(entry.date),
						)}
					</div>
					<div className="availability-table">
						{this.slotsHeader()}
						{schedule_days.map(
							entry => this.slotsFromSchedule(entry.schedule, entry.date),
						)}
					</div>
				</div>
			</div>
		);
	}
}

SalonetteScheduleCard.propTypes = {
	user: PropTypes.object.isRequired,
	editable: PropTypes.bool,
};
