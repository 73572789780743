import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor() {
		super();

		this.state = {
			service_id: null,
			quantity: 1,
		};

		this.onServiceChange = this.onServiceChange.bind(this);
		this.addToBasket = this.addToBasket.bind(this);
	}

	onServiceChange(service_id) {
		if (this.state.service_id === service_id) return;

		this.setState({
			service_id,
		});
	}

	addToBasket(e) {
		e.preventDefault();

		const { basket, onBasketChange } = this.props;
		const { service_id, quantity } = this.state;

		if (!onBasketChange) return;

		// update basket
		onBasketChange([
			...basket,
			{
				service_id,
				quantity,
			},
		]);

		// reset state.
		this.setState({
			service_id: null,
			quantity: 1,
		});
	}

	basketPrice() {
		return 0;
	}
}
