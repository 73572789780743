import React, { Component } from 'react';
import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor() {
		super();

		this.state = {
			professionals: [],
			rankedProfessionals: [],
		};
	}

	getZone() {
		const { postcode } = this.props;

		// wrap in a try in case of invalid formatting.
		try {
			// remove last 3 chars from string.
			const zone = postcode.slice(0, -3).toUpperCase();

			return zone;
		} catch (error) {
			console.warn(error);
			return null;
		}
	}

	filterAndSortPros(professionals) {
		const { rankedProfessionals } = this.state;
		const { serviceIds } = this.props;
		const zone = this.getZone();

		const rankedProIDS = rankedProfessionals.map(pro => pro.id);

		return professionals

			.filter(professional => !rankedProIDS.includes(professional.id))

			.filter(user => {
				for (const region of user.regions) {
					if (region.zone.post_code === zone) {
						return true;
					}
				}

				return false;
			}).filter(user => {
				// skip this filtering if no service IDs were supplied.
				if (!serviceIds || !serviceIds.length) return true;

				// we're going to iterate through the therapist's offered treatments and count the number of matches against the basket from props.
				// we need a perfect match in order to show this Salonette.
				let matching_services = 0;

				for (const service of user.professional_services) {
					if (serviceIds.indexOf(service.service_id) > -1) {
						matching_services += 1;
					}
				}

				// if we've matched every desired serviceId, this therapist offers the full basket and we can show them.
				if (matching_services >= serviceIds.length) {
					return true;
				}

				return false;
			}).sort((a, b) => {
				const a_name = (`${a.first_name} ${a.last_name}`).toUpperCase();
				const b_name = (`${b.first_name} ${b.last_name}`).toUpperCase();

				// alphabetical asc
				if (a_name < b_name) return -1;
				if (a_name > b_name) return 1;
				return 0;
			});
	}

	async loadRankedPros() {
		const { appointmentId } = this.props;

		try {
			const result = await this.api.request('get', `/appointments/${appointmentId}/ranked_professionals`);

			if (!result.success) throw new Error(result.error);

			const professionals = result.data;

			this.setState({
				rankedProfessionals: professionals,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async loadProfessionals() {
		const relations = [
			'regions.zone',
			'professional_services',
			'offer_preferences',
		];

		const result = await this.api.request('get', `/users?where={"type":"PROFESSIONAL"}&load=${relations.join(',')}`);
		try {
			if (!result.success) throw new Error(result.error);

			const professionals = result.data;

			const alternativeProfessionalsInArea = this.filterAndSortPros(professionals);

			this.setState({
				professionals: alternativeProfessionalsInArea,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}
}
