import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';

import Table from '../../tables/Appointments';
import Autocomplete from '../../forms/Autocomplete';
import Title from '../../misc/Title';

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	state = {
		...this.state,
	}

	render() {
		const {
			appointments: unformatted_appointments,

			users,
			services,
			locations,

			statusFilter,
			statuses
		} = this.state;

		//build appointments with user data included for the table.
		let appointments = unformatted_appointments.map(booking => {
			booking.customer = users[booking.customer_id];
			booking.professional = users[booking.professional_id];
			booking.location = locations[booking.location_id];

			booking.services = booking.services.map(s => {
				s.service = services[s.service_id];

				return s;
			})

			return booking;
		});

		//optional filtering by status.
		if (statusFilter) {
			appointments = appointments.filter(
				booking => booking.status === statusFilter
			);
		}

		return (
			<Fragment>
				<Title h1 text="Appointments">
					<form className="status-filter">
						<Autocomplete
							value={statusFilter}
							options={statuses}
							onChange={this.onStatusFilterChange}
							clearable={true}
							multiple={false}
							placeholder={"Any status"}
						/>
					</form>

					<Link to="/appointments/new">
						<button>
							New Booking
						</button>
					</Link>
				</Title>
				<div className="Card">
					<Table
						data={appointments}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</Fragment>
		);
	}
}