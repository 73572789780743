import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageUploadField } from 'react-image-file';
import ReactCrop from 'react-image-crop';
import ResizeImage from 'resize-image';

import LeComponent from '../../_DataModel';

import './style.less';

export default class UpdateImageCard extends LeComponent {
	init(props) {
		props = props || this.props;

		const width = props.width || this.DEFAULT_SIZE;
		const height = props.height || this.DEFAULT_SIZE;

		this.setState({
			crop: {
				aspect: width / height,
			},
			rawImage: null,
			base64Image: null,
			finalBase64Image: null,
		});
	}

	constructor() {
		super();

		this.DEFAULT_SIZE = 10;

		this.onCropChange = this.onCropChange.bind(this);
		this.onCropComplete = this.onCropComplete.bind(this);
		this.onImageChange = this.onImageChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitNoCrop = this.onSubmitNoCrop.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentWillReceiveProps(props) {
		this.init(props);
	}

	async onImageChange(rawImage) {
		const base64Image = await this.convertFileToBase64(rawImage);

		this.setState({
			rawImage,
			base64Image,
		});
	}

	onCropChange(crop) {
		this.setState({
			crop,
		});
	}

	async onCropComplete(crop, pixelCrop) {
		const { base64Image } = this.state;
		const { width, height } = this.props;

		const croppedBase64Image = await this.cropImage(base64Image, pixelCrop);
		const finalBase64Image = await this.resizeImage(croppedBase64Image, width, height);

		this.setState({
			finalBase64Image,
		});
	}

	onSubmitNoCrop() {
		// cropped base64 image that we are to upload...
		const { base64Image } = this.state;

		const { onImageUpdate } = this.props;

		// pass image to embedding component.
		onImageUpdate && onImageUpdate(base64Image);

		// reset state.
		this.init();
	}

	onSubmit() {
		// cropped base64 image that we are to upload...
		const { finalBase64Image } = this.state;

		const { onImageUpdate } = this.props;

		// pass image to embedding component.
		onImageUpdate && onImageUpdate(finalBase64Image);

		// reset state.
		this.init();
	}

	render() {
		const {
			crop, rawImage, base64Image, finalBase64Image,
		} = this.state;

		return (
			<div className="Card UpdateImageCard">
				<div className="selector">
					<ImageUploadField
						label="Choose new photo..."
						imageWidth={50}
						imageHeight={50}
						onChange={this.onImageChange}
						files={rawImage}
					/>
				</div>
				{base64Image && (
					<div className="cropper">
						<p>
							Draw a box below to crop this image.
						</p>
						<ReactCrop
							src={base64Image}
							crop={crop}
							onChange={this.onCropChange}
							onComplete={this.onCropComplete}
						/>
					</div>
				)}
				{base64Image && (
					<p>
						{
							finalBase64Image
								? (
									<button onClick={this.onSubmit}>
										Upload image
									</button>
								)
								: (
									<button onClick={this.onSubmitNoCrop}>
										Upload image without cropping
									</button>
								)
						}
					</p>
				)}
			</div>
		);
	}

	async convertFileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	async resizeImage(base64Image, width, height) {
		return new Promise(resolve => {
			// we need to load our original image into a placeholder html image.
			const image = document.createElement('img');
			image.onload = () => {
				// resize the image using a 3rd party module.
				const resizedBase64Image = ResizeImage.resize(
					image,
					width || this.DEFAULT_SIZE,
					height || this.DEFAULT_SIZE,
					ResizeImage.JPEG,
				);

				// finally, return the cropped image.
				resolve(resizedBase64Image);
			};
			// setting the src of the image will trigger the above code.
			image.src = base64Image;
		});
	}

	async cropImage(base64Image, pixelCrop) {
		return new Promise(resolve => {
			// crop is specified in percentage terms whereas pixelCrop is converted to real pixels.
			// we need to use the latter to process the crop through canvas.
			const {
				x, y, width, height,
			} = pixelCrop;

			// create the canvas and context we'll use to crop the image.
			const canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;
			const ctx = canvas.getContext('2d');

			// we need to load our original image into a placeholder html image.
			const image = document.createElement('img');
			// we can't render the canvas until the image has finished loading.
			image.onload = () => {
				// draw the image with the offsets
				ctx.drawImage(
					image,
					x,
					y,
					width,
					height,
					0,
					0,
					width,
					height,
				);

				// convert the canvas to a base 64 string.
				const croppedBase64Image = canvas.toDataURL('image/jpeg');

				// finally, return the cropped image.
				resolve(croppedBase64Image);
			};
			// setting the src of the image will trigger the above code.
			image.src = base64Image;
		});
	}
}

UpdateImageCard.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	onImageUpdate: PropTypes.func.isRequired,
};
