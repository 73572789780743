import React, { Component } from 'react';
import {
	Router, Route, Link, browserHistory,
} from 'react-router';

import PageModel from '../_PageModel';

export default class LeModel extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);
	}

	state = {
		professionals: [],
		filtered_professionals: [],
		metrics: {},
		services: [],
		categories: [],
		professional_services: new Map(),
		showFilterModal: false,
		selectedServices: [],
		selectedCategories: [],
		showDeactivated: false,
	}

	async getCoreData() {
		try {
			const relations = [
				'regions.zone',
				'professional_services.service.category',
			];

			const professionals_result = await this.api.request("get", `/users?where={"type":"PROFESSIONAL"}&load=${relations.join(',')}`);

			if (!professionals_result.success) throw new Error(professionals_result.error);

			const professionals = professionals_result.data;

			this.setState({
				professionals,
				filtered_professionals: professionals,
			});

			const metrics = {};

			const metrics_result = await Promise.all(professionals.map(async professional => {
				const { id } = professional;

				const result = await this.api.request('get', `/users/${id}/metrics`);

				metrics[id] = result.data;
			}));

			this.setState({
				metrics,
			});

			const services_result = await this.api.request("get", '/services');

			if (!services_result.success) throw new Error(services_result.error);

			const services = services_result.data;

			this.setState({
				services,
			});

			const categories_result = await this.api.request("get", '/service_categories');

			if (!categories_result.success) throw new Error(categories_result.error);

			const categories = categories_result.data;

			this.setState({
				categories,
			});

			const professional_services_result = await this.api.request("get", '/professional_services');

			if (!professional_services_result.success) throw new Error(professional_services_result.error);

			const professional_services = professional_services_result.data;

			const professional_services_map = new Map();
			professional_services.map(pro_serv => {
				if (professional_services_map.has(pro_serv.user_id)) {
					professional_services_map.get(pro_serv.user_id).push(pro_serv.service_id);
				} else {
					professional_services_map.set(pro_serv.user_id, [pro_serv.service_id]);
				}
			});

			this.setState({
				professional_services: professional_services_map,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	showFilterModal = () => {
		this.setState({
			showFilterModal: true,
		});
	}

	hideFilterModal = () => {
		this.setState({
			showFilterModal: false,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedServices: nextServices } = this.state;
		const { selectedServices: prevServices } = prevState;

		// do nada when the filters are empty
		if (prevServices.length < 1 && nextServices.length === prevServices.length) return;

		// if there are none in nextState, reset filters,
		// to prevent loop, also ensure it has actually changed.
		if (nextServices.length < 1 && (nextServices !== prevServices)) return this.resetFilters(false);

		// if we deselect one, filter results
		// if we click another one, filter results
		if (prevServices !== nextServices) {
			this.filterResults(nextServices);
		}
	}

	filterResults = () => {
		const { professionals, professional_services, selectedServices } = this.state;

		if (!selectedServices || !selectedServices.length) {
			this.resetFilters();
			this.hideFilterModal();
			return;
		}

		const filteredProfessionals = professionals.filter(professional => {
			// professional must cover all treatments
			const proServices = professional_services.get(professional.id);
			if (!proServices) return false;
			for (const serviceId of selectedServices) {
				if (!proServices.includes(serviceId)) return false;
			}

			return true;
		});

		this.setState({
			filtered_professionals: filteredProfessionals,
		});
	}

	resetFilters = (hideModal = true) => {
		const { professionals } = this.state;
		this.setState({
			selectedCategories: [],
			selectedServices: [],
			filtered_professionals: professionals,
		});

		if (hideModal) this.hideFilterModal();
	}

	showResetButton = () => {
		const { selectedServices } = this.state;
		return (selectedServices.length > 0);
	}

	removeFromSelected = ({ categoryId, serviceId }) => {
		const { services, selectedCategories, selectedServices } = this.state;
		const categoryServices = services.filter(service => service.category_id === categoryId);
		const categoryServicesIds = categoryServices.map(service => service.id);

		// either serviceId or categoryId is sent in, never both.
		if (serviceId) {
			this.setState({
				selectedServices: selectedServices.filter(selectedServiceId => selectedServiceId !== serviceId),
			});
			return;
		}

		if (categoryId) {
			this.setState({
				selectedServices: selectedServices.filter(serviceId => !categoryServicesIds.includes(serviceId)),
				selectedCategories: selectedCategories.filter(selectedCategoryId => selectedCategoryId !== categoryId),
			});
		}
	}

	addToSelected = ({ serviceIds, categoryId }) => {
		this.setState(prevState => ({
			selectedServices: prevState.selectedServices.concat(serviceIds),
		}));

		if (categoryId) {
			this.setState(prevState => ({
				selectedCategories: [...prevState.selectedCategories, categoryId],
			}));
		}
	}

	onUpdate(data) {
		const professionals = this.state.professionals;

		// add newly created appointment to appointments list.
		professionals.push(data);

		this.setState({
			professionals,
		});
	}

	toggleDeactivated = () => {
		this.setState({
			showDeactivated: !this.state.showDeactivated,
		});
	}
}
