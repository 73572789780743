import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';

import { Table, THead, TBody, TRow, TCol } from '../../tables/Generic';

import UserLabel from '../../labels/UserLabel'
import Basket from '../../forms/Basket';

import './style.less';

export default class ServiceDetailsCard extends DataModel {
	constructor() {
		super();

		this.onBasketChange = this.onBasketChange.bind(this);
		this.onDeleteService = this.onDeleteService.bind(this);
	}

	state = {

	}

	onBasketChange(basket) {
		//don't do anything if the basket is empty.
		if (!basket || !basket.length) return;

		const new_service = basket[0];
		const { service_id } = new_service;

		const { onAddItem } = this.props;

		//pass basket contents to parent component.
		onAddItem && onAddItem(service_id);
	}

	onDeleteService(appointment_service_id) {
		const { onRemoveItem } = this.props;

		onRemoveItem && onRemoveItem(appointment_service_id);
	}

	onRescheduleService(appointment_service_id) {
		const { onRescheduleItem } = this.props;

		onRescheduleItem && onRescheduleItem(appointment_service_id);
	}

	render() {
		const { items, addableServices, type } = this.props;

		type === 'CORPORATE' &&
			items.sort((a, b) => {
				if (a.time < b.time) return -1;
				if (a.time > b.time) return 1;
				return 0;
			});

		return (
			<div className="Card ServiceDetailsCard">
				<Table>
					<THead className="titles">
						<TRow>
							<TCol>
								Service ID
							</TCol>
							<TCol>
								Treatment
							</TCol>
							{type === 'CORPORATE' &&
								<TCol>
									Customer
								</TCol>
							}
							<TCol>
								Price
							</TCol>
							{type === 'CORPORATE' &&
								<Fragment>
									<TCol>
										Time
									</TCol>
									<TCol /> {/* for Reschedule button */}
								</Fragment>
							}
							<TCol /> {/* intentionally left blank for delete button */}
						</TRow>
					</THead>

					<TBody>
						{items.map(item => item.service && <TRow key={item.id}>
							<TCol>
								{item.service_id}
							</TCol>
							<TCol>
								{item.service.option}
							</TCol>
							{type === 'CORPORATE' &&
								<TCol>
									<UserLabel id={item.user_id} fetch />
								</TCol>
							}
							<TCol>
								£{(item.service.price / 100).toFixed(2)}
							</TCol>
							{type === 'CORPORATE' &&
								<Fragment>
									<TCol>
										{item.time ? item.time.slice(0, 5) : <i>Not set</i>}
									</TCol>
									<TCol>
										<a className="remove-link" onClick={() => this.onRescheduleService(item.id)}>
											Reschedule
										</a>
									</TCol>
								</Fragment>
							}
							<TCol>
								<a className="remove-link" onClick={() => this.onDeleteService(item.id)}>
									{
										type === 'CORPORATE'
											? 'Cancel'
											: 'Remove'
									}
								</a>
							</TCol>
						</TRow>)}
						{/* no addable services for corporates */}
						{type === 'HOME' &&
							<TRow>
								<TCol className="add">
									<div>
										<Basket
											basket={[]}
											onBasketChange={this.onBasketChange}
											services={addableServices}
										/>
									</div>
								</TCol>
							</TRow>
						}
					</TBody>
				</Table>
			</div>
		)
	}
}

ServiceDetailsCard.propTypes = {
	items: PropTypes.array.isRequired,
	onRemoveItem: PropTypes.func.isRequired,
	onAddItem: PropTypes.func.isRequired,
	onRescheduleItem: PropTypes.func,

	//key value list of treatments for basket
	addableServices: PropTypes.object.isRequired
}