import React, { Component } from 'react';

import DataModel from '../../_DataModel';

export default class LeModel extends DataModel {
	constructor(){
		super();

		this.state = {
			full_list: [],
			selected_list: []
		}
	}

	async loadZones(){
		try{
			const result = await this.api.request('get', '/zones');

			if(!result.success) throw new Error(result.error);

			this.setState({
				full_list: result.data.sort((a, b) => {
					//a-z
					if(a.post_code < b.post_code) return -1;
					if(a.post_code > b.post_code) return 1;
					return 0;
				})
			});

		} catch(error) {
			this.notification.error(error.message);
		}
	}

	async loadActivatedZones(props){
		props = props || this.props;

		const { professional_id } = props;

		if(!professional_id) return;

		try{
			const result = await this.api.request('get', `/users/${professional_id}?load=regions`);

			if(!result.success) throw new Error(result.error);

			let selected = result.data.regions;
			let formatted = {};

			selected.forEach(item => {
				formatted[item.zone_id] = item;
			})

			this.setState({
				selected_list: formatted
			});

		} catch(error) {
			this.notification.error(error.message);
		}
	}
}