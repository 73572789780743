import React, { Component } from 'react';

import Moment from 'moment';

import TableModel from '../_TableModel';

import './style.less';

export default class CommissionTable extends TableModel {
	constructor() {
		super();

		this.className = "CommissionTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true
		}, {
			title: 'Date',
			type: Date,
			sortable: true,
			searchable: true
		}, {
			title: 'Customer',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Description',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Price (£)',
			type: Number,
			sortable: true,
			searchable: true,
		}, {
			title: 'Discount (£)',
			type: Number,
			sortable: true,
			searchable: true,
		}, {
			title: 'Commission (£)',
			type: Number,
			sortable: true,
			searchable: true,
		}, {
			title: 'VAT (£)',
			type: Number,
			sortable: true,
			searchable: true,
		}, {
			title: 'Earnings (£)',
			type: Number,
			sortable: true,
			searchable: true,
		}]

		this.PDF_METEOR_METHOD = 'tableCommissionPDF';
	}

	dataForRows(data) {
		if (!data) return;

		const rows = data.map(accounts => {
			return [
				accounts.appointment_id,
				Moment(accounts.datetime).unix(),
				accounts.customer_name,
				accounts.description,
				(accounts.price / 100).toFixed(2),
				(accounts.discount / 100).toFixed(2),
				(accounts.commission / 100).toFixed(2),
				(accounts.tax / 100).toFixed(2),
				(accounts.earnings / 100).toFixed(2),
			];
		})

		return rows;
	}

	dataForExport(data) {
		if (!data) return;

		//container for total earnings information.
		let total_earnings = 0;

		const rows = data.map(accounts => {
			//increment total earnings as we map through the bookings.
			total_earnings += accounts.earnings;

			return [
				accounts.appointment_id,
				Moment(accounts.datetime).format('DD/MM/YYYY HH:mm'),
				accounts.customer_name,
				accounts.description,
				(accounts.price / 100).toFixed(2),
				(accounts.discount / 100).toFixed(2),
				(accounts.commission / 100).toFixed(2),
				(accounts.tax / 100).toFixed(2),
				(accounts.earnings / 100).toFixed(2),
			];
		})

		return [
			...rows,
			//blank row for total.
			[],
			//display total earnings under everything else
			['TOTAL', null, null, null, null, null, null, null, (total_earnings / 100).toFixed(2)]
		];
	}

	hyperlink(data) {
		return '/appointments/' + data[0];
	}
}