import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserCreditModal from '../../modals/UserCredit';

import './style.less';

export default class UserActionsCard extends Component {
	constructor() {
		super();

		this.state = {
			modalOpen: false
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	render() {
		let user = this.props.user;

		if (!user) return null;

		return (
			<div className="Card UserActionsCard ActionsCard">
				<div className="actions">
					{user.email && <div className="action">
						<a href={"mailto:" + user.email}>
							📩 Email {user.first_name} ({user.email})
						</a>
					</div>}
					{user.phone_number && <div className="action">
						<a href={"tel:" + user.phone_number}>
							📞 Call {user.first_name} ({user.phone_number})
						</a>
					</div>}
					{!isNaN(this.props.creditBalance) && <div className="action">
						<a onClick={this.openModal}>
							💸 Manage Credit (currently £{(this.props.creditBalance / 100).toFixed(2)})
						</a>
					</div>}
					{user.stripe_customer_id && <div className="action">
						<a href={`https://dashboard.stripe.com/customers/${user.stripe_customer_id}`} target="_blank">
							🏦 View in Stripe
						</a>
					</div>}
					{user && user.braintree_customer_id ? <div className="action">
						<a href={`https://www.braintreegateway.com/merchants/wdd3bbqr3bnmcrxb/customers/${user.braintree_customer_id}`} target="_blank">
							🌳 View in Braintree
						</a>
					</div> : null}
				</div>
				<UserCreditModal user={user} creditBalance={this.props.creditBalance} show={this.state.modalOpen} onHide={this.closeModal} onUpdate={this.props.onCreditUpdate} />
			</div>
		)
	}
}

UserActionsCard.propTypes = {
	user: PropTypes.object.isRequired,
	creditBalance: PropTypes.number,
	onCreditUpdate: PropTypes.func.isRequired
}