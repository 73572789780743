import React, { Fragment } from 'react';

import CustomersTable from '../tables/Customers';
import CreateResource from '../buttons/CreateResource';
import Title from '../misc/Title';

import PageModel from './_PageModel';

export default class CustomersPage extends PageModel {
	constructor() {
		super();

		this.state = {
			screen: 'CUSTOMER',
			customers: [],
			corporates: [],
			showDeactivated: false
		}

		this.onUpdate = this.onUpdate.bind(this);
		this.toggleScreen = this.toggleScreen.bind(this);
	}

	componentDidMount() {
		Promise.all([
			this.api.request("get", '/users?where={"type":"CUSTOMER"}', null, data => {
				this.setState({
					customers: [
						...this.state.customers,
						...data
					]
				})
			}),

			this.api.request("get", '/users?where={"type":"CORPORATE"}', null, data => {
				this.setState({
					corporates: [
						...this.state.corporates,
						...data
					]
				})
			})

		]);
	}

	onUpdate(data) {
		switch (data.type) {
			case "CUSTOMER":
				let customers = this.state.customers;

				customers.push(data);

				this.setState({
					customers
				})
				break;

			case "CORPORATE":
				let corporates = this.state.corporates;

				corporates.push(data);

				this.setState({
					corporates
				})
				break;
		}
	}

	toggleScreen() {
		let screen = this.state.screen;

		if (screen === "CUSTOMER") screen = "CORPORATE";
		else if (screen === "CORPORATE") screen = "CUSTOMER";

		this.setState({
			screen
		})
	}

	toggleDeactivated = () => {
		this.setState({
			showDeactivated: !this.state.showDeactivated
		})
	}

	render() {
		return (
			<Fragment>
				<Title h1 text="Customers">
					<form className="toggle-deactivated">
						<input
							id="UsersPageToggleDeactivated"
							type="checkbox"
							checked={this.state.showDeactivated}
							onChange={this.toggleDeactivated}
						/>
						<label htmlFor="UsersPageToggleDeactivated">
							Inactive
						</label>
					</form>

					{this.state.screen === "CUSTOMER" && <div>
						<button onClick={this.toggleScreen}>
							Switch to Corporate
						</button>
						<CreateResource
							title={"New Customer"}
							resource={"users"}
							existingData={{ type: this.state.screen }}
							onNewData={this.onUpdate} />
					</div>}

					{this.state.screen === "CORPORATE" && <div>
						<button onClick={this.toggleScreen}>
							Switch to Home
						</button>
						<CreateResource
							title={"New Corporate"}
							resource={"users"}
							excludeKeys={[
								'password',
								'admin'
							]}
							existingData={{ type: this.state.screen }}
							onNewData={this.onUpdate} />
					</div>}
				</Title>
				<div className="Card">
					<CustomersTable
						data={(
							this.state.screen === "CUSTOMER"
								? this.state.customers
								: this.state.corporates
						).filter(user => {
							if (!this.state.showDeactivated) {
								return !!user.activated;
							}

							return true;
						})}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</Fragment>
		);
	}
}