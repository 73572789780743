import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeView from './view';

import './style.less';

export default class RankedProfessionalsList extends LeView {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		await this.loadProfessionals();
	}
}

RankedProfessionalsList.propTypes = {
	appointmentId: PropTypes.number.isRequired,
}