import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeView from './view';
import './style.less';

export default class ProfessionalsInPostcodeCard extends LeView {
	async componentDidMount() {
		await this.loadRankedPros();
		await this.loadProfessionals();
	}
}

ProfessionalsInPostcodeCard.propTypes = {
	postcode: PropTypes.string.isRequired,
	serviceIds: PropTypes.array
}