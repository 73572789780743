import PageModel from '../_PageModel';

export default class LeModel extends PageModel {
	constructor() {
		super();

		this.onUserUpdate = this.onUserUpdate.bind(this);
		this.onCreditUpdate = this.onCreditUpdate.bind(this);
		this.onLocationUpdate = this.onLocationUpdate.bind(this);
		this.onCardUpdate = this.onCardUpdate.bind(this);
		this.onAvatarUpdate = this.onAvatarUpdate.bind(this);
	}

	state = {
		user: null,
		credit_balance: null,
		metrics: {},
		metadata_keys: {},
		metadata_values: [],

		recurrence_rules: [],

		// comms logs
		logs_sms: [],
		logs_push: [],
		logs_email: [],

		vouchersData: null,
		bulkSheduleModalVisible: false,
	}

	getCoreData() {
		const relations = [
			'locations',
			'cards',
			'vouchers',
			'credit_transactions.reason',
			'experiments',
			'professional_searches',
		];

		this.api.request('get', `/users/${this.id}?load=${relations.join(',')}`).then(result => {
			const user = result.data;

			this.setState({
				user,
			});

			for (const voucher of user.vouchers) {
				if (voucher.user_id === user.id) {
					this.setState({
						personal_voucher: voucher,
					});

					break;
				}
			}
		});

		const appointments_relations = [
			'customer',
			'professional',
			'location',
			'services.service',
		];

		this.api.request(
			'get',
			`/appointments?where={"customer_id":${this.id}}&load=${appointments_relations.join(',')}`,
		).then(result => {
			const appointments_as_customer = result.data;

			this.setState({
				appointments_as_customer,
			});
		});

		this.api.request(
			'get',
			`/appointments?where={"professional_id":${this.id}}&load=${appointments_relations.join(',')}`,
		).then(result => {
			const appointments_as_professional = result.data;

			this.setState({
				appointments_as_professional,
			});
		});
	}

	async getAdditionalData() {
		this.api.request('get', `/users/${this.id}/credit_balance`).then(result => {
			this.setState({
				credit_balance: result.data,
			});
		});

		const recurrence_rule_where = `{"user_id":${this.id}}`;

		const recurrence_rule_relations = [
			'appointment.corporate_metadata',
			'appointment.customer',
			'appointment.professional',
			'appointment.location',
		];

		// recurrence rules for corporates
		this.api.request(
			'get',
			`/appointment_recurrence_rules?where=${recurrence_rule_where}&load=${recurrence_rule_relations.join(',')}`,
		).then(result => {
			this.setState({
				recurrence_rules: result.data,
			});
		});

		this.api.request('get', `/users/${this.id}/metrics`).then(result => {
			this.setState({
				metrics: result.data,
			});
		});

		this.api.request('get', `/user_metadata_keys`).then(result => {
			const metadata_keys = {};

			for (const key of result.data) {
				const { id, name, label } = key;

				metadata_keys[id] = label || name;
			}

			this.setState({
				metadata_keys,
			});
		});

		this.api.request('get', `/user_metadata_values?where={"user_id":${this.id}}`).then(result => {
			this.setState({
				metadata_values: result.data,
			});
		});

		// sms logs
		this.api.request('get', `/logs_sms?where={"user_id":${this.id}}`, null, data => {
			this.setState({
				logs_sms: [
					...this.state.logs_sms,
					...data,
				],
			});
		});

		// push logs
		this.api.request('get', `/logs_push?where={"user_id":${this.id}}`, null, data => {
			this.setState({
				logs_push: [
					...this.state.logs_push,
					...data,
				],
			});
		});

		// email logs
		this.api.request('get', `/logs_email?where={"user_id":${this.id}}`, null, data => {
			this.setState({
				logs_email: [
					...this.state.logs_email,
					...data,
				],
			});
		});

		// vouchers tree
		this.api.request('get', `/vouchers/referral_map?user_id=${this.id}`, null, data => {
			console.log('data :', data);

			this.setState({
				vouchersData: data,
			});
		});
	}

	deleteRule = async (id) => {
		try {
			await this.api.request('delete', `/appointment_recurrence_rules/${id}`);
			this.notification.success('Success! You ended the recurrence rule. Well done you.');
		} catch (error) {
			console.error('Oopsy Daisy', error);
		}
	}

	onUserUpdate(data) {
		let user = this.state.user;

		user = Object.assign(user, data);

		this.setState({
			user,
		});

		window.EventSystem.emit(window.EventName.PROFILE_UPDATED, user);
	}

	async onCreditUpdate(amount) {
		this.setState({
			credit_balance: amount,
		});

		await this.getCoreData();
	}

	onLocationUpdate(data) {
		const user = this.state.user;

		// add newly created appointment to appointments list.
		user.locations.push(data);

		this.setState({
			user,
		});
	}

	onCardUpdate(data) {
		const user = this.state.user;

		// add newly created appointment to appointments list.
		user.cards.push(data);

		this.setState({
			user,
		});
	}

	async onAvatarUpdate(base64) {
		const { user } = this.state;

		// get user id.
		const { id } = user;

		try {
			const result = await this.api.request('post', `/users/${id}/avatar`, {
				image: base64.split(';base64,').pop(),
			});

			if (!result.success) throw new Error(result.error);

			const { avatar } = result.data;

			user.avatar = avatar;

			this.setState({
				user,
			});

			window.EventSystem.emit(window.EventName.PROFILE_UPDATED, user);
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	isCustomer(user) {
		user = user || this.state.user;

		if (!user) return null;

		return user.type === "CUSTOMER" || user.type === "CORPORATE";
	}

	isProfessional(user) {
		user = user || this.state.user;

		if (!user) return null;

		return ['PROFESSIONAL', 'STOREFRONT'].includes(user.type);
	}

	isAdmin(user) {
		user = user || this.state.user;

		if (!user) return null;

		return user.admin === true;
	}

	commsData() {
		const { logs_email = [], logs_push = [], logs_sms = [] } = this.state;

		const result = [];

		for (const email_log of logs_email) {
			result.push({
				type: 'EMAIL',
				data: email_log,
			});
		}

		for (const push_log of logs_push) {
			result.push({
				type: 'PUSH',
				data: push_log,
			});
		}

		for (const sms_log of logs_sms) {
			result.push({
				type: 'SMS',
				data: sms_log,
			});
		}

		return result;
	}
}
