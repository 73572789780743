import React from 'react';

import PageModel from '../_PageModel';

import JSONPanel from '../../misc/JSONPanel';
import UpdateResource from '../../buttons/UpdateResource';
import Title from '../../misc/Title';

import './style.less';

export default class CategoryPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;

		this.onUpdate = this.onUpdate.bind(this);
		this.onImageUpdate = this.onImageUpdate.bind(this);
	}

	getCoreData() {
		return this.api.request(
			'get',
			'/service_categories/'
			+ this.id
			+ "?load="
		).then(result => {
			if (result.success) {
				this.setState({
					category: result.data
				})

				return result.data;
			}

			throw new Error(result.error);
		})
	}

	componentDidMount() {
		this.getCoreData().then(category => {


		}).catch(error => {
			console.error(error);
		})
	}

	onUpdate(data) {
		let category = this.state.category;

		category = Object.assign(category, data);

		this.setState({
			category
		})
	}

	async onImageUpdate(image) {
		let { category } = this.state;

		//get category id.
		const { id } = category;

		try {
			const result = await this.api.request('post', `/service_categories/${id}/image`, {
				image
			})

			if (!result.success) throw new Error(result.error);

			const { image_url } = result.data;

			category.image_url = image_url;

			this.setState({
				category
			});

		} catch (error) {
			this.notification.error(error.message);
		}
	}

	render() {
		let category = this.state.category;

		if (!category) return null;

		return (
			<div className="Page CategoryPage">
				<Title h1 text="Category Details">
					<UpdateResource
						title={"Edit Category"}
						resource={"service_categories"}
						existingData={category}
						onNewData={this.onUpdate} />
				</Title>

				<div className="split-view bordered">
					<div flex={2}>
						<div className="Card">
							<JSONPanel data={category} />
						</div>
					</div>
					<div>
						<Title h3 text="Current image" />
						<div className="Card">
							{
								category.image_url
									? <img className="image-preview" src={category.image_url} />
									: <i>None</i>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}