import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import RRule from 'rrule';

import DataModel from '../../_DataModel';
import GenericModal from '../Generic';
import Title from '../../misc/Title';

import Moment from 'moment-timezone';

import './style.less';
import Autocomplete from '../../forms/Autocomplete';
import Tabs from '../../misc/Tabs';
import UserLabel from '../../labels/UserLabel';
import { Table, THead, TBody, TRow, TCol } from '../../tables/Generic';

export default class RegularCorporateModal extends DataModel {
	constructor(props) {
		super(props);

		this.state = {
			metadata: {},
			services: [],
			frequency: null,
			interval: null,
			weekday: null,
			nth_weekday: null,
			start_date: null,
			end_date: null,
		}
	}

	async componentDidMount() {
		const { appointment } = this.props;
		try {
			const relations = ['service']
			const services_result = await this.api.request('get', `/appointment_corporate_services?where={"appointment_id":${appointment.id}}&load=${relations.join(', ')}`);

			const metadata_result = await this.api.request('get', `/appointments/${appointment.id}/corporate_metadata`);

			this.setState({
				services: services_result.data,
				metadata: metadata_result.data,
			})
		} catch (error) {
			this.notification.error('Ah shucks :(', error)
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.show && !this.props.show) {
			this.setState({
				frequency: null,
				interval: null,
				weekday: null,
				nth_weekday: null,
				start_date: null,
				end_date: null,
			})
		}
	}

	setFrequency = (value) => {
		this.setState({
			interval: null,
			weekday: null,
			nth_weekday: null,
			start_date: null,
			end_date: null,
			frequency: value,
		})
	}

	setInterval = (value) => {
		this.setState({
			weekday: null,
			nth_weekday: null,
			start_date: null,
			end_date: null,
			interval: value,
		})
	}

	setWeekday = weekday => {
		this.setState({
			nth_weekday: null,
			start_date: null,
			end_date: null,
			weekday,
		})
	}

	setNthWeekday = value => {
		this.setState({
			start_date: null,
			end_date: null,
			nth_weekday: value,
		})
	}

	setStartDate = value => {
		this.setState({
			start_date: value,
			end_date: null,
		})
	}

	setEndDate = value => {
		this.setState({
			end_date: value,
		})
	}

	formatTime = () => {
		const { appointment: { datetime } } = this.props;
		const { metadata: { duration } } = this.state;

		const start_time = Moment(datetime).clone().format('h:mma')

		const end_time = Moment(datetime).clone().add(duration, 'm').format('h:mma')

		return `${start_time} - ${end_time}`
	}

	getRRule = () => {
		const { frequency, interval, weekday, nth_weekday, start_date } = this.state;

		let { end_date } = this.state;

		const { appointment: { datetime } } = this.props;

		const hour = Moment(datetime).format('h')
		const minute = Moment(datetime).format('m')

		if (!weekday) return [];

		let byweekday = null;
		if (weekday) {
			if (nth_weekday) {
				byweekday = RRule[weekday.slice(0, 2).toUpperCase()].nth(nth_weekday);
			} else {
				byweekday = RRule[weekday.slice(0, 2).toUpperCase()];
			}
		}

		const dtstart = start_date ? Moment(start_date, 'DD/MM/YYYY').utc().hour(hour).minute(minute).toDate() : null;
		end_date = end_date ? Moment(end_date, 'DD/MM/YYYY').endOf('day').utc().toDate() : null;

		try {
			const rule = new RRule({
				freq: RRule[frequency],
				interval,
				byweekday,
				dtstart,
				until: end_date,
			});

			return {
				text: rule.toText(),
				next_apps: rule.all((date, i) => { return i < 5; }),
			};
		} catch (error) {
			this.notification.error('Ah shucks :(', error)
		}

	}

	shouldShowDateSection = () => {
		const { frequency, weekday, interval, nth_weekday } = this.state;

		if (!frequency || !interval) return false;
		if (frequency === 'WEEKLY' && !weekday) return false;
		if (frequency === 'MONTHLY' && !nth_weekday) return false;

		return true;
	}

	onSubmit = async (event) => {
		event.preventDefault();

		const { frequency, weekday, interval, nth_weekday, start_date } = this.state;
		let { end_date } = this.state;
		const { appointment: { id: appointment_id, datetime, customer_id: user_id } } = this.props;

		try {
			const hour = Moment(datetime).format('h')
			const minute = Moment(datetime).format('m')

			const start_datetime = Moment(start_date, 'DD/MM/YYYY').hour(hour).minute(minute).format()
			end_date = Moment(end_date, 'DD/MM/YYYY').format()

			const result = await this.api.request('post', '/appointment_recurrence_rules', {
				user_id,
				frequency,
				week_day: weekday.slice(0, 2).toUpperCase(),
				interval,
				nth_week_day: nth_weekday,
				appointment_id,
				start_datetime,
				end_date,
			});

			this.notification.success('Amazing! New rule created for the corporate appointment. Check the current rules on the Corporate client\'s page for details!')

			this.props.onHide();
		} catch (error) {
			this.notification.error('Ah shucks :(', error)
		}

	}

	render() {
		const { show, onHide, appointment } = this.props;

		if (!appointment) return null;
		if (appointment.type !== 'CORPORATE') return null;

		const { frequency, weekday, metadata, services, interval, nth_weekday, start_date, end_date } = this.state;

		const options = [
			{ value: 1, label: `Every ${frequency && frequency.toLowerCase().slice(0, -2)}` },
			{ value: 2, label: `Every 2 ${frequency && frequency.toLowerCase().slice(0, -2)}s` },
			{ value: 3, label: `Every 3 ${frequency && frequency.toLowerCase().slice(0, -2)}s` },
		]

		const nth_weekday_options = [
			{ value: 1, label: `Every 1st ${weekday}` },
			{ value: 2, label: `Every 2nd ${weekday}` },
			{ value: 3, label: `Every 3rd ${weekday}` },
			{ value: 4, label: `Every 4th ${weekday}` },
		]

		const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

		const frequency_options = [{ value: 'MONTHLY', label: 'Monthly' }, { value: 'WEEKLY', label: 'Weekly' }];
		const weekday_options = weekdays.map(w => ({ value: w, label: w.slice(0, 3) }));

		const rule = this.getRRule();

		let next_apps = [];
		let rrule_text = null;

		if (rule) {
			next_apps = rule.next_apps
			rrule_text = rule.text
		}

		let available_times = [];

		for (
			let time_iterator = Moment().startOf('day').add(8, 'hours');
			time_iterator.hour() < 23;
			time_iterator.add(30, 'minutes')
		) {
			available_times.push(time_iterator.format('HH:mm'))
		}

		const available_dates = [];

		for (
			let date_iterator = Moment().startOf('day');
			date_iterator.isBefore(Moment().add(6, 'months'));
			date_iterator.add(1, 'day')
		) {
			available_dates.push(date_iterator.format('DD/MM/YYYY'))
		}

		return (
			<GenericModal className="RegularCorporate" show={show} onHide={onHide}>
				<Title h1 text="Create Regular Corporate" />

				<div className="split-view Cron">
					<div>
						<Title h3>Settings</Title>
						<form onSubmit={this.onSubmit}>
							<p>
								What frequency do you want?
							</p>
							<Tabs
								tabs={frequency_options}
								selectedTab={frequency}
								onSelect={this.setFrequency}
							/>
							{
								frequency
								&& <div className="interval">
									<Autocomplete
										value={interval}
										options={options}
										clearable={true}
										placeholder={"Select interval"}
										onChange={this.setInterval}
									/>
								</div>
							}
							{
								interval
								&& <Fragment>
									<p>
										Which day of the week?
									</p>
									<Tabs
										tabs={weekday_options}
										selectedTab={weekday}
										onSelect={this.setWeekday}
									/>
								</Fragment>
							}
							{
								frequency === 'MONTHLY' && weekday
								&& <div className="nth_weekday">
									<Autocomplete
										value={nth_weekday}
										options={nth_weekday_options}
										clearable={true}
										placeholder={`Every XX ${weekday}`}
										onChange={this.setNthWeekday}
									/>
								</div>
							}
							{
								this.shouldShowDateSection()
								&& <Fragment>
									<Autocomplete
										value={start_date}
										clearable={true}
										options={available_dates.map(date => {
											return {
												value: date,
												label: date
											};
										})}
										onChange={this.setStartDate}
										placeholder={'Start date...'}
									/>
									{
										start_date
										&& <Autocomplete
											value={end_date}
											clearable={true}
											options={available_dates.filter(
												date => Moment(start_date, 'DD/MM/YYYY').isBefore(Moment(date, 'DD/MM/YYYY'))
											).map(date => {
												return {
													value: date,
													label: date
												};
											})}
											onChange={this.setEndDate}
											placeholder={'End date... (optional)'}
										/>
									}
								</Fragment>
							}

							{
								start_date
								&& <button className="submit" type="submit">
									Submit
								</button>
							}
						</form>
					</div>
					<div className="RRule">
						<Title h3>Schedule Preview</Title>
						{
							this.shouldShowDateSection()
							&& <div className="preview">
								{next_apps.map(date => {
									const stripped = date.toISOString().replace(/Z/g, '');
									return <p key={date}>{Moment(stripped).format('LLLL')}</p>
								})}
								<p>"{rrule_text}"</p>
							</div>
						}
					</div>
				</div>

				<Title h3>Source booking</Title>
				<p>
					Each booking will be created with the information below. You can modify the automatically created bookings on a case-by-case basis. Expand this section to double-check all the information is correct.
				</p>

				{/* table of details fam */}
				<Title h3 toggle>Appointment details</Title>
				<div className="details">
					<Table className="split-view">
						<TBody>
							<TRow>
								<TCol>Customer</TCol>
								<TCol><UserLabel user={appointment.customer} /></TCol>
							</TRow>
							<TRow>
								<TCol>Location</TCol>
								<TCol>{appointment.location.address}</TCol>
							</TRow>
							<TRow>
								<TCol>Time</TCol>
								<TCol>{this.formatTime()}</TCol>
							</TRow>
							<TRow>
								<TCol>Salonette</TCol>
								<TCol>
									{
										appointment.professional
											? <UserLabel user={appointment.professional} />
											: <i>Any Salonette</i>
									}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Attendees choose slots</TCol>
								<TCol>
									{
										metadata && metadata.flexible_slots
											? 'Yes'
											: 'No'
									}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Vouchers</TCol>
								<TCol>
									{
										metadata && metadata.vouchers_allowed
											? 'Yes'
											: 'No'
									}
								</TCol>
							</TRow>
							<TRow>
								<TCol>Company Pays</TCol>
								<TCol>
									{
										metadata && metadata.company_pays
											? 'Yes'
											: 'No'
									}
								</TCol>
							</TRow>
						</TBody>
						<TBody>
							<TRow>
								<TCol>
									Bookable services
								</TCol>
							</TRow>
							<TRow>
								<TCol>
									<div className="service-list">
										{services.map(service => (
											<li key={service.id}>
												{service.service.option}
											</li>
										))}
									</div>
								</TCol>
							</TRow>
						</TBody>
					</Table>
				</div>
			</GenericModal >
		)
	}
}

RegularCorporateModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
}