import React from 'react';
import { browserHistory } from 'react-router';
import Moment from 'moment';
import qs from 'qs';

import PageModel from "../_PageModel";
import {
	Table, TBody, TRow, TCol,
} from '../../tables/Generic';
import SearchResultsTable from '../../tables/SearchResults';
import UserLabel from '../../labels/UserLabel';
import Title from '../../misc/Title';

import './style.less';

export default class SearchPage extends PageModel {
	constructor(props) {
		super(props);

		this.id = props.id || props.params.id || null;
	}

	async componentDidMount() {
		const search_relations = [
			'professional_search_results.professional',
		];

		try {
			const result = await this.api.request('get', `/professional_searches/${this.id}?load=${search_relations.join(',')}`);
			this.setState({
				search: result.data,
			});
		} catch (error) {
			this.notification.error(error.message);
		}

		const service_relations = [
			'category',
		];

		try {
			const result = await this.api.request(
				"get",
				`/services?load=${service_relations.join(',')}`,
			);
			this.setState({
				services: result.data,
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	formatServices(serviceIds) {
		const { services } = this.state;
		if (!services) {
			// if services are not loaded, just stringify the ids
			return JSON.stringify(serviceIds);
		}
		// otherwise get the service names and list them
		const getService = id => services.find(service => service.id === parseInt(id));
		const names = serviceIds.map(id => getService(id).option);
		return names.join(', ');
	}

	formatDuration(mins) {
		return `${mins} mins`;
	}

	formatCustomer(customerId) {
		return <UserLabel id={parseInt(customerId)} fetch />;
	}

	formatDatetime(datetime) {
		return Moment(datetime).format('ddd Do MMM YYYY [at] HH:mm');
	}

	formatDatetimes(datetimes) {
		const formatted = datetimes.map(this.formatDatetime);
		return formatted.join(', ');
	}

	formatLocationTags(locationTags) {
		return (
			locationTags.map(tag => (
				<div className="Label Badge tag">
					<span>{tag.label}</span>
				</div>
			))
		);
	}

	// format the various query values we're expecting
	// stringify as a fallback in case of new parameters
	formatQueryValue(key, value) {
		switch (key) {
			case 'service_ids':
				return this.formatServices(value);
			case 'duration':
				return this.formatDuration(value);
			case 'customer_id':
				return this.formatCustomer(value);
			case 'datetimes':
				return this.formatDatetimes(value);
			case 'location_tag_ids':
				return this.formatLocationTags(value);
			case 'type':
			case 'postcode':
				return value;
			default:
				return JSON.stringify(value);
		}
	}

	capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	formatQueryKey(key) {
		switch (key) {
			case 'service_ids':
				return 'Services';
			case 'customer_id':
				return 'Customer';
			case 'location_tag_ids':
				return 'Location tags';
			default:
				return this.capitalize(key);
		}
	}

	renderQueryParameter(paramKeyValuePair) {
		// define the key and value from the pair
		const key = paramKeyValuePair[0];
		const value = paramKeyValuePair[1];
		// if either is absent/null, don't bother rendering
		if (!key || !value) {
			return null;
		}
		// if duration is 0 don't render the duration query param
		// not sure whether to do this or not?
		if (key === 'duration' && parseInt(value) === 0) {
			return null;
		}
		// format the key and value and render the row
		return (
			<TRow>
				<TCol>{this.formatQueryKey(key)}</TCol>
				<TCol>
					{this.formatQueryValue(key, value)}
				</TCol>
			</TRow>
		);
	}

	renderQueryParameters() {
		const search = this.state.search;
		if (!search || !search.query) return null;
		// parse query to get a query object
		const parsedQuery = qs.parse(search.query);
		// get an array of key-value pairs from the object
		const paramKeyValuePairs = Object.entries(parsedQuery);
		// map through the array to render each parameter
		return (
			<>
				{paramKeyValuePairs.map((queryParamPair) => this.renderQueryParameter(queryParamPair))}
			</>
		);
	}

	render() {
		const search = this.state.search;

		if (!search) return null;

		const isRobot = search.source.charAt(0) !== '/';
		const showRobotEmoji = isRobot && !search.searcher_id;
		const showPersonEmoji = !isRobot && !search.searcher_id;

		return (
			<div className="Page SearchPage">
				<Title h1 text="Search Details" />

				<div className="split-view">
					<div>
						<Title h3 text="Search Query" />
						<div className="Card">
							<Table>
								<TBody>
									{this.renderQueryParameters()}
									<TRow>
										<TCol>Raw query</TCol>
										<TCol><div className="techy">{search.query}</div></TCol>
									</TRow>
								</TBody>
							</Table>
						</div>
					</div>
				</div>
				<div>
					<Title h3 text="Search Details" />
					<div className="Card">
						<Table>
							<TBody>
								<TRow>
									<TCol>Searcher</TCol>
									<TCol>{showRobotEmoji && <span>🤖</span>}{showPersonEmoji && <span>💁</span>}{search.searcher_id ? <UserLabel id={parseInt(search.searcher_id)} fetch /> : 'Not set'}</TCol>
								</TRow>
								<TRow>
									<TCol>Appointment ID</TCol>
									<TCol>{search.appointment_id || 'Not set'}</TCol>
								</TRow>
								<TRow>
									<TCol>Searched at</TCol>
									<TCol>{this.formatDatetime(search.searched_at)}</TCol>
								</TRow>
								<TRow>
									<TCol>Source</TCol>
									<TCol>{search.source}</TCol>
								</TRow>
							</TBody>
						</Table>
					</div>
				</div>
				<Title h3 text="Search Results" />
				<div className="Card">
					<SearchResultsTable
						data={search.professional_search_results}
						infinite
						interactive={false}
						filterable
					/>
				</div>
			</div>
		);
	}
}
