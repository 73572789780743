/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Parser } from 'json2csv';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router';
import {
	Table, THead, TBody, TRow, TCol,
} from './Generic';
import FilterForm from '../forms/Filter';

import DataModel from '../_DataModel';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

const isSubsetOf = (set, subset) => Array.from(new Set([...set, ...subset])).length === set.length;

export default class TableModel extends DataModel {
	// OVERRIDE THIS
	constructor() {
		super();

		// override this with an identifier for the child table.
		this.className = "Table";

		// override this with the column index containing a unique value (important for correct re-rendering)
		this.UNIQUE_INDEX = 0;

		// override this with an array of strings and/or elements.
		this.titles = [];

		// conform to this pattern:
		/*
			[
				{title: "ID", searchable: true, description: "User ID"},
				{title: "Name", searchable: true, description: "Name"},
				{title: <i>etc</i>, searchable: false, description: "Something"}
			]
		*/

		this.state = {
			data: [],
			sort: {
				index: 0, // this.titles[] index
				direction: -1, // can be 1 or -1
			},
			currentIndex: 0,
			currentFilter: null,
			infiniteIndex: 20,
			allRowsLoaded: true,
			tableIsExpanded: true,
		};

		// override this if you intend to export anything.
		this.PDF_METEOR_METHOD = null;

		this.generateHead = this.generateHead.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.handleShowMore = this.handleShowMore.bind(this);
		this.handleShowLess = this.handleShowLess.bind(this);
		this.loadRows = this.loadRows.bind(this);
		this.exportCSV = this.exportCSV.bind(this);
	}

	// OVERRIDE THIS
	dataForRows(data) {
		// format your data in here and then return the array with:
		/*
			return formatted_rows;
		*/
		// formatted data should conform to a pattern like this:
		/*
			[
				[
					"123", //ID
					"Nabil", //Name
					<i>foo</i>, //etc
				],
				[
					"456", //ID
					"Test", //Name
					<i>bar</i>, //etc
				]
			]
		*/
	}

	// OVERRIDE THIS IF INTERACTIVE
	hyperlink(data) {
		return null;
		// return "/some/path/" + data.id;
	}

	// OVERRIDE THIS IF EXPORTABLE
	dataForExport(data) {
		return [];
	}

	// AVOID OVERRIDING BELOW HERE
	filter(index, text) {
		if (!text) {
			return null;
		}

		text = text.toUpperCase();

		return this.state.data.filter(datum => {
			let column = datum[index];

			// special transformation for Date fields - these use a unix timestamp so sorting works properly.
			if (this.titles[index].type === Date) {
				column = Moment.unix(column).format(DATE_FORMAT);
			}

			// ignore all values that are null, or cannot be converted to a string.
			if (!column || !column.toString) return false;

			// normalize everything by putting into uppercase
			column = column.toString().toUpperCase();

			// if the column value is fewer characters than the search string it's defo not a match.
			if (column.length < text.length) return false;

			// check if the column's data contains the searched string.
			if (column.indexOf(text) === -1) return false;

			// all checks passed!
			return true;
		});
	}

	sort(data, sort) {
		sort = sort || this.state.sort;
		const column = this.titles[sort.index];

		if (!column.sortable) return data;

		data.sort((a, b) => {
			a = a[sort.index];
			b = b[sort.index];

			// if data needs to be formatted in a special way, do it here.
			// this is extremely costly in terms of processing power, so be careful!
			if (column.type === String) {
				a = a || "";
				b = b || "";

				a = a.toUpperCase().replace(/ /g, ''); // caps lock and trim whitespace
				b = b.toUpperCase().replace(/ /g, '');
			}

			if (a < b) return (-1 * sort.direction);
			if (a > b) return (1 * sort.direction);
			return 0;
		});

		return data;
	}

	exportCSV() {
		const filename =			[
			this.props.name,
			Moment().diff(Moment().startOf('year'), 'seconds'),
		]
			.filter(entry => (!!entry))
			.join('_')
			+ '.csv';

		const fields = this.titles.map(t => t.title);

		const data = this.dataForExport(this.props.data).map(row => {
			const result = {};

			for (const i in fields) {
				result[fields[i]] = row[i];
			}

			return result;
		});

		try {
			const parser = new Parser({
				fields,
				withBOM: true,
			});

			const csv = parser.parse(data);

			const href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;

			const link = document.createElement("a");
			link.setAttribute("href", href);
			link.setAttribute("download", filename);

			document.body.appendChild(link);

			// trigger the download.
			link.click();
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	setData(data) {
		if (!data) return;

		data = this.dataForRows(data);

		if (!data) return;

		data = this.sort(data);

		if (!data) return;

		// by default, we shouldn't reset the rows in local state because there is a chance that the data coming through to us is partial.
		let payload = {
			// the source data.
			data,
		};

		// to be sure that we should reset the current data, we must must must...
		// we must make sure that the data coming in is not an "extension" of the current data
		// new data must equal or be a superset of existing data.
		// because we're dealing with arrays the easiest way to compare this data is to stringify them.
		// they should both already be sorted due to the above operations.
		const new_keys = data.map(columns => columns[this.UNIQUE_INDEX]);
		const existing_keys = this.state.data.map(columns => columns[this.UNIQUE_INDEX]);

		// if some of our existing data is missing from the new dataset, then we assume the data has dramatically changed and must reset the table.
		if (
			existing_keys.length > new_keys.length // if the data available has reduced then we must force a refresh.
			|| !isSubsetOf(new_keys, existing_keys)
		) {
			payload = {
				...payload,
				// this resets the current set of filtered results (if any)
				formatted: data,
			};
		}

		if (data.length > this.state.currentIndex) {
			payload = {
				...payload,
				// this tells the infinite scroll component that there are more rows to be rendered.
				allRowsLoaded: false,
			};
		}

		this.setState(
			payload,
			// important to use a callback here so we can make sure the set data has gone into state.
			() => this.state.currentIndex === 0 && this.loadRows(),
		);
	}

	componentDidMount() {
		this.setData(this.props.data);

		this.titles.some((title, index) => {
			if (!title.sortable) return false;

			this.setState({
				sort: {
					index,
					direction: -1,
				},
			});

			return true;
		});

		if (this.props.defaultLimit) {
			this.setState({
				tableIsExpanded: false,
			});
		}
	}

	componentWillReceiveProps(props) {
		this.setData(props.data);

		if (props.defaultLimit) {
			this.setState({
				tableIsExpanded: false,
			});
		}
	}

	handleFilterChange(index, text) {
		if (!text || !text.length) text = null; // blank filter means no filter!

		const formatted = this.filter(index, text);

		// category.value is an index related to this.titles[]
		this.setState({
			formatted,
			allRowsLoaded: false,
		});
	}

	handleFilterSelect = (option) => {
		this.setState({
			currentFilter: option.label,
		});
	}

	handleSort(index) {
		const column = this.titles[index];

		if (!column.sortable) return;

		const sort = this.state.sort;

		if (sort.index === index) {
			sort.direction *= -1; // flip the direction of the sort's direction
		} else {
			sort.index = index;
			sort.direction = 1; // default to 1 (ASC)
		}

		const state_additions = {
			sort,
			data: this.sort(this.state.data, sort),
			allRowsLoaded: false,
		};

		if (this.state.formatted) {
			state_additions.formatted = this.sort(this.state.formatted);
		}

		this.setState(state_additions);
	}

	handleShowMore() {
		// expand table.
		this.setState({
			tableIsExpanded: true,
		});
	}

	handleShowLess() {
		// collapse table.
		this.setState({
			tableIsExpanded: false,
		});
	}

	generateHead(titles) {
		if (!titles) return;

		const head_columns = [];

		const sort = this.state.sort;

		titles.forEach((column, index) => {
			head_columns.push(
				<TCol key={index}>
					<div className={column.sortable && "sortable"} onClick={this.handleSort.bind(this, index)}>
						<span className="title">{column.title}</span> {sort.index === index && <FontAwesome name={"sort-alpha-" + (sort.direction > 0 ? "asc" : "desc")} />}
					</div>
				</TCol>,
			);
		});

		return head_columns;
	}

	loadRows() {
		const data = this.state.formatted || this.state.data; // filtered dataset || full dataset

		if (!data) return;

		const { currentIndex } = this.state;
		let newIndex = null;

		// if infinite scrolling is enabled, only load the next 5.
		// if it's disabled, load the entire list.
		if (this.props.infinite) {
			newIndex = currentIndex + 5;

			if (newIndex > data.length) newIndex = data.length;
		} else {
			newIndex = data.length; // load everything
		}

		let payload = {
			currentIndex: newIndex,
		};

		if (newIndex >= data.length) {
			payload = {
				...payload,
				allRowsLoaded: true,
			};
		}

		this.setState(payload);
	}

	renderRows() {
		const data = this.state.formatted || this.state.data; // filtered dataset || full dataset

		if (!data) return null;

		const { currentIndex } = this.state;

		return data.slice(0, currentIndex).map(entry => {
			// get a unique key for this row.
			const key = entry[this.UNIQUE_INDEX];

			const body_columns = [];

			entry.forEach((column, index) => {
				if (!column) {
					column = <i>Not set</i>;
				} else if (this.titles[index].type === Date) {
					column = Moment.unix(column).format(DATE_FORMAT);
				}

				body_columns.push(<TCol key={index}>{column}</TCol>);
			});

			let row = <TRow key={"row_" + key}>{body_columns}</TRow>;

			// wrap the row in a hyperlink if it's interactive
			if (this.props.interactive) {
				row = (
					<Link className="interactive" to={this.hyperlink(entry)} key={"link_" + key}>
						{row}
					</Link>
				);
			}

			return row;
		});
	}

	render() {
		const { filterable, exportable, defaultLimit } = this.props;

		const {
			allRowsLoaded, formatted, data, tableIsExpanded, currentFilter,
		} = this.state;

		const filter_options = this.titles.map((title, index) => ({
			value: index,
			label: title.description || title.title,
			searchable: title.searchable,
		})).filter(title => title.searchable);

		const head = this.generateHead(this.titles);
		const results_count = (formatted || data || []).length;

		let rows = this.renderRows();

		// truncate list of visible rows to the limit set in props.
		// only do this if the user has not fully expanded the table (i.e. "See more" clicked)
		if (defaultLimit && !tableIsExpanded) {
			rows = rows.slice(0, defaultLimit);
		}

		const classNames = [
			this.className,
		];

		if (currentFilter) classNames.push(`filter-${currentFilter.toLowerCase().replace(/ /g, '-')}`);

		return (
			<Table className={classNames.join(' ')}>
				<THead className="toolbar">
					<TRow>
						{filterable && (
							<TCol className="filter_section">
								{
									filter_options.length
									&& (
										<FilterForm
											options={filter_options}
											onChange={this.handleFilterChange}
											onSelect={this.handleFilterSelect}
										/>
									)
								}
							</TCol>
						)}
						<TCol className="actions_section">
							<span className="results_count">
								{results_count} result{results_count === 1 ? "" : "s"}
							</span>
							{exportable && results_count > 0 && (
								<button onClick={this.exportCSV}>
									Export CSV
								</button>
							)}
						</TCol>
					</TRow>
				</THead>

				<THead className="titles">
					<TRow>
						{head}
					</TRow>
				</THead>

				<TBody>
					<InfiniteScroll
						loader={null}
						loadMore={this.loadRows}
						hasMore={
							tableIsExpanded && !allRowsLoaded
						}
					>
						{rows}
					</InfiniteScroll>
				</TBody>

				{
					// only show the "See more" button if there are actually more results to show
					results_count !== rows.length
					&& (
						<a className="see-more" onClick={this.handleShowMore}>
							Show {results_count - rows.length} more
						</a>
					)
				}

				{
					// logic for showing "See less" button
					results_count === rows.length
					&& defaultLimit
					&& results_count > defaultLimit
					&& (
						<a className="see-more" onClick={this.handleShowLess}>
							Show less
						</a>
					)
				}

			</Table>
		);
	}
}

TableModel.propTypes = {
	// table name (optional)
	name: PropTypes.string,

	// raw data to build rows & columns from.
	data: PropTypes.array.isRequired,

	// only show max N rows by default - with a "Show all" button. Useful for large lists.
	defaultLimit: PropTypes.number,

	// infinite scroll (for better performance on large lists)
	infinite: PropTypes.bool,

	// is the table clickable?
	interactive: PropTypes.bool,

	// filter feature
	filterable: PropTypes.bool,

	// csv export feature
	exportable: PropTypes.bool,
};
