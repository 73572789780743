import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import './style.less';

import Page from '../_PageModel';

export default class SprintPage extends Page {
	constructor() {
		super();

		this.state = {
			dev_team: [],
			chart_data: [],
			features: [],
			bugs: [],
		};
	}

	componentDidMount() {
		this.sprintRefresh = setInterval(() => this.getSprintData(), 1000 * 60 * 10) // 10 minutes;
		this.videoForcer = setInterval(() => this.playVideo(), 1000 * 5) // 5 seconds

		setTimeout(() => {
			window.location.reload();
		}, 1000 * 60 * 60 * 2) // reload every 2 hours, just to clean stuff up

		this.getSprintData();
	}

	componentWillUnmount() {
		clearInterval(this.sprintRefresh);
		clearInterval(this.videoForcer);
	}

	async getSprintData() {
		try {
			const { data } = await this.api.request(
				'GET',
				'/sprint'
			)

			this.setState({
				...data
			});
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	async playVideo() {
		const video = document.querySelector('video');

		if (!video) return;

		await video.play();
	}

	renderTeam() {
		const { dev_team } = this.state;

		const rows = dev_team.map(person => (
			<tr>
				<td>
					{person.name.split(' ')[0]}
				</td>
				<td>
					<div
						className="capacity"
						style={{
							width: (person.capacity * 100) + '%'
						}}
					></div>
				</td>
				<td>
					{person.capacity * 100}%
				</td>
			</tr>
		))

		return (
			<table className="team">
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}

	renderFeatures() {
		const { features } = this.state;

		const rows = features.map(feature => (
			<tr>
				<td>
					<div
						className={`label ${feature.color}`}
						style={{
							background: feature.color
						}}
					></div>
					{feature.name}
				</td>
				<td>
					{Math.floor((feature.done_cards / feature.total_cards) * 100)}%
				</td>
				<td>
					{
						feature.active
							? `🚧`
							: feature.done_cards === feature.total_cards
								? `✅`
								: null
					}
				</td>
			</tr>
		))

		return (
			<table className="features">
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}

	renderBugs() {
		const { bugs } = this.state;

		const rows = bugs.map(bug => (
			<tr>
				<td>
					<div className="label red"></div>
					{bug.title}
				</td>
				<td>
					{bug.done && `✅`}
				</td>
			</tr>
		))

		return (
			<table className="bugs">
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}

	render() {
		const { chart_data } = this.state;

		const data = {
			labels: chart_data.map(
				(item, index) => (
					index === chart_data.length - 1
						? `Ceremony`
						: this.moment(item.date).format('ddd')
				)
			),
			datasets: [{
				fill: false,
				borderColor: '#4000FF',
				borderWidth: 6,
				lineTension: 0,
				pointRadius: 6,
				pointBackgroundColor: '#4000FF',
				data: chart_data.filter(
					item => item.done !== null // filter out unfinished days
				).map(
					item => Math.trunc(chart_data[chart_data.length - 1].standard - item.done)
				),
				labels: chart_data.filter(
					item => item.done !== null // filter out unfinished days
				).map(
					item => Math.trunc(chart_data[chart_data.length - 1].standard - item.done) + ' points'
				),
			}, {
				fill: false,
				borderColor: 'red',
				borderWidth: 6,
				borderDash: [18, 6],
				lineTension: 0,
				pointRadius: 6,
				pointBackgroundColor: 'red',
				data: chart_data.map(
					item => Math.trunc(chart_data[chart_data.length - 1].standard - item.standard)
				),
				labels: chart_data.map(
					item => Math.trunc(chart_data[chart_data.length - 1].standard - item.standard) + ' points'
				),
			}]
		};
		const options = {
			legend: {
				display: false
			},
			tooltips: {
				mode: 'index',
				intersect: false,
			},
			scales: {
				xAxes: [{
					display: true,
					scaleLabel: {
						display: false,
						labelString: 'Day'
					},
					ticks: {
						fontSize: 20,
						fontColor: 'black',
						fontStyle: 'bold',
						autoSkip: false,
					},
					gridLines: {
						display: false,
						lineWidth: 2,
						color: '#F0F0F0'
					},
				}],
				yAxes: [{
					display: true,
					scaleLabel: {
						display: false,
						labelString: 'Points'
					},
					ticks: {
						fontSize: 20,
						fontColor: 'black',
						fontStyle: 'bold',
						beginAtZero: true
					},
					gridLines: {
						lineWidth: 2,
						color: '#F0F0F0'
					},
				}]
			},
			responsive: true,
			maintainAspectRatio: false
		};

		return (
			<div className="Page SprintPage">
				<div>
					<Line
						data={data}
						options={options}
						redraw
					/>
				</div>
				<div>
					<div className="header">
						Team
					</div>
					{this.renderTeam()}
					<div className="header">
						Features
					</div>
					{this.renderFeatures()}
					<div className="header">
						Bugs
					</div>
					{this.renderBugs()}
				</div>
				<video
					src="https://dz9tcnaek5flw.cloudfront.net/videos/beach.mp4"
					autoPlay
					loop
					controls={false}
				/>
			</div>
		);
	}
}