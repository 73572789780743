import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabelModel from '../_LabelModel';

import './style.less';

export default class SurgeLabel extends LabelModel {
	render() {
		const { events } = this.props;

		if (events && events.length) {
			for (const event of events) {
				const { name, metadata } = event;

				//skip irrelevant events.
				if (!name || name.label !== 'PLACED') {
					continue;
				}

				if (metadata.is_surge === true) return (
					<div className="Label Badge">
						Surge
					</div>
				)
			}
		}

		return null;
	}
}

SurgeLabel.propTypes = {
	events: PropTypes.array.isRequired
}