import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, THead, TBody, TRow, TCol } from '../../tables/Generic';
import PaymentsTable from '../../tables/Payments';
import PaymentTypeLabel from '../../labels/PaymentTypeLabel';

import './style.less';

export default class PaymentDetailsCard extends Component {
	render() {
		let payments = this.props.payments;

		if (!payments.length) return (
			<div className="Card PaymentDetailsCard">
				<Table>
					<TBody>
						<TRow>
							<TCol>
								<PaymentTypeLabel type="unpaid" />
							</TCol>
						</TRow>
					</TBody>
				</Table>
			</div>
		);

		return (
			<div className="Card PaymentDetailsCard">
				<PaymentsTable data={payments} interactive={true} />
			</div>
		);
	}
}

PaymentDetailsCard.propTypes = {
	payments: PropTypes.array.isRequired
}