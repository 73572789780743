import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataModel from '../../_DataModel';

import './style.less';

export default class JSONPanel extends DataModel {
	load(path) {
		if (!path) return;

		this.getData(path);
	}

	componentDidMount() {
		this.load(this.props.path);
	}

	componentWillReceiveProps(props) {
		this.load(props.path);
	}

	render() {
		const data = this.props.data || this.state.data;

		if (!data) return null;

		const keys = Object.keys(data);

		if (!keys.length) return null;

		const rows = keys.map(key => {
			let value = data[key];

			try {
				value = value.toString();
			} catch (error) {
				//fallback to basic string conversion to values without toString
				value += '';
			}

			return (
				<tr key={key}>
					<td>
						{key}
					</td>
					<td>
						{value}
					</td>
				</tr>
			)
		})

		return (
			<div className="JSONPanel">
				{this.props.path && <div className="title">
					{this.props.path}
				</div>}

				<table>
					<tbody>
						{rows}
					</tbody>
				</table>
			</div>
		);
	}
}

JSONPanel.propTypes = {
	path: PropTypes.string,
	data: PropTypes.object
}