import React from 'react';

import Textarea from 'react-textarea-autosize';

import Moment from 'moment';

import PageModel from "../_PageModel";
import Config from '../../../Config';

import './style.less';
import Autocomplete from '../../forms/Autocomplete';

const TWILIO_NUMBER = '+447897033141';
const RELOAD_MESSAGES_TIMEOUT = 20000; // 20s
const PREFILLED_MESSAGES = [
	`It's a new app for therapists. You can find more information here, getStorefrontApp.com. Sign up through the website & one of my team will be in touch :)`,
	`OK great! I have added you to the waitlist. One of the team will be in touch soon to tell you more!`,
	`The app is separate from LeSalon. It provides a management tool for therapists to control their own business. We also offer a personalised website and booking form! You can find out more here.`,
];

const MOCK_SENT = [
	{
		to: "+44",
		from: "+100",
		body: 'Hey from LeSalon',
		dateCreated: '1',
	},
	{
		to: "+441",
		from: "+100",
		body: 'Hey from LeSalon',
		dateCreated: '1',
	},
	{
		to: "+442",
		from: "+100",
		body: 'Hey from LeSalon',
		dateCreated: '1',
	},
];

const MOCK_RECEIVED = [
	{
		from: "+441",
		to: "+100",
		body: 'Hey from Billy',
		dateCreated: '2',
	},
	{
		from: "+441",
		to: "+100",
		body: 'Hey from Billy',
		dateCreated: '2',
	},

];

export default class SearchPage extends PageModel {
	constructor(props) {
		super(props);

		this.state = {
			received: MOCK_RECEIVED,
			sent: MOCK_SENT,
			selectedNumber: null,
			currentMessage: '',
			contacts: [],
		};

		this.messagesEndRef = React.createRef();
	}

	componentDidMount() {
		this.scrollToBottom();

		this.startAutoRefresh();

		this.fetchContacts();
	}

	componentDidUpdate() {
		this.scrollToBottom();
	}

	componentWillUnmount() {
		clearTimeout(this.messagesTimeout);
	}

	startAutoRefresh = async () => {
		console.log('Refreshing messages');

		clearTimeout(this.messagesTimeout);

		this.messagesTimeout = setInterval(() => {
			this.fetchSentMessagesTo(TWILIO_NUMBER);
			this.fetchReceivedMessagesFrom(TWILIO_NUMBER);
		}, RELOAD_MESSAGES_TIMEOUT);
	}

	scrollToBottom = () => {
		this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
	}

	updateCurrentMessage = (message) => {
		this.setState({
			currentMessage: message,
		});
	}

	onSubmitMessage = (e) => {
		e.preventDefault();

		const { selectedNumber, currentMessage } = this.state;

		this.sendMessage(selectedNumber, currentMessage);

		this.setState({
			currentMessage: '',
		});
	}

	selectUser = (number) => {
		this.setState({
			currentMessage: '',
			selectedNumber: number,
		});
	}

	getRelevantMessagesForNumber(number) {
		const { sent, received } = this.state;

		const sentByNumber = sent.filter(message => message.to === number);
		const recievedByNumber = received.filter(message => message.from === number);

		const allMessages = sentByNumber.concat(recievedByNumber).sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated));

		return allMessages;
	}

	fetchContacts() {
		this.api.request("get", `/sms_response/fetch_contacts`, null, (data) => {
			this.setState({
				contacts: data,
			});
		});
	}

	fetchSentMessagesTo(number) {
		// if (Config.environment === 'development') {
		// 	return;
		// }

		const encoded_number = encodeURIComponent(number);

		this.api.request("get", `/sms_response/fetch_messages?from=${encoded_number}`, null, (data) => {
			this.setState({
				sent: data,
			});
		});
	}

	fetchReceivedMessagesFrom(number) {
		// if (Config.environment === 'development') {
		// 	return;
		// }

		const encoded_number = encodeURIComponent(number);

		this.api.request("get", `/sms_response/fetch_messages?to=${encoded_number}`, null, (data) => {
			this.setState({
				received: data,
			});
		});
	}

	sendMessage(to_number, message) {
		if (!to_number || !message) {
			this.notification.error('You must supply a number and message.');
			return;
		}

		try {
			this.api.request("post", `/sms_response/send`, { to_number, message }, (data) => {
				this.setState({
					sent: [
						...this.state.sent,
						data,
					],
				});
			});

			this.notification.success('Success! You sent ' + to_number + ' a message: ' + message);
		} catch (error) {
			this.notification.error('Message failed to send.', error.message);
		}
	}

	isWaitingReply(number) {
		const messages = this.getRelevantMessagesForNumber(number);

		const latestMessage = messages[messages.length - 1];

		if (latestMessage.from === number) return true;

		return false;
	}

	getContactName(number) {
		const { contacts } = this.state;

		const contact = contacts.find(c => c.phone_number === number);

		if (!contact) return 'No name';

		return contact.name;
	}

	onChoosePrefill = (message) => {
		this.updateCurrentMessage(message);
	}

	formatPrefillDropdown() {
		return PREFILLED_MESSAGES.map(message => ({
			value: message,
			label: message,
		}));
	}

	uniqueContacts() {
		const { received } = this.state;

		const unique = [];

		for (const contact of received) {
			if (unique.includes(contact.from)) {
				continue;
			}

			unique.push(contact.from);
		}

		return unique;
	}

	render() {
		console.log('Re rendering');
		return (
			<div className="Page SMSResponsePage">
				<div className="contacts">
					{
						this.uniqueContacts().map(number => (
							<div onClick={() => this.selectUser(number)}>
								{this.getContactName(number)} {number} <span>{this.isWaitingReply(number) ? '📧' : ''}</span>
							</div>
						))
					}
				</div>
				<div className="chatbox">
					<div className="messages">
						{
							this.getRelevantMessagesForNumber(this.state.selectedNumber).map(message => {
								const classNames = ["message"];
								// ordered by date
								if (message.from === this.state.selectedNumber) {
									classNames.push("sent");
								} else {
									classNames.push("received");
								}

								return (
									<div className={classNames.join(' ')}>
										<p>{message.body}</p>
										<p>{Moment(message.dateCreated).format('lll')}</p>

									</div>
								);
							})
						}
						<div ref={this.messagesEndRef} />
					</div>
					<div className="textInput">
						<form onSubmit={this.onSubmitMessage}>
							<Textarea
								value={this.state.currentMessage}
								onChange={(e) => this.updateCurrentMessage(e.target.value)}
								placeholder="Be nice..."
							/>
							<button> Send </button>
						</form>
						<p> Message length: {this.state.currentMessage.length} </p>
						<Autocomplete
							options={this.formatPrefillDropdown()}
							clearable
							placeholder="Choose a prefilled message..."
							value={null}
							onChange={this.onChoosePrefill}
						/>
					</div>
				</div>
			</div>
		);
	}
}
