import React, { Component } from 'react';

import Moment from 'moment';

import TableModel from '../_TableModel';

import './style.less';
import UserLabel from '../../labels/UserLabel';

export default class AlternativeTimesTable extends TableModel {
	constructor() {
		super();

		this.className = "AlternativeTimesTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'Event ID',
			searchable: true,
			sortable: true,
			description: "Event ID"
		}, {
			title: 'Suggested by',
			type: Object,
			sortable: true,
			searchable: true,
			description: "Suggested by"
		}, {
			title: 'New date & time',
			type: Date,
			sortable: true,
			searchable: true,
			description: "Date"
		}, {
			title: 'Suggested at',
			type: Date,
			sortable: true,
			searchable: false,
			description: "Suggested at"
		}, {
			title: '',
			type: Object,
			sortable: false,
			searchable: false,
			description: "Delete"
		}]
	}

	dataForRows(data) {
		if (!data) return;

		let rows = [];

		// data is an array of other time suggested events
		data.forEach(event => {
			let columns = [
				event.id,

				// customer or pro or admin?
				<div className="note">
					{
						event.user && event.user.id
							? <UserLabel user={event.user} fetch={false} />
							: <UserLabel id={event.user_id} fetch={true} />
					}
				</div>,

				Moment(event.metadata.datetime).unix(),

				Moment(event.created_at).unix(),

				<a onClick={() => this.props.onDeleteAlternativeTime(event.id)}>
					Delete
				</a>
			];

			rows.push(columns);
		})

		return rows;
	}
}