import React, { Component } from 'react';
import { Link } from 'react-router';

import {
	Table, TBody, TRow, TCol,
} from '../../tables/Generic';
import GenericModal from '../../modals/Generic';
import UpdateImageCard from "../UpdateImageCard";

import LeModel from './model';

const PortfolioItem = ({
	selected, item, onClickDelete, onClickImage,
}) => (
	<TRow className={selected ? "PortfolioPictureContainerSelected" : "PortfolioPictureContainer"}>
		<img onClick={onClickImage} className="PortfolioPicture" src={item.image_url} />
		<img className="BinIcon" src="/images/icons/trash.jpg" onClick={() => onClickDelete(item)} />
	</TRow>
);

export default class LeView extends LeModel {
	constructor(props) {
		super(props);
	}

	render() {
		const portfolioItems = this.state.portfolio_items || [];
		const selected = this.state.selectedItemIds;
		const swapButtonActive = this.state.selectedItemIds.length === 2;

		return (
			<div className="Card ProfessionalPortfolioCard">
				<Table>
					<TBody>
						{portfolioItems.map(item => (
							<PortfolioItem
								key={item.id}
								item={item}
								onClickDelete={this.deletePortfolioPic}
								selected={selected.includes(item.id)}
								onClickImage={() => { this.toggleSelectItem(item.id); }}
							/>
						))}
					</TBody>
				</Table>
				<button onClick={() => { this.showModal(); }}>
					Add new image
				</button>
				<button onClick={this.swapImageOrders} disabled={!swapButtonActive}>
					Swap selected images
				</button>
				<GenericModal className="AddImageModal" show={this.state.showModal} onHide={() => { this.hideModal(); }}>
					<UpdateImageCard
						className="UpdateImageCard"
						width={1000}
						height={625}
						onImageUpdate={this.addPortfolioPic}
					/>
				</GenericModal>
			</div>
		);
	}
}
