import React from 'react';
import {
	Router, Route, IndexRoute, browserHistory,
} from 'react-router';
import Alert from 'react-s-alert';

import CoreUI from './components/CoreUI';
import AuthenticatedSection from './components/Authenticated';
import LoginPage from './components/pages/Login';

import DashboardPage from './components/pages/Dashboard';
import AppointmentsPage from './components/pages/Appointments';
import NewAppointmentPage from './components/pages/NewAppointment';
import AppointmentPage from './components/pages/Appointment';
import CustomersPage from './components/pages/Customers';
import ProfessionalsPage from './components/pages/Professionals';
import StorefrontsPage from './components/pages/Storefronts';
import UsersPage from './components/pages/Users';
import UserPage from './components/pages/User';
import CardsPage from './components/pages/Cards';
import CardPage from './components/pages/Card';
import SchedulePage from './components/pages/Schedule';
import VouchersPage from './components/pages/Vouchers';
import VoucherPage from './components/pages/Voucher';
import ZonesPage from './components/pages/Zones';
import ZonePage from './components/pages/Zone';
import ServicesPage from './components/pages/Services';
import ServicePage from './components/pages/Service';
import PaymentDetailsPage from './components/pages/PaymentDetails';
import CategoryPage from './components/pages/Category';
import CategoriesPage from './components/pages/Categories';
import PaymentsPage from './components/pages/Payments';
import GiftsPage from './components/pages/Gifts';
import GiftPage from './components/pages/Gift';
import SprintPage from './components/pages/Sprint';
import LocationPage from './components/pages/Location';
import SearchesPage from './components/pages/Searches';
import SearchPage from './components/pages/Search';
import VoucherMapPage from './components/pages/VoucherMap';
import SMSResponsePage from './components/pages/SMSResponse';
import UserWebsitesPage from './components/pages/UserWebsite';

export default function Routes() {
	return (
		<div>
			<Router history={browserHistory}>
				<Route component={CoreUI}>
					<Route name="Dashboard" path="/" component={AuthenticatedSection}>
						<IndexRoute component={DashboardPage} />

						<Route name="Appointments" path="appointments">
							<IndexRoute component={AppointmentsPage} />
							<Route name="New" path="new" component={NewAppointmentPage} />
							<Route path=":id" component={AppointmentPage} />
						</Route>

						<Route name="Customers" path="customers">
							<IndexRoute component={CustomersPage} />
							<Route path=":id" component={UserPage} />
						</Route>

						<Route name="Professionals" path="professionals">
							<IndexRoute component={ProfessionalsPage} />
							<Route path=":id" component={UserPage} />
							<Route name="Manage Website" path=":id/website" component={UserWebsitesPage} />
						</Route>

						<Route name="Storefronts" path="storefronts">
							<IndexRoute component={StorefrontsPage} />
							<Route path=":id" component={UserPage} />
							<Route name="Manage Website" path=":id/website" component={UserWebsitesPage} />
						</Route>

						<Route name="Schedule" path="schedule">
							<IndexRoute component={SchedulePage} />
						</Route>

						<Route name="Users" path="users">
							<IndexRoute component={UsersPage} />
							<Route path=":id" component={UserPage} />
							<Route name="Manage Website" path=":id/website" component={UserWebsitesPage} />
						</Route>

						<Route name="Credit Cards" path="cards">
							<IndexRoute component={CardsPage} />
							<Route path=":id" component={CardPage} />
						</Route>

						<Route name="Vouchers" path="vouchers">
							<IndexRoute component={VouchersPage} />
							<Route path=":id" component={VoucherPage} />
						</Route>

						<Route name="Zones" path="zones">
							<IndexRoute component={ZonesPage} />
							<Route path=":id" component={ZonePage} />
						</Route>

						<Route name="Services" path="services">
							<IndexRoute component={ServicesPage} />
							<Route path=":id" component={ServicePage} />
						</Route>

						<Route name="Categories" path="categories">
							<IndexRoute component={CategoriesPage} />
							<Route path=":id" component={CategoryPage} />
						</Route>

						<Route name="Payments" path="payments">
							<IndexRoute component={PaymentsPage} />
							<Route path=":id" component={PaymentDetailsPage} />
						</Route>

						<Route name="Locations" path="locations">
							<IndexRoute component={LocationPage} />
							<Route path=":id" component={LocationPage} />
						</Route>

						<Route name="Referrals" path="referrals">
							<IndexRoute component={VoucherMapPage} />
						</Route>

						<Route name="Gifts" path="gifts">
							<IndexRoute component={GiftsPage} />
							<Route path=":id" component={GiftPage} />
						</Route>

						<Route name="Sprint" path="sprint">
							<IndexRoute component={SprintPage} />
						</Route>

						<Route name="Professional Searches" path="searches">
							<IndexRoute component={SearchesPage} />
							<Route path=":id" component={SearchPage} />
						</Route>

					</Route>

					<Route name="Login" path="login" component={LoginPage} />
				</Route>

				<Route name="SMS Response" path="sms-response">
					<IndexRoute component={SMSResponsePage} />
				</Route>
			</Router>
			<Alert stack={{ limit: 3 }} />
		</div>
	);
}
