import React, { Component } from 'react';

import PageModel from '../_PageModel';

import Table from '../../tables/Vouchers';
import CreateResource from '../../buttons/CreateResource';
import Title from '../../misc/Title';

import './style.less';

export default class VouchersPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);

		this.state = {
			vouchers: []
		}
	}

	componentDidMount() {
		this.api.request("get", `/vouchers?where={"user_id":null}`, null, data => {
			this.setState({
				vouchers: [
					...this.state.vouchers,
					...data
				]
			})
		});
	}

	onUpdate(data) {
		let vouchers = this.state.vouchers;

		if (data.channel) data.channel = data.channel.toUpperCase().trim()

		//add newly created voucher to vouchers list.
		vouchers.push(data);

		this.setState({
			vouchers: vouchers
		})
	}

	render() {
		return (
			<div className="Page VouchersPage">
				<Title h1 text="Vouchers">
					<CreateResource
						title={"New Voucher"}
						resource={"vouchers"}
						className={"Voucher"}
						onNewData={this.onUpdate} />
				</Title>
				<div className="Card">
					<Table
						data={this.state.vouchers}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</div>
		);
	}
}