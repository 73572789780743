import React, { Component } from 'react';

import { Table, TBody, TRow, TCol } from '../../tables/Generic';

import './style.less';

export default class UserPreferencesCard extends Component {
	constructor() {
		super();
	}

	render() {
		const { instant_book_enabled, marketing_allowed, push_allowed } = this.props;

		return (
			<div className="Card UserPreferencesCard">
				<Table>
					<TBody>
						<TRow key="instant_book">
							<TCol>Instant Book</TCol>
							<TCol>{instant_book_enabled ? "On" : "Off"}</TCol>
						</TRow>
						<TRow key="push_allowed">
							<TCol>Push Notifications</TCol>
							<TCol>{push_allowed ? "On" : "Off"}</TCol>
						</TRow>
						<TRow key="marketing_allowed">
							<TCol>Marketing Allowed</TCol>
							<TCol>{marketing_allowed ? "Yes" : "No"}</TCol>
						</TRow>
					</TBody>
				</Table>
			</div>
		)
	}
}