import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabelModel from '../_LabelModel';

import './style.less';

export default class ServicesLabel extends LabelModel {
	componentDidMount() {
		this._mounted = true;
	}

	render() {
		let services = this.props.services || [];

		if (!services.length) return <i>None</i>;

		return (
			<div className="Label Services">
				{services.map(s => {
					if (!s.service) return null;

					let service = `${s.quantity}x ${s.service.option}`;

					return <div key={s.id}>
						<i className="emoji">💅</i>
						<span>{service}</span>
					</div>
				})}
			</div>
		)
	}
}

ServicesLabel.propTypes = {
	services: PropTypes.array
}