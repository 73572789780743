import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import DataModel from '../../_DataModel';
import Notification from '../../misc/Notification';
import GenericModal from '../Generic';
import UserLabel from '../../labels/UserLabel';
import Title from '../../misc/Title';
import {
	Table, THead, TBody, TRow, TCol,
} from '../../tables/Generic';

import './style.less';

export default class ReviewDetailsModal extends DataModel {
	constructor() {
		super();

		this.state = {};

		this.notification = new Notification();
	}

	componentDidMount() {

	}

	componentWillReceiveProps(nextProps) {

	}

	async toggleIgnore(review) {
		if (!review) return null;

		const { id, ignore } = review;

		const route = `/appointment_review_answers/${id}`;

		const { onReviewUpdate } = this.props;

		try {
			const result = await this.api.request(
				'PUT',
				`${route}`,
				{ ignore: !ignore },
			);

			if (!result.success) throw new Error(result.error);

			onReviewUpdate && onReviewUpdate({
				...review,
				ignore: result.data.ignore,
			});

			if (result.data.ignore) {
				this.notification.success(`Success! This review will not affect the other party's rating.`);
			} else {
				this.notification.success(`Done. This review won't be ignored any more.`);
			}
		} catch (error) {
			this.notification.error(error.message);
		}
	}

	findOverallRating(answers) {
		return answers.find(answer => {
			switch (answer.question.key) {
				case 'CUSTOMER_OVERALL':
				case 'SALONETTE_OVERALL':
					return answer;
			}
		});
	}

	render() {
		const { answers } = this.props;

		const rating = this.findOverallRating(answers);

		return (
			<GenericModal className="ReviewDetails" show={this.props.show} onHide={this.props.onHide}>
				<Title h1 text="Review Details">
					<button onClick={() => this.toggleIgnore(rating)}>
						{rating.ignore ? 'Undo ignore' : 'Ignore'}
					</button>
				</Title>
				<div className="Card info">
					<UserLabel id={rating.user_id} fetch />
				</div>
				<Table>
					<THead>
						<TRow>
							<TCol>
								Question
							</TCol>
							<TCol>
								Answer
							</TCol>
						</TRow>
					</THead>
					<TBody>
						{answers.filter(a => a.id !== rating.id).map(answer => {
							const question = (
								answer.question.text
									.replace('{{', '<span class="placeholder">')
									.replace('}}', '</span>')
							);

							return (
								<TRow key={answer.id}>
									<TCol dangerouslySetInnerHTML={{ __html: question }} />
									<TCol>
										{answer.option.text}
									</TCol>
								</TRow>
							);
						})}
					</TBody>
				</Table>
			</GenericModal>
		);
	}
}

ReviewDetailsModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	answers: PropTypes.any.isRequired,
	onReviewUpdate: PropTypes.func.isRequired,
};
