import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import FormModel from '../_FormModel';

export default class AppointmentDescription extends FormModel {
	constructor() {
		super();

		this.state = {
			text: ""
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		let appointment = this.props.appointment || {};

		this.setText(appointment.details);
	}

	componentWillReceiveProps(next) {
		let appointment = next.appointment || {};

		this.setText(appointment.details);
	}

	handleChange(event) {
		this.setText(event.target.value);
	}

	setText(text) {
		text = text || "";

		this.setState({
			text: text
		});
	}

	handleSubmit(event) {
		event.preventDefault();

		let appointment = this.props.appointment;

		if (!appointment) return;

		let text = this.state.text;
		if (!text.length) text = null;

		appointment.details = text;

		this.api.request('put', '/appointments/' + appointment.id, {
			details: appointment.details
		}).then(result => {
			if (!result.success) throw new Error(result.error);

			this.notification.success("Done! Your changes are now live.");

			if (this.props.onUpdate) this.props.onUpdate(appointment);

		}).catch(error => {
			this.notification.error(error.message);
		})
	}

	render() {
		return (
			<form className="AppointmentDescription" onSubmit={this.handleSubmit}>
				<Textarea value={this.state.text} onChange={this.handleChange} />
				<button type="submit">
					Save
				</button>
			</form>
		)
	}
}

AppointmentDescription.propTypes = {
	appointment: PropTypes.object,
	onUpdate: PropTypes.func
}