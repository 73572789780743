import React, { Component } from 'react';

import TableModel from '../_TableModel';

import UserLabel from '../../labels/UserLabel';

import './style.less';

export default class AppointmentTimelineTable extends TableModel {
	constructor() {
		super();

		this.className = "AppointmentTimelineTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true
		}, {
			title: 'Date',
			type: Date,
			sortable: true,
			searchable: true,
			description: "Date"
		}, {
			title: 'User'
		}, {
			title: 'Action',
			type: String,
			sortable: true,
			searchable: true
		}, {
			title: 'Metadata'
		}]

		//special formatting
		this.PRETTY_EVENTS = {
			'PLACED': 'Placed booking',
			'WAS_OFFERED': 'Was offered booking',
			'OTHER_TIME_SUGGESTED': 'Offered another time',
			'OTHER_TIME_APPROVED': 'Approved other time',
			'CANCELLED': 'Cancelled booking'
		}
	}

	dataForRows(data) {
		if (!data) return;

		const { prettyEvents } = this.props;

		return data.map(event => {
			//if possible, beautify the action.
			const label = event.name.label;

			let action = label;

			//has there been a custom action set?
			if (this.PRETTY_EVENTS[label]) {
				action = this.PRETTY_EVENTS[label];
			} else {
				//by default, lowercase the label
				action = action.toLowerCase();
				//replace _ with a space
				action = action.replace(/_/g, ' ');
				//also capitalize the first letter
				action = action.charAt(0).toUpperCase() + action.slice(1);
			}

			//METADATA
			//copy metadata to a non-persistent object (because we might modify it).
			let metadata_display = {
				...event.metadata
			}

			if (metadata_display.datetime) {
				metadata_display.datetime = this.moment(metadata_display.datetime).format('DD/MM HH:mm:ss');
			}

			let metadata_formatted = JSON.stringify(metadata_display, null, 2);

			//if there is one thing in the metadata (or it's empty)...
			//collapse the object to only take up one line.
			if (Object.keys(metadata_display) <= 1) {
				metadata_formatted = metadata_formatted.replace(/\n/g, ' ').replace(/\s\s+/g, ' ');
			}

			return [
				event.id,
				this.moment(event.created_at).unix(),
				<UserLabel user={event.user} shortName />,
				action,
				(
					Object.keys(metadata_display).length
						? <pre>
							{metadata_formatted}
						</pre>
						: <i>None</i>
				)
			];
		})
	}
}
