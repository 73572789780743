import React from 'react';

import { Table, TBody, TRow, TCol } from '../../tables/Generic';

import LeModel from './model';

export default class LeView extends LeModel {
	constructor(props){
		super(props);
	}

	render(){
		const full_list = this.state.full_list || [];
		const selected_list = this.state.selected_list || {};
		let rows = [];

		full_list.forEach(item => {
			const isSelected = !!selected_list[item.id] //convert to boolean

			rows.push(
				<TRow key={item.id} className={isSelected ? 'selected' : null} >
					<TCol>{item.title}</TCol>
					<TCol><input type="checkbox" checked={isSelected} onChange={() => { this.toggle(isSelected, item) }} /></TCol>
				</TRow>
			)
		})

		return (
			<div className="Card ProfessionalBadgesCard CheckboxListCard">
				<Table>
					<TBody>
						{rows}
					</TBody>
				</Table>
			</div>
		)
	}
}