import _ from 'lodash';
import React, { Component, Fragment } from 'react';

import TableModel from '../_TableModel';

import Avatar from '../../misc/Avatar';

import './style.less';

export default class StorefrontsTable extends TableModel {
	constructor() {
		super();

		this.className = "StorefrontsTable";

		this.UNIQUE_INDEX = 1;

		this.titles = [{
			title: '',
			searchable: false,
			sortable: false,
			description: "Avatar",
		}, {
			title: 'ID',
			type: Number,
			searchable: false,
			sortable: true,
			description: "Professional ID",
		}, {
			title: 'Name',
			type: String,
			searchable: true,
			sortable: true,
		}, {
			title: 'Email',
			type: String,
			searchable: true,
			sortable: true,
			description: "Email Address",
		}, {
			title: 'Phone',
			type: String,
			searchable: true,
			sortable: true,
			description: "Phone Number",
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const professional = datum;

			const {
				id,
				avatar,
				first_name,
				last_name,
				email,
				phone_number,
			} = professional;

			const avatarColumn = (
				<Fragment>
					<Avatar url={avatar} />
				</Fragment>
			);

			const columns = [
				avatarColumn,
				id,
				`${first_name} ${last_name}`,
				email,
				phone_number,
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/storefronts/' + data[1];
	}
}
