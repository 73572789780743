import React, { Component } from 'react';
import { Link } from 'react-router';

import './style.less';

export default class DashboardPage extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<div className="Page DashboardPage">
				<Link to="/appointments">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1522.png" />
							<span className="title">Booking Log</span>
						</div>
					</div>
				</Link>

				<Link to="/customers">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1373.png" />
							<span className="title">Customer List</span>
						</div>
					</div>
				</Link>

				<Link to="/professionals">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1394.png" />
							<span className="title">Salonette List</span>
						</div>
					</div>
				</Link>

				<Link to="/storefronts">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1223.png" />
							<span className="title">Storefront List</span>
						</div>
					</div>
				</Link>

				<Link to="/schedule">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1716.png" />
							<span className="title">Schedule</span>
						</div>
					</div>
				</Link>

				<Link to="/payments">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/174.png" />
							<span className="title">Payments</span>
						</div>
					</div>
				</Link>

				<Link to="/vouchers">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1488.png" />
							<span className="title">Vouchers</span>
						</div>
					</div>
				</Link>

				<Link to="/zones">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1725.png" />
							<span className="title">Zones</span>
						</div>
					</div>
				</Link>

				<Link to="/services">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1401.png" />
							<span className="title">Services</span>
						</div>
					</div>
				</Link>

				<Link to="/categories">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1712.png" />
							<span className="title">Categories</span>
						</div>
					</div>
				</Link>

				<Link to="/gifts">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/1456.png" />
							<span className="title">Gifts</span>
						</div>
					</div>
				</Link>

				<Link to="/referrals">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/544.png" />
							<span className="title">Referrals Graph</span>
						</div>
					</div>
				</Link>

				<Link to="/sprint">
					<div className="module">
						<div className="inner">
							<img className="emoji" src="/images/emoji/688.png" />
							<span className="title">Sprint</span>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}
