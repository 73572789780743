import PropTypes from 'prop-types';

import LeView from './view';

import './style.less';

export default class Basket extends LeView {
	constructor(props) {
		super(props);
	}
}

Basket.propTypes = {
	// all data should be in key:value format( {id: service} )
	services: PropTypes.object.isRequired,

	basket: PropTypes.array,
	onBasketChange: PropTypes.func.isRequired,
};
