import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.less';

export default class Avatar extends Component {
	render() {
		const { url, square } = this.props;

		return (
			<div className={"Avatar" + (url ? " borderless" : "") + (square ? " square" : "")}>
				{
					this.props.url
					&& <img src={this.props.url} />
				}
			</div>
		);
	}
}

Avatar.propTypes = {
	url: PropTypes.any,
};
