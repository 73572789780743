export default function (booking) {
	switch (booking.type) {
		case 'HOME':
		case 'STOREFRONT':
			let minutes = 0;

			const { services } = booking;

			if (!services) return minutes;
			if (!services.length) return minutes;

			booking.services.forEach(s => {
				minutes += (s.service.duration * s.quantity);
			});

			return minutes;
		case 'CORPORATE':
			const { corporate_metadata } = booking;

			if (!corporate_metadata) return 0;

			const { duration } = corporate_metadata;

			return duration || 0;
		case 'PLATFORM':
			const { platform_metadata } = booking;

			if (!platform_metadata) return 0;

			return platform_metadata.duration || 0;
		default:
			return 0;
	}
}
