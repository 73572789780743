import React, { Component } from 'react';

import TableModel from '../_TableModel';

import './style.less';

export default class SearchResultsTable extends TableModel {
	constructor() {
		super();

		this.className = "SearchResultsTable";

		this.UNIQUE_INDEX = 0;

		this.titles = [{
			title: 'ID',
			type: Number,
			sortable: true,
			searchable: true,
			description: "Search Result ID",
		}, {
			title: 'Professional',
			type: String,
			sortable: true,
			searchable: true,
			description: "Professional",
		}, {
			title: 'Eligibility',
			type: String,
			searchable: false,
			sortable: false,
			description: "Eligibility",
		}, {
			title: 'Excluded',
			type: String,
			sortable: true,
			searchable: true,
			description: "Excluded",
		}];
	}

	dataForRows(data) {
		if (!data) return;

		const rows = [];

		data.forEach(datum => {
			const search_result = datum;
			const { professional } = search_result;

			const columns = [
				search_result.id,
				professional && professional.id ? `${professional.first_name} ${professional.last_name}` : null,
				JSON.stringify(search_result.eligibility),
				search_result.excluded ? 'Yes' : 'No',
			];

			rows.push(columns);
		});

		return rows;
	}

	hyperlink(data) {
		return '/searches/' + data[0];
	}
}
