import React from 'react';

import PageModel from './_PageModel';

import Table from '../tables/Zones';
import CreateResource from '../buttons/CreateResource';
import Title from '../misc/Title';

export default class VouchersPage extends PageModel {
	constructor() {
		super();

		this.onUpdate = this.onUpdate.bind(this);
	}

	componentDidMount() {
		this.api.request("get", "/zones").then(result => {
			if (result.success) {
				this.setState({
					zones: result.data
				})
			}
		})
	}

	onUpdate(data) {
		let zones = this.state.zones;

		//add newly created zone to zones list.
		zones.push(data);

		this.setState({
			zones: zones
		})
	}

	render() {
		const zones = this.state.zones;

		return (
			<div className="Page ZonesPage">
				<Title h1 text="Zones">
					<CreateResource
						title={"New Zone"}
						resource={"zones"}
						className={"Zone"}
						onNewData={this.onUpdate} />
				</Title>
				<div className="Card">
					<Table
						data={zones}
						infinite={true}
						interactive={true}
						filterable={true}
					/>
				</div>
			</div>
		);
	}
}